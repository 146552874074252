import React, { useCallback, useMemo, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { DataSourceTableProps } from 'types/data-source-table-types'
import { styled } from '@mui/material'
// import { CUSTOM_COLORS } from 'theme'
import CustomPagination from './molecules/pagination'
import CustomNoRowsOverlay from './molecules/CustomNoRowsOverlay'

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .super-app-theme--DISABLED': {
    // opacity: 0.5,
    backgroundColor: '#F1C0C0 !important',
  },
  '& .super-app-theme--INACTIVE': {
    // opacity: 0.5,
    backgroundColor: '#F1C0C0 !important',
  },
  // [`& .${gridClasses.row}.even`]: {
  //   backgroundColor: CUSTOM_COLORS.backgroundColor,
  // },
}))

const DataSourceTable = ({
  rows,
  columns,
  getSelectedRows,
  rowHeight,
  handlePageChange,
  checkboxSelection = true,
  disableRowSelectionOnClick = false,
}: DataSourceTableProps) => {
  const [selectedRows, setSelectedRows] = useState([])

  const handleSelectionModelChange = useCallback((newSelection: any) => {
    setSelectedRows(newSelection)
    getSelectedRows?.(newSelection)
  }, [])

  const getRowClassName = useMemo(
    () => (params: any) => {
      const statusClass = `super-app-theme--${params.row.status}`
      const evenOddClass =
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'

      return `${evenOddClass} ${statusClass}`
    },
    [],
  )

  return (
    <div
      style={{
        height: rows?.length > 0 ? '65%' : 300,
        marginTop: '37px',
        marginRight: '20px',
      }}
    >
      <StyledDataGrid
        rows={rows || []}
        columns={columns}
        pagination
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: CustomPagination,
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 1, pageSize: 10 },
          },
        }}
        pageSizeOptions={[1, 5, 10, 20]}
        checkboxSelection={checkboxSelection}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={handleSelectionModelChange}
        getRowClassName={getRowClassName}
        isRowSelectable={(params) =>
          params.row?.status !== 'DISABLED' &&
          params.row?.status !== 'INACTIVE' &&
          !params.row?.is_none_modifyable_role
        }
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        getRowHeight={rowHeight}
        onPaginationModelChange={(newPageParams) =>
          handlePageChange?.(newPageParams)
        }
        // autoHeight
        // hideFooter
      />
    </div>
  )
}

export default DataSourceTable
