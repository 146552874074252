/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { DataSourceTableProps } from 'types/data-source-table-types'
import {
  styled,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
  CardContent,
  Card,
  Divider,
  ListItem,
  ListItemText,
  List,
  AvatarGroup,
  Tooltip,
  Avatar,
  Tabs,
  Tab,
  Drawer,
  Chip,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CustomNoRowsOverlay from 'components/molecules/CustomNoRowsOverlay'
import CustomPagination from 'components/molecules/pagination'

import {
  Close as CloseIcon,
  AccessTime,
  AccountCircle,
} from '@mui/icons-material'
import { removeToken, removeProfileInfo } from 'api/apiUtility'
import {
  getProjectMemberRoles,
  getProjectRoleDetails,
  getProjectActivityLog,
} from 'api/services/api-services'
import { useNavigate } from 'react-router-dom'
const StyledDataGrid = styled(DataGrid)(() => ({
  '& .super-app-theme--DISABLED': {
    backgroundColor: '#F1C0C0 !important',
  },
  '& .super-app-theme--INACTIVE': {
    backgroundColor: '#F1C0C0 !important',
  },
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
}))
const renderChips = (permissions: any) => {
  const chips = []

  for (const section in permissions) {
    // Add a title for the section
    chips.push(
      <Typography
        key={`${section}-title`}
        variant="subtitle2"
        sx={{ marginBottom: 0.5, fontWeight: 'bold' }}
      >
        {section.replace(/_/g, ' ').toUpperCase()}{' '}
        {/* Converts section name to a readable format */}
      </Typography>,
    )

    // Create chips for each permission
    for (const [key, value] of Object.entries(permissions[section])) {
      if (value) {
        // Only show chips for true permissions
        chips.push(
          <Chip
            key={`${section}-${key}`}
            label={key.replace(/_/g, ' ')} // Converts key to a readable format
            sx={{
              marginRight: 0.5,
              marginBottom: 0.5,
              fontWeight: '600',
              color: '#5C6BC0',
              background: 'transparent',
              border: '1px solid #5C6BC0',
              fontSize: '10px', // Smaller font size
              padding: '1px 2px', // Smaller padding for the chip
            }}
          />,
        )
      }
    }
  }
  return chips
}
const ProjectListTable = ({
  rows,
  columns,
  getSelectedRows,
  rowHeight,
  handlePageChange,
  checkboxSelection = true,
  disableRowSelectionOnClick = false,
}: DataSourceTableProps) => {
  const [selectedRows, setSelectedRows] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedRowId, setSelectedRowId] = useState(null)
  const navigate = useNavigate()
  const [userProjectRoleDetails, setUserprojectRoleDetails] = useState<any>([])
  const [activityDetails, setActivityDetails] = useState<any>([])
  const [projectDetails, setProjectDetails] = useState<any>([])
  const [tabIndex, setTabIndex] = React.useState(0)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const handleMoreVertClick = (event: any, rowId: any) => {
    setAnchorEl(event.currentTarget)
    setSelectedRowId(rowId)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedRowId(null)
  }

  const handleSelectionModelChange = useCallback((newSelection: any) => {
    setSelectedRows(newSelection)
    getSelectedRows?.(newSelection)
  }, [])

  const getRowClassName = useMemo(
    () => (params: any) => {
      const statusClass = `super-app-theme--${params.row.status}`
      const evenOddClass =
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      return `${evenOddClass} ${statusClass}`
    },
    [],
  )

  const getProjectDetails = async (id: string) => {
    try {
      const response = await getProjectMemberRoles(id)
      setProjectDetails(response.data)
    } catch (error: any) {
      console.error('Error fetching configuration:', error)
    }
  }

  const getProjectaccessDetails = async (id: string) => {
    try {
      const response = await getProjectRoleDetails(id)
      setUserprojectRoleDetails(response.data)
    } catch (error: any) {
      console.error('Error fetching configuration:', error)
    }
  }

  const getProjectActivityDetails = async (id: string) => {
    try {
      const response = await getProjectActivityLog({ project_id: id })
      setActivityDetails(response.data)
    } catch (error: any) {
      console.error('Error fetching configuration:', error)
    }
  }
  // Add a custom column for the More Vert icon
  const columnsWithActions = [
    ...columns,
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params: any) => (
        <>
          <IconButton
            aria-controls={`menu-${params.id}`}
            aria-haspopup="true"
            onClick={(e: any) => {
              e.stopPropagation()
              handleMoreVertClick(e, params.id)
            }}
          >
            <MoreVertIcon />
          </IconButton>
          {/* Conditionally render the menu */}
          {selectedRowId === params.id && (
            <Menu
              id={`menu-${params.id}`}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Edit</MenuItem>
              <MenuItem onClick={handleClose}>Delete</MenuItem>
              <MenuItem
                onClick={() => {
                  getProjectDetails(params.id)
                  getProjectActivityDetails(params.id)
                  getProjectaccessDetails(params.id)
                  setAnchorEl(null)
                  setDrawerOpen(true)
                }}
              >
                Details
              </MenuItem>
            </Menu>
          )}
        </>
      ),
    },
  ]

  return (
    <div style={{ height: rows?.length > 0 ? '65%' : 300 }}>
      <StyledDataGrid
        rows={rows || []}
        columns={columnsWithActions}
        pagination
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: CustomPagination,
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 1, pageSize: 10 },
          },
        }}
        pageSizeOptions={[1, 5, 10, 20]}
        checkboxSelection={checkboxSelection}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={handleSelectionModelChange}
        getRowClassName={getRowClassName}
        isRowSelectable={(params) =>
          params.row?.status !== 'DISABLED' &&
          params.row?.status !== 'INACTIVE' &&
          !params.row?.is_none_modifyable_role
        }
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        getRowHeight={rowHeight}
        onPaginationModelChange={(newPageParams) =>
          handlePageChange?.(newPageParams)
        }
      />
      <Drawer
        anchor="right"
        sx={{ zIndex: 300, width: '40%' }}
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false)
        }}
      >
        <Box sx={{ width: '500px', padding: 2, marginTop: '80px' }}>
          {/* Header with Close Icon and Project Name */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" fontWeight="600">
              {userProjectRoleDetails?.projectName || 'Project Name'}
            </Typography>
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Tabs */}
          <Tabs value={tabIndex} onChange={handleTabChange} sx={{ marginY: 2 }}>
            <Tab label="Details" sx={{ flex: 1, fontWeight: '600' }} />
            <Tab label="Activity" sx={{ flex: 1, fontWeight: '600' }} />
          </Tabs>

          {tabIndex === 0 && (
            <Box sx={{ padding: 2 }}>
              {/* Avatar Group for Users */}
              <Typography variant="h6" gutterBottom>
                Users
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginLeft: '20px',
                }}
              >
                <AvatarGroup max={10}>
                  {projectDetails &&
                    projectDetails.map((user: any, index: any) => (
                      <Tooltip title={user.userFullName} arrow key={index}>
                        <Avatar
                          sx={{
                            fontSize: '12px',
                            width: '35px',
                            height: '35px',
                            background: '#757575',
                            border: '2px solid #000',

                            color: '#000',
                            fontWeight: '600',
                          }}
                        >
                          {user.userFullName
                            .split(' ')
                            .map((name: string) => name.charAt(0))
                            .join('')}
                        </Avatar>
                      </Tooltip>
                    ))}
                </AvatarGroup>
              </Box>

              {/* File Details */}
              <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                File Details
              </Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Project Name"
                    secondary={userProjectRoleDetails?.projectName || 'NA'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Owner"
                    secondary={
                      (activityDetails?.data?.length > 0 &&
                        activityDetails?.data[activityDetails?.data.length - 1]
                          ?.user_name) ||
                      'NA'
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="First Created"
                    secondary={
                      (activityDetails?.data?.length > 0 &&
                        activityDetails?.data[activityDetails?.data.length - 1]
                          ?.date_time.date) ||
                      'NA'
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Last Modified"
                    secondary={new Date(
                      activityDetails?.data?.length > 0 &&
                        activityDetails?.data[0]?.date_time.date,
                    ).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    })}
                  />
                </ListItem>
              </List>

              {/* Access Chips */}
              <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                Your Access
              </Typography>
              {renderChips(userProjectRoleDetails?.role?.permissions)}
            </Box>
          )}

          {tabIndex === 1 && (
            <Box>
              {/* Custom Activity Timeline */}
              <Typography variant="h6" gutterBottom>
                Version History
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginLeft: '10px',
                }}
              >
                {activityDetails?.data?.map((activity: any, index: any) => (
                  <Box
                    key={index}
                    sx={{ display: 'flex', alignItems: 'flex-start' }}
                  >
                    {/* Timeline Line */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <AccountCircle sx={{ color: '#0056b3' }} />
                      {index < activityDetails.data.length - 1 && (
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            bgcolor: '#0056b3',
                            width: '2px',
                            minHeight: '80px',
                          }}
                        />
                      )}
                    </Box>

                    {/* Card Content */}
                    <Card
                      sx={{
                        width: '380px',
                        boxShadow: 'none',
                      }}
                    >
                      <CardContent>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {activity.user_name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {activity.action} on {activity.component_name} (
                          {activity.component_type})
                        </Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                          <AccessTime
                            sx={{
                              fontSize: '16px',
                              marginRight: '4px',
                              color: '#888',
                            }}
                          />
                          <Typography variant="caption" color="textSecondary">
                            {activity.date_time.date} —{' '}
                            {activity.date_time.time}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
    </div>
  )
}

export default ProjectListTable
