import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { getProfileInfo } from 'api/apiUtility'
import { setDataRepo } from 'store/dataSourceTypesSlice'

export const getAllDataSourceTypes = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.get(
      `/data_repository_types/?pageNumber=${projectContent.page}&pageSize=${projectContent.size}&status=ACTIVE`,
    )

    const data: any = response.data
    return data
  },
)

export const getProjectsAndApps = (params: any) => {
  return axiosInstance.post(`/project/_search`, params.search)
}

export const getScopeTemplate = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.get(
      `/role_permissions/default_template?scope=${projectContent.id}`,
    )

    const data: any = response.data
    return data
  },
)

export const getOrgDefaultRole = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.get(
      `/role_permissions/default_role_details?scope=${projectContent.id}`,
    )

    const data: any = response.data
    return data
  },
)

export const getAllUserRoles = createAsyncThunk(
  'project',
  async (projectContent: any, { dispatch }) => {
    const response: any = await axiosInstance.post(
      `/role_permissions/_search?pageNumber=${projectContent.page}&pageSize=${projectContent.size}`,
      projectContent.search,
    )

    const data: any = response.data
    dispatch(setDataRepo(data))
    return data
  },
)

export const searchUserRoles = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.post(
      `/role_permissions/_search?pageNumber=${projectContent.page}&pageSize=${projectContent.size}`,
      projectContent.search,
    )

    const data: any = response.data
    return data
  },
)

export const addEditRolePermissions = async (projectContent: any) => {
  const response: any = await axiosInstance.post(
    `/role_permissions/`,
    projectContent,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  return data
}

export const removeSelectedUserRole = async (projectContent: any) => {
  const response: any = await axiosInstance.patch(
    `/role_permissions/disabled`,
    projectContent,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

// =================* User Management * =======================

export const addUsers = async (projectContent: any) => {
  const response: any = await axiosInstance.post(
    `/auth/initiate_signup`,
    projectContent,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  return data
}

export const reInviteUser = async (projectContent: any) => {
  const response: any = await axiosInstance.post(
    `/auth/_reinvite`,
    projectContent,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  return data
}

export const getAllRegisteredUsers = createAsyncThunk(
  'users',
  async (params: any) => {
    const response: any = await axiosInstance.post(
      `/user_credential/_search`,
      params,
    )
    const data = response.data
    return data
  },
)

export const editUser = async (projectContent: any) => {
  const response: any = await axiosInstance.patch(
    `/user_credential/`,
    projectContent,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  return data
}

export const handleStatusOfUser = async (projectContent: any) => {
  const response: any = await axiosInstance.patch(
    `/user_credential/status`,
    projectContent,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  return data
}
//==================* Org Member Roles *=======================

export const getOrgMemberRoles = createAsyncThunk(
  'project',
  async (projectContent: any, { dispatch }) => {
    const response: any = await axiosInstance.post(
      `/user_roles/_search_org_roles?pageNumber=${projectContent.pageNumber}&pageSize=${projectContent.pageSize}`,
      projectContent.search,
    )

    const data: any = response.data
    dispatch(setDataRepo(data))
    return data
  },
)

export const getAllUsers = createAsyncThunk('users', async (params: any) => {
  const response: any = await axiosInstance.post(
    `/user_credential/_search`,
    params,
  )
  const data = response.data
  return data
})

export const addEditUserRoles = async (projectContent: any) => {
  const response: any = await axiosInstance.post(
    `/user_roles/org_role`,
    projectContent,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  return data
}

export const deleteUserOrgRole = createAsyncThunk(
  'project',
  async (userId: any) => {
    const response: any = await axiosInstance.delete(
      `/user_roles/org_role?userId=${userId}`,
    )

    const data: any = JSON.parse(JSON.stringify(response))
    return data
  },
)

//==================* Project Member Roles *=======================

export const getProjMemberRoles = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.post(
      `/user_roles/_search_project_roles?pageNumber=${projectContent.pageNumber}&pageSize=${projectContent.pageSize}`,
      projectContent.search,
    )

    const data: any = response.data
    return data
  },
)

export const getOnlyRecentProjects = () => {
  const orgId = getProfileInfo().teamId
  return axiosInstance.get(
    `/project?org_id=${orgId}&edit=true&project_type=DEFAULT`,
  )
}

export const addEditProjectUserRoles = async (projectContent: any) => {
  const response: any = await axiosInstance.post(
    `/user_roles/project_role`,
    projectContent,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  return data
}

export const deleteUserProjRole = createAsyncThunk(
  'project',
  async (params: any) => {
    const response: any = await axiosInstance.delete(
      `/user_roles/project_role?userId=${params.userId}&projectId=${params.projectId}`,
    )

    const data: any = JSON.parse(JSON.stringify(response))
    return data
  },
)

//==================* Project Member Roles *=======================

export const getAppMemberRoles = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.post(
      `/user_roles/_search_app_roles?pageNumber=${projectContent.pageNumber}&pageSize=${projectContent.pageSize}`,
      projectContent.search,
    )

    const data: any = response.data
    return data
  },
)
export const getOperationMemberRoles = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.post(
      `/user_roles/_search_operation_roles?pageNumber=${projectContent.pageNumber}&pageSize=${projectContent.pageSize}`,
      projectContent.search,
    )

    const data: any = response.data
    return data
  },
)

// export const getOnlyRecentApp = () => {
//   const orgId = getProfileInfo().teamId
//   return axiosInstance.get(
//     `/project?org_id=${orgId}&edit=true&project_type=APP`,
//   )
// }

export const addEditAppUserRoles = async (projectContent: any) => {
  const response: any = await axiosInstance.post(
    `/user_roles/app_role`,
    projectContent,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  return data
}

export const addEditOperationUserRoles = async (projectContent: any) => {
  const response: any = await axiosInstance.post(
    `/user_roles/operation_role`,
    projectContent,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  return data
}
export const deleteUserAppRole = createAsyncThunk(
  'project',
  async (params: any) => {
    const response: any = await axiosInstance.delete(
      `/user_roles/app_role?userId=${params.userId}&projectId=${params.projectId}`,
    )

    const data: any = JSON.parse(JSON.stringify(response))
    return data
  },
)
export const deleteUserOperationRole = createAsyncThunk(
  'project',
  async (params: any) => {
    const response: any = await axiosInstance.delete(
      `/user_roles/operation_role?userId=${params.userId}&projectId=${params.projectId}`,
    )

    const data: any = JSON.parse(JSON.stringify(response))
    return data
  },
)
