/* eslint-disable prettier/prettier */
import React from 'react'

import SearchIcon from '@mui/icons-material/Search'
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material'

const SearchInputComponent = (props: any) => {
  return (
    <FormControl variant="outlined" sx={{ padding: '0px !important' }}>
      <OutlinedInput
        type={'text'}
        startAdornment={
          <InputAdornment position="start">
            <IconButton>{props.icon ? props.icon : <SearchIcon />}</IconButton>
          </InputAdornment>
        }
        placeholder="Search"
        sx={{
          height: '2.4rem',
          padding: '0px !important',
          width: '18rem',
          ...props.sx,
        }}
        {...props}
      />
    </FormControl>
  )
}

export default SearchInputComponent
