import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { Fragment } from 'react'
import { loginService } from 'api/services'
import { encryptObject } from 'utility/enc-dec'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SnackbarComponent, {
  snackbarConfigTypes,
} from 'components/atoms/snackbar-component'
import { Variant } from 'types/props-types'
// import { useNavigate } from 'react-router-dom'
// import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'

const SignUp = () => {
  const [alertConfig, setAlertConfig] = useState<snackbarConfigTypes>({
    isOpen: false,
    type: 'success',
    autoHideDuration: 4000,
    message: '',
  })

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [userName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [token, setToken] = useState('')
  const [error, setError] = useState<any>({})

  useEffect(() => {
    const token = searchParams.get('param')
    token && setToken(token)

    if (!token || token === '') {
      handleShowAlert('Invalid token', 'error')
    }
  }, [])

  function verifyPasswordStrength(password: string) {
    const minLength = 8
    const maxLength = 12
    const uppercase = /[A-Z]/
    const lowercase = /[a-z]/
    const numbers = /[0-9]/
    const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/

    const hasLength =
      password.length >= minLength && password.length <= maxLength
    const hasUppercase = uppercase.test(password)
    const hasLowercase = lowercase.test(password)
    const hasNumbers = numbers.test(password)
    const hasSpecialCharacters = specialCharacters.test(password)

    if (!hasLength) {
      setError({
        ...error,
        password: 'At least min 8 character and max 12 character please',
      })
      return
    }

    if (!hasUppercase) {
      setError({
        ...error,
        password: 'At least one upper case character',
      })
      return
    }

    if (!hasLowercase) {
      setError({
        ...error,
        password: 'At least one lower case character',
      })
      return
    }

    if (!hasNumbers) {
      setError({
        ...error,
        password: 'At least one number',
      })
      return
    }

    if (!hasSpecialCharacters) {
      setError({
        ...error,
        password: 'At least one special character',
      })
      return
    }

    const isStrong =
      hasLength &&
      hasUppercase &&
      hasLowercase &&
      hasNumbers &&
      hasSpecialCharacters

    return isStrong
  }

  const handleSignUp = useCallback(() => {
    if (password === '') {
      setError({ ...error, password: 'Please enter password' })
      return
    }

    if (!verifyPasswordStrength(password)) {
      return
    }

    if (confirmPassword === '') {
      setError({ ...error, confirmPassword: 'Please enter password again' })
      return
    }
    if (confirmPassword !== password) {
      setError({ ...error, confirmPassword: 'Passwords does not match' })
      return
    }

    loginService
      .apiResetPassword({
        data: encryptObject({ token: token, password: password })['data'],
      })
      .then(() => {
        handleShowAlert('Password reset successfully')
        setTimeout(() => {
          navigate('/')
        }, 2000)
      })
      .catch((error) => {
        handleShowAlert(error.response.data.message, 'error')
      })
  }, [userName, password, confirmPassword, token])

  const handleShowAlert = useCallback(
    (msg: string, type: Variant = 'success') => {
      setAlertConfig({
        ...alertConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [alertConfig],
  )

  return (
    <Fragment>
      {/* <>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '600',
            color: '#6B778C',
            marginBottom: '10px',
          }}
          gutterBottom
        >
          Username
        </Typography>

        <TextField
          placeholder="Username"
          fullWidth
          margin="normal"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          // error={!!errors.username}
          // helperText={errors.username?.message}
          sx={{ m: 0, marginBottom: '20px' }}
          size="small"
        />
      </> */}
      <>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '600',
            color: '#6B778C',
            marginBottom: '10px',
          }}
          gutterBottom
        >
          Enter New Password
        </Typography>

        <TextField
          placeholder="Password"
          fullWidth
          margin="normal"
          value={password}
          type="password"
          onChange={(e) => {
            setPassword(e.target.value)
            setError({ ...error, password: '' })
          }}
          inputProps={{ maxLength: 12 }}
          onBlur={() => verifyPasswordStrength(password)}
          error={!!error?.password}
          helperText={error?.password}
          sx={{ m: 0, marginBottom: '20px' }}
          size="small"
        />
      </>
      <>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '600',
            color: '#6B778C',
            marginBottom: '10px',
          }}
          gutterBottom
        >
          Confirm Password
        </Typography>

        <TextField
          placeholder="Confirm Password"
          fullWidth
          margin="normal"
          value={confirmPassword}
          type="password"
          onChange={(e) => {
            setConfirmPassword(e.target.value)
            setError({ ...error, confirmPassword: '' })
          }}
          inputProps={{ maxLength: 12 }}
          error={!!error?.confirmPassword}
          helperText={error?.confirmPassword}
          sx={{ m: 0, marginBottom: '20px' }}
          size="small"
        />
      </>

      <Box sx={{ textAlign: 'center', mb: 4, mt: 2 }}>
        <Button
          variant="contained"
          onClick={() => {
            handleSignUp()
          }}
        >
          Sign Up
        </Button>
      </Box>
      <SnackbarComponent
        open={alertConfig.isOpen}
        setOpen={setAlertConfig}
        type={alertConfig.type}
        autoHideDuration={alertConfig.autoHideDuration}
        message={alertConfig.message}
        alertConfig={alertConfig}
      />
    </Fragment>
  )
}

export default SignUp
