/* eslint-disable prettier/prettier */
import { blue, grey } from '@mui/material/colors'

export const darkThemeStyle: any = [
  {
    selector: 'node',
    style: {
      'background-color': grey[800],
      'color': 'white',
      'font-size': 7,
      'text-valign': 'center',
      'text-halign': 'center',
      'text-wrap': 'wrap',
      'text-max-width': 50,
      'text-overflow-wrap': 'anywhere',
      width: 200,
      height: 80,
      shape: 'roundrectangle',
      'border-width': '1px',
      'border-color': grey[700],
    },
  },
  {
    selector: 'edge',
    style: {
      width: 3,
      'line-color': blue[300],
      'target-arrow-color': blue[300],
      'target-arrow-shape': 'triangle',
      'source-arrow-shape': 'circle',
      'source-arrow-color': blue[300],
      'curve-style': 'bezier',
    },
  },
]

export const style: any = [
  {
    selector: 'node',
    style: {
      'background-color': 'white',
      'font-size': 7,
      'text-valign': 'center',
      'text-halign': 'center',
      'text-wrap': 'wrap',
      'text-max-width': 50,
      'text-overflow-wrap': 'anywhere',
      width: 200,
      height: 80,
      shape: 'roundrectangle',
      'border-width': '1px',
    },
  },
  {
    selector: 'edge',
    style: {
      width: 2,
      'line-color': '#808080',
      'target-arrow-color': '#808080',
      'target-arrow-shape': 'triangle',
      'source-arrow-shape': 'circle',
      'source-arrow-color': '#808080',
      'curve-style': 'bezier',
      'line-style': 'dashed',
    },
  },
  
]
