/* eslint-disable no-unused-vars */
import { Box, Button, Container, TextField, Typography } from '@mui/material'
import DrawerComponent from 'components/atoms/drawer'
import React, { useCallback, useState } from 'react'

const AppKnowledgeFilter = (props: any) => {
  const { getLocationOrDefendant } = props
  const [location, setLocation] = useState('')
  const [defendant, setDefendant] = useState('')

  const onSubmit = useCallback(
    (event: any) => {
      event.preventDefault()
      getLocationOrDefendant(location, defendant)
    },
    [getLocationOrDefendant, location, defendant],
  )

  return (
    <DrawerComponent anchor={'left'} open={true} width={'300px'}>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
        Configure Graph
      </Typography>
      <Container sx={{ mt: 5, p: '0 !important' }}>
        <form onSubmit={onSubmit}>
          <TextField
            required
            fullWidth
            variant="outlined"
            value={location}
            onChange={(e) => {
              setLocation(e.target.value)
            }}
            label="Location"
          />

          <TextField
            required
            fullWidth
            variant="outlined"
            value={defendant}
            onChange={(e) => {
              setDefendant(e.target.value)
            }}
            label="Defendant"
            sx={{ mt: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}>
            {/* <Button variant="contained">Cancel</Button> */}
            <Button
              type="submit"
              variant="contained"
              disabled={location && defendant ? false : true}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Container>
    </DrawerComponent>
  )
}

export default AppKnowledgeFilter
