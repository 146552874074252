/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import CardDetailsMolecule from 'components/molecules/card-details'
import {
  Close as CloseIcon,
  AccessTime,
  AccountCircle,
} from '@mui/icons-material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import { ApiServices } from 'api/services'
import LoaderComponent from 'components/atoms/loader-component'
import { changeDateFormat } from 'utility/utility'
import NoRecordsFoundComponent from 'components/atoms/no-record'
import { getProfileInfo, removeProfileInfo, removeToken } from 'api/apiUtility'
import SearchInputComponent from 'components/atoms/search-input'
import ProjectPagination from 'components/molecules/ProjectPagination'
import { FormatAlignJustify, Apps } from '@mui/icons-material'
import { GridColDef } from '@mui/x-data-grid'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store/store'
import { setPage, setPagination } from 'store/dataSourceTypesSlice'
import ProjectListTable from './components/ProjectListTable'
import { ReactComponent as ProjectIcon } from 'assets/icons/project.svg'
import {
  getProjectActivityLog,
  getProjectMemberRoles,
  getProjectRoleDetails,
} from 'api/services/api-services'

interface ProjectListProps {
  reloadPage: number | boolean
  isRecent?: boolean
}

const renderChips = (permissions: any) => {
  const chips = []

  for (const section in permissions) {
    // Add a title for the section
    chips.push(
      <Typography
        key={`${section}-title`}
        variant="subtitle2"
        sx={{ marginBottom: 0.5, fontWeight: 'bold' }}
      >
        {section.replace(/_/g, ' ').toUpperCase()}{' '}
        {/* Converts section name to a readable format */}
      </Typography>,
    )

    // Create chips for each permission
    for (const [key, value] of Object.entries(permissions[section])) {
      if (value) {
        // Only show chips for true permissions
        chips.push(
          <Chip
            key={`${section}-${key}`}
            label={key.replace(/_/g, ' ')} // Converts key to a readable format
            sx={{
              marginRight: 0.5,
              marginBottom: 0.5,
              fontWeight: '600',
              color: '#5C6BC0',
              background: 'transparent',
              border: '1px solid #5C6BC0',
              fontSize: '10px', // Smaller font size
              padding: '1px 2px', // Smaller padding for the chip
            }}
          />,
        )
      }
    }
  }
  return chips
}

const ProjectList = ({ reloadPage, isRecent = false }: ProjectListProps) => {
  const dsState = useSelector((state: RootState) => state.dataSourceSlice)

  const dsColumns = useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: 'project_name',
        headerName: 'Name',
        flex: 1.5,
      },
      {
        field: 'last_updated_date',
        headerName: 'Modified',
        flex: 1.5,
      },
    ]
    return columns
  }, [])

  const profileInfo = getProfileInfo()
  const dispatch = useDispatch<AppDispatch>()

  const timer: any = React.useRef(null)
  const [searchInput, setSearchInput] = useState('')
  const [toggleView, setToggleView] = React.useState('cards')

  const [projectRules, setProjectRules] = useState<any>()
  const [isLoading, setLoading] = useState(false)
  const [recentProjectData, setRecentProjectData] = useState<any>()

  const [tableRows, setTableRows] = useState([])
  const [selectedRows, setSelectedRows] = useState<any>()

  //Pagination
  const [totalCount, setTotalCount] = useState(0)
  const [pageSize, setPageSize] = useState(12)
  const [page, setApiPage] = useState(1)

  const navigate = useNavigate()
  const [userProjectRoleDetails, setUserprojectRoleDetails] = useState<any>([])
  const [activityDetails, setActivityDetails] = useState<any>([])
  const [projectDetails, setProjectDetails] = useState<any>([])
  const [tabIndex, setTabIndex] = React.useState(0)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  // Sample data for users

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string,
  ) => {
    setTableRows([])
    setToggleView(nextView)
  }

  useEffect(() => {
    setTableRows(recentProjectData)
  }, [toggleView])

  useEffect(() => {
    setApiPage(dsState.paginationData.page)
  }, [dsState.paginationData.page])

  useEffect(() => {
    const newPage =
      Math.ceil(parseFloat(`${totalCount / pageSize}`)) >= page ? page : 1
    setApiPage(newPage)
  }, [pageSize])

  useEffect(() => {
    if (selectedRows?.length > 0) {
      navigateToDataPipelineList(selectedRows[0])
    }
  }, [selectedRows])

  const handlePageChange = useCallback((params: any) => {
    setApiPage(params)
  }, [])

  const handleTablePageChange = useCallback(
    (params: any) => {
      setPageSize(params.pageSize)

      const pagination = {
        totalRecords: totalCount,
        pageSize: params.pageSize,
      }
      dispatch(setPagination(pagination))
    },
    [totalCount],
  )

  const callRecentProjectDataApi = useCallback(async () => {
    const params = {
      pageNumber: page,
      pageSize: 12,
      search: {
        org_id: getProfileInfo().teamId,
        project_type: 'DEFAULT',
        search: searchInput,
      },
    }
    setTableRows([])
    try {
      setLoading(true)
      const resp = await ApiServices.getProjectsAndApps(params)
      setLoading(false)
      setTotalCount(resp.data.totalRecords)
      setRecentProjectData(resp.data.data)
      setTableRows(resp.data.data)
      const pagination = {
        totalRecords: resp?.data?.totalRecords,
        pageSize: pageSize,
      }
      dispatch(setPagination(pagination))
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [page])

  useEffect(() => {
    const profileInfoRoles = profileInfo
    if (profileInfoRoles && !projectRules) {
      setProjectRules(profileInfoRoles?.orgRole?.permissions?.projects)
    }
  }, [profileInfo])

  useEffect(() => {
    callRecentProjectDataApi()
  }, [callRecentProjectDataApi, reloadPage, page])

  const navigateToDataPipelineList = useCallback(
    (id: string) => {
      navigate(
        `/${PAGE_ROUTE_CONSTANTS.PROJECTS}/${PAGE_ROUTE_CONSTANTS.DATA_PIPELINE_LIST}?projectId=${id}`,
      )
    },
    [navigate],
  )

  const getProjectDetails = async (id: string) => {
    try {
      const response = await getProjectMemberRoles(id)
      setProjectDetails(response.data)
    } catch (error: any) {
      console.error('Error fetching configuration:', error)
    }
  }

  const getProjectaccessDetails = async (id: string) => {
    try {
      const response = await getProjectRoleDetails(id)
      setUserprojectRoleDetails(response.data)
    } catch (error: any) {
      console.error('Error fetching configuration:', error)
    }
  }

  const getProjectActivityDetails = async (id: string) => {
    try {
      const response = await getProjectActivityLog({ project_id: id })
      setActivityDetails(response.data)
    } catch (error: any) {
      console.error('Error fetching configuration:', error)
    }
  }

  const onSearchInputChange = useCallback(async () => {
    dispatch(setPage(1))
    setApiPage(1)
    setTableRows([])
    const params = {
      pageNumber: 1,
      pageSize: 12,
      search: {
        org_id: getProfileInfo().teamId,
        project_type: 'DEFAULT',
        search: searchInput,
      },
    }
    try {
      setLoading(true)

      const resp = await ApiServices.getProjectsAndApps(params)
      setLoading(false)
      setRecentProjectData(resp.data.data)
      setTotalCount(resp.data.totalRecords)
      setTableRows(resp.data.data)
      const pagination = {
        totalRecords: resp?.data?.totalRecords,
        pageSize: pageSize,
      }
      dispatch(setPagination(pagination))
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [searchInput, page])

  const inputHandler = useCallback(() => {
    onSearchInputChange()
  }, [searchInput, onSearchInputChange])

  const debounce: any =
    (func: () => void, timeout = 500) =>
    (...args: any) => {
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        func.apply(this, args)
      }, timeout)
    }

  const processChange = debounce(inputHandler)

  return (
    <>
      <Box>
        <Container sx={{ mt: 2, paddingBottom: 2 }}>
          {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton
              onClick={() => navigate(`/${PAGE_ROUTE_CONSTANTS.HOME}`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Box> */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '2%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <SearchInputComponent
                value={searchInput}
                onChange={(e: any) => setSearchInput(e.target.value)}
                onKeyUp={processChange}
              />
              <ToggleButtonGroup
                orientation="horizontal"
                size="small"
                value={toggleView}
                exclusive
                onChange={handleViewChange}
              >
                <ToggleButton value="list" aria-label="list">
                  <FormatAlignJustify />
                </ToggleButton>
                <ToggleButton value="cards" aria-label="cards">
                  <Apps />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
          {toggleView === 'cards' ? (
            <Container>
              <Grid container spacing={2}>
                {recentProjectData &&
                  recentProjectData.map((item: any) => {
                    return (
                      <Grid key={`recent_${item.sNo}`} item md={4} sm={6}>
                        <CardDetailsMolecule
                          label={`${item.project_name}`}
                          subLabel={`Last updated ${changeDateFormat(
                            item.last_updated_date,
                          )}`}
                          onClick={() => navigateToDataPipelineList(item.id)}
                          version="v2"
                          data={{ type: 'component' }}
                          icon={<ProjectIcon />}
                          handleDetails={(e) => {
                            // details drawer
                            getProjectDetails(item.id)
                            getProjectActivityDetails(item.id)
                            getProjectaccessDetails(item.id)

                            setDrawerOpen(true)
                          }}
                        ></CardDetailsMolecule>
                      </Grid>
                    )
                  })}
              </Grid>
              {(!recentProjectData || recentProjectData.length === 0) && (
                <NoRecordsFoundComponent />
              )}
              {recentProjectData &&
                recentProjectData.length > 0 &&
                !isRecent && (
                  <Box sx={{ marginTop: '20px' }}>
                    <ProjectPagination
                      totalCount={totalCount}
                      onPageChange={handlePageChange}
                      page={page}
                    />
                  </Box>
                )}
              {/* <Divider sx={{ mt: 2 }} /> */}
            </Container>
          ) : (
            <Container>
              <ProjectListTable
                rows={tableRows}
                columns={dsColumns}
                getSelectedRows={setSelectedRows}
                handlePageChange={handleTablePageChange}
                totalRecords={totalCount}
                checkboxSelection={false}
              />
            </Container>
          )}
        </Container>
      </Box>
      <LoaderComponent open={isLoading} />
      <Drawer
        anchor="right"
        sx={{ zIndex: 300, width: '40%' }}
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false)
        }}
      >
        <Box sx={{ width: '500px', padding: 2, marginTop: '80px' }}>
          {/* Header with Close Icon and Project Name */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" fontWeight="600">
              {userProjectRoleDetails?.projectName || 'Project Name'}
            </Typography>
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Tabs */}
          <Tabs value={tabIndex} onChange={handleTabChange} sx={{ marginY: 2 }}>
            <Tab label="Details" sx={{ flex: 1, fontWeight: '600' }} />
            <Tab label="Activity" sx={{ flex: 1, fontWeight: '600' }} />
          </Tabs>

          {tabIndex === 0 && (
            <Box sx={{ padding: 2 }}>
              {/* Avatar Group for Users */}
              <Typography variant="h6" gutterBottom>
                Users
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginLeft: '20px',
                }}
              >
                <AvatarGroup max={10}>
                  {projectDetails &&
                    projectDetails.map((user: any, index: any) => (
                      <Tooltip title={user.userFullName} arrow key={index}>
                        <Avatar
                          sx={{
                            fontSize: '12px',
                            width: '35px',
                            height: '35px',
                            background: '#757575',
                            border: '2px solid #000',

                            color: '#000',
                            fontWeight: '600',
                          }}
                        >
                          {user.userFullName
                            .split(' ')
                            .map((name: string) => name.charAt(0))
                            .join('')}
                        </Avatar>
                      </Tooltip>
                    ))}
                </AvatarGroup>
              </Box>

              {/* File Details */}
              <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                File Details
              </Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Project Name"
                    secondary={userProjectRoleDetails?.projectName || 'NA'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Owner"
                    secondary={
                      (activityDetails?.data?.length > 0 &&
                        activityDetails?.data[activityDetails?.data.length - 1]
                          ?.user_name) ||
                      'NA'
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="First Created"
                    secondary={
                      (activityDetails?.data?.length > 0 &&
                        activityDetails?.data[activityDetails?.data.length - 1]
                          ?.date_time.date) ||
                      'NA'
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Last Modified"
                    secondary={new Date(
                      activityDetails?.data?.length > 0 &&
                        activityDetails?.data[0]?.date_time.date,
                    ).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    })}
                  />
                </ListItem>
              </List>

              {/* Access Chips */}
              <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                Your Access
              </Typography>
              {renderChips(userProjectRoleDetails?.role?.permissions)}
            </Box>
          )}

          {tabIndex === 1 && (
            <Box>
              {/* Custom Activity Timeline */}
              <Typography variant="h6" gutterBottom>
                Version History
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginLeft: '10px',
                }}
              >
                {activityDetails?.data?.map((activity: any, index: any) => (
                  <Box
                    key={index}
                    sx={{ display: 'flex', alignItems: 'flex-start' }}
                  >
                    {/* Timeline Line */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <AccountCircle sx={{ color: '#0056b3' }} />
                      {index < activityDetails.data.length - 1 && (
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            bgcolor: '#0056b3',
                            width: '2px',
                            minHeight: '80px',
                          }}
                        />
                      )}
                    </Box>

                    {/* Card Content */}
                    <Card
                      sx={{
                        width: '380px',
                        boxShadow: 'none',
                      }}
                    >
                      <CardContent>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {activity.user_name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {activity.action} on {activity.component_name} (
                          {activity.component_type})
                        </Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                          <AccessTime
                            sx={{
                              fontSize: '16px',
                              marginRight: '4px',
                              color: '#888',
                            }}
                          />
                          <Typography variant="caption" color="textSecondary">
                            {activity.date_time.date} —{' '}
                            {activity.date_time.time}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  )
}
export default ProjectList
