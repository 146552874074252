import { Add, ArrowBack, Delete, Edit } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  MenuItem,
  Typography,
} from '@mui/material'
import { DQMetrics, dataSourceService } from 'api/services'
import Text from 'components/atoms/Text'
import DropdownComponent from 'components/atoms/dropdown'
import Dialog from 'components/molecules/Dialog'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import lodash from 'lodash'

import { AppDispatch } from 'store/store'
import { EditDataSourceProps } from 'types/props-types'
import AddDQMetricsFun from './AddDQMetricsFun'
import { CUSTOM_COLORS } from 'theme'

function ViewDataMetrics({
  data,
  type,
  heading,
  onClose,
  triggerReload,
  messageConfig,
  showMessage,
}: EditDataSourceProps) {
  const editedData = lodash.cloneDeep(data)
  const [repositoryTypes, setRepositoryTypes] = useState([])
  const [selectedDataSource, setSelectedDataSource] = useState('')
  const [repositoryObjectMeta, setRepoObjectMetaData] = useState<any>([])
  const [selectedObjectTable, setSelectedObjectTable] = useState<any>('')
  const [profilingFun, setProfilingFun] = useState([])
  const [selectedProFun, setSelectedProFun] = useState<any>('')
  const [dqmName, setDqmName] = useState('')

  const [error, setError] = useState<any>({})
  // const [isTestLoading, setTestLoading] = useState(false)
  const [isSaveLoading, setSaveLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState({
    isOpen: false,
    editedData: {},
    index: 0,
  })
  const [delimiter, setDelimiter] = useState('')

  const [dqMetricsFunctionsData, setDQMetricsFunctionsData] = useState<any>([])

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(
      dataSourceService.searchDataSources({
        page: 1,
        size: 10,
        search: { data_repository_status: ['LIVE'] },
      }),
    )
      .then((res: any) => {
        return res?.payload?.totalRecords
      })
      .then((size: any) => {
        dispatch(
          dataSourceService.searchDataSources({
            page: 1,
            size: size,
            search: { data_repository_status: ['LIVE'] },
          }),
        ).then((res: any) => {
          setRepositoryTypes(res?.payload?.data)
        })
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
      })
  }, [])

  useEffect(() => {
    if (selectedDataSource !== '') {
      DQMetrics.getRepositoryMetaDataById({ id: selectedDataSource })
        .then((res: any) => {
          setRepoObjectMetaData(res?.data?.dataset)
        })
        .catch((error) => {
          if (error.message) {
            handleShowAlert(error.message, 'error')
          } else {
            handleShowAlert('Something went wrong', 'error')
          }
        })
    }
  }, [selectedDataSource])

  useEffect(() => {
    DQMetrics.getDQProfilingFunctions()
      .then((res: any) => {
        setProfilingFun(res?.data)
      })
      .catch((error: any) => {
        if (error) {
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        } else {
          handleShowAlert('Something went wrong', 'error')
        }
      })
  }, [])

  useEffect(() => {
    setSelectedDataSource(editedData?.data_repository_id)
    setSelectedObjectTable(editedData?.dataset)
    setSelectedProFun(editedData?.dq_metrics_profiling_function_type)
    setDQMetricsFunctionsData(editedData?.dq_metrics_functions_configuration)
    setDqmName(editedData?.dq_meterics_name)
    setDelimiter(editedData?.delimiter)
  }, [])

  const handleSelectDataSource = useCallback((e: any) => {
    setSelectedDataSource(e.target.value)
    setSelectedObjectTable('')
    setSelectedProFun('')
    setError({ ...error, config: '' })
  }, [])

  const handleShowAlert = useCallback(
    (msg: string, type = 'success') => {
      showMessage({
        ...messageConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [messageConfig],
  )

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(!openDialog)
  }, [openDialog])

  const handleCloseEditDialog = useCallback(
    (editData = {}, index = -1) => {
      setOpenEditDialog({
        isOpen: !openEditDialog.isOpen,
        editedData: editData,
        index: index,
      })
    },
    [openEditDialog],
  )

  const handleDropDownErrors = useCallback(
    (errorObj: any) => {
      if (typeof errorObj !== 'undefined') {
        if (errorObj !== '') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    [error],
  )

  const enableAddDQFunBtn = useCallback(() => {
    if (
      selectedDataSource === '' ||
      selectedObjectTable === '' ||
      selectedProFun === ''
    ) {
      return true
    } else {
      return false
    }
  }, [selectedDataSource, selectedObjectTable, selectedProFun])

  const handleSaveData = useCallback(() => {
    if (selectedDataSource === '') {
      setError({ ...error, dataRepo: 'This field is mandatory' })
      return
    }

    if (selectedObjectTable === '') {
      setError({ ...error, objTable: 'This field is mandatory' })
      return
    }

    if (selectedProFun === '') {
      setError({ ...error, pfType: 'This field is mandatory' })
      return
    }

    if (dqmName === '') {
      setError({ ...error, dqmName: 'This field is mandatory' })
      return
    }

    setSaveLoading(true)
    const data: any = {
      id: null,
      dq_meterics_name: dqmName,
      data_repository_id: selectedDataSource,
      dataset: selectedObjectTable,
      delimiter: delimiter !== '' ? delimiter : ',',
      dq_metrics_profiling_function_type: selectedProFun,
      dq_metrics_functions_configuration: [],
    }
    if (dqMetricsFunctionsData?.length > 0) {
      dqMetricsFunctionsData?.map((dqmfun: any, index: number) => {
        const dqData = {
          id: dqmfun?.id,
          name: dqmfun?.name,
          custom_name: `data quality metrics fun ${index}`,
          columns: dqmfun?.columns,
          params: dqmfun?.params?.map((obj: any) =>
            Object.fromEntries(
              Object.entries(obj).filter(
                ([key]) =>
                  key !== 'type' && key !== 'isRequired' && key !== 'fieldType',
              ),
            ),
          ),
        }
        data.dq_metrics_functions_configuration.push(dqData)
      })
    }

    DQMetrics.addNewDQMatrices(data)
      .then((res: any) => {
        handleShowAlert(res?.data?.message)
        triggerReload?.()
        setSaveLoading(false)
        onClose?.()
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        setSaveLoading(false)
      })
  }, [
    dqmName,
    selectedDataSource,
    selectedObjectTable,
    selectedProFun,
    dqMetricsFunctionsData,
    delimiter,
  ])

  const handleDeleteDQMFun = useCallback(
    (index: any) => {
      const dqmData = lodash.cloneDeep(dqMetricsFunctionsData)
      dqmData?.splice(index, 1)
      setDQMetricsFunctionsData(dqmData)
    },
    [dqMetricsFunctionsData],
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '424px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '28px 0px 28px 0px',
          gap: '16px',
        }}
      >
        <ArrowBack sx={{ cursor: 'pointer' }} onClick={onClose} />
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          {heading}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '32px',
          overflowY: 'scroll',
          paddingTop: '20px',
        }}
      >
        {/* <SelectComponent /> */}
        <Box>
          <DropdownComponent
            sx={{ width: 361 }}
            size="small"
            label="Data Repository *"
            value={selectedDataSource}
            onChange={(e) => handleSelectDataSource(e)}
            disabled={dqMetricsFunctionsData?.length > 0}
            error={handleDropDownErrors(error?.dataRepo) || false}
          >
            {repositoryTypes?.length > 0 ? (
              repositoryTypes.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.data_repository_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>No Record Found</em>
              </MenuItem>
            )}
          </DropdownComponent>
          <Typography
            sx={{
              fontSize: '0.75rem',
              margin: '4px 0px 0px 14px',
              fontWeight: '400',
              color: '#d32f2f',
            }}
          >
            {error?.dataRepo}
          </Typography>
        </Box>
        <Box>
          <DropdownComponent
            sx={{ width: 361 }}
            size="small"
            label="Table/File *"
            value={selectedObjectTable}
            onChange={(e) => {
              setSelectedObjectTable(e.target.value)
              setSelectedProFun('')
              setError({ ...error, objTable: '' })
            }}
            disabled={dqMetricsFunctionsData?.length > 0}
            error={handleDropDownErrors(error?.objTable) || false}
          >
            {repositoryObjectMeta?.length > 0 ? (
              repositoryObjectMeta.map((item: any, index: number) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>No Record Found</em>
              </MenuItem>
            )}
          </DropdownComponent>
          <Typography
            sx={{
              fontSize: '0.75rem',
              margin: '4px 0px 0px 14px',
              fontWeight: '400',
              color: '#d32f2f',
            }}
          >
            {error?.objTable}
          </Typography>
        </Box>
        <Box>
          <Text
            label="Column Delimiter (If reading to file system)"
            value={delimiter}
            onChange={(e: any) => {
              setDelimiter(e.target.value)
            }}
            disabled={type === 'view' || type === 'clone'}
            name="delimiter"
          />
        </Box>
        <Box>
          <DropdownComponent
            sx={{ width: 361 }}
            size="small"
            label="Profiling Function Type *"
            value={selectedProFun}
            onChange={(e) => {
              setSelectedProFun(e.target.value)
              setError({ ...error, pfType: '' })
            }}
            disabled={dqMetricsFunctionsData?.length > 0}
            error={handleDropDownErrors(error?.pfType) || false}
          >
            {profilingFun?.length > 0 ? (
              profilingFun.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>No Record Found</em>
              </MenuItem>
            )}
          </DropdownComponent>
          <Typography
            sx={{
              fontSize: '0.75rem',
              margin: '4px 0px 0px 14px',
              fontWeight: '400',
              color: '#d32f2f',
            }}
          >
            {error?.pfType}
          </Typography>
        </Box>
        {/* Dynamic Component Load From below */}
        <Box sx={{ display: 'flex', width: 361, justifyContent: 'flex-end' }}>
          <Button
            sx={{ color: '#42526E' }}
            variant="outlined"
            onClick={handleCloseDialog}
            disabled={enableAddDQFunBtn() || type === 'view'}
          >
            <Add />
          </Button>
        </Box>

        <Text
          label="Metric Name *"
          value={dqmName}
          onChange={(e: any) => {
            setDqmName(e.target.value)
            setError({ ...error, dqmName: '' })
          }}
          disabled={type === 'view'}
          name="dqmName"
          error={error}
        />

        <Box sx={{ width: '100%', paddingRight: '20px' }}>
          {dqMetricsFunctionsData?.length > 0 &&
            dqMetricsFunctionsData.map((dqFun: any, index: number) => (
              <Accordion key={index} sx={{ width: '100%' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Data Metrics Function {index + 1}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                  >
                    <Text
                      label="Data Metrics Function"
                      value={dqFun?.name}
                      disabled={true}
                    />
                    <Box>
                      <Typography>Data Metrics Fields</Typography>
                      {dqFun?.columns?.length > 0 &&
                        dqFun?.columns?.map((item: any) => (
                          <Chip key={item} label={item} variant="outlined" />
                        ))}
                    </Box>
                    {dqFun?.params?.length > 0 &&
                      dqFun?.params?.map((item: any, index: number) => (
                        <Text
                          key={index}
                          label={item?.label}
                          value={item?.value}
                          disabled={true}
                        />
                      ))}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                      paddingTop: '20px',
                      gap: '20px',
                    }}
                  >
                    {type === 'clone' && (
                      <Edit
                        sx={{
                          color: CUSTOM_COLORS.backgroundColor,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleCloseEditDialog(dqFun, index)}
                      />
                    )}

                    {type !== 'view' && (
                      <Delete
                        sx={{
                          color: CUSTOM_COLORS.backgroundColor,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleDeleteDQMFun(index)}
                      />
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            marginRight: '40px',
            justifyContent: 'flex-end',
            paddingBottom: '15%',
          }}
        >
          {type !== 'view' && (
            <Button
              sx={{ color: '#42526E' }}
              variant="text"
              onClick={handleSaveData}
              disabled={isSaveLoading}
            >
              {isSaveLoading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          )}
        </Box>
      </Box>
      <Dialog open={openDialog} handleClose={handleCloseDialog}>
        <AddDQMetricsFun
          selectedProFun={selectedProFun}
          onClose={handleCloseDialog}
          metaData={{
            dataRepository: selectedDataSource,
            objTable: selectedObjectTable,
            profilingFun: selectedProFun,
          }}
          delimiter={delimiter}
          showAlert={handleShowAlert}
          setDQFunData={setDQMetricsFunctionsData}
        />
      </Dialog>
      <Dialog open={openEditDialog.isOpen} handleClose={handleCloseEditDialog}>
        <AddDQMetricsFun
          selectedProFun={selectedProFun}
          onClose={handleCloseEditDialog}
          metaData={{
            dataRepository: selectedDataSource,
            objTable: selectedObjectTable,
            profilingFun: selectedProFun,
          }}
          delimiter={delimiter}
          showAlert={handleShowAlert}
          setDQFunData={setDQMetricsFunctionsData}
          type={type}
          editData={openEditDialog.editedData}
          editDataIndex={openEditDialog.index}
          dqmAllData={dqMetricsFunctionsData}
        />
      </Dialog>
    </Box>
  )
}

export default ViewDataMetrics
