/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

const LineChartComponent = ({ data, width, height, margin, id }: any) => {
  const svgRef = useRef<any>()

  useEffect(() => {
    if (!data || data.length === 0) return
    svgRef.current = document.getElementById(id)
    const svg = d3.select(svgRef.current)

    // Clear previous chart
    svg.selectAll('*').remove()

    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom
    const maxVal_X: any = d3.max(data, (d: any) => d.x)
    const xScale = d3.scaleLinear().domain([0, maxVal_X]).range([0, innerWidth])
    const maxVal_Y: any = d3.max(data, (d: any) => d.y)
    const yScale = d3
      .scaleLinear()
      .domain([0, maxVal_Y])
      .range([innerHeight, 0])

    const line = d3
      .line()
      .x((d: any) => xScale(d.x))
      .y((d: any) => yScale(d.y))

    const chartGroup = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    chartGroup
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 2)
      .attr('d', line)

    chartGroup
      .append('g')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(d3.axisBottom(xScale))

    chartGroup.append('g').call(d3.axisLeft(yScale))
  }, [data, width, height, margin, id])

  return <svg id={id} width={width} height={height}></svg>
}

export default LineChartComponent
