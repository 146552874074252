import axios from 'axios'

import { getHeadersWithToken } from './apiUtility'

import { environment } from 'environments/environment'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'

const axiosInstance = axios.create({
  // baseURL: 'http://172.31.26.60:3001',
  baseURL: environment.loadBalancerNode,
})

axiosInstance.interceptors.request.use(
  (config) => {
    const headers = getHeadersWithToken() // Use the headers module
    config.headers = { ...config.headers, ...headers } // Merge headers
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token is expired or invalid, navigate to the login page
      localStorage.clear()
      window.location.href = `/${PAGE_ROUTE_CONSTANTS.LOGIN}`
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
