import axiosInstance from 'api'
import { loginType } from 'types/request'

const endPoint_login = '/auth/signinToAccount'

export const apiLogin = (payload: loginType) => {
  return axiosInstance.post(endPoint_login, payload)
}

export const apiResetPassword = (payload: any) => {
  return axiosInstance.post('/auth/reset_password', payload)
}
