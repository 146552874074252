/* eslint-disable no-unused-vars */
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import CardDetailsMolecule from 'components/molecules/card-details'
import React, { useCallback, useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import { ApiServices } from 'api/services'
import LoaderComponent from 'components/atoms/loader-component'
import { changeDateFormat } from 'utility/utility'
import NoRecordsFoundComponent from 'components/atoms/no-record'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store/store'
import { setProjectName } from 'store/breadcrumbSlice'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
import { ReactComponent as DataPipeline } from 'assets/icons/data-pipeline.svg'
import { ReactComponent as ModelTraining } from 'assets/icons/model-training.svg'
import { ReactComponent as DocumentProcessing } from 'assets/icons/document-processing.svg'
import { ReactComponent as DqPipeline } from 'assets/icons/dq-pipeline.svg'

const OperationDetailListPage = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [isLoading, setLoading] = useState(false)

  const [dataPipelineProj, setDataPipelineProj] = useState<any>()
  const [modelTrainingProj, setModelTrainingProj] = useState<any>()
  const [documentProcessingProj, setDocumentProcessingProj] = useState<any>()
  const [dqPipelineProj, setDQPipelineProj] = useState<any>()

  const [projectData, setProjectData] = useState<any>()
  const [projectId, setProjectId] = useState<string>()
  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams) {
      const project_Id = searchParams.get('projectId')
      project_Id && setProjectId(project_Id)
    }
  }, [searchParams])
  const navigate = useNavigate()

  const callRecentAppsDataApi = useCallback(async (projectId: string) => {
    try {
      setLoading(true)
      const dataPipelineResp = await ApiServices.getDataPipelineApps(projectId)
      setLoading(false)
      setDataPipelineProj(dataPipelineResp.data)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
    try {
      setLoading(true)
      const modelTrainingResp =
        await ApiServices.getModelTrainingPipelineApps(projectId)
      setLoading(false)
      setModelTrainingProj(modelTrainingResp.data)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
    try {
      setLoading(true)
      const documentProcessingResp =
        await ApiServices.getDocumentProcessingPipelineApps(projectId)
      setLoading(false)
      setDocumentProcessingProj(documentProcessingResp.data)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
    try {
      setLoading(true)
      const dq_pipelineResp = await ApiServices.getDQPipelineApps(projectId)
      setLoading(false)
      setDQPipelineProj(dq_pipelineResp.data)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [])

  useEffect(() => {
    if (projectId) {
      const getProjectDetails = async () => {
        try {
          setLoading(true)
          const resp: any = await ApiServices.getProjectDetails(projectId)
          setLoading(false)
          setProjectData(resp.data)
          dispatch(setProjectName(resp?.data?.project_name))
        } catch (error: any) {
          setProjectData(null)
          setLoading(false)
          console.log('error', error)
        }
      }

      getProjectDetails()
    }
  }, [projectId])

  useEffect(() => {
    if (projectId) {
      callRecentAppsDataApi(projectId)
    }
  }, [callRecentAppsDataApi, projectId])

  const navigateToDataPipelineList = useCallback(
    (id: string, name: string, uid: string) => {
      navigate(
        `/${PAGE_ROUTE_CONSTANTS.OPERATIONS_PAGE}/${PAGE_ROUTE_CONSTANTS.OPERATION_DETAIL_LIST_PAGE}/${PAGE_ROUTE_CONSTANTS.OPERATION_DETAIL_TABLE}?projectId=${projectId}&componentId=${id}&uid=${uid}&componentName=${name}`,
      )
    },
    [navigate, projectId],
  )

  return (
    <>
      <Box>
        <Container sx={{ mt: 2, paddingBottom: 2 }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Container>
            {projectData && (
              <Box>
                <Typography
                  variant="button"
                  gutterBottom
                  sx={{ fontWeight: 'bold', fontSize: 24 }}
                >
                  {projectData.project_name}
                </Typography>
              </Box>
            )}
            <Box sx={{ mb: 2, py: 2 }}>
              <Typography
                sx={{ fontWeight: 'bold' }}
                variant="button"
                gutterBottom
              >
                {`Data pipeline jobs`}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {dataPipelineProj &&
                dataPipelineProj.map((item: any) => {
                  return (
                    <Grid key={`recent_${item.id}`} item md={4} sm={6}>
                      <CardDetailsMolecule
                        label={`${item.component_name}`}
                        subLabel={`Last updated ${changeDateFormat(
                          item.last_updated_date,
                        )}`}
                        onClick={() =>
                          navigateToDataPipelineList(
                            item.id,
                            item.component_name,
                            item.uid,
                          )
                        }
                        version="v2"
                        icon={<DataPipeline />}
                        handleDetails={(e) => {
                          // details drawer
                        }}
                      ></CardDetailsMolecule>
                    </Grid>
                  )
                })}
            </Grid>
            {dataPipelineProj && dataPipelineProj.length === 0 && (
              <NoRecordsFoundComponent />
            )}
            <Divider sx={{ mt: 2 }} />
            <Box sx={{ mb: 2, py: 2 }}>
              <Typography
                sx={{ fontWeight: 'bold' }}
                variant="button"
                gutterBottom
              >
                {`Model Training Pipeline Jobs`}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {modelTrainingProj &&
                modelTrainingProj.map((item: any) => {
                  return (
                    <Grid key={`recent_${item.id}`} item md={4} sm={6}>
                      <CardDetailsMolecule
                        label={`${item.component_name}`}
                        subLabel={`Last updated ${changeDateFormat(
                          item.last_updated_date,
                        )}`}
                        onClick={() =>
                          navigateToDataPipelineList(
                            item.id,
                            item.component_name,
                            item.uid,
                          )
                        }
                        version="v2"
                        icon={<ModelTraining />}
                        handleDetails={(e) => {
                          // details drawer
                        }}
                      ></CardDetailsMolecule>
                    </Grid>
                  )
                })}
            </Grid>
            {modelTrainingProj && modelTrainingProj.length === 0 && (
              <NoRecordsFoundComponent />
            )}
            <Divider sx={{ mt: 2 }} />
            <Box sx={{ mb: 2, py: 2 }}>
              <Typography
                sx={{ fontWeight: 'bold' }}
                variant="button"
                gutterBottom
              >
                {`Document Processing Pipeline Jobs`}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {documentProcessingProj &&
                documentProcessingProj.map((item: any) => {
                  return (
                    <Grid key={`recent_${item.id}`} item md={4} sm={6}>
                      <CardDetailsMolecule
                        label={`${item.component_name}`}
                        subLabel={`Last updated ${changeDateFormat(
                          item.last_updated_date,
                        )}`}
                        onClick={() =>
                          navigateToDataPipelineList(
                            item.id,
                            item.component_name,
                            item.uid,
                          )
                        }
                        version="v2"
                        icon={<DocumentProcessing />}
                        handleDetails={(e) => {
                          // details drawer
                        }}
                      ></CardDetailsMolecule>
                    </Grid>
                  )
                })}
            </Grid>
            {documentProcessingProj && documentProcessingProj.length === 0 && (
              <NoRecordsFoundComponent />
            )}
            <Divider sx={{ mt: 2 }} />
            <Box sx={{ mb: 2, py: 2 }}>
              <Typography
                sx={{ fontWeight: 'bold' }}
                variant="button"
                gutterBottom
              >
                {`DQ Pipeline Jobs`}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {dqPipelineProj &&
                dqPipelineProj.map((item: any) => {
                  return (
                    <Grid key={`recent_${item.id}`} item md={4} sm={6}>
                      <CardDetailsMolecule
                        label={`${item.component_name}`}
                        subLabel={`Last updated ${changeDateFormat(
                          item.last_updated_date,
                        )}`}
                        onClick={() =>
                          navigateToDataPipelineList(
                            item.id,
                            item.component_name,
                            item.uid,
                          )
                        }
                        version="v2"
                        icon={<DqPipeline />}
                        handleDetails={(e) => {
                          // details drawer
                        }}
                      ></CardDetailsMolecule>
                    </Grid>
                  )
                })}
            </Grid>
            {dqPipelineProj && dqPipelineProj.length === 0 && (
              <NoRecordsFoundComponent />
            )}
          </Container>
        </Container>
      </Box>
      <LoaderComponent open={isLoading} />
    </>
  )
}
export default OperationDetailListPage
