import axiosInstance from 'api'
import { environment } from 'environments/environment'

export const getAllDqChecks = async (params: {
  pageNumber: string | any
  pageSize: string | any
  search: any
}) => {
  const response: any = await axiosInstance.post(
    `dq_checks/_search?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`,
    params.search,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const getAllDqCheckswithSearch = async (params: {
  pageNumber: string | any
  pageSize: string | any
  search: string | any
}) => {
  const response: any = await axiosInstance.post(
    `dq_checks/_search?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`,
    params.search,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const addDqChecks = async (param: any) => {
  const response: any = await axiosInstance.post('/dq_checks/', param)

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const disableDQChecks = async (params: { ids: any }) => {
  const response: any = await axiosInstance.patch(`/dq_checks/disabled`, params)

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const getDqCheckTypes = async () => {
  const response: any = await axiosInstance.get(`/dq_check_type/basic`)

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const getAllDqCheckTypes = async () => {
  const response: any = await axiosInstance.get(`/dq_check_type/`)

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const getDQCheckTrendRules = async () => {
  const response: any = await axiosInstance.get(`/dq_checks/trend-rules`)

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const getDQCheckFunctions = async (params: {
  pageNumber: number | any
  pageSize: number | any
  body: {
    dq_check_basic_type: string | any
    dq_check_type: string | any
    db_type: string | any
  }
}) => {
  const response: any = await axiosInstance.post(
    `/dq_check_functions/_search?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}&status=ACTIVE`,
    params.body,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const getAllDQMatrices = async (params: {
  pageNumber?: number | any
  pageSize?: number | any
  search: any
}) => {
  const response: any = await axiosInstance.post(
    `dq_metrics/_search`,
    params.search,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const checkQueryValidation = async (params: {
  repository_id: string | any
  body: {
    query: string | any
  }
}) => {
  const response: any = await axiosInstance.post(
    `${environment.TEST_CONNECTION}/query_verification/${params.repository_id}`,
    params.body,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}
