import React, { forwardRef, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import AllComponents from './pipeline-components/AllComponents'
import StreamingDataPipeline from './pipeline-components/streamingDataPipeline/StreamingDataPipeline'

const NodeDetailsDrawer = forwardRef((props: any, ref) => {
  const [searchParams] = useSearchParams()
  const [componentType, setComponentType] = useState<any>('')
  useEffect(() => {
    const compType = searchParams.get('componentType')
    compType && setComponentType(compType)
  }, [])
  if (componentType === 'STREAMING_DATA_PIPELINE') {
    return <StreamingDataPipeline {...props} ref={ref} />
  } else {
    return <AllComponents {...props} ref={ref} />
  }
})

NodeDetailsDrawer.displayName = 'NodeDetailsDrawer'

export default NodeDetailsDrawer
