import { Add, ArrowBack, Delete, Edit } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  MenuItem,
  Typography,
} from '@mui/material'
import { DQChecks, DQMetrics, dataSourceService } from 'api/services'
import Text from 'components/atoms/Text'
import DropdownComponent from 'components/atoms/dropdown'
import Dialog from 'components/molecules/Dialog'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import lodash from 'lodash'

import { AppDispatch } from 'store/store'
import { EditDataSourceProps } from 'types/props-types'
import AddDataCheckFun from './AddDataCheckFun'
import { CUSTOM_COLORS } from 'theme'

function ViewDataChecks({
  data,
  type,
  heading,
  onClose,
  triggerReload,
  messageConfig,
  showMessage,
}: EditDataSourceProps) {
  const editedData = useMemo(() => lodash.cloneDeep(data), [data])

  const [repositoryTypes, setRepositoryTypes] = useState([])
  const [selectedDataSource, setSelectedDataSource] = useState('')
  const [selectedRepositoryDetail, setSelectedRepositoryDetail] = useState()
  const [repositoryObjectMeta, setRepoObjectMetaData] = useState<any>([])
  const [selectedObjectTable, setSelectedObjectTable] = useState<any>('')
  const [basicCheckTypes, setBasicCheckTypes] = useState([])
  const [selectedCheckTypes, setSelectedCheckTypes] = useState<any>('')
  const [allCheckTypes, setAllCheckTypes] = useState([])
  const [selectedAllCheckTypes, setSelectedAllCheckTypes] = useState<any>('')
  const [dqCheckName, setDqCheckName] = useState('')

  const [error, setError] = useState<any>({})
  // const [isTestLoading, setTestLoading] = useState(false)
  const [isSaveLoading, setSaveLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [dqCheckFunctionsData, setDQChecksFunctionsData] = useState<any>([])
  const [delimiter, setDelimiter] = useState('')

  const [openEditDialog, setOpenEditDialog] = useState({
    isOpen: false,
    editedData: {},
    index: 0,
  })

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(
      dataSourceService.searchDataSources({
        page: 1,
        size: 10,
        search: { data_repository_status: ['LIVE'] },
      }),
    )
      .then((res: any) => {
        return res?.payload?.totalRecords
      })
      .then((size: any) => {
        dispatch(
          dataSourceService.searchDataSources({
            page: 1,
            size: size,
            search: { data_repository_status: ['LIVE'] },
          }),
        )
          .then((res: any) => {
            setRepositoryTypes(res?.payload?.data)
          })
          .catch((error: any) => {
            const errorMessage = error.response
              ? error.response.data.message
              : error.message
            handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
          })
      })
  }, [])

  useEffect(() => {
    if (selectedDataSource !== '') {
      DQMetrics.getRepositoryMetaDataById({ id: selectedDataSource })
        .then((res: any) => {
          setRepoObjectMetaData(res?.data?.dataset)
        })
        .catch((error) => {
          if (error.message) {
            handleShowAlert(error.message, 'error')
          } else {
            handleShowAlert('Something went wrong', 'error')
          }
        })
    }
  }, [selectedDataSource])

  useEffect(() => {
    DQChecks.getDqCheckTypes()
      .then((res: any) => {
        setBasicCheckTypes(res?.data)
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
      })
  }, [])

  useEffect(() => {
    DQChecks.getAllDqCheckTypes()
      .then((res: any) => {
        setAllCheckTypes(res?.data)
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
      })
  }, [])

  useEffect(() => {
    if (repositoryTypes?.length > 0) {
      const repoId: any = repositoryTypes.find(
        (item: any) =>
          item?.data_repository_name === editedData?.data_repository_name,
      )
      setSelectedCheckTypes(editedData?.dq_check_basic_type)
      setSelectedAllCheckTypes(editedData?.dq_check_type)
      setSelectedDataSource(repoId?.id)
      setSelectedObjectTable(editedData?.dataset)
      setDqCheckName(editedData?.dq_check_name)
      setDQChecksFunctionsData(editedData?.dq_check_functions_configuration)
      setDelimiter(editedData?.delimiter)
    }
  }, [repositoryTypes, repositoryObjectMeta])

  const handleSelectDataSource = useCallback(
    (e: any) => {
      setSelectedDataSource(e.target.value)

      setSelectedRepositoryDetail(
        repositoryTypes?.find((item: any) => item?.id === e.target.value),
      )
      setSelectedObjectTable('')
      setError({ ...error, config: '' })
    },
    [repositoryTypes],
  )

  const handleShowAlert = useCallback(
    (msg: string, type = 'success') => {
      showMessage({
        ...messageConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [messageConfig],
  )

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(!openDialog)
  }, [openDialog])

  const handleDropDownErrors = useCallback(
    (errorObj: any) => {
      if (typeof errorObj !== 'undefined') {
        if (errorObj !== '') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    [error],
  )

  const enableAddDQFunBtn = useCallback(() => {
    if (selectedDataSource === '' || selectedCheckTypes === '') {
      return true
    } else {
      if (selectedCheckTypes === 'CHECKS') {
        if (selectedAllCheckTypes === '') {
          return true
        } else {
          if (selectedAllCheckTypes !== 'SQL') {
            if (selectedObjectTable === '') {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        }
      } else {
        if (selectedAllCheckTypes !== 'SQL') {
          if (selectedObjectTable === '') {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      }
    }
  }, [
    selectedDataSource,
    selectedObjectTable,
    selectedCheckTypes,
    selectedAllCheckTypes,
  ])

  const handleSaveData = useCallback(() => {
    if (selectedCheckTypes === '') {
      setError({ ...error, pfType: 'This field is mandatory' })
      return
    }

    if (selectedCheckTypes === 'CHECKS') {
      if (selectedAllCheckTypes === '') {
        setError({ ...error, allCheckType: 'This field is mandatory' })
        return
      }
    }

    if (selectedDataSource === '') {
      setError({ ...error, dataRepo: 'This field is mandatory' })
      return
    }

    if (selectedAllCheckTypes !== 'SQL') {
      if (selectedObjectTable === '') {
        setError({ ...error, objTable: 'This field is mandatory' })
        return
      }
    }

    if (dqCheckName === '') {
      setError({ ...error, dqmName: 'This field is mandatory' })
      return
    }

    setSaveLoading(true)
    const data: any = {
      id: null,
      dq_check_name: dqCheckName,
      data_repository_id: selectedDataSource,
      dataset: selectedObjectTable || null,
      delimiter: delimiter !== '' ? delimiter : ',',
      dq_check_basic_type: selectedCheckTypes,
      dq_check_type: selectedAllCheckTypes,
      dq_check_functions_configuration: [],
    }
    if (dqCheckFunctionsData?.length > 0) {
      dqCheckFunctionsData?.map((dqmfun: any, index: number) => {
        const dqData: any = {
          id: dqmfun?.id,
          name: dqmfun?.name,
          custom_name: `data quality check fun ${index}`,
          dq_metrics_id: dqmfun?.dq_metrics_id,
          metrics: dqmfun?.metrics?.length > 0 ? dqmfun?.metrics : [],
          query: dqmfun?.query,
          option: dqmfun?.option,
          rule: dqmfun?.rule,
          params:
            dqmfun?.params?.length > 0
              ? dqmfun?.params?.map((obj: any) =>
                  Object.fromEntries(
                    Object.entries(obj).filter(
                      ([key]) =>
                        key !== 'type' &&
                        key !== 'isRequired' &&
                        key !== 'fieldType',
                    ),
                  ),
                )
              : [],
        }
        data.dq_check_functions_configuration.push(dqData)
      })
    }

    DQChecks.addDqChecks(data)
      .then((res: any) => {
        handleShowAlert(res?.data?.message)
        triggerReload?.()
        setSaveLoading(false)
        onClose?.()
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        setSaveLoading(false)
      })
  }, [
    dqCheckName,
    selectedDataSource,
    selectedObjectTable,
    selectedCheckTypes,
    dqCheckFunctionsData,
    delimiter,
  ])

  const handleDeleteDQMFun = useCallback(
    (index: any) => {
      const dqmData = lodash.cloneDeep(dqCheckFunctionsData)
      dqmData?.splice(index, 1)
      setDQChecksFunctionsData(dqmData)
    },
    [dqCheckFunctionsData],
  )

  const handleCloseEditDialog = useCallback(
    (editData = {}, index = -1) => {
      setOpenEditDialog({
        isOpen: !openEditDialog.isOpen,
        editedData: editData,
        index: index,
      })
    },
    [openEditDialog],
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '424px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '28px 0px 28px 0px',
          gap: '16px',
        }}
      >
        <ArrowBack sx={{ cursor: 'pointer' }} onClick={onClose} />
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          {heading}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '32px',
          overflowY: 'scroll',
          paddingTop: '20px',
        }}
      >
        {/* <SelectComponent /> */}
        <Box>
          <DropdownComponent
            sx={{ width: 361 }}
            size="small"
            label="Basic Check Type *"
            value={selectedCheckTypes}
            onChange={(e) => {
              setSelectedCheckTypes(e.target.value)
              setSelectedAllCheckTypes('')
              setSelectedDataSource('')
              setSelectedObjectTable('')
              setError({ ...error, pfType: '' })
            }}
            disabled={dqCheckFunctionsData?.length > 0}
            error={handleDropDownErrors(error?.pfType) || false}
          >
            {basicCheckTypes?.length > 0 ? (
              basicCheckTypes.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>No Record Found</em>
              </MenuItem>
            )}
          </DropdownComponent>
          <Typography
            sx={{
              fontSize: '0.75rem',
              margin: '4px 0px 0px 14px',
              fontWeight: '400',
              color: '#d32f2f',
            }}
          >
            {error?.pfType}
          </Typography>
        </Box>
        {selectedCheckTypes === 'CHECKS' && (
          <Box>
            <DropdownComponent
              sx={{ width: 361 }}
              size="small"
              label="All Check Types *"
              value={selectedAllCheckTypes}
              onChange={(e) => {
                setSelectedAllCheckTypes(e.target.value)
                setSelectedDataSource('')
                setSelectedObjectTable('')
                setError({ ...error, allCheckType: '' })
              }}
              disabled={dqCheckFunctionsData?.length > 0}
              error={handleDropDownErrors(error?.allCheckType) || false}
            >
              {allCheckTypes?.length > 0 ? (
                allCheckTypes.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No Record Found</em>
                </MenuItem>
              )}
            </DropdownComponent>
            <Typography
              sx={{
                fontSize: '0.75rem',
                margin: '4px 0px 0px 14px',
                fontWeight: '400',
                color: '#d32f2f',
              }}
            >
              {error?.allCheckType}
            </Typography>
          </Box>
        )}
        <Box>
          <DropdownComponent
            sx={{ width: 361 }}
            size="small"
            label="Data Repository *"
            value={selectedDataSource}
            onChange={(e) => handleSelectDataSource(e)}
            disabled={dqCheckFunctionsData?.length > 0}
            error={handleDropDownErrors(error?.dataRepo) || false}
          >
            {repositoryTypes?.length > 0 ? (
              repositoryTypes.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.data_repository_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>No Record Found</em>
              </MenuItem>
            )}
          </DropdownComponent>
          <Typography
            sx={{
              fontSize: '0.75rem',
              margin: '4px 0px 0px 14px',
              fontWeight: '400',
              color: '#d32f2f',
            }}
          >
            {error?.dataRepo}
          </Typography>
        </Box>
        {selectedAllCheckTypes !== 'SQL' && (
          <Box>
            <DropdownComponent
              sx={{ width: 361 }}
              size="small"
              label="Table/File *"
              value={selectedObjectTable}
              onChange={(e) => {
                setSelectedObjectTable(e.target.value)
                setError({ ...error, objTable: '' })
              }}
              disabled={dqCheckFunctionsData?.length > 0}
              error={handleDropDownErrors(error?.objTable) || false}
            >
              {repositoryObjectMeta?.length > 0 ? (
                repositoryObjectMeta.map((item: any, index: number) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No Record Found</em>
                </MenuItem>
              )}
            </DropdownComponent>
            <Typography
              sx={{
                fontSize: '0.75rem',
                margin: '4px 0px 0px 14px',
                fontWeight: '400',
                color: '#d32f2f',
              }}
            >
              {error?.objTable}
            </Typography>
          </Box>
        )}

        {/* {selectedAllCheckTypes !== 'SQL' && (
          <Box>
            <Text
              label="Column Delimiter (If reading to file system)"
              value={delimiter}
              onChange={(e: any) => {
                setDelimiter(e.target.value)
              }}
              disabled={type === 'view' || type === 'clone'}
              name="delimiter"
            />
          </Box>
        )} */}

        {/* Dynamic Component Load From below */}
        <Box sx={{ display: 'flex', width: 361, justifyContent: 'flex-end' }}>
          <Button
            sx={{ color: '#42526E' }}
            variant="outlined"
            onClick={handleCloseDialog}
            disabled={enableAddDQFunBtn() || type === 'view'}
          >
            <Add />
          </Button>
        </Box>

        <Text
          label="Check Name *"
          value={dqCheckName}
          onChange={(e: any) => {
            setDqCheckName(e.target.value)
            setError({ ...error, dqmName: '' })
          }}
          disabled={type === 'view'}
          name="dqmName"
          error={error}
        />

        <Box sx={{ width: '100%', paddingRight: '20px' }}>
          {dqCheckFunctionsData?.length > 0 &&
            dqCheckFunctionsData.map((dqFun: any, index: number) => (
              <Accordion key={index} sx={{ width: '100%' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>DQ Check Function {index + 1}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                  >
                    <Text
                      label="Data Check Function"
                      value={dqFun?.name}
                      disabled={true}
                    />
                    {dqFun?.query !== '' && (
                      <Text
                        label="DB Query"
                        value={dqFun?.query}
                        disabled={true}
                        multiline={true}
                      />
                    )}
                    {dqFun?.option !== '' && (
                      <Text
                        label="Option"
                        value={dqFun?.option}
                        disabled={true}
                      />
                    )}
                    {dqFun?.rule !== '' && (
                      <Text label="Rule" value={dqFun?.rule} disabled={true} />
                    )}
                    {dqFun?.metrics?.length > 0 && dqFun?.metrics !== '' && (
                      <Text
                        label="Metrics"
                        value={dqFun?.metrics[0]}
                        disabled={true}
                      />
                    )}
                    {dqFun?.metrics_name && dqFun?.metrics_name !== '' && (
                      <Text
                        label="Metrics"
                        value={dqFun?.metrics_name}
                        disabled={true}
                      />
                    )}
                    <Box>
                      {dqFun?.columns?.length && (
                        <Typography>Data Check Fields</Typography>
                      )}
                      {dqFun?.columns?.length > 0 &&
                        dqFun?.columns?.map((item: any) => (
                          <Chip key={item} label={item} variant="outlined" />
                        ))}
                    </Box>
                    {dqFun?.params?.length > 0 &&
                      dqFun?.params?.map((item: any, index: number) => (
                        <Text
                          key={index}
                          label={item?.label}
                          value={item?.value}
                          disabled={true}
                        />
                      ))}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                      paddingTop: '20px',
                      gap: '20px',
                    }}
                  >
                    {type === 'clone' && (
                      <Edit
                        sx={{
                          color: CUSTOM_COLORS.backgroundColor,
                          cursor: 'pointer',
                        }}
                        onClick={() => handleCloseEditDialog(dqFun, index)}
                      />
                    )}
                    {type !== 'view' && (
                      <Delete
                        sx={{ color: CUSTOM_COLORS.backgroundColor }}
                        onClick={() => handleDeleteDQMFun(index)}
                      />
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            marginRight: '40px',
            justifyContent: 'flex-end',
            paddingBottom: '15%',
          }}
        >
          {type !== 'view' && (
            <Button
              sx={{ color: '#42526E' }}
              variant="text"
              onClick={handleSaveData}
              disabled={isSaveLoading || dqCheckFunctionsData?.length === 0}
            >
              {isSaveLoading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          )}
        </Box>
      </Box>
      <Dialog open={openDialog} handleClose={handleCloseDialog}>
        <AddDataCheckFun
          selectedBasicCheck={selectedCheckTypes}
          onClose={handleCloseDialog}
          metaData={{
            dataRepository: selectedDataSource,
            objTable: selectedObjectTable,
            checkType: selectedCheckTypes,
            allCheckType: selectedAllCheckTypes,
          }}
          showAlert={handleShowAlert}
          setDQFunData={setDQChecksFunctionsData}
          repoDetails={selectedRepositoryDetail}
        />
      </Dialog>

      <Dialog open={openEditDialog.isOpen} handleClose={handleCloseEditDialog}>
        <AddDataCheckFun
          selectedBasicCheck={selectedCheckTypes}
          onClose={handleCloseEditDialog}
          metaData={{
            dataRepository: selectedDataSource,
            objTable: selectedObjectTable,
            checkType: selectedCheckTypes,
            allCheckType: selectedAllCheckTypes,
          }}
          showAlert={handleShowAlert}
          setDQFunData={setDQChecksFunctionsData}
          repoDetails={selectedRepositoryDetail}
          type={type}
          editData={openEditDialog.editedData}
          editDataIndex={openEditDialog.index}
          dqmAllData={dqCheckFunctionsData}
        />
      </Dialog>
    </Box>
  )
}

export default ViewDataChecks
