/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { memo, useCallback, useEffect, useState } from 'react'

import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import SearchInputComponent from 'components/atoms/search-input'
import CardDetailsMolecule from 'components/molecules/card-details'

import { ApiServices } from 'api/services'
import LoaderComponent from 'components/atoms/loader-component'
import { useNavigate } from 'react-router-dom'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import { changeDateFormat } from 'utility/utility'
import NoRecordsFoundComponent from 'components/atoms/no-record'
import { getProfileInfo, removeProfileInfo, removeToken } from 'api/apiUtility'
import ProjectPagination from 'components/molecules/ProjectPagination'
import { ReactComponent as AppIcon } from 'assets/icons/operations.svg'

const LandingOperationPage = () => {
  const timer: any = React.useRef(null)

  const [isLoading, setLoading] = useState(true)
  const [recentAppsData, setRecentAppsData] = useState([])
  const [searchInput, setSearchInput] = useState('')

  //Pagination
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)

  const navigate = useNavigate()

  const handlePageChange = useCallback((params: any) => {
    setPage(params)
  }, [])

  const callRecentProjectDataApi = useCallback(async () => {
    const params = {
      pageNumber: page,
      pageSize: 12,
      search: {
        org_id: getProfileInfo().teamId,
        project_type: 'APP',
        search: searchInput,
      },
    }
    try {
      setLoading(true)

      const resp = await ApiServices.getProjectsAndApps(params)
      setLoading(false)
      setRecentAppsData(resp.data.data)
      setTotalCount(resp.data.totalRecords)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [page])

  useEffect(() => {
    callRecentProjectDataApi()
  }, [callRecentProjectDataApi, page])

  const onSearchInputChange = useCallback(async () => {
    const params = {
      pageNumber: page,
      pageSize: 12,
      search: {
        org_id: getProfileInfo().teamId,
        project_type: 'APP',
        search: searchInput,
      },
    }
    try {
      setLoading(true)

      const resp = await ApiServices.getProjectsAndApps(params)
      setLoading(false)
      setRecentAppsData(resp.data.data)
      setTotalCount(resp.data.totalRecords)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [searchInput, page])

  const navigateToGraphDashboard = useCallback(
    (id: string) => {
      navigate(
        `/${PAGE_ROUTE_CONSTANTS.OPERATIONS_PAGE}/${PAGE_ROUTE_CONSTANTS.OPERATION_DETAIL_LIST_PAGE}?projectId=${id}`,
      )
    },
    [navigate],
  )

  const inputHandler = useCallback(() => {
    onSearchInputChange()
  }, [searchInput, onSearchInputChange])

  const debounce: any =
    (func: () => void, timeout = 500) =>
    (...args: any) => {
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        func.apply(this, args)
      }, timeout)
    }

  const processChange = debounce(inputHandler)

  return (
    <>
      <Box>
        <Container sx={{ mt: 2, paddingBottom: 2 }}>
          {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton
              onClick={() => navigate(`/${PAGE_ROUTE_CONSTANTS.HOME}`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Box> */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '2%',
            }}
          >
            <SearchInputComponent
              value={searchInput}
              onChange={(e: any) => setSearchInput(e.target.value)}
              onKeyUp={processChange}
            />
          </Box>
          <Container>
            <Box sx={{ mb: 2 }}>
              <Typography
                sx={{ fontWeight: 'bold' }}
                variant="button"
                gutterBottom
              >
                {`ALL OPERATIONS`}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {recentAppsData &&
                recentAppsData.map((item: any) => {
                  return (
                    <Grid key={`recent_${item.sNo}`} item md={4} sm={6}>
                      <CardDetailsMolecule
                        label={`${item.project_name}`}
                        subLabel={`Last updated ${changeDateFormat(
                          item.last_updated_date,
                        )}`}
                        onClick={() => navigateToGraphDashboard(item.id)}
                        version="v2"
                        handleDetails={(e) => {
                          // details drawer
                        }}
                        icon={<AppIcon />}
                      ></CardDetailsMolecule>
                    </Grid>
                  )
                })}
            </Grid>
            {(!recentAppsData || recentAppsData.length === 0) && (
              <NoRecordsFoundComponent />
            )}
            {recentAppsData && recentAppsData.length > 0 && (
              <Box sx={{ marginTop: '20px' }}>
                <ProjectPagination
                  totalCount={totalCount}
                  onPageChange={handlePageChange}
                  page={page}
                />
              </Box>
            )}
            {/* <Divider sx={{ mt: 2 }} /> */}
          </Container>
        </Container>
      </Box>
      {isLoading && <LoaderComponent open={isLoading} />}
    </>
  )
}

export default memo(LandingOperationPage)
