import { Box, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'

interface DocumentPreviewProps {
  children?: any
  onClose?: any
  title?: string
}

function DocumentPreview({
  children,
  onClose,
  title = 'Document Preview',
}: DocumentPreviewProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        width: 'fit-content',
      }}
    >
      <Box
        sx={{
          // width: '100%',
          // height: '46px',
          display: 'flex',
          // alignItems: 'center',
          justifyContent: 'space-between',
          // px: '16px',
          position: 'sticky',
          padding: '10px',
          height: '100%',
          background: 'white',
          right: '10px',
          top: '0px',
          color: 'black',
          gap: '5px',
          zIndex: 99,
        }}
      >
        <Typography sx={{ fontWeight: '700', fontSize: '14px' }}>
          {title}
        </Typography>
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => onClose()} />
      </Box>
      {children}
    </Box>
  )
}

export default DocumentPreview
