/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { AccessTime, StarRate, Group, Delete } from '@mui/icons-material'
import { CUSTOM_COLORS } from 'theme'
import { getProfileInfo, removeProfileInfo, removeToken } from 'api/apiUtility'
import ProjectList from '../project-list'
import NewProjectComponent from 'pages/home/new-project'
import LoaderComponent from 'components/atoms/loader-component'
import { ApiServices } from 'api/services'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export const PROJECT_NAV_BAR = [
  {
    id: 0,
    name: 'All',
    icon: null,
    isActive: true,
  },
  {
    id: 2,
    name: 'Recent',
    icon: <AccessTime />,
    isActive: false,
  },
  // {
  //   id: 3,
  //   name: 'Starred',
  //   icon: <StarRate />,
  //   isActive: false,
  // },
  // {
  //   id: 4,
  //   name: 'Shared with me',
  //   icon: <Group />,
  //   isActive: false,
  // },
  // {
  //   id: 5,
  //   name: 'Trash',
  //   icon: <Delete />,
  //   isActive: false,
  // },
]

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: '79vh', overflowY: 'scroll' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '0px 25px 25px 0px' }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const TabsPanel = () => {
  const profileInfo = getProfileInfo()

  const [value, setValue] = useState(0)
  const [repoRules, setRepoRules] = useState<any>()
  const [navBar, setNavBar] = useState<Array<any>>([])
  const [open, setOpen] = useState(false)
  const [reloadPage, setReloadPage] = useState<any>(false)
  const [isLoading, setLoading] = useState(false)
  const [projectRules, setProjectRules] = useState<any>()
  const navigate = useNavigate()
  useEffect(() => {
    const profileInfoRoles = profileInfo
    if (profileInfoRoles && !repoRules) {
      setRepoRules(profileInfoRoles?.orgRole?.permissions)
      setProjectRules(profileInfoRoles?.orgRole?.permissions?.projects)
    }
  }, [profileInfo])

  useEffect(() => {
    setNavBar(PROJECT_NAV_BAR)
  }, [])

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue)
    },
    [],
  )

  const addNewProject = useCallback(
    async ({ project_name, project_user, project_description }: any) => {
      const payload = {
        data: {
          source: '10_org',
          project_items: [
            {
              project_name,
              project_description,
              configuration: {},
              active: true,
              project_user_id: project_user,
            },
          ],
        },
      }

      try {
        setLoading(true)
        ApiServices.addNewProject(payload)
        setLoading(false)
        setTimeout(() => {
          setReloadPage(Math.random())
        }, 1000)
        // console.log('res', res.data)
      } catch (error: any) {
        setLoading(false)
        console.log(error)
      }
    },
    [],
  )

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          paddingX: '1%',
        }}
      >
        <Typography variant="h4">Your Projects</Typography>
        <Box sx={{ textAlign: 'end' }}>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(true)
            }}
            disabled={!projectRules?.create}
          >
            Create New
          </Button>
        </Box>
      </Box>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {navBar &&
            navBar.map((navItem: any) => (
              <Tab
                key={navItem.id}
                sx={{
                  fontSize: '14px',
                  fontWeight: '600',
                  marginRight: '32px',
                  borderBottom: `2px solid ${CUSTOM_COLORS.tabOptionsBorder}`,
                }}
                icon={navItem.icon}
                iconPosition="start"
                label={navItem.name}
                {...a11yProps(navItem.id)}
              />
            ))}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ProjectList reloadPage={reloadPage} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ProjectList reloadPage={reloadPage} isRecent={true} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ProjectList reloadPage={reloadPage} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <ProjectList reloadPage={reloadPage} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <ProjectList reloadPage={reloadPage} />
      </CustomTabPanel>
      <NewProjectComponent
        open={open}
        setOpen={setOpen}
        addNewProject={addNewProject}
      />
      <LoaderComponent open={isLoading} />
    </Box>
  )
}

export default TabsPanel
