import { ArrowBack } from '@mui/icons-material'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { Admin } from 'api/services'

import React, { useCallback, useState } from 'react'
// import { useDispatch } from 'react-redux'

// import { AppDispatch } from 'store/store'
import { AddDataSourceProps } from 'types/props-types'
import Text from 'components/atoms/Text'
const getQueryParam = (param: string) => {
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.get(param)
}
const AddUser = ({
  heading,
  onClose,
  triggerReload,
  messageConfig,
  showMessage,
}: AddDataSourceProps) => {
  // const dispatch = useDispatch<AppDispatch>()

  const [error, setError] = useState<any>({})
  const [isSaveLoading, setSaveLoading] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const id = getQueryParam('id')
  const handleShowAlert = useCallback(
    (msg: string, type = 'success') => {
      showMessage({
        ...messageConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [messageConfig],
  )

  const handleRequiredFieldValidation = useCallback(() => {
    const validateEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (firstName === '') {
      setError({ ...error, firstName: 'Please Enter First Name' })
      return
    }
    if (lastName === '') {
      setError({ ...error, lastName: 'Please Enter Last Name' })
      return
    }
    if (email === '') {
      setError({ ...error, email: 'Please Enter Email' })
      return
    }

    if (!validateEmail.test(String(email).toLowerCase())) {
      setError({ ...error, email: 'Please Enter Valid Email' })
      return
    }
    return true
  }, [error, isSaveLoading])

  const handleSaveData = useCallback(() => {
    const isValidationOk = handleRequiredFieldValidation()

    if (!isValidationOk) {
      return
    }

    setSaveLoading(true)
    if (id) {
      const newDataSource = {
        firstName: firstName,
        lastName: lastName,
        username: email,
        orgId: id,
        isAdminUser: true,
      }

      Admin.addUsers(newDataSource)
        .then((res: any) => {
          setSaveLoading(false)
          if (res?.status === 200) {
            triggerReload?.()
            handleShowAlert(
              res?.data?.msg ? res.data.msg : 'User Added Successfully',
            )
            onClose?.()
          } else {
            triggerReload?.()
            handleShowAlert(
              res?.error?.message
                ? res?.error?.message
                : 'Something Went Wrong',
              'error',
            )
            onClose?.()
          }
        })
        .catch((error: any) => {
          setSaveLoading(false)
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        })
    } else {
      const newDataSource = {
        firstName: firstName,
        lastName: lastName,
        username: email,
      }

      Admin.addUsers(newDataSource)
        .then((res: any) => {
          setSaveLoading(false)
          if (res?.status === 200) {
            triggerReload?.()
            handleShowAlert(
              res?.data?.msg ? res.data.msg : 'User Added Successfully',
            )
            onClose?.()
          } else {
            triggerReload?.()
            handleShowAlert(
              res?.error?.message
                ? res?.error?.message
                : 'Something Went Wrong',
              'error',
            )
            onClose?.()
          }
        })
        .catch((error: any) => {
          setSaveLoading(false)
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        })
    }
  }, [error, isSaveLoading, firstName, lastName, email])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '424px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '28px 0px 28px 0px',
          gap: '16px',
        }}
      >
        <ArrowBack sx={{ cursor: 'pointer' }} onClick={onClose} />
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          {heading}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '32px',
          overflowY: 'scroll',
          paddingTop: '20px',
        }}
      >
        {/* <SelectComponent /> */}
        <Text
          label="First Name *"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value)
            setError({ ...error, firstName: '' })
          }}
          name="firstName"
          error={error}
        />

        <Text
          label="Last Name *"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value)
            setError({ ...error, lastName: '' })
          }}
          name="lastName"
          error={error}
        />

        <Text
          label="Email *"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
            setError({ ...error, email: '' })
          }}
          name="email"
          error={error}
        />

        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            marginRight: '40px',
            justifyContent: 'flex-end',
            paddingBottom: '15%',
          }}
        >
          <Button
            sx={{ color: '#42526E' }}
            variant="text"
            onClick={handleSaveData}
            disabled={isSaveLoading}
          >
            {isSaveLoading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AddUser
