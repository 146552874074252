/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import {
  Box,
  ButtonBase,
  CardProps,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material'
import CardComponent from 'components/atoms/card'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import React, { useCallback, useState } from 'react'
import { Delete, Info, Publish } from '@mui/icons-material'
import ConfirmationDialog from './ConfirmationDialog'

interface CardDetailsInterface extends CardProps {
  label?: string
  subLabel?: string
  data?: any
  icon?: any
  version?: 'v1' | 'v2'
  template?: any
  handleDetails: (event: any) => void // Define function signature for handleDetails
}

const CardDetailsMolecule = (props: CardDetailsInterface) => {
  const {
    label = '',
    subLabel = '',
    children,
    data,
    version,
    icon,
    handleDetails,
    template = false,
  } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [openDialog, setOpenDialog] = useState(false)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleOpenDialog = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setOpenDialog(true)
    },
    [openDialog, setOpenDialog],
  )

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false)
    handleClose()
  }, [openDialog, setOpenDialog])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [anchorEl, setAnchorEl])

  const handleComponentDelete = useCallback(() => {
    console.log('Component Delete Clicked')
  }, [])

  const options = [
    {
      label: 'Delete',
      icon: <Delete />,
      onclick: (event: React.MouseEvent<HTMLElement>) =>
        handleOpenDialog(event),
    },
    {
      label: 'Publish',
      icon: <Publish />,
      onclick: (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        console.log('Publish Btn Clicked')
        handleClose()
      },
    },
    {
      label: 'Details',
      icon: <Info />, // Use an appropriate icon for details (e.g., Info)
      onclick: (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        console.log('Details Btn Clicked')
        if (handleDetails) {
          // Ensure handleDetails exists before calling
          handleDetails(event) // Pass the event if needed
          handleClose()
        } // Define your handleDetails function
      },
    },
  ]

  return (
    <>
      {version && version === 'v1' && (
        <Box>
          <CardComponent {...props}>{children}</CardComponent>
          {data?.type ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{ mt: 1, background: 'transparent !important' }}
              >
                {label}
              </Typography>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {options.map((option) => (
                  <MenuItem key={option.label} onClick={option.onclick}>
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                      {option.icon}
                      {option.label}
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <Typography variant="subtitle2" gutterBottom sx={{ mt: 1 }}>
              {label}
            </Typography>
          )}
          <Typography variant="caption" gutterBottom>
            {subLabel}
          </Typography>
          <ConfirmationDialog
            open={openDialog}
            handleYesButton={handleComponentDelete}
            handleClose={handleCloseDialog}
            dialogHeading="Confirmation"
            dialogContentText="Do You Really Want to Delete This Component"
          />
        </Box>
      )}
      {version && version === 'v2' && (
        <Paper
          sx={{ height: '100px', padding: '20px' }}
          elevation={3}
          {...props}
        >
          {data?.type ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{
                  background: 'transparent !important',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {icon ? icon : null}
                <span style={{ marginLeft: '20px' }}>{label}</span>
              </Typography>

              <ButtonBase
                sx={{ borderRadius: 50, marginLeft: 'auto' }}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </ButtonBase>

              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation()
                  handleClose()
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option.label} onClick={option.onclick}>
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                      {option.icon}
                      {option.label}
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              {icon && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {icon ? icon : null}
                </Box>
              )}

              {template ? (
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{ mt: 1, background: 'transparent !important' }}
                >
                  {label}
                </Typography>
              ) : (
                <Typography
                  noWrap
                  variant="subtitle2"
                  gutterBottom
                  sx={{
                    mt: 1,
                    background: 'transparent !important',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {label}
                </Typography>
              )}
            </Box>
          )}
          <Box sx={{ display: 'flex' }}>
            <Typography
              sx={{ color: 'text.secondary' }}
              variant="caption"
              gutterBottom
            >
              {subLabel}
            </Typography>
          </Box>
          <ConfirmationDialog
            open={openDialog}
            handleYesButton={handleComponentDelete}
            handleClose={handleCloseDialog}
            dialogHeading="Confirmation"
            dialogContentText="Do You Really Want to Delete This Component"
          />
        </Paper>
      )}
      {!version && (
        <Box>
          <CardComponent {...props}>{children}</CardComponent>
          {data?.type ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{ mt: 1, background: 'transparent !important' }}
              >
                {label}
              </Typography>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {options.map((option) => (
                  <MenuItem key={option.label} onClick={option.onclick}>
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                      {option.icon}
                      {option.label}
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <Typography variant="subtitle2" gutterBottom sx={{ mt: 1 }}>
              {label}
            </Typography>
          )}
          <Typography variant="caption" gutterBottom>
            {subLabel}
          </Typography>
          <ConfirmationDialog
            open={openDialog}
            handleYesButton={handleComponentDelete}
            handleClose={handleCloseDialog}
            dialogHeading="Confirmation"
            dialogContentText="Do You Really Want to Delete This Component"
          />
        </Box>
      )}
    </>
  )
}

export default CardDetailsMolecule
