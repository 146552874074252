export const style: any = [
  {
    selector: 'node',
    style: {
      'background-color': 'blue',
      label: function (element: any) {
        const name = element.data('cname')
        return `${name}`
      },
      'font-size': 4,
      'text-valign': 'bottom',
      'text-halign': 'center',
      'text-wrap': 'wrap',
      'text-max-width': 50,
      'text-overflow-wrap': 'anywhere',
      width: 25,
      height: 25,
      'border-width': '1px',
    },
  },
  {
    selector: 'edge',
    style: {
      width: 1,
      'line-color': function (element: any) {
        const edgeColor = element.data('color')
        return edgeColor
      },
      'curve-style': 'bezier',
    },
  },
]
