import { ArrowBack } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Typography,
} from '@mui/material'
import { dataSourceService } from 'api/services'
import Text from 'components/atoms/Text'
import DropdownComponent from 'components/atoms/dropdown'
import DynamicForm from 'components/molecules/DynamicForm'

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { AppDispatch } from 'store/store'
import { AddDataSourceProps } from 'types/props-types'
import { encryptObject } from 'utility/enc-dec'

const AddDataSource = ({
  heading,
  onClose,
  triggerReload,
  messageConfig,
  showMessage,
}: AddDataSourceProps) => {
  const [dataSourceTypes, setDataSourceTypes] = useState([])
  const [selectedDataSource, setSelectedDataSource] = useState('')
  const [editConfiguration, setEditConfiguration] = useState<any>({})
  const [dataSourceData, setDataSourceData] = useState<any>({})
  const [dataAlias, setDataAlias] = useState('')
  const [dataSourceDesc, setDataSourceDesc] = useState('')
  const [error, setError] = useState<any>({})
  const [isTestLoading, setTestLoading] = useState(false)
  const [isSaveLoading, setSaveLoading] = useState(false)
  const [isConnectionTested, setConnectionTested] = useState(false)

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(dataSourceService.getAllDataSourceTypes({ page: 1, size: 10 }))
      .then((res: any) => {
        setDataSourceTypes(res?.payload?.data)
      })
      .catch((error) => {
        if (error.message) {
          handleShowAlert(error.message, 'error')
        } else {
          handleShowAlert('Something went wrong', 'error')
        }
      })
  }, [])

  useEffect(() => {
    if (selectedDataSource !== '') {
      dispatch(
        dataSourceService.getDataSourceTypesById({ id: selectedDataSource }),
      )
        .then((res: any) => {
          setEditConfiguration(res.payload?.configuration.input)
          setDataSourceData(res?.payload)
        })
        .catch((error: any) => {
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        })
    }
  }, [selectedDataSource])

  const handleSelectDataSource = useCallback((e: any) => {
    setSelectedDataSource(e.target.value)
    setError({ ...error, config: '' })
  }, [])

  const handleDataAliasChange = useCallback((e: any) => {
    if (e.target.value !== '') {
      setDataAlias(e.target.value)
      setError({ ...error, dsName: '' })
    } else {
      setDataAlias(e.target.value)
    }
  }, [])

  const handleShowAlert = useCallback(
    (msg: string, type = 'success') => {
      showMessage({
        ...messageConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [messageConfig],
  )

  const getDatabyUsingKey = useCallback(
    (key: any) => {
      const arr = editConfiguration
      const index = arr?.findIndex((item: any) => item?.key === key)
      if (index !== -1 && key !== 'port') {
        return arr[index].value
      } else if (index !== -1 && key === 'port') {
        return parseInt(arr[index].value)
      } else {
        return null
      }
    },
    [editConfiguration],
  )

  const handleRequiredFieldValidation = useCallback(() => {
    let isValidationOk = true

    if (Object.keys(editConfiguration).length === 0) {
      setError({ ...error, config: 'Please Select Repository Type' })
      return false
    }

    if (dataAlias === '') {
      setError({ ...error, dsName: 'This is Mandatory Field' })
      return false
    }

    if (dataSourceDesc === '') {
      setError({ ...error, dsDesc: 'This is Mandatory Field' })
      return false
    }

    editConfiguration?.some((item: any) => {
      if (item?.value === '') {
        if (item?.isRequired === true) {
          setError({
            ...error,
            [item?.key]: 'This is Mandatory Field',
          })
          isValidationOk = false
          return true
        }
      }
    })

    return isValidationOk
  }, [
    dataAlias,
    dataSourceDesc,
    selectedDataSource,
    editConfiguration,
    error,
    isSaveLoading,
  ])

  const handleTestConnection = useCallback(() => {
    const isValidationOk = handleRequiredFieldValidation()

    if (!isValidationOk) {
      return
    }

    setTestLoading(true)
    const caseType = dataSourceData?.data_repository_type

    const testObject: any = {
      repositoryType: caseType,
      databaseSettings: null,
      awsS3Settings: null,
      awsAthenaSettings: null,
      azureBlobStorageSettings: null,
      elasticSearchSettings: null,
    }

    switch (caseType) {
      case 'RELATIONAL_POSTGRESQL':
        testObject.databaseSettings = encryptObject({
          server: getDatabyUsingKey('server'),
          hostname: getDatabyUsingKey('hostname'),
          username: getDatabyUsingKey('username'),
          password: getDatabyUsingKey('password'),
          database: getDatabyUsingKey('database'),
          port: getDatabyUsingKey('port') | 0,
        })['data']
        break
      case 'AWS_ATHENA':
        testObject.awsAthenaSettings = encryptObject({
          regionName: getDatabyUsingKey('region_name'),
          awsAccessKeyId: getDatabyUsingKey('aws_access_key_id'),
          awsSecretAccessKey: getDatabyUsingKey('aws_secret_access_key'),
          s3StagingDir: getDatabyUsingKey('s3_staging_dir'),
          database: getDatabyUsingKey('database'),
        })['data']
        break
      case 'AWS_S3':
        testObject.awsS3Settings = encryptObject({
          regionName: getDatabyUsingKey('region_name'),
          awsAccessKeyId: getDatabyUsingKey('aws_access_key_id'),
          awsSecretAccessKey: getDatabyUsingKey('aws_secret_access_key'),
          bucketName: getDatabyUsingKey('bucket_name'),
          directoryAbsolutePath: getDatabyUsingKey('directory_absolute_path'),
        })['data']
        break
      case 'AZURE_BLOB_STORAGE':
        testObject.azureBlobStorageSettings = encryptObject({
          connectionString: getDatabyUsingKey('connection_string'),
          containerName: getDatabyUsingKey('container_name'),
        })['data']
        break
      case 'RELATIONAL_MSSQL':
        testObject.databaseSettings = encryptObject({
          server: getDatabyUsingKey('server'),
          hostname: getDatabyUsingKey('hostname'),
          username: getDatabyUsingKey('username'),
          password: getDatabyUsingKey('password'),
          database: getDatabyUsingKey('database'),
          port: getDatabyUsingKey('port') | 0,
        })['data']
        break
      case 'DGRAPH':
        testObject.dgraphSettings = encryptObject({
          dgraphEndpoint: getDatabyUsingKey('url'),
        })['data']
        break
      case 'ELASTIC_SEARCH':
        testObject.elasticSearchSettings = encryptObject({
          elasticSearchUrl: getDatabyUsingKey('elastic_search_url'),
          elasticCloudId: getDatabyUsingKey('elastic_cloud_id'),
          elasticApiKey: getDatabyUsingKey('elastic_api_key'),
        })['data']
        break
      case 'KAFKA':
        testObject.kafkaSettings = encryptObject({
          server: getDatabyUsingKey('server'),
          port: getDatabyUsingKey('port'),
          groupId: getDatabyUsingKey('group_id'),
          offsets: getDatabyUsingKey('offsets'),
          protocol: getDatabyUsingKey('protocol'),
        })['data']
        break
    }

    dataSourceService
      .testDataSourceConnection(testObject)
      .then((res: any) => {
        setTestLoading(false)
        handleShowAlert(
          res?.data?.message
            ? res.data.message
            : 'Data Repository Connected Successfully',
        )
        setConnectionTested(true)
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message

        setTestLoading(false)
        handleShowAlert(errorMessage, 'error')
      })
  }, [
    dataAlias,
    dataSourceDesc,
    selectedDataSource,
    editConfiguration,
    error,
    isSaveLoading,
    dataSourceData,
    isTestLoading,
  ])

  const handleSaveData = useCallback(() => {
    const isValidationOk = handleRequiredFieldValidation()

    if (!isValidationOk) {
      return
    }

    setSaveLoading(true)

    const newDataSource = {
      id: null,
      data_repository_name: dataAlias,
      data_repository_description: dataSourceDesc,
      data_repository_type_id: selectedDataSource,
      configuration: {
        input: encryptObject(editConfiguration)['data'],
      },
    }

    dispatch(dataSourceService.addEditDataSource(newDataSource))
      .then((res: any) => {
        setSaveLoading(false)
        if (res?.payload?.status === 200) {
          triggerReload?.()
          handleShowAlert(
            res?.payload?.data?.message
              ? res.payload.data.message
              : 'Data Repository Added Successfully',
          )
          onClose?.()
        } else {
          triggerReload?.()
          handleShowAlert(
            res?.error?.message ? res?.error?.message : 'Something Went Wrong',
            'error',
          )
          onClose?.()
        }
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
      })
  }, [
    dataAlias,
    dataSourceDesc,
    selectedDataSource,
    editConfiguration,
    error,
    isSaveLoading,
  ])

  const handleDSError = useCallback(() => {
    if (typeof error?.config !== 'undefined') {
      if (error?.config !== '') {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }, [error])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '424px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '28px 0px 28px 0px',
          gap: '16px',
        }}
      >
        <ArrowBack sx={{ cursor: 'pointer' }} onClick={onClose} />
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          {heading}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '32px',
          overflowY: 'scroll',
          paddingTop: '20px',
        }}
      >
        {/* <SelectComponent /> */}
        <Box>
          <DropdownComponent
            sx={{ width: 361 }}
            size="small"
            label="Repository Type *"
            value={selectedDataSource}
            onChange={(e) => {
              handleSelectDataSource(e)
              setDataAlias('')
              setDataSourceDesc('')
            }}
            error={handleDSError() || false}
          >
            {dataSourceTypes?.length > 0 ? (
              dataSourceTypes.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.data_repository_type}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>No Record Found</em>
              </MenuItem>
            )}
          </DropdownComponent>
          <Typography
            sx={{
              fontSize: '0.75rem',
              margin: '4px 0px 0px 14px',
              fontWeight: '400',
              color: '#d32f2f',
            }}
          >
            {error?.config}
          </Typography>
        </Box>
        <Text
          label="Short Alias for the Data Repository *"
          value={dataAlias}
          onChange={handleDataAliasChange}
          name="dsName"
          error={error}
        />
        <Text
          label="Data Repository Description *"
          value={dataSourceDesc}
          onChange={(e: any) => {
            setDataSourceDesc(e.target.value)
            setError({ ...error, dsDesc: '' })
          }}
          name="dsDesc"
          error={error}
        />
        {editConfiguration?.length > 0 && (
          <Box>
            <DynamicForm
              formData={editConfiguration}
              handleChange={setEditConfiguration}
              error={error}
              setError={setError}
              type="add"
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            marginRight: '40px',
            justifyContent: 'flex-end',
            paddingBottom: '15%',
          }}
        >
          <Button
            sx={{ color: '#42526E' }}
            variant="text"
            onClick={handleTestConnection}
            disabled={isTestLoading}
          >
            {isTestLoading ? <CircularProgress size={24} /> : 'Test Connection'}
          </Button>
          <Button
            sx={{ color: '#42526E' }}
            variant="text"
            onClick={handleSaveData}
            disabled={isSaveLoading || !isConnectionTested}
          >
            {isSaveLoading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AddDataSource
