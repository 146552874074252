/* eslint-disable no-unused-vars */
import DataProcessingDialog from 'pages/projects/data-pipeline/data-processing.dialog'
import TransformOperationReact from './transform-operation.dialog'
import React from 'react'

const DataDialog = (props: any) => {
  const { selectedNode, NODE_TYPES } = props
  return (
    <>
      {selectedNode.component_node_type_id ===
        NODE_TYPES.documentProcessing.id || NODE_TYPES.knowledgeRetrieval.id ? (
        <DataProcessingDialog {...props} />
      ) : (
        <TransformOperationReact {...props} />
      )}
    </>
  )
}

export default DataDialog
