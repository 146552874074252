/* eslint-disable prettier/prettier */
import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

interface DataItem {
  category: string
  value1: number
  value2: number
}

const PieChart: React.FC<{
  data: DataItem[]
  width: number
  height: number
  fields: any
  dataName: any
  legendName: any
}> = ({ data, width, height, fields, dataName, legendName }) => {
  const svgRef = useRef<SVGSVGElement>(null)

  useEffect(() => {
    const concatDynamicString = (d: any) => {
      const text: any = [`${d.data[dataName]}:`]
      fields.forEach((item: any) => {
        // valueAdd = valueAdd + d[item.field]
        text.push(` ${item.headerName} - ${d.data[item.field]}, `)
      })
      // `${d.data.name}: Value1 - ${d.data.value1}, Value2 - ${d.data.value2}`
      return text.toString()
    }

    if (!data || !svgRef.current) return

    const svg = d3.select(svgRef.current)

    const pieWidth = width / 2

    const radius = Math.min(pieWidth, height) / 2
    const color = d3.scaleOrdinal(d3.schemeCategory10)

    const pie = d3
      .pie<any>()
      .sort(null)
      .value((d) => {
        let valueAdd = 0
        fields.forEach((item: any) => {
          valueAdd = valueAdd + d[item.field]
        })
        return valueAdd
      })

    const arc = d3
      .arc<d3.PieArcDatum<DataItem>>()
      .innerRadius(0)
      .outerRadius(radius)

    const arcs = svg
      .selectAll('.arc')
      .data(pie(data))
      .enter()
      .append('g')
      .attr('class', 'arc')
      .attr('transform', `translate(${pieWidth / 2}, ${height / 2})`)

    arcs
      .append('path')
      .attr('d', arc)
      .attr('fill', (d) => color(d.data[dataName]))
      .append('title')
     
    // .text(
    //   (d) =>
    //     `${d.data.category}: Value1 - ${d.data.value1}, Value2 - ${d.data.value2}`,
    // )

    arcs
      .on('mouseover', function (event, d) {
        // const tooltipText = `${d.data.name}: Value1 - ${d.data.value1}, Value2 - ${d.data.value2}`
        const tooltipText = concatDynamicString(d)
        d3.select(this).select('path').attr('fill', 'orange')
        tooltip.text(tooltipText).style('visibility', 'visible')
      })
      .on('mousemove', function (event) {
        tooltip
          .style('top', event.pageY - 10 + 'px')
          .style('left', event.pageX + 10 + 'px')
      })
      .on('mouseout', function () {
        d3.select(this)
          .select('path')
          .attr('fill', (d: any) => color(d.data[dataName]))
        tooltip.style('visibility', 'hidden')
      })

    svg
      .append('text')
      .attr('class', 'legend-title')
      .attr('x', width - 100)
      .attr('y', 30)
      .style('text-anchor', 'middle')
      .text(legendName)
      .attr('fill', '#808080');

    // Add legend
    const legend = svg
      .selectAll('.legend')
      .data(data.map((d: any) => d[dataName]))
      .enter()
      .append('g')
      .attr('class', 'legend')
      .attr('transform', (_, i) => `translate(0, ${i * 20})`)

    legend
      .append('rect')
      .attr('x', width - 18)
      .attr('y', 40)
      .attr('width', 18)
      .attr('height', 18)
      .style('fill', (d) => color(d))

    legend
      .append('text')
      .attr('x', width - 24)
      .attr('y', 50)
      .attr('dy', '.35em')
      .style('text-anchor', 'end')
      .text((d) => d)
      .attr('fill', '#808080');

  }, [data, width, height])

  const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('z-index', '10')
    .style('visibility', 'hidden')
    .style('background-color', 'rgba(255, 255, 255, 0.9)')
    .style('padding', '5px')
    .style('border', '1px solid #ccc')

  return <svg ref={svgRef} width={width} height={height} />
}

// Sample data
// const dataO: DataItem[] = [
//   { category: 'A', value1: 10.5, value2: 15 },
//   { category: 'B', value1: 20, value2: 25.9 },
//   { category: 'C', value1: 30.5, value2: 35 },
// ]

interface PieChartProps {
  data: Array<any>
  fields: any
  dataName: any
  legendName: any
}

const PieChartExample = (props: PieChartProps) => {
  const { data, fields, dataName, legendName } = props
  return (
    <PieChart
      data={data}
      width={1000}
      height={600}
      fields={fields}
      dataName={dataName}
      legendName={legendName}
    />
  )
}

export default PieChartExample
