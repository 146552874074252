import React from 'react'
import { Outlet } from 'react-router-dom'

const AppsPage = () => {
  return (
    <>
      {/* <LandingApps /> */}
      <Outlet />
    </>
  )
}

export default AppsPage
