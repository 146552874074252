import { Backdrop, BackdropProps, CircularProgress } from '@mui/material'
import React from 'react'

const LoaderComponent = (props: BackdropProps) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      {...props}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default LoaderComponent
