import React from 'react'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'

interface ProjectPaginationProps {
  onPageChange: any
  totalCount: any
  page?: any
}

function ProjectPagination({
  onPageChange,
  totalCount,
  page,
}: ProjectPaginationProps) {
  return (
    <Stack spacing={2} alignItems={'center'}>
      <Pagination
        color="primary"
        page={page}
        count={Math.ceil(parseFloat(`${totalCount / 12}`)) || 1}
        showFirstButton
        showLastButton
        onChange={(_, newPage) => {
          onPageChange(newPage)
        }}
      />
    </Stack>
  )
}

export default ProjectPagination
