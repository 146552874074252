import React, { useMemo } from 'react'
import { GridPagination } from '@mui/x-data-grid'
import MuiPagination from '@mui/material/Pagination'
import { TablePaginationProps } from '@mui/material/TablePagination'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store/store'
import { setPage } from 'store/dataSourceTypesSlice'

const Pagination = ({
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
  const dispatch = useDispatch<AppDispatch>()
  const dsState = useSelector((state: RootState) => state.dataSourceSlice)
  const totalCount = useMemo(
    () => dsState?.paginationData?.totalRecords,
    [dsState?.paginationData?.totalRecords],
  )
  const pageSize = useMemo(
    () => dsState?.paginationData?.pageSize,
    [dsState?.paginationData?.pageSize],
  )
  const page = useMemo(
    () => dsState?.paginationData?.page,
    [dsState?.paginationData?.page],
  )

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={Math.ceil(parseFloat(`${totalCount / pageSize}`)) || 1}
      page={
        Math.ceil(parseFloat(`${totalCount / pageSize}`)) >= page ? page : 1
      }
      onChange={(event, newPage) => {
        dispatch(setPage(newPage))
        onPageChange(event as any, newPage - 1)
      }}
    />
  )
}

const CustomPagination = (props: any) => {
  return <GridPagination ActionsComponent={Pagination} {...props} />
}

export default CustomPagination
