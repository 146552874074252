import styled from '@emotion/styled'
import { FormControl, InputLabel, Select, SelectProps } from '@mui/material'
import React from 'react'

const StyledSelect = styled(Select)<SelectProps>(() => ({
  height: '2.4rem',
}))

const DropdownComponent = (props: SelectProps) => {
  const { label = '', children } = props
  return (
    <FormControl fullWidth>
      <InputLabel sx={{ top: '-8px' }}>{label}</InputLabel>

      <StyledSelect {...props}>{children}</StyledSelect>
    </FormControl>
  )
}

export default DropdownComponent
