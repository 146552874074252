import CryptoJS from 'crypto-js'

const crypto_crendentials = {
  secretKey: 'vmnTU2MpiRL5swzfZTdstXGElmwMx0iM',
}

const secret_in_bytes = convertSecretToBytes(crypto_crendentials.secretKey)
const secret_in_hex = toHexString(secret_in_bytes)

/**
 * This method converts the
 * secret key to bytes
 * @param {string} params
 * @returns array
 */
function convertSecretToBytes(params: string) {
  const utf8Encode = new TextEncoder()
  return utf8Encode.encode(params)
}

/**
 * This method converts bytes
 * array into hex string
 * @param {array} byteArray
 * @returns string
 */
function toHexString(byteArray: any) {
  return Array.from(byteArray, function (byte: any) {
    return ('0' + (byte & 0xff).toString(16)).slice(-2)
  }).join('')
}

/**
 * This method encrypts the data
 * before sending the response
 * back to the client.
 * @param {JSON} data
 * @returns JSON
 */
export function encryptObject(data: any) {
  try {
    const enc = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      CryptoJS.enc.Hex.parse(secret_in_hex),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      },
    )

    return { data: enc.ciphertext.toString(), status: 200 }
  } catch (error: any) {
    return { err: 'CANNOT_ENCRYPT_DATA', status: 500 }
  }
}

/**
 * This method decrypts the data
 * @param {string} encrypted_string
 * @returns JSON
 */
export function decryptObject(encrypted_string: string) {
  try {
    const bytes = CryptoJS.AES.decrypt(
      CryptoJS.format.Hex.parse(encrypted_string),
      CryptoJS.enc.Hex.parse(secret_in_hex),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      },
    )
    const decryptedData = CryptoJS.enc.Utf8.stringify(bytes)
    return { data: decryptedData, status: 200 }
  } catch (error: any) {
    return { err: 'CANNOT_DECRYPT_DATA', status: 500 }
  }
}

export function decryptSettings(encryptedSettings: any) {
  const decryptedSettings: any = decryptObject(encryptedSettings)['data']

  // Remove surrounding quotes from the decrypted string
  const strippedSettings = decryptedSettings?.replace(/^"(.*)"$/, '$1')

  // Parse the decrypted and stripped settings as JSON
  return JSON.parse(strippedSettings)
}
