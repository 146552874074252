/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { Add } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Drawer,
  Typography,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from '@mui/material'

// Import your services from the API
import {
  getAllEventTypes,
  getAllRolePermissions,
  getEventTypeCategories,
  getEventTypeModules,
  getEventTypePriorities,
  getEventTypeSearch,
  getNotificationChannel,
  getNotificationPreference,
  updateNotificationPreference,
} from 'api/services/api-services'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { CUSTOM_COLORS } from 'theme'
import { getProfileInfo, removeProfileInfo, removeToken } from 'api/apiUtility'
import { useNavigate } from 'react-router-dom'
import DropdownComponent from 'components/atoms/dropdown'
// Define types for form data and preferences
interface NotificationPreferenceData {
  module: string
  category: string
  eventType: string[]
  roles: string[]
  priority: string
  channels: string[]
}

interface Preference {
  id: string
  module: string
  category: string
  event_type: { id: string; eventType: string }[]
  notification_channels: { id: string; notificationChannel: string }[]
}

const NotificationPreference: React.FC = () => {
  const [allModulesData, setAllModulesData] = useState<
    { id: string; name: string }[]
  >([])
  const [allCategoryData, setAllCategoryData] = useState<
    { id: string; name: string }[]
  >([])
  const [allEventTypesData, setAllEventTypesData] = useState<
    { id: string; event_type: string }[]
  >([])
  const [allRolesData, setAllRolesData] = useState<
    { id: string; role: string }[]
  >([])
  const [allPriorityData, setAllPriorityData] = useState<
    { id: string; name: string }[]
  >([])
  const [allNotificationChannel, setAllNotificationChannel] = useState<
    { id: string; label: string }[]
  >([])
  const [notificationPreferences, setNotificationPreferences] = useState<
    Preference[]
  >([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [editData, setEditData] = useState<Preference | null>(null) // Store data for edit mode
  const [formData, setFormData] = useState<NotificationPreferenceData>({
    module: '',
    category: '',
    eventType: [],
    roles: [],
    priority: '',
    channels: [],
  })
  const [userRoles, setUserRoles] = useState<any>()
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  })
  const navigate = useNavigate()
  useEffect(() => {
    fetchInitialData()
  }, [])

  const profileInfo = getProfileInfo()

  useEffect(() => {
    const profileInfoRoles = profileInfo
    if (profileInfoRoles && !userRoles) {
      setUserRoles(
        profileInfoRoles?.orgRole?.permissions?.org_notification_preferences,
      )
    }
  }, [profileInfo, userRoles])

  // Fetch only modules and notification preferences initially
  const fetchInitialData = async () => {
    try {
      const [modulesRes, preferencesRes, priorities, channels] =
        await Promise.all([
          getEventTypeModules(),
          getNotificationPreference({ module: null, category: null }),
          getEventTypePriorities(),
          getNotificationChannel(),
        ])

      // Set modules and preferences data
      setAllModulesData(modulesRes.data || [])
      setAllPriorityData(priorities.data || [])
      setAllNotificationChannel(channels?.data || [])
      setNotificationPreferences(preferencesRes.data?.data || [])
    } catch (error: any) {
      console.error('Error fetching initial data:', error)
    }
  }

  // Fetch dependent data after module is selected
  const fetchCategoryAndRoles = async (module: string) => {
    try {
      const [categoriesRes, rolesRes] = await Promise.all([
        getEventTypeCategories(module),
        getAllRolePermissions({
          search: '',
          status: 'ACTIVE',
          scope: module,
          searchAll: false,
        }),
      ])

      // Set categories and roles data
      setAllCategoryData(categoriesRes.data || [])
      setAllRolesData(rolesRes.data?.data || [])
    } catch (error: any) {
      console.error('Error fetching category and roles:', error)
    }
  }

  // Fetch event types after category is selected
  const fetchEventTypesAndSetDefaults = async (
    module: string,
    category: string,
  ) => {
    try {
      const eventTypesRes = await getEventTypeSearch({ module, category })

      // Set event types data
      setAllEventTypesData(eventTypesRes.data.data || [])

      // Extract default values from event type response
      if (eventTypesRes.data && eventTypesRes.data.data.length > 0) {
        const defaultEventType = eventTypesRes.data.data[0]
        setFormData((prevFormData) => ({
          ...prevFormData,
          priority: defaultEventType.default_priority || '', // Default priority
          channels:
            defaultEventType.default_notification_channels?.map(
              (channel: any) => channel._id,
            ) || [], // Default channels
        }))
      }
    } catch (error: any) {
      console.error('Error fetching event types and setting defaults:', error)
    }
  }

  useEffect(() => {
    // Fetch initial data when component mounts
    fetchInitialData()
  }, [])

  const handleChange = (e: any) => {
    const { name, value } = e.target

    if (name) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value as string | string[],
      }))

      // When the module changes, fetch category and roles data
      if (name === 'module') {
        fetchCategoryAndRoles(value as string) // Call the function to fetch categories and roles
        setFormData((prevFormData) => ({
          ...prevFormData,
          category: '', // Reset category when module changes
          eventType: [], // Reset event types when module changes
        }))
      }

      // When the category changes, fetch event types and set default values
      if (name === 'category') {
        fetchEventTypesAndSetDefaults(formData.module, value as string) // Call the function to fetch event types
      }
    }
  }

  const handleEdit = (preference: any) => {
    setFormData({
      module: preference.module || '',
      category: preference.category || '',
      eventType: preference.event_type.map((event: any) => event._id) || [],
      roles: preference.roles.map((channel: any) => channel._id) || [],
      priority: preference.priority,
      channels:
        preference.notification_channels.map((channel: any) => channel._id) ||
        [],
    })
    setEditData(preference.id) // Set the data being edited
    setOpenDrawer(true) // Open the drawer
  }

  const handleSave = async () => {
    if (editData) {
      updateNotificationPreference({
        id: editData,
        module: formData.module,
        category: formData.category,
        event_type: formData.eventType,
        roles: formData.roles,
        notification_channels: formData.channels,
        priority: formData.priority,
      })
        .then(async () => {
          setNotificationPreferences(
            (await getNotificationPreference({ module: null, category: null }))
              .data.data,
          )
        })
        .catch((error) => {
          console.error('Error updating notification preference:', error)
        })
    } else {
      // Handle the save logic for adding a new record
      // console.log('New Data:', formData)

      updateNotificationPreference({
        module: formData.module,
        category: formData.category,
        event_type: formData.eventType,
        roles: formData.roles,
        notification_channels: formData.channels,
        priority: formData.priority,
      })
        .then(async () => {
          setNotificationPreferences(
            (await getNotificationPreference({ module: null, category: null }))
              .data.data,
          )
        })
        .catch((error) => {
          console.error('Error updating notification preference:', error)
        })
    }

    setOpenDrawer(false)
  }

  const handleTestConnection = () => {
    // Test connection logic
    console.log('Test Connection')
  }

  return (
    <div>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}
      >
        {/* <Button
          variant="contained"
          color="primary"
          sx={{ marginBottom: '20px' }}
          onClick={() => setOpenDrawer(true)}
        >
          Add New
        </Button> */}
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ marginBottom: '20px' }}
          disabled={userRoles && !userRoles?.create}
          onClick={() => {
            setOpenDrawer(true)
            setFormData({
              module: '',
              category: '',
              eventType: [],
              roles: [],
              priority: '',
              channels: [],
            })
          }}
        >
          Add New
        </Button>
        {/* <Button
          size={'small'}
          sx={{
            color: CUSTOM_COLORS.backgroundColor,
            textTransform: 'none',
            marginBottom: '20px',
            fontWeight: '600',
            fontSize: '16px',
            // background: CUSTOM_COLORS.tableRowBgLightGray,
          }}
          variant="text"
          startIcon={<Add sx={{ fontSize: '16px' }} />}
          onClick={() => setOpenDrawer(true)}
        >
          Add
        </Button> */}
      </Box>
      <TableContainer component={Paper} sx={{ background: 'transparent' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Module</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Event Types</TableCell>
              <TableCell>Notification Channels</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationPreferences.map((preference) => (
              <TableRow key={preference.id}>
                <TableCell>{preference.module}</TableCell>
                <TableCell>{preference.category}</TableCell>
                <TableCell>
                  {preference.event_type.map((event) => (
                    <div key={event.id}>{event.eventType}</div>
                  ))}
                </TableCell>
                <TableCell>
                  {preference.notification_channels.map((channel) => (
                    <div key={channel.id}>{channel.notificationChannel}</div>
                  ))}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleEdit(preference)}
                    disabled={userRoles && !userRoles?.edit}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Drawer */}
      <Drawer
        anchor="left"
        open={openDrawer}
        sx={{ zIndex: '300' }}
        onClose={() => setOpenDrawer(false)}
      >
        <Box
          sx={{
            width: 450,
            padding: '20px',
            marginLeft: '60px',
            marginTop: '60px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => setOpenDrawer(false)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" gutterBottom>
              {editData
                ? 'Edit Notification Preference'
                : 'Add Notification Preference'}
            </Typography>
          </Box>

          {/* Form fields */}
          {/* <Box>Module</Box> */}
          <FormControl fullWidth margin="normal">
            <DropdownComponent
              sx={{ marginBottom: '10px' }}
              required
              label={'Module'}
              size="small"
              name="module"
              value={formData.module}
              onChange={handleChange}
            >
              {allModulesData.map((module) => (
                <MenuItem key={module.id} value={module.id}>
                  {module.name}
                </MenuItem>
              ))}
            </DropdownComponent>
          </FormControl>
          {/* <Box>Category</Box> */}
          <FormControl fullWidth margin="normal">
            <DropdownComponent
              sx={{ marginBottom: '10px' }}
              required
              label={'Category'}
              name="category"
              value={formData.category}
              size="small"
              onChange={handleChange}
            >
              {allCategoryData.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </DropdownComponent>
          </FormControl>
          <Box>Event Type</Box>
          <FormControl fullWidth margin="normal">
            <Select
              name="eventType"
              multiple
              size="small"
              value={formData.eventType}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) =>
                selected
                  .map((id) => {
                    const eventType = allEventTypesData.find(
                      (et) => et.id === id,
                    )
                    return eventType ? eventType.event_type : ''
                  })
                  .join(', ')
              }
            >
              {allEventTypesData?.map((eventType) => (
                <MenuItem key={eventType.id} value={eventType.id}>
                  <Checkbox
                    checked={formData.eventType.indexOf(eventType.id) > -1}
                  />
                  <ListItemText primary={eventType.event_type} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box>Roles that should be notified</Box>

          <FormControl fullWidth margin="normal">
            <Select
              name="roles"
              multiple
              size="small"
              value={formData.roles}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) =>
                selected
                  .map((id) => {
                    const Roles = allRolesData.find((et) => et.id === id)
                    return Roles ? Roles.role : ''
                  })
                  .join(', ')
              }
              // renderValue={(selected) => (selected as string[]).join(', ')}
            >
              {allRolesData.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  <Checkbox checked={formData.roles.indexOf(role.id) > -1} />
                  <ListItemText primary={role.role} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>Priority</Box>
          <FormControl fullWidth margin="normal">
            <Select
              name="priority"
              value={formData.priority}
              size="small"
              onChange={handleChange}
            >
              {allPriorityData?.map((priority) => (
                <MenuItem key={priority.id} value={priority.id}>
                  {priority.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>Channels to notify on</Box>
          <FormControl fullWidth margin="normal">
            <Select
              name="channels"
              multiple
              size="small"
              value={formData.channels}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) =>
                selected
                  .map((id) => {
                    const channel = allNotificationChannel.find(
                      (ch) => ch.id === id,
                    )
                    return channel ? channel.label : ''
                  })
                  .join(', ')
              }
            >
              {allNotificationChannel.map((channel) => (
                <MenuItem key={channel.id} value={channel.id}>
                  <Checkbox
                    checked={formData.channels.indexOf(channel.id) > -1}
                  />
                  <ListItemText primary={channel.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'right',
              marginTop: '20px',
            }}
          >
            {/* <Button variant="outlined" onClick={handleTestConnection}>
              Test Connection
            </Button> */}
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      </Drawer>
    </div>
  )
}

export default NotificationPreference
