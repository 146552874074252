import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  projectName: '',
  componentName: '',
}

export const breadcrumbSlice = createSlice({
  name: 'navDrawer',
  initialState,
  reducers: {
    setProjectName: (state, action) => {
      // state.open = !state.open
      state.projectName = action.payload
    },
    setComponentName: (state, action) => {
      // state.open = !state.open
      state.componentName = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setProjectName, setComponentName } = breadcrumbSlice.actions

export default breadcrumbSlice.reducer
