import React, { Fragment } from 'react'
import Grid from '@mui/material/Grid'
import TabsPanel from './components/TabsPanel'

const DsDashboard = () => {
  return (
    <Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            height: '87vh',
            marginTop: '10px',
            paddingLeft: '50px',
            backgroundColor: '#fff',
          }}
        >
          <TabsPanel />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default DsDashboard
