import React, { useEffect, useState } from 'react'
import { LineChart } from '@mui/x-charts'
import { Box } from '@mui/material'

interface MuiLineChartProps {
  chartSetting: any
  dataset: any
  yAxis: any
  series: any
}

const MuiLineChart = (props: MuiLineChartProps) => {
  const { chartSetting, dataset, yAxis, series } = props

  const [lineChartData, setLineChartData] = useState<any>([])
  const [xLabels, setXLabels] = useState([])

  useEffect(() => {
    if (series && series?.length > 0 && dataset && dataset?.length > 0) {
      const chartDataArray: any = []
      series.map((item: any) => {
        const data = { data: '', label: '' }
        const valueArray = dataset.map((dataItem: any) => dataItem[item?.field])
        data.data = valueArray
        data.label = item?.headerName
        chartDataArray.push(data)
      })
      setLineChartData(chartDataArray)
      setXLabels(dataset.map((item: any) => item[yAxis[0]?.field]))
    }
  }, [])

  return (
    <Box sx={{ overflow: 'scroll' }}>
      <LineChart
        width={chartSetting.height || 1000}
        height={chartSetting.width || 500}
        series={lineChartData}
        yAxis={[{ label: series[0].headerName }]}
        xAxis={[
          { scaleType: 'point', data: xLabels, label: yAxis[0].headerName },
        ]}
      />
    </Box>
  )
}

export default MuiLineChart
