import { ArrowBack } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import { Admin } from 'api/services'
import Text from 'components/atoms/Text'
import DropdownComponent from 'components/atoms/dropdown'

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { AppDispatch } from 'store/store'
import { AddDataSourceProps } from 'types/props-types'
import { UserRoleScopes } from 'utility/constants'
import RolePermissionDynamicForm from './RolePermissionsDynamicForm'
import DefaultRoleConfirmationDialog from './DefaultRoleConfirmationDialog'

const AddRolePermissions = ({
  heading,
  onClose,
  triggerReload,
  messageConfig,
  showMessage,
}: AddDataSourceProps) => {
  const [userScopes, setUserScopes] = useState([])
  const [selectedScope, setSelectedScope] = useState('')
  const [editConfiguration, setEditConfiguration] = useState<any>({})

  const [userRole, setUserRole] = useState('')
  const [userRoleLabel, setUserRoleLabel] = useState('')
  const [userRoleDesc, setUserRoleDesc] = useState('')

  const [isDefaultRoleExists, setIsDefaultRoleExists] = useState(false)
  const [isDefaultRole, setIsDefaultRole] = useState(false)
  const [showDefaultRoleConfirmation, setShowDefaultRoleConfirmation] =
    useState(false)

  const [error, setError] = useState<any>({})
  const [isSaveLoading, setSaveLoading] = useState(false)

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    setUserScopes(UserRoleScopes)
  }, [])

  useEffect(() => {
    if (selectedScope !== '') {
      dispatch(Admin.getScopeTemplate({ id: selectedScope }))
        .then((res: any) => {
          setEditConfiguration(res.payload?.permissions)
        })
        .catch((error: any) => {
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        })

      dispatch(Admin.getOrgDefaultRole({ id: selectedScope }))
        .then((res: any) => {
          setIsDefaultRoleExists(res.payload.isDefaultRoleExists)
          setIsDefaultRole(!res.payload.isDefaultRoleExists)
        })
        .catch((error: any) => {
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        })
    }
  }, [selectedScope])

  const handleScopeChange = useCallback((e: any) => {
    setSelectedScope(e.target.value)
    setError({ ...error, config: '' })
  }, [])

  const handleUserRoleChange = useCallback((e: any) => {
    if (e.target.value !== '') {
      setUserRole(e.target.value)
      setError({ ...error, role: '' })
    } else {
      setUserRole(e.target.value)
    }
  }, [])

  const handleShowAlert = useCallback(
    (msg: string, type = 'success') => {
      showMessage({
        ...messageConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [messageConfig],
  )

  const handleRequiredFieldValidation = useCallback(() => {
    if (Object.keys(editConfiguration).length === 0) {
      setError({ ...error, config: 'Please Select Scope' })
      return false
    }

    if (userRole === '') {
      setError({ ...error, role: 'This is Mandatory Field' })
      return false
    }

    if (userRoleLabel === '') {
      setError({ ...error, roleLabel: 'This is Mandatory Field' })
      return false
    }

    if (userRoleDesc === '') {
      setError({ ...error, roleDesc: 'This is Mandatory Field' })
      return false
    }

    return true
  }, [
    userRole,
    userRoleLabel,
    selectedScope,
    editConfiguration,
    error,
    isSaveLoading,
  ])

  const handleDefaultRoleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isDefaultRoleExists && event.target.checked) {
        setShowDefaultRoleConfirmation(true)
      } else {
        setIsDefaultRole(event.target.checked)
      }
    },
    [isDefaultRoleExists],
  )

  const handleConfirmationDialogClose = useCallback((confirmation: boolean) => {
    setIsDefaultRole(confirmation)
    setShowDefaultRoleConfirmation(false)
  }, [])

  const handleSaveData = useCallback(() => {
    const isValidationOk = handleRequiredFieldValidation()

    if (!isValidationOk) {
      return
    }

    setSaveLoading(true)

    const newDataSource = {
      id: null,
      role: userRole,
      label: userRoleLabel,
      scope: selectedScope,
      role_description: userRoleDesc,
      permissions: editConfiguration,
      is_system_generated_role: isDefaultRole,
    }

    Admin.addEditRolePermissions(newDataSource)
      .then((res: any) => {
        setSaveLoading(false)
        if (res?.status === 200) {
          triggerReload?.()
          handleShowAlert(
            res?.data?.message
              ? res.data.message
              : 'Data Repository Added Successfully',
          )
          onClose?.()
        } else {
          triggerReload?.()
          handleShowAlert(
            res?.error?.message ? res?.error?.message : 'Something Went Wrong',
            'error',
          )
          onClose?.()
        }
      })
      .catch((error: any) => {
        setSaveLoading(false)
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
      })
  }, [
    userRole,
    userRoleLabel,
    userRoleDesc,
    selectedScope,
    editConfiguration,
    error,
    isSaveLoading,
    isDefaultRole,
  ])

  const handleDSError = useCallback(() => {
    if (typeof error?.config !== 'undefined') {
      if (error?.config !== '') {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }, [error])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '424px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '28px 0px 28px 0px',
          gap: '16px',
        }}
      >
        <ArrowBack sx={{ cursor: 'pointer' }} onClick={onClose} />
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          {heading}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '32px',
          overflowY: 'scroll',
          paddingTop: '20px',
        }}
      >
        {/* <SelectComponent /> */}
        <Box>
          <DropdownComponent
            sx={{ width: 361 }}
            size="small"
            label="Scope *"
            value={selectedScope}
            onChange={(e) => {
              handleScopeChange(e)
              setUserRole('')
              setUserRoleLabel('')
            }}
            error={handleDSError() || false}
          >
            {userScopes?.length > 0 ? (
              userScopes.map((item: any) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>No Record Found</em>
              </MenuItem>
            )}
          </DropdownComponent>
          <Typography
            sx={{
              fontSize: '0.75rem',
              margin: '4px 0px 0px 14px',
              fontWeight: '400',
              color: '#d32f2f',
            }}
          >
            {error?.config}
          </Typography>
        </Box>
        <Text
          label="Role *"
          value={userRole}
          onChange={handleUserRoleChange}
          name="role"
          error={error}
        />
        <Text
          label="Role Display Name *"
          value={userRoleLabel}
          onChange={(e: any) => {
            setUserRoleLabel(e.target.value)
            setError({ ...error, roleLabel: '' })
          }}
          name="roleLabel"
          error={error}
        />
        <Text
          label="Role Description *"
          value={userRoleDesc}
          onChange={(e: any) => {
            setUserRoleDesc(e.target.value)
            setError({ ...error, roleDesc: '' })
          }}
          multiline={true}
          name="roleDesc"
          error={error}
        />
        {selectedScope !== '' && (
          <Box
            sx={{
              width: 361,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography>Default Role</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <Switch
                checked={isDefaultRole}
                onChange={handleDefaultRoleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography>Yes</Typography>
            </Stack>
          </Box>
        )}
        {Object.keys(editConfiguration)?.length > 0 && (
          <Box>
            <RolePermissionDynamicForm
              formData={editConfiguration}
              handleChange={setEditConfiguration}
              type="add"
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            marginRight: '40px',
            justifyContent: 'flex-end',
            paddingBottom: '15%',
          }}
        >
          <Button
            sx={{ color: '#42526E' }}
            variant="text"
            onClick={handleSaveData}
            disabled={isSaveLoading}
          >
            {isSaveLoading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Box>
      </Box>
      <DefaultRoleConfirmationDialog
        open={showDefaultRoleConfirmation}
        handleClose={handleConfirmationDialogClose}
      />
    </Box>
  )
}

export default AddRolePermissions
