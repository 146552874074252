import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import ChartContainer from 'components/components/ChartContainer'
import TableChartComponent from 'components/components/TableChartComponent'
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart'
import BarChartIcon from '@mui/icons-material/BarChart'
import TableRowsIcon from '@mui/icons-material/TableRows'
import React from 'react'

// const label = { inputProps: { 'aria-label': 'Switch demo' } }

interface TwoDChartProps {
  chartData: any
  setLoading?: any
  index?: any
}

// const data = [
//   {
//     values: [
//       { x: 0, y: 10 },
//       { x: 1, y: 20 },
//       // Add more points for the first line
//     ],
//   },
//   {
//     values: [
//       { x: 0, y: 15 },
//       { x: 1, y: 25 },
//       // Add more points for the second line
//     ],
//   },
//   // Add more objects for additional lines
// ]

// const yAxis = [
//   {
//     field: 'sales_volume',
//     headerName: 'Sales Volume',
//     width: 150,
//   },
//   {
//     field: 'price',
//     headerName: 'Price',
//     width: 150,
//   },
// ]

const OperationTableChart = (props: TwoDChartProps) => {
  const [selectedView, setSelectedView] = React.useState<string | null>('table')

  const handleToggleButton = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null,
  ) => {
    setSelectedView(newAlignment)
  }

  const { chartData, setLoading, index = 0 } = props
  // const [chartTableToggle, setChartTableToggle] = useState(true)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '50px',
          justifyContent: 'end',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ToggleButtonGroup
            value={selectedView}
            exclusive
            onChange={handleToggleButton}
            aria-label="text alignment"
          >
            <ToggleButton value="Line Chart" aria-label="left aligned">
              <StackedLineChartIcon />
            </ToggleButton>
            <ToggleButton value="Bar Chart" aria-label="centered">
              <BarChartIcon />
            </ToggleButton>
            <ToggleButton value="table" aria-label="right aligned">
              <TableRowsIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>

      {selectedView === 'table' && (
        <Box sx={{ padding: '30px', backgroundColor: '#fff' }}>
          <TableChartComponent
            showFilters={false}
            index={index}
            chartData={chartData}
            setLoading={setLoading}
          />
        </Box>
      )}

      {selectedView !== 'table' && (
        <Box sx={{ padding: '30px', backgroundColor: '#fff' }}>
          <ChartContainer
            index={index}
            chartData={chartData}
            setLoading={setLoading}
            selectedChart={selectedView}
          />
        </Box>
      )}
    </Box>
  )
}

export default OperationTableChart
