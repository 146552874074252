import { PayloadAction, createSlice } from '@reduxjs/toolkit'
interface PaginationData {
  totalRecords: number | any
  pageSize: number | any
  page: number | any
}

interface DataRepo {
  currentPageNumber: number | any
  data: Array<any>
  totalRecords: number | any
}

interface InitialState {
  paginationData: PaginationData
  dataRepo: DataRepo
}

interface PaginationPayload {
  totalRecords: number | any
  pageSize: number | any
}

const initialState: InitialState = {
  paginationData: {
    totalRecords: 0,
    pageSize: 0,
    page: 1,
  },
  dataRepo: {
    currentPageNumber: 0,
    data: [],
    totalRecords: 0,
  },
}

export const dataSourceSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<PaginationPayload>) => {
      state.paginationData.totalRecords = action.payload.totalRecords
      state.paginationData.pageSize = action.payload.pageSize
    },
    setPage: (state, action: PayloadAction<any>) => {
      state.paginationData.page = action.payload
    },
    setDataRepo: (state, action: PayloadAction<DataRepo>) => {
      state.dataRepo = { ...action.payload }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setPagination, setPage, setDataRepo } = dataSourceSlice.actions

export default dataSourceSlice.reducer
