import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import DataQualityMetrics from '../DataQualityMetrics/DataQualityMetrics'
import { DATASOURCE_NAV_BAR } from 'utility/constants'
import { CUSTOM_COLORS } from 'theme'
import DataSources from '../DataRepositories/DataSources'
import DataQualityChecks from '../DataQualityChecks/DataQualityChecks'
import ModelInstances from '../LLMModels/ModelInstances'
import { getProfileInfo } from 'api/apiUtility'
import CustomFunctions from 'pages/CustomFunction/CustomFunctions'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: '79vh', overflowY: 'scroll' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '0px 25px 25px 0px' }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const TabsPanel = () => {
  const profileInfo = getProfileInfo()

  const [value, setValue] = useState(0)
  const [repoRules, setRepoRules] = useState<any>()

  useEffect(() => {
    const profileInfoRoles = profileInfo
    if (profileInfoRoles && !repoRules) {
      setRepoRules(profileInfoRoles?.orgRole?.permissions)
    }
  }, [profileInfo])

  const navBar = useMemo(() => {
    return JSON.parse(JSON.stringify(DATASOURCE_NAV_BAR))
  }, [])

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue)
    },
    [],
  )

  const checkPermissions = useCallback(
    (tabName: string) => {
      switch (tabName) {
        case 'Repository Config':
          return !repoRules?.repository_config?.view
        case 'Data Quality Metrics':
          return !repoRules?.data_quality_metrics?.view
        case 'Data Quality Checks':
          return !repoRules?.data_quality_checks?.view
        case 'Manage LLM/ Model instances':
          return !repoRules?.manage_llm_model_instance?.view
        case 'Custom Fuctions':
          return !repoRules?.custom_functions?.view
      }
    },
    [repoRules],
  )

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {navBar &&
            navBar.map((navItem: any) => (
              <Tab
                key={navItem.id}
                sx={{
                  fontSize: '14px',
                  fontWeight: '600',
                  marginRight: '32px',
                  borderBottom: `2px solid ${CUSTOM_COLORS.tabOptionsBorder}`,
                }}
                disabled={checkPermissions(navItem.name)}
                label={navItem.name}
                {...a11yProps(navItem.id)}
              />
            ))}
        </Tabs>
      </Box>
      {repoRules && repoRules?.repository_config?.view && (
        <CustomTabPanel value={value} index={0}>
          <DataSources />
        </CustomTabPanel>
      )}
      {/* <CustomTabPanel value={value} index={1}>
        Data Sinks
      </CustomTabPanel> */}
      {repoRules && repoRules?.data_quality_metrics?.view && (
        <CustomTabPanel value={value} index={1}>
          <DataQualityMetrics />
        </CustomTabPanel>
      )}
      {repoRules && repoRules?.data_quality_checks?.view && (
        <CustomTabPanel value={value} index={2}>
          <DataQualityChecks />
        </CustomTabPanel>
      )}
      {repoRules && repoRules?.manage_llm_model_instance?.view && (
        <CustomTabPanel value={value} index={3}>
          <ModelInstances />
        </CustomTabPanel>
      )}

      {repoRules && repoRules?.repository_config?.view && (
        <CustomTabPanel value={value} index={4}>
          <CustomFunctions />
        </CustomTabPanel>
      )}
    </Box>
  )
}

export default TabsPanel
