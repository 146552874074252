import React from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { PAGE_ROUTE_CONSTANTS, SIGN_IN } from 'utility/constants'

const SignUp = (props: any) => {
  const { changePage } = props
  const navigate = useNavigate()

  return (
    <Fragment>
      <>
        <Typography variant="subtitle1" gutterBottom>
          Username
        </Typography>
        <TextField
          id="outlined-basic"
          label="Username"
          variant="outlined"
          fullWidth
        />
      </>
      <>
        <Typography variant="subtitle1" gutterBottom>
          Password
        </Typography>
        <TextField
          id="outlined-basic"
          label="Password"
          variant="outlined"
          fullWidth
        />
      </>
      <>
        <Typography variant="subtitle1" gutterBottom>
          Confirm Password
        </Typography>
        <TextField
          id="outlined-basic"
          label="Confirm Password"
          variant="outlined"
          fullWidth
        />
      </>

      <Box sx={{ textAlign: 'center', mb: 4, mt: 2 }}>
        <Button
          variant="contained"
          onClick={() => {
            navigate(`/${PAGE_ROUTE_CONSTANTS.HOME}`)
          }}
        >
          Sign Up
        </Button>
      </Box>

      <>
        <Typography variant="subtitle1" gutterBottom>
          Already have an account?
          <Button
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={() => {
              changePage(SIGN_IN)
            }}
          >
            Login
          </Button>
        </Typography>
      </>
    </Fragment>
  )
}

export default SignUp
