import { Box, Divider, IconButton } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'

// Initialize zoom extension
const zoomOptions = {
  zoomFactor: 0.1, // Adjust as needed
  minZoom: 0.1,
  maxZoom: 3,
}
const AppGraphControl = (props: any) => {
  const { cy } = props

  useEffect(() => {
    if (cy) cy.zoom(zoomOptions)
  }, [cy])

  const zoomIn = useCallback(() => {
    if (cy) {
      cy.zoom({
        level: cy.zoom() + zoomOptions.zoomFactor,
      })
    }
  }, [cy])

  const zoomOut = useCallback(() => {
    if (cy) {
      cy.zoom({
        level: cy.zoom() - zoomOptions.zoomFactor,
      })
    }
  }, [cy])

  return (
    <>
      <Box
        sx={{ position: 'absolute', bottom: 100, left: 20, zIndex: '11111' }}
      >
        <Box
          sx={{
            border: '1px solid #d3d3d3',
            display: 'flex',
            flexDirection: 'column',
            // background: '#fff',
          }}
        >
          <IconButton onClick={zoomIn}>
            <ZoomInIcon />
          </IconButton>
          <Divider />
          <IconButton onClick={zoomOut}>
            <ZoomOutIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  )
}

export default AppGraphControl
