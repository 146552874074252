/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { Fragment, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Snackbar,
  Alert,
  Switch,
  TablePagination,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'

import {
  getAllOrganizations,
  getOrganizationDetails,
  onBoarding,
  updateOrganization,
  updateOrganizationStatus,
} from 'api/services/api-services'
import { log } from 'console'
import GroupsSharpIcon from '@mui/icons-material/GroupsSharp'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import { useNavigate } from 'react-router-dom'
import UserManagement from 'pages/Admin/UserManagement/UserManagement'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
interface Organization {
  id: string
  name: string
  status: 'LIVE' | 'DISABLED' | 'PENDING'
  start_date: string
  noOfUsers: number
}

const SuperAdminDashboard = () => {
  const [rows, setRows] = useState<Organization[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [openDialogEdit, setOpenDialogEdit] = useState(false)
  const [formData, setFormData] = useState({
    teamId: '',
    orgName: '',
    domain: '',
    username: '',
    firstName: '',
    lastName: '',
  })
  const [userFlag, setUserFlag] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'error',
  )
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  // Function to handle page change
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage)
  }

  useEffect(() => {
    // Get the current window href
    const currentUrl = window.location.href

    // Check if the URL contains the id parameter
    if (currentUrl.includes('id=')) {
      setUserFlag(true)
    }
  }, [])

  // Function to handle rows per page change
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const [currentorgId, setCurrentOrgId] = useState('')
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false)
  }
  const navigate = useNavigate()
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(email)
  }

  const handleSubmit = () => {
    // Check mandatory fields
    const { teamId, orgName, username, firstName, lastName } = formData
    if (!teamId || !orgName || !username || !firstName || !lastName) {
      setSnackbarMessage('Please fill all mandatory fields.')
      setSnackbarSeverity('error')
      setOpenSnackbar(true)
      return
    }

    // Validate email
    if (!validateEmail(username)) {
      setSnackbarMessage('Please enter a valid email address.')
      setSnackbarSeverity('error')
      setOpenSnackbar(true)
      return
    }

    const payload = {
      orgDetails: {
        teamId: formData.teamId,
        orgName: formData.orgName,
        domain: formData.domain,
      },
      adminDetails: {
        username: formData.username,
        firstName: formData.firstName,
        lastName: formData.lastName,
      },
    }

    // Perform API call or other submission logic here
    onBoarding(payload)
      .then((response) => {
        // setRows(response?.data.data || []);
        // console.log(response.data.data);
        tableReload()
      })
      .catch((error) => {
        console.error('Error fetching organizations:', error)
      })
    // Close dialog after successful submission
    handleCloseDialog()
  }

  const handleBackClick = () => {
    setUserFlag(false)
  }

  const handleSubmitEdit = () => {
    // Check mandatory fields
    const { teamId, orgName } = formData
    if (!teamId || !orgName) {
      setSnackbarMessage('Please fill all mandatory fields.')
      setSnackbarSeverity('error')
      setOpenSnackbar(true)
      return
    }

    const payload = {
      id: currentorgId,
      // teamId: formData.teamId,
      name: formData.orgName,
      domain: formData.domain,
    }

    // Perform API call or other submission logic here
    updateOrganization(payload)
      .then((response) => {
        tableReload()
        // console.log(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching organizations:', error)
      })
    // Close dialog after successful submission
    handleCloseDialogEdit()
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const tableReload = () => {
    const payload = {
      search: '',
      status: 'SHOW_ALL',
    }

    getAllOrganizations(payload)
      .then((response) => {
        setRows(response?.data.data || [])
        // console.log(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching organizations:', error)
      })
  }

  useEffect(() => {
    tableReload()
  }, [])
  useEffect(() => {}, [userFlag])
  const handleAdd = () => {
    // Logic to add new organization
    setFormData({
      teamId: '',
      orgName: '',
      domain: '',
      username: '',
      firstName: '',
      lastName: '',
    })
    setOpenDialog(true)
    // console.log('Add new organization');
  }

  const handleEdit = (id: string) => {
    // Logic to edit an organization
    // console.log('Edit organization with ID:', id);

    getOrganizationDetails(id)
      .then((response) => {
        // setRows(response?.data.data || []);
        setCurrentOrgId(id)
        setFormData({
          teamId: response.data.teamId,
          orgName: response.data.name,
          domain: response.data.domain,
          username: response.data.username,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
        })
        setOpenDialogEdit(true)
      })
      .catch((error) => {
        console.error('Error fetching organizations:', error)
      })
  }

  const handleUserManagement = (id: string) => {
    const newUrl = `${window.location.origin}/sysadmin/organizations?id=${id}`

    // Update the URL in the browser
    window.history.pushState({}, '', newUrl)
    // window.location.reload()
    setUserFlag(!userFlag)
    // navigate(`/${PAGE_ROUTE_CONSTANTS.ADMIN}?id=${id}`)
  }

  const filteredRows = rows.filter((row) =>
    row?.name?.toLowerCase().includes(searchTerm?.toLowerCase()),
  )

  const toggleStatus = (id: string) => {
    setRows(
      rows.map((row) => {
        if (row.id === id) {
          const newStatus = row.status === 'LIVE' ? 'DISABLED' : 'LIVE'
          const payload = {
            id: id,
            status: newStatus,
          }

          updateOrganizationStatus(payload)
            .then((response) => {
              // console.log(response.data.data);
            })
            .catch((error) => {
              console.error('Error fetching organizations:', error)
            })
          return { ...row, status: newStatus }
        }
        return row
      }),
    )
  }

  return (
    <Box sx={{ padding: 4 }}>
      {!userFlag && (
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              height: '87vh',
              marginTop: '10px',
              paddingLeft: '50px',
              paddingRight: '50px',
              marginBottom: '50px',
            }}
          >
            <Box
              sx={{ fontSize: '28px', fontWeight: '700', marginBottom: '20px' }}
            >
              Organizations
            </Box>

            <Box
              sx={{
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Search organizations"
                value={searchTerm}
                onChange={handleSearch}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ marginRight: '10px' }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAdd}
                size="small"
                sx={{ padding: '10px 20px', fontWeight: '600' }}
              >
                Add Organization
              </Button>
            </Box>

            <TableContainer
              component={Paper}
              sx={{ overflow: 'auto', background: 'transparent !important' }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: '600', fontSize: '16px' }}>
                      Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: '600', fontSize: '16px' }}>
                      Status
                    </TableCell>
                    <TableCell sx={{ fontWeight: '600', fontSize: '16px' }}>
                      Start Date
                    </TableCell>
                    <TableCell sx={{ fontWeight: '600', fontSize: '16px' }}>
                      No. of Users
                    </TableCell>
                    <TableCell sx={{ fontWeight: '600', fontSize: '16px' }}>
                      Actions
                    </TableCell>
                    <TableCell sx={{ fontWeight: '600', fontSize: '16px' }}>
                      Users
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {filteredRows.map((row) => ( */}
                  {filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell sx={{ fontSize: '14px' }}>
                          {row.name}
                        </TableCell>
                        <TableCell>
                          {row.status === 'PENDING' ? (
                            <Chip
                              label={row.status}
                              sx={{
                                backgroundColor: '#f8d7da', // Red background
                                color: '#721c24', // Red text
                                borderRadius: '4px',
                                fontSize: '14px',
                                fontWeight: '600',
                              }}
                            />
                          ) : (
                            <Switch
                              checked={row.status === 'LIVE'}
                              onChange={() => toggleStatus(row.id)}
                              inputProps={{ 'aria-label': 'controlled' }}
                              color="primary"
                            />
                          )}
                        </TableCell>
                        <TableCell sx={{ fontSize: '14px' }}>
                          {new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                          }).format(new Date(row.start_date))}
                        </TableCell>
                        <TableCell sx={{ fontSize: '14px' }}>
                          {row.noOfUsers}
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEdit(row.id)}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleUserManagement(row.id)}
                          >
                            <GroupsSharpIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add Organization</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            {/* Organization Details Section */}
            <Box>
              <Typography variant="h6" sx={{ marginBottom: '16px' }}>
                Organization Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Team ID"
                    name="teamId"
                    value={formData.teamId}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Organization Name"
                    name="orgName"
                    value={formData.orgName}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Domain"
                    name="domain"
                    value={formData.domain}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Admin Details Section */}
            <Box>
              <Typography
                variant="h6"
                sx={{ marginBottom: '16px', marginTop: '24px' }}
              >
                Admin Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Dialog>

      <Dialog
        open={openDialogEdit}
        onClose={handleCloseDialogEdit}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Organization</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            {/* Organization Details Section */}
            <Box>
              <Typography variant="h6" sx={{ marginBottom: '16px' }}>
                Organization Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Team ID"
                    name="teamId"
                    value={formData.teamId}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Organization Name"
                    name="orgName"
                    value={formData.orgName}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Domain"
                    name="domain"
                    value={formData.domain}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogEdit} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitEdit} color="primary">
            Submit
          </Button>
        </DialogActions>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Dialog>
      {userFlag && (
        <Box>
          <IconButton
            onClick={handleBackClick}
            sx={{ position: 'relative', left: 16 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box ml={8} mt={'-70px'}>
            {' '}
            {/* Adjust the margin-top to avoid overlap with the back button */}
            <UserManagement />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default SuperAdminDashboard
