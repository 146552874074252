/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  TextField,
  TextareaAutosize,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import DrawerComponent from 'components/atoms/drawer'
import CloseIcon from '@mui/icons-material/Close'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DropdownComponent from 'components/atoms/dropdown'
import useClasses from 'hooks/styleHook'
import { styles } from '../../style'
import CancelIcon from '@mui/icons-material/Cancel'
import { ApiServices } from 'api/services'
import _, { over } from 'lodash'
import { getRandomNumber } from 'utility/utility'
import {
  CONFIGURATION_INPUT_TYPES,
  DefaultTranformerPopup,
  NODE_TEMPLATE_NAMES,
  NODE_TYPES_NAMES,
  VISUALIZATION_TEMPLATES_IDS,
} from '../data-pipeline-constants'
import AddIcon from '@mui/icons-material/Add'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import {
  ConfigurationInputInterface,
  NodeDrawerPropertyInterface,
} from '../data-pipeline-types'
import NoRecordsFoundComponent from 'components/atoms/no-record'
import { useNavigate, useSearchParams } from 'react-router-dom'
import DataDialog from 'pages/projects/data-pipeline/data-dialog'
import { Height, Info, RemoveRedEye } from '@mui/icons-material'
import { REGEX_EXPRESSION } from 'utility/constants'
import Dialog from 'components/molecules/Dialog'
import FolderExplorer from '../FolderExplorer'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
import {
  TooltipNoDataFound,
  TooltipTemplate,
} from '../../components/TooltipTemplate'

const DefaultNodeProperty: NodeDrawerPropertyInterface = {
  nodeType: '',
  show: false,
  isEdit: false,
  nodeAttached: null,
}

const DrawerWidth = 550
const AllComponents = forwardRef((props: any, ref) => {
  const {
    showNodePropertiesDrawer,
    setLoading,
    setNodePropertiesDrawer,
    getProjectDetails,
    projectId,
    componentId,
    selectedNode,
    componentDetailsData,
    NODE_TYPES,
    projectRole,
  } = props
  const [drawerConfiguration, setDrawerConfiguration] = useState<
    ConfigurationInputInterface[]
  >([])
  const [
    tranformationOperationConfiguration,
    setTranformationOperationConfiguration,
  ] = useState<ConfigurationInputInterface[]>([])

  const [nodeName, setNodeName] = useState('')
  const [componentNodeDetailConfig, setComponentNodeDetailConfig] =
    useState<any>({})
  const [openTransformerPopup, setOpenTransformerPopup] = useState(
    DefaultTranformerPopup,
  )
  const [searchParams] = useSearchParams()
  const [outputNodeData, setOutputNodeData] = useState<any>()
  const [isInputNodeError, setInputNodeError] = useState(false)
  const [componentNodeTemplateId, setComponentNodeTemplateId] = useState<any>()
  const [componentNodeTypeId, setComponentNodeTypeId] = useState<any>()
  const [selectedTranformationTemplateId, setselectedTranformationTemplateId] =
    useState(null)
  const [componentType, setComponentType] = useState('')
  const [originNodeDetail, setOriginNodeDetail]: any = useState([])

  const [inputTable, setInputTable]: any = useState('')

  const classes = useClasses(styles)

  const singleTextSearchInputRef = useRef(null)
  const [singleTextSearch, setSingleTextSearch] = useState('')

  const multiTextSearchInputRef = useRef(null)
  const [multiTextSearch, setMultiTextSearch] = useState('')

  const [openFileExplorer, setOpenFileExplorer] = useState({
    isOpen: false,
    type: '',
    data: {},
    input: {},
  })

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      overflow: 'scroll',
      height: '600px',
      maxWidth: '700px',
    },
  }))

  const navigate = useNavigate()
  const handleSearchChange = (event: any) => {
    setSingleTextSearch(event.target.value)
  }

  const handleMultiTextSearchChange = (event: any) => {
    setMultiTextSearch(event.target.value)
  }

  const MultiObjTextSearchInputRef = useRef(null)
  const [MultiObjTextSearch, setMultiObjSearch] = useState('')

  const handleMultiSearchChange = (event: any) => {
    setMultiObjSearch(event.target.value)
  }

  useEffect(() => {
    const inputElement: any = singleTextSearchInputRef.current
    const handleFocus = () => {
      if (inputElement) {
        inputElement.focus()
      }
    }
    if (inputElement) {
      inputElement.addEventListener('blur', handleFocus)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('blur', handleFocus)
      }
    }
  }, [singleTextSearch])

  useEffect(() => {
    const inputElement: any = multiTextSearchInputRef.current
    const handleFocus = () => {
      if (inputElement) {
        inputElement.focus()
      }
    }
    if (inputElement) {
      inputElement.addEventListener('blur', handleFocus)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('blur', handleFocus)
      }
    }
  }, [multiTextSearch])

  useEffect(() => {
    const inputElement: any = MultiObjTextSearchInputRef.current
    const handleFocus = () => {
      if (inputElement) {
        inputElement.focus()
      }
    }
    if (inputElement) {
      inputElement.addEventListener('blur', handleFocus)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('blur', handleFocus)
      }
    }
  }, [MultiObjTextSearch])

  useEffect(() => {
    const compType = searchParams.get('componentType')
    compType && setComponentType(compType)
  }, [searchParams])

  const handleSingleFolderClick = (input: any) => {
    const repositoryId =
      componentNodeDetailConfig.configuration.input.find(
        (item: any) => item.key === NODE_TYPES.source.keys.source_repository,
      ) ||
      componentNodeDetailConfig.configuration.input.find(
        (item: any) => item.key === NODE_TYPES.target.keys.target_repository,
      )

    if (repositoryId && repositoryId.dropdownData.length > 0) {
      const repositoryData = repositoryId.dropdownData.find(
        (item: any) => item.id === repositoryId.value,
      )

      if (repositoryData) {
        setOpenFileExplorer({
          isOpen: true,
          type: 'single',
          data: repositoryData,
          input: input,
        })
      }
    }
  }

  const handleFileExplorerCloseClick = () => {
    setOpenFileExplorer({ isOpen: false, type: '', data: {}, input: {} })
  }

  const getDataRepository = useCallback(
    async (key: string) => {
      const dbType = componentType === 'DOCUMENT_PROCESSING' ? 'DOCUMENT' : ''
      try {
        const payload = {
          data_repository_status: ['LIVE'],
          db_type: dbType ? [dbType] : [],
        }
        const resp: any = await ApiServices.getDataRepository(payload)
        const sourceList = resp.data.data
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [componentType],
  )

  const getMasterRepository = useCallback(
    async (componentNodeType: string, key: string) => {
      try {
        const payload: any = {}
        if (
          componentNodeType ===
          NODE_TYPES_NAMES.DOCUMENT_04_DATA_TARGET.componentNodeType
        ) {
          payload.db_type = ['DOCUMENT', 'NL_QUERY']
        } else {
          payload.db_type = ['NL_QUERY']
        }

        // const payload = {
        //   // data_repository_status: ['LIVE'],
        //   db_type: ['NL_QUERY'],
        // }

        const resp: any = await ApiServices.getDataRepository(payload)
        const sourceList = resp.data.data
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [componentType],
  )

  const getSubComponent = useCallback(
    (arg: any) => {
      const { key } = arg
      try {
        const data = [
          {
            id: 'VISUALIZATION_01_SUBCOMPONENT_TYPE_LINECHART',
            subcomponent_type_name: 'Line Chart',
          },
          {
            id: 'VISUALIZATION_01_SUBCOMPONENT_TYPE_NETWORK',
            subcomponent_type_name: 'Network Visualization',
          },
        ]
        setLoading(true)
        const resp: any = data
        setLoading(false)
        const sourceList = resp
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getDataAccessApi = useCallback(
    async (key: string) => {
      try {
        const payload = {
          componentType: 'DATA_ACCESS_API',
          id: componentDetailsData?.project_id,
        }
        setLoading(true)
        const resp: any = await ApiServices.getAllProjects(payload)
        setLoading(false)
        const sourceList = resp.data
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [componentDetailsData, setLoading],
  )

  const GetTooltipData = ({ functionName }: any) => {
    const [tooltipData, setTooltipData] = useState('')
    useEffect(() => {
      const fetchData = async () => {
        try {
          const resp: any = await ApiServices.getTooltipData(functionName)
          setTooltipData(TooltipTemplate(resp.data))
        } catch (error) {
          setTooltipData(TooltipNoDataFound())
        }
      }

      fetchData()
    }, [])

    return <div dangerouslySetInnerHTML={{ __html: tooltipData }} />
  }

  const getEndpointNodes = useCallback(
    async (arg: any) => {
      const { key, id } = arg
      try {
        const payload = {
          component_id: id,
        }

        setLoading(true)
        const resp: any = await ApiServices.getAllEndpointNodes(payload)
        setLoading(false)
        const sourceList = resp.data
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [componentDetailsData, setLoading],
  )

  const getXYSeriesData = useCallback(
    async (arg: any) => {
      const { inputKeyX, outputKeyY, filterKey, id } = arg
      try {
        setLoading(true)
        const resp: any = await ApiServices.getXYSeriesData(id)
        setLoading(false)
        const sourceList = resp.data
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === inputKeyX,
          )
          if (repository) {
            repository.dropdownData = sourceList.retain_columns
            // repository.value = ''
          }
          return [...newData]
        })

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === outputKeyY,
          )
          if (repository) {
            repository.dropdownData = sourceList.retain_columns
            // repository.value = ''
          }
          return [...newData]
        })

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === filterKey,
          )
          if (repository) {
            repository.dropdownData = sourceList.filter_columns
            // repository.value = ''
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )
  const getOutputData = useCallback(
    async (arg: any) => {
      const { retain_columns, filterKey, componentId, componentNodeId } = arg
      const params = {
        componentId: componentId,
        componentNodeId: componentNodeId,
      }
      try {
        setLoading(true)
        const resp: any = await ApiServices.getOutputData(params)
        setLoading(false)
        const sourceList = resp.data
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === retain_columns,
          )
          if (repository) {
            repository.dropdownData = sourceList.retain_columns
            // repository.value = ''
          }
          return [...newData]
        })

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === filterKey,
          )
          if (repository) {
            repository.dropdownData = sourceList.filter_columns
            // repository.value = ''
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getDQMetrices = useCallback(
    async (dataRepositoryId: string, dataSet: string) => {
      try {
        const payload = {
          status: 'LIVE',
          data_repository_id: dataRepositoryId,
          dataset: dataSet,
        }
        const resp: any = await ApiServices.getDQMetrices(payload)
        const sourceList = resp.data.data

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.dq_metrics,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [NODE_TYPES],
  )

  const getDQCheckValidation = useCallback(
    async (key: string, repository_id?: string) => {
      try {
        const payload: any = {
          // search: "INSERT_METRICS_TEST",
          dq_check_basic_type: 'CHECKS',
          dq_check_type: 'SNAPSHOT',
          status: 'LIVE',
        }
        if (repository_id) {
          payload.data_repository_id = repository_id
        }
        const resp: any = await ApiServices.getDQCheckValidation(payload)
        const sourceList = resp.data.data
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [NODE_TYPES],
  )

  const getInputTableData = useCallback(
    async (arg: any) => {
      const { dataRepositoryId, key } = arg
      try {
        setLoading(true)
        const resp: any = await ApiServices.getInputTableData(dataRepositoryId)
        setLoading(false)
        const sourceList = resp.data.dataset
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
            // repository.value = ''
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getInputFolderData = useCallback(
    async (arg: any) => {
      const { dataRepositoryId, key } = arg
      try {
        setLoading(true)
        const resp: any = await ApiServices.getInputFolderData(dataRepositoryId)
        setLoading(false)
        const sourceList = resp.data.dataset
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
            // repository.value = ''
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getRetainColumnsData = useCallback(
    async (arg: any) => {
      const { dataRepositoryId, dataSets, key } = arg

      try {
        setLoading(true)
        const resp: any = await ApiServices.getRetainColumnsData(
          dataRepositoryId,
          dataSets,
        )
        setLoading(false)

        const sourceList = resp.data.columns

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getFilteredRetainColumnsData = useCallback(
    async (arg: any) => {
      const { dataRepositoryId, dataSets, key } = arg

      try {
        setLoading(true)
        const resp: any = await ApiServices.getRetainColumnsData(
          dataRepositoryId,
          dataSets,
        )
        setLoading(false)

        const sourceList = resp.data.columns.filter(
          (item: any) => item.data_type !== 'uid',
        )

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getFilteredColumnsToFilterData = useCallback(
    async (arg: any) => {
      const { dataRepositoryId, dataSets, key } = arg

      try {
        setLoading(true)
        const resp: any = await ApiServices.getRetainColumnsData(
          dataRepositoryId,
          dataSets,
        )
        setLoading(false)

        const sourceList = resp.data.columns.filter(
          (item: any) => item.data_type !== 'uid',
        )

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getInputNodes = useCallback(
    async (arg: Record<string, string>) => {
      const { key, componentNodeId } = arg

      try {
        const payload = {
          componentId,
          componentNodeId,
        }
        const resp: any = await ApiServices.getInputNodes(payload)
        const sourceList = resp.data
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )

          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [componentId],
  )

  const getOutputNodes = useCallback(async (arg: Record<string, any>) => {
    const { key: inputDataFrameKey, inputNodeIds, key2: retainColumnsKey } = arg
    try {
      const payload = {
        inputNodeIds: JSON.stringify(inputNodeIds),
      }
      const resp: any = await ApiServices.getOutputNodes(payload)
      const sourceList = resp.data
      setOutputNodeData(sourceList)
      setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
        const newData = [...prev]
        const repository_inputDataFrame = newData.find(
          (item: ConfigurationInputInterface) => item.key === inputDataFrameKey,
        )
        if (repository_inputDataFrame) {
          repository_inputDataFrame.dropdownData =
            sourceList.output_dataframe || sourceList.retain_variables
        }
        if (retainColumnsKey) {
          const repository_retainColumns = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === retainColumnsKey,
          )

          if (repository_retainColumns) {
            repository_retainColumns.dropdownData = sourceList.retain_columns
          }
        }

        return [...newData]
      })
    } catch (error: any) {
      if (
        error?.response?.data?.error ===
          'User session not authorized. Please sign in' ||
        error?.response?.data?.error === 'jwt expired'
      ) {
        removeToken()
        removeProfileInfo()
        navigate('/login')
      }
      console.log('error', error)
    }
  }, [])

  const getVectorNodes = useCallback(async (arg: Record<string, any>) => {
    const { key: inputDataFrameKey, inputNodeIds, key2: retainColumnsKey } = arg
    try {
      const payload = {
        inputNodeIds: JSON.stringify(inputNodeIds),
      }
      const resp: any = await ApiServices.getOutputNodes(payload)
      const sourceList = resp.data
      setOutputNodeData(sourceList)
      setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
        const newData = [...prev]
        const repository_inputDataFrame = newData.find(
          (item: ConfigurationInputInterface) => item.key === inputDataFrameKey,
        )
        if (repository_inputDataFrame) {
          repository_inputDataFrame.dropdownData =
            sourceList.output_dataframe || sourceList.retain_vectors
        }

        return [...newData]
      })
    } catch (error: any) {
      if (
        error?.response?.data?.error ===
          'User session not authorized. Please sign in' ||
        error?.response?.data?.error === 'jwt expired'
      ) {
        removeToken()
        removeProfileInfo()
        navigate('/login')
      }
      console.log('error', error)
    }
  }, [])

  const getColumnsToFilterData = useCallback(
    async (arg: any) => {
      const { dataRepositoryId, dataSets, key } = arg

      try {
        setLoading(true)
        const resp: any = await ApiServices.getRetainColumnsData(
          dataRepositoryId,
          dataSets,
        )
        setLoading(false)

        const sourceList = resp.data.columns

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getInputNodeData = useCallback(
    (arg: any) => {
      const { key } = arg
      try {
        setLoading(true)
        const resp: any = componentDetailsData
        setLoading(false)
        const sourceList = [resp.configuration.input.nodes[0].data]
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
            // repository.value = [sourceList[0].id]
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading, componentDetailsData],
  )

  const getOutputFieldData = useCallback(
    (arg: any) => {
      const { key } = arg
      try {
        setLoading(true)
        const resp: any = componentDetailsData
        setLoading(false)
        const sourceList = [
          { data_repository_name: 'REST API (JSON)', id: 'REST_API_JSON' },
          {
            data_repository_name: 'Nodes and Edges in a network (JSON)',
            id: 'NETWORK_VISUALIZATION_JSON',
          },
        ]
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
            // repository.value = ''
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading, componentDetailsData],
  )

  const getLlmModels = useCallback(
    async (arg: any) => {
      const { key } = arg
      try {
        setLoading(true)
        const resp: any = await ApiServices.getAllModelInstances({
          model_instance_status: ['LIVE'],
          search: '',
        })
        setLoading(false)
        const sourceList = resp.data.data
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )

          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading, componentType],
  )

  const getInputOutputFieldData = useCallback(
    async (arg: any) => {
      const { inputKey, outputKey, outputKey2, id } = arg
      let param = { ids: [''] }
      try {
        setLoading(true)
        if (id) {
          param = {
            ids: [id],
          }
        } else {
          param = {
            ids: [
              `${componentDetailsData.configuration.input.nodes[0].data.id}`,
            ],
          }
        }
        const resp: any = await ApiServices.getComponentOutputNodes(param.ids)
        setLoading(false)
        const sourceList = resp.data
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === inputKey,
          )
          if (repository) {
            repository.dropdownData = sourceList.filter_columns
            // repository.value = ''
          }
          return [...newData]
        })

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === outputKey,
          )
          if (repository) {
            repository.dropdownData = sourceList.retain_columns
            // repository.value = ''
          }
          return [...newData]
        })

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === outputKey2,
          )
          if (repository) {
            repository.dropdownData = sourceList.retain_columns
            // repository.value = ''
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading, componentDetailsData],
  )

  const updateEditFields = useCallback(
    (arg: any) => {
      const {
        componentInputConfigurationData,
        isEdit = false,
        originNodeId,
      } = arg
      const inputConfiguration =
        componentInputConfigurationData.configuration.input
      const componentNodeTemplate =
        componentInputConfigurationData?.component_node_template_id
          ?.componentNodeTemplate ||
        componentInputConfigurationData?.component_node_template

      if (componentInputConfigurationData) {
        let nodeType = ''
        if (componentInputConfigurationData.component_node_type_id) {
          nodeType =
            componentInputConfigurationData.component_node_type_id
              .componentNodeType
        } else if (componentInputConfigurationData.component_node_type) {
          nodeType = componentInputConfigurationData.component_node_type
        }
        if (
          nodeType ===
          NODE_TYPES_NAMES.VISUALIZATION_01_SUBCOMPONENT.componentNodeType
        ) {
          const repository = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.source.keys.source_repository,
          )
          if (repository) {
            getDataRepository(NODE_TYPES.source.keys.source_repository)
          }
          getSubComponent({ key: NODE_TYPES.source.keys.node_template })
          getDataAccessApi(NODE_TYPES.source.keys.data_acess_api)
          if (isEdit) {
            const accessApiValue = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.source.keys.data_acess_api,
            )?.value
            const isRetainColumns = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.source.keys.retain_columns,
            )
            const isRetainColumns01 = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.source.keys.retain_columns_01,
            )
            const isRetainColumns02 = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.source.keys.retain_columns_02,
            )
            const isRetainColumns03 = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.source.keys.retain_columns_03,
            )
            const isFilterColumns = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.source.keys.filter_columns,
            )
            const isEndpointColumns = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.source.keys.endpoint_node,
            )

            if (
              accessApiValue &&
              componentNodeTemplate !==
                VISUALIZATION_TEMPLATES_IDS.VISUALIZATION_TEMPLATE_NETWORK_GRAPH_API_BASED
            ) {
              getXYSeriesData({
                inputKeyX: NODE_TYPES.source.keys.retain_columns_01,
                outputKeyY: NODE_TYPES.source.keys.retain_columns_02,
                filterKey: NODE_TYPES.source.keys.filter_columns,
                id: accessApiValue,
              })
              getEndpointNodes({
                key: NODE_TYPES.source.keys.endpoint_node,
                id: accessApiValue,
              })
            }
            if (isEndpointColumns?.value && accessApiValue) {
              getOutputData({
                retain_columns: NODE_TYPES.source.keys.retain_columns,
                filterKey: NODE_TYPES.source.keys.filter_columns,
                componentId: accessApiValue,
                componentNodeId: isEndpointColumns.value,
              })
            }
            if (repository) {
              const repoValue = inputConfiguration.find(
                (item: any) =>
                  item.key === NODE_TYPES.source.keys.source_repository,
              )?.value
              const inputTableValue = inputConfiguration.find(
                (item: any) => item.key === NODE_TYPES.source.keys.input_table,
              )?.value
              if (repoValue) {
                getInputTableData({
                  dataRepositoryId: repoValue,
                  key: NODE_TYPES.source.keys.input_table,
                })
              }

              if (repoValue && inputTableValue) {
                if (isFilterColumns) {
                  getColumnsToFilterData({
                    dataRepositoryId: repoValue,
                    dataSets: inputTableValue,
                    key: NODE_TYPES.source.keys.filter_columns,
                  })
                }
                if (isRetainColumns) {
                  getRetainColumnsData({
                    dataRepositoryId: repoValue,
                    dataSets: inputTableValue,
                    key: NODE_TYPES.source.keys.retain_columns,
                  })
                }
                if (isRetainColumns01) {
                  getRetainColumnsData({
                    dataRepositoryId: repoValue,
                    dataSets: inputTableValue,
                    key: NODE_TYPES.source.keys.retain_columns_01,
                  })
                }
                if (isRetainColumns02) {
                  getRetainColumnsData({
                    dataRepositoryId: repoValue,
                    dataSets: inputTableValue,
                    key: NODE_TYPES.source.keys.retain_columns_02,
                  })
                }
                if (isRetainColumns03) {
                  getRetainColumnsData({
                    dataRepositoryId: repoValue,
                    dataSets: inputTableValue,
                    key: NODE_TYPES.source.keys.retain_columns_03,
                  })
                }
              }
            }
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.DOCUMENT_01_SOURCE.componentNodeType
        ) {
          const repository = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.source.keys.source_repository,
          )
          if (repository) {
            getDataRepository(NODE_TYPES.source.keys.source_repository)
          }
          if (isEdit) {
            const inputTable = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.source.keys.input_table,
            )
            const inputFolder = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.documentProcessing.keys.input_folder,
            )
            if (inputTable) {
              getInputTableData({
                dataRepositoryId: repository.value,
                key: NODE_TYPES.target.keys.input_table,
              })
            }
            if (inputFolder) {
              getInputFolderData({
                dataRepositoryId: repository.value,
                key: NODE_TYPES.documentProcessing.keys.input_folder,
              })
            }
          }
        } else if (
          nodeType ===
          NODE_TYPES_NAMES.DQ_01_SOURCE_REPOSITORY.componentNodeType
        ) {
          const repository = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.source.keys.source_repository,
          )
          if (repository) {
            getDataRepository(NODE_TYPES.source.keys.source_repository)
          }
          getDQCheckValidation(
            NODE_TYPES.source.keys.dq_checks,
            repository?.value,
          )
          if (isEdit) {
            const sourceRepositoryValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.source.keys.source_repository,
            )?.value
            getDQCheckValidation(
              NODE_TYPES.source.keys.dq_checks,
              sourceRepositoryValue,
            )
            const inputTableValue = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.source.keys.input_table,
            )?.value
            if (sourceRepositoryValue)
              getInputTableData({
                dataRepositoryId: sourceRepositoryValue,
                key: NODE_TYPES.source.keys.input_table,
              })
            if (sourceRepositoryValue && inputTableValue) {
              getRetainColumnsData({
                dataRepositoryId: sourceRepositoryValue,
                dataSets: inputTableValue,
                key: NODE_TYPES.source.keys.retain_columns,
              })
            }
            getDQMetrices(sourceRepositoryValue, inputTableValue)
            getDQCheckValidation(NODE_TYPES.source.keys.dq_checks)
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.DQ_02_CHECKS.componentNodeType
        ) {
          const repository = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.transformation.keys.source_repository,
          )
          if (repository) {
            getDataRepository(NODE_TYPES.transformation.keys.source_repository)
          }
          if (isEdit) {
            const sourceRepositoryValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.transformation.keys.source_repository,
            )?.value

            const inputTableValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.transformation.keys.input_table,
            )?.value
            if (sourceRepositoryValue)
              getInputTableData({
                dataRepositoryId: sourceRepositoryValue,
                key: NODE_TYPES.transformation.keys.input_table,
              })
            if (sourceRepositoryValue && inputTableValue) {
              getRetainColumnsData({
                dataRepositoryId: sourceRepositoryValue,
                dataSets: inputTableValue,
                key: NODE_TYPES.transformation.keys.retain_columns,
              })
            }
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.DQ_03_METRICS.componentNodeType
        ) {
          const repository = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.transformation.keys.source_repository,
          )
          if (repository) {
            getDataRepository(NODE_TYPES.transformation.keys.source_repository)
          }
          if (isEdit) {
            const sourceRepositoryValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.transformation.keys.source_repository,
            )?.value

            const inputTableValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.transformation.keys.input_table,
            )?.value
            if (sourceRepositoryValue)
              getInputTableData({
                dataRepositoryId: sourceRepositoryValue,
                key: NODE_TYPES.transformation.keys.input_table,
              })
            if (sourceRepositoryValue && inputTableValue) {
              getRetainColumnsData({
                dataRepositoryId: sourceRepositoryValue,
                dataSets: inputTableValue,
                key: NODE_TYPES.transformation.keys.retain_columns,
              })
            }
          }
        } else if (
          nodeType ===
          NODE_TYPES_NAMES.DQ_04_TARGET_REPOSITORY.componentNodeType
        ) {
          getDataRepository(NODE_TYPES.target.keys.target_repository)

          const inputNodesValue = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.transformation.keys.input_node,
          )?.value

          if (inputNodesValue) {
            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.transformation.keys.input_data_frame,
              key2: NODE_TYPES.transformation.keys.retain_columns,
            })
          }

          // const repository = inputConfiguration.find(
          //   (item: any) =>
          //     item.key === NODE_TYPES.target.keys.target_repository,
          // )
          // if (repository) {
          //   getDataRepository(NODE_TYPES.target.keys.target_repository)
          // }
          if (isEdit) {
            getInputNodes({
              key: NODE_TYPES.target.keys.input_node,
              componentNodeId: originNodeId,
            })
            getDataRepository(NODE_TYPES.target.keys.target_repository)
            const sourceRepositoryValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.target.keys.source_repository,
            )?.value
            const targetRepositoryValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.target.keys.target_repository,
            )?.value

            const outputTableValue = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.target.keys.output_table,
            )?.value
            if (targetRepositoryValue)
              getInputTableData({
                dataRepositoryId: targetRepositoryValue,
                key: NODE_TYPES.target.keys.output_table,
              })
            if (targetRepositoryValue && outputTableValue) {
              getRetainColumnsData({
                dataRepositoryId: targetRepositoryValue,
                dataSets: outputTableValue,
                key: NODE_TYPES.target.keys.retain_columns,
              })
            }

            const inputTableValue = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.target.keys.input_table,
            )?.value
            if (sourceRepositoryValue)
              getInputTableData({
                dataRepositoryId: sourceRepositoryValue,
                key: NODE_TYPES.target.keys.input_table,
              })

            if (sourceRepositoryValue && inputTableValue) {
              getRetainColumnsData({
                dataRepositoryId: sourceRepositoryValue,
                dataSets: inputTableValue,
                key: NODE_TYPES.target.keys.retain_columns,
              })
            }
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.DOCUMENT_02_EXTRACTION.componentNodeType
        ) {
          if (isEdit) {
            getInputNodes({
              key: NODE_TYPES.target.keys.input_node,
              componentNodeId: originNodeId,
            })
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.DOCUMENT_02_PROCESSING.componentNodeType
        ) {
          const inputNodesValue = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.transformation.keys.input_node,
          )?.value

          if (inputNodesValue) {
            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.transformation.keys.input_data_frame,
              key2: NODE_TYPES.transformation.keys.retain_columns,
            })
          }
          if (isEdit) {
            const inputNodesValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.documentProcessing.keys.input_node,
            )?.value
            getInputNodes({
              key: NODE_TYPES.target.keys.input_node,
              componentNodeId: originNodeId,
            })

            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.documentProcessing.keys.input_data_frame,
              key2: NODE_TYPES.documentProcessing.keys.retain_columns,
            })
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.DOCUMENT_03_STAGING.componentNodeType
        ) {
          const inputNodesValue = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.transformation.keys.input_node,
          )?.value

          if (inputNodesValue) {
            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.transformation.keys.input_data_frame,
              key2: NODE_TYPES.transformation.keys.retain_columns,
            })
          }
          if (isEdit) {
            const inputNodesValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.documentProcessing.keys.input_node,
            )?.value
            getInputNodes({
              key: NODE_TYPES.target.keys.input_node,
              componentNodeId: originNodeId,
            })

            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.documentProcessing.keys.input_data_frame,
              key2: NODE_TYPES.documentProcessing.keys.retain_columns,
            })
          }
        } else if (
          nodeType ===
          NODE_TYPES_NAMES.DOCUMENT_04_DATA_TARGET.componentNodeType
        ) {
          const repository = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.target.keys.target_repository,
          )
          const inputNode = inputConfiguration.find(
            (item: any) => item.key === NODE_TYPES.target.keys.input_node,
          )
          if (repository) {
            // getDataRepository(NODE_TYPES.target.keys.target_repository)
            getMasterRepository(
              nodeType,
              NODE_TYPES.target.keys.target_repository,
            )
          }
          getOutputNodes({
            inputNodeIds: inputNode.value,
            key: NODE_TYPES.documentProcessing.keys.input_variables,
          })
          getVectorNodes({
            inputNodeIds: inputNode.value,
            key: NODE_TYPES.documentProcessing.keys.input_vectors,
          })

          // if (inputNode) {
          //   getInputNodeData({ key: NODE_TYPES.source.keys.input_node })
          // }
          if (isEdit) {
            getInputTableData({
              dataRepositoryId: repository.value,
              key: NODE_TYPES.target.keys.output_table,
            })
            getInputNodes({
              key: NODE_TYPES.target.keys.input_node,
              componentNodeId: originNodeId,
            })
            if (inputNode) {
              getOutputNodes({
                inputNodeIds: inputNode.value,
                key: NODE_TYPES.documentProcessing.keys.input_variables,
              })
            }
          }
        } else if (
          nodeType ===
          NODE_TYPES_NAMES.PIPELINE_01_DATA_SOURCE.componentNodeType
        ) {
          /** For Source Node*/

          getDataRepository(NODE_TYPES.source.keys.source_repository)
          if (isEdit) {
            const sourceRepositoryValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.source.keys.source_repository,
            )?.value

            const inputTableValue = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.source.keys.input_table,
            )?.value
            if (sourceRepositoryValue)
              getInputTableData({
                dataRepositoryId: sourceRepositoryValue,
                key: NODE_TYPES.source.keys.input_table,
              })
            if (sourceRepositoryValue && inputTableValue) {
              getRetainColumnsData({
                dataRepositoryId: sourceRepositoryValue,
                dataSets: inputTableValue,
                key: NODE_TYPES.source.keys.retain_columns,
              })
              if (componentDetailsData?.component_type === 'DATA_ACCESS_API') {
                getColumnsToFilterData({
                  dataRepositoryId: sourceRepositoryValue,
                  dataSets: inputTableValue,
                  key: NODE_TYPES.source.keys.filter_columns,
                })
              }
              getDQMetrices(sourceRepositoryValue, inputTableValue)
              getDQCheckValidation(NODE_TYPES.source.keys.dq_checks)
            }
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.API_01_DATA_SOURCE.componentNodeType
        ) {
          if (
            componentNodeTemplate ===
            NODE_TEMPLATE_NAMES.API_TEMPLATE_NETWORK_GRAPHDB_BASED
          ) {
            getDataRepository(NODE_TYPES.source.keys.source_repository)
            if (isEdit) {
              const sourceRepositoryValue = inputConfiguration.find(
                (item: any) =>
                  item.key === NODE_TYPES.source.keys.source_repository,
              )?.value

              const inputTableValue = inputConfiguration.find(
                (item: any) => item.key === NODE_TYPES.source.keys.input_table,
              )?.value
              setInputTable(inputTableValue)
              if (sourceRepositoryValue)
                getInputTableData({
                  dataRepositoryId: sourceRepositoryValue,
                  key: NODE_TYPES.source.keys.input_table,
                })
              if (sourceRepositoryValue && inputTableValue) {
                getFilteredRetainColumnsData({
                  dataRepositoryId: sourceRepositoryValue,
                  dataSets: inputTableValue,
                  key: NODE_TYPES.source.keys.retain_columns,
                })
                if (
                  componentDetailsData?.component_type === 'DATA_ACCESS_API'
                ) {
                  getFilteredColumnsToFilterData({
                    dataRepositoryId: sourceRepositoryValue,
                    dataSets: inputTableValue,
                    key: NODE_TYPES.source.keys.filter_columns,
                  })
                }
                getDQMetrices(sourceRepositoryValue, inputTableValue)
                getDQCheckValidation(NODE_TYPES.source.keys.dq_checks)
              }
            }
          } else {
            getDataRepository(NODE_TYPES.source.keys.source_repository)
            if (isEdit) {
              const sourceRepositoryValue = inputConfiguration.find(
                (item: any) =>
                  item.key === NODE_TYPES.source.keys.source_repository,
              )?.value

              const inputTableValue = inputConfiguration.find(
                (item: any) => item.key === NODE_TYPES.source.keys.input_table,
              )?.value
              if (sourceRepositoryValue)
                getInputTableData({
                  dataRepositoryId: sourceRepositoryValue,
                  key: NODE_TYPES.source.keys.input_table,
                })
              if (sourceRepositoryValue && inputTableValue) {
                getRetainColumnsData({
                  dataRepositoryId: sourceRepositoryValue,
                  dataSets: inputTableValue,
                  key: NODE_TYPES.source.keys.retain_columns,
                })
                if (
                  componentDetailsData?.component_type === 'DATA_ACCESS_API'
                ) {
                  getColumnsToFilterData({
                    dataRepositoryId: sourceRepositoryValue,
                    dataSets: inputTableValue,
                    key: NODE_TYPES.source.keys.filter_columns,
                  })
                }
                getDQMetrices(sourceRepositoryValue, inputTableValue)
                getDQCheckValidation(NODE_TYPES.source.keys.dq_checks)
              }
            }
          }
        } else if (
          nodeType ===
          NODE_TYPES_NAMES.PIPELINE_04_DATA_TARGET.componentNodeType
        ) {
          /** For Target Node */
          getDataRepository(NODE_TYPES.target.keys.target_repository)

          const inputNodesValue = inputConfiguration.find(
            (item: any) => item.key === NODE_TYPES.target.keys.input_node,
          )?.value

          if (inputNodesValue) {
            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.target.keys.input_data_frame,
            })
          }

          if (isEdit) {
            getInputNodes({
              key: NODE_TYPES.target.keys.input_node,
              componentNodeId: originNodeId,
            })
            const inputNodesValue = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.target.keys.input_node,
            )?.value
            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.target.keys.input_data_frame,
            })

            const targetRepositoryValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.target.keys.target_repository,
            )?.value

            const outputTableValue = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.target.keys.output_table,
            )?.value
            if (targetRepositoryValue) {
              getInputTableData({
                dataRepositoryId: targetRepositoryValue,
                key: NODE_TYPES.target.keys.output_table,
              })
              getInputFolderData({
                dataRepositoryId: targetRepositoryValue,
                key: NODE_TYPES.documentProcessing.keys.output_table,
              })
            }
            if (targetRepositoryValue && outputTableValue) {
              getRetainColumnsData({
                dataRepositoryId: targetRepositoryValue,
                dataSets: outputTableValue,
                key: NODE_TYPES.target.keys.retain_columns,
              })
              getDQMetrices(targetRepositoryValue, outputTableValue)
              getDQCheckValidation(NODE_TYPES.source.keys.dq_checks)
              if (componentDetailsData?.component_type === 'DATA_ACCESS_API') {
                getColumnsToFilterData({
                  dataRepositoryId: targetRepositoryValue,
                  dataSets: outputTableValue,
                  key: NODE_TYPES.source.keys.filter_columns,
                })
              }
            }
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.API_02_API_OUTPUT.componentNodeType
        ) {
          getOutputFieldData({ key: NODE_TYPES.source.keys.output_type })
          const inputNodesValue = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.transformation.keys.input_node,
          )?.value

          if (inputNodesValue) {
            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.transformation.keys.input_data_frame,
              key2: NODE_TYPES.transformation.keys.retain_columns,
            })
          }
          // getInputOutputFieldData({
          //   inputKey: NODE_TYPES.source.keys.filter_columns,
          //   outputKey: NODE_TYPES.source.keys.retain_columns,
          //   outputKey2: NODE_TYPES.source.keys.retain_columns_02,
          // })
          if (isEdit) {
            getInputNodes({
              key: NODE_TYPES.transformation.keys.input_node,
              componentNodeId: originNodeId,
            })
            const inputNodesValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.transformation.keys.input_node,
            )?.value

            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.transformation.keys.input_data_frame,
              key2: NODE_TYPES.transformation.keys.retain_columns,
            })
          }
        } else if (
          nodeType ===
          NODE_TYPES_NAMES.PIPELINE_03_TRANSFORMATION_BLOCK.componentNodeType
        ) {
          /** For Transform Node */
          const inputNodesValue = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.transformation.keys.input_node,
          )?.value

          if (inputNodesValue) {
            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.transformation.keys.input_data_frame,
              key2: NODE_TYPES.transformation.keys.retain_columns,
            })
          }
          if (isEdit) {
            getInputNodes({
              key: NODE_TYPES.transformation.keys.input_node,
              componentNodeId: originNodeId,
            })
            const inputNodesValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.transformation.keys.input_node,
            )?.value
            setOriginNodeDetail(inputNodesValue)
            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.transformation.keys.input_data_frame,
              key2: NODE_TYPES.transformation.keys.retain_columns,
            })
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.RETRIEVAL_01_REQUEST.componentNodeType
        ) {
          getLlmModels({ key: NODE_TYPES.knowledgeRetrieval.keys.stt_model })
          getLlmModels({
            key: NODE_TYPES.knowledgeRetrieval.keys.translation_model,
          })
          if (isEdit) {
            getLlmModels({ key: NODE_TYPES.knowledgeRetrieval.keys.stt_model })
            getLlmModels({
              key: NODE_TYPES.knowledgeRetrieval.keys.translation_model,
            })
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.RETRIEVAL_04_RESPONSE.componentNodeType
        ) {
          if (isEdit) {
            const inputNode = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.target.keys.input_node,
            )
            if (inputNode) {
              getInputNodes({
                key: NODE_TYPES.target.keys.input_node,
                componentNodeId: originNodeId,
              })
            }
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.RETRIEVAL_02_RAG.componentNodeType
        ) {
          getMasterRepository(
            nodeType,
            NODE_TYPES.documentProcessing.keys.master_index_repository,
          )
          const masterRepositoryValue = inputConfiguration.find(
            (item: any) =>
              item.key ===
              NODE_TYPES.documentProcessing.keys.master_index_repository,
          )?.value
          const masterIndex = inputConfiguration.find(
            (item: any) =>
              item.key ===
              NODE_TYPES.documentProcessing.keys.master_index_table,
          )
          if (masterIndex) {
            getInputTableData({
              dataRepositoryId: masterRepositoryValue,
              key: NODE_TYPES.documentProcessing.keys.master_index_table,
            })
          }
          if (isEdit) {
            getInputNodes({
              key: NODE_TYPES.target.keys.input_node,
              componentNodeId: originNodeId,
            })
            const inputNodesValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.transformation.keys.input_node,
            )?.value
            getMasterRepository(
              nodeType,
              NODE_TYPES.documentProcessing.keys.master_index_repository,
            )
            // const masterRepositoryValue = inputConfiguration.find(
            //   (item: any) =>
            //     item.key === NODE_TYPES.documentProcessing.keys.master_index_repository,
            // )?.value
            // const masterIndex = inputConfiguration.find(
            //   (item: any) => item.key === NODE_TYPES.documentProcessing.keys.master_index_table,
            // )
            // if (masterIndex) {
            //   getInputTableData({
            //     dataRepositoryId: masterRepositoryValue,
            //     key: NODE_TYPES.documentProcessing.keys.master_index_table,
            //   })
            // }
            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.documentProcessing.keys.input_variables,
            })
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.TRAINING_01_SOURCE.componentNodeType
        ) {
          getDataRepository(NODE_TYPES.source.keys.source_repository)
          if (isEdit) {
            const sourceRepositoryValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.source.keys.source_repository,
            )?.value

            const inputTable = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.source.keys.input_table,
            )
            if (inputTable) {
              getInputTableData({
                dataRepositoryId: sourceRepositoryValue,
                key: NODE_TYPES.source.keys.input_table,
              })

              getRetainColumnsData({
                dataRepositoryId: sourceRepositoryValue,
                dataSets: inputTable.value,
                key: NODE_TYPES.source.keys.retain_columns,
              })
            }
            if (sourceRepositoryValue) {
              getInputFolderData({
                dataRepositoryId: sourceRepositoryValue,
                key: NODE_TYPES.documentProcessing.keys.input_folder,
              })
            }
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.TRAINING_02_EXTRACTION.componentNodeType
        ) {
          const inputNodesValue = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.transformation.keys.input_node,
          )?.value

          if (inputNodesValue) {
            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.documentProcessing.keys.input_data_frame,
              key2: NODE_TYPES.documentProcessing.keys.retain_columns,
            })
          }
          if (isEdit) {
            getInputNodes({
              key: NODE_TYPES.target.keys.input_node,
              componentNodeId: originNodeId,
            })
            const inputNodesValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.transformation.keys.input_node,
            )?.value
            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.documentProcessing.keys.input_data_frame,
              key2: NODE_TYPES.documentProcessing.keys.retain_columns,
            })
          }
        } else if (
          nodeType === NODE_TYPES_NAMES.TRAINING_04_TARGET.componentNodeType
        ) {
          getLlmModels({ key: NODE_TYPES.documentProcessing.keys.llm_model })
          const inputNode = inputConfiguration.find(
            (item: any) => item.key === NODE_TYPES.target.keys.input_node,
          )
          if (inputNode) {
            getOutputNodes({
              inputNodeIds: inputNode.value,
              key: NODE_TYPES.documentProcessing.keys.input_data_frame,
            })
          }
          if (isEdit) {
            const inputNode = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.target.keys.input_node,
            )
            getInputNodes({
              key: NODE_TYPES.target.keys.input_node,
              componentNodeId: originNodeId,
            })
            if (inputNode) {
              getOutputNodes({
                inputNodeIds: inputNode.value,
                key: NODE_TYPES.documentProcessing.keys.input_data_frame,
              })
            }
          }
        }
      }
    },
    [
      getSubComponent,
      NODE_TYPES,
      getDataAccessApi,
      getDataRepository,
      getXYSeriesData,
      getInputTableData,
      getColumnsToFilterData,
      getRetainColumnsData,
      getInputFolderData,
      getInputNodeData,
      componentDetailsData,
      getDQMetrices,
      getDQCheckValidation,
      getInputNodes,
      getOutputNodes,
      getOutputFieldData,
      getInputOutputFieldData,
    ],
  )

  const getComponentNodeDetailsForTransformation = useCallback(
    async (arg: any) => {
      const { templateId } = arg
      try {
        setLoading(true)
        const component_node_type_id = templateId
        const resp = await ApiServices.getComponentNodeDetailsConfiguration(
          component_node_type_id,
        )
        setLoading(false)
        const inputConfiguration = resp.data
        setTranformationOperationConfiguration(inputConfiguration)
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const handleTransformationPopup = useCallback(
    (transformerPopupProperty: any) => {
      const objectType = transformerPopupProperty.objectType
      const checkInputNodeValue = drawerConfiguration.find(
        (item) => item.key === NODE_TYPES.transformation.keys.input_node,
      )
      const inputNodeNotRequiredIds = [
        NODE_TYPES_NAMES.DOCUMENT_01_SOURCE.id,
        NODE_TYPES_NAMES.RETRIEVAL_01_REQUEST.id,
        NODE_TYPES_NAMES.TRAINING_01_SOURCE.id,
        NODE_TYPES_NAMES.API_01_DATA_SOURCE.id,
      ]
      let sourceTypeId
      if (componentNodeDetailConfig?.component_node_type_id?._id) {
        sourceTypeId = componentNodeDetailConfig?.component_node_type_id?._id
      } else {
        sourceTypeId = componentNodeDetailConfig?.id
      }

      if (
        (!checkInputNodeValue || checkInputNodeValue.value.length === 0) &&
        !inputNodeNotRequiredIds.includes(sourceTypeId)
      ) {
        setInputNodeError(true)
        return
      }
      setselectedTranformationTemplateId(objectType)
      getComponentNodeDetailsForTransformation({ templateId: objectType })

      setOpenTransformerPopup(transformerPopupProperty)
    },
    [drawerConfiguration, getComponentNodeDetailsForTransformation, NODE_TYPES],
  )

  const handleTransformationDialogClose = useCallback(() => {
    setOpenTransformerPopup(DefaultTranformerPopup)
  }, [])

  useEffect(() => {
    if (selectedNode && selectedNode.id) {
      const component_node_type_id = selectedNode.component_node_type_id
      const nodeType = component_node_type_id

      setNodePropertiesDrawer({
        nodeType,
        show: true,
        isEdit: true,
      })
      const nodeId = selectedNode.id
      const getComponentNode = async () => {
        try {
          const resp = await ApiServices.getComponentNodeDetails(nodeId)
          const inputConfiguration = resp.data.configuration.input
          setComponentNodeDetailConfig(resp.data)
          setComponentNodeTemplateId(resp.data?.component_node_template_id?._id)
          setComponentNodeTypeId(resp.data?.component_node_type_id?._id)
          setDrawerConfiguration(inputConfiguration)
          updateEditFields({
            componentInputConfigurationData: resp.data,
            isEdit: true,
            originNodeId: nodeId,
          })
        } catch (error: any) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
          console.log('error', error)
        }
      }
      getComponentNode()
    }
  }, [selectedNode, setNodePropertiesDrawer, updateEditFields])

  const getComponentNodeDetailsConfiguration = useCallback(
    async ({ nodeTypeId, originNodeDetail }: any) => {
      try {
        const resp: any =
          await ApiServices.getComponentNodeDetailsConfiguration(nodeTypeId)
        const inputConfiguration = resp.data.configuration.input
        setComponentNodeDetailConfig(resp.data)
        setComponentNodeTypeId(resp.data?.id)
        //todo: code not deleted for future reference
        // if (
        //   originNodeDetail &&
        //   (originNodeDetail.newNodeTypeName ===
        //     NODE_TYPES.transformation.componentNodeTypeName ||
        //     originNodeDetail.newNodeTypeName ===
        //       NODE_TYPES.target.componentNodeTypeName)
        // ) {
        if (
          originNodeDetail &&
          originNodeDetail.id &&
          originNodeDetail.component_node_name
        ) {
          const repository = inputConfiguration.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.transformation.keys.input_node,
          )
          if (repository) {
            repository.dropdownData = [
              {
                id: originNodeDetail.id,
                component_node_name: originNodeDetail.component_node_name,
              },
            ]
            repository.value = [originNodeDetail.id]
            if (resp.data) {
              let nodeType = ''
              if (resp.data.component_node_type_id) {
                nodeType = resp.data.component_node_type_id.componentNodeType
              } else if (resp.data.component_node_type) {
                nodeType = resp.data.component_node_type
              }
              if (
                nodeType ===
                NODE_TYPES_NAMES.API_02_API_OUTPUT.componentNodeType
              ) {
                getInputOutputFieldData({
                  inputKey: NODE_TYPES.source.keys.filter_columns,
                  outputKey: NODE_TYPES.source.keys.retain_columns,
                  outputKey2: NODE_TYPES.source.keys.retain_columns_02,
                  id: originNodeDetail.id,
                })
              }
            }
            setOriginNodeDetail([originNodeDetail.id])
          }
        }
        // }

        setDrawerConfiguration(inputConfiguration)
        updateEditFields({
          componentInputConfigurationData: resp.data,
          originNodeDetail,
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [updateEditFields, NODE_TYPES],
  )

  const getComponentNodeTemplateConfiguration = useCallback(
    async ({ nodeTypeId, originNodeDetail }: any) => {
      setComponentNodeTemplateId(nodeTypeId)
      try {
        const resp: any =
          await ApiServices.getComponentNodeTemplateConfiguration(nodeTypeId)
        const inputConfiguration = resp.data.configuration.input
        setComponentNodeTypeId(resp.data?.component_node_type_id?._id)
        setComponentNodeDetailConfig(resp.data)
        //todo: code not deleted for future reference
        // if (
        //   originNodeDetail &&
        //   originNodeDetail.newNodeTypeName ===
        //     NODE_TYPES.transformation.componentNodeTypeName
        // ) {
        if (
          originNodeDetail &&
          originNodeDetail.id &&
          originNodeDetail.component_node_name
        ) {
          const repository = inputConfiguration.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.transformation.keys.input_node,
          )
          if (repository) {
            repository.dropdownData = [
              {
                id: originNodeDetail.id,
                component_node_name: originNodeDetail.component_node_name,
              },
            ]
            repository.value = [originNodeDetail.id]
            if (resp.data) {
              let nodeType = ''
              if (resp.data.component_node_type_id) {
                nodeType = resp.data.component_node_type_id.componentNodeType
              } else if (resp.data.component_node_type) {
                nodeType = resp.data.component_node_type
              }
              if (
                nodeType ===
                NODE_TYPES_NAMES.API_02_API_OUTPUT.componentNodeType
              ) {
                getInputOutputFieldData({
                  inputKey: NODE_TYPES.source.keys.filter_columns,
                  outputKey: NODE_TYPES.source.keys.retain_columns,
                  outputKey2: NODE_TYPES.source.keys.retain_columns_02,
                  id: originNodeDetail.id,
                })
              }
            }
            setOriginNodeDetail([originNodeDetail.id])
          }
        }
        // }
        setDrawerConfiguration(inputConfiguration)
        updateEditFields({
          componentInputConfigurationData: resp.data,
          originNodeDetail,
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [updateEditFields, NODE_TYPES],
  )

  const onDropDownChange = useCallback(
    (arg: any) => {
      const { input, e: event } = arg
      const inputConfiguration = drawerConfiguration
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        updateDrawerConfiguration[index].value = event.target.value
      }
      setDrawerConfiguration(updateDrawerConfiguration)
      if (input.key === NODE_TYPES.source.keys.source_repository) {
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.input_table,
          )
          if (repository) {
            repository.dropdownData = []
            repository.value = ''
          }
          const retainColrepository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.retain_columns,
          )
          if (retainColrepository) {
            retainColrepository.dropdownData = []
            retainColrepository.value = []
          }

          if (componentDetailsData?.component_type === 'DATA_ACCESS_API') {
            const filterColrepository = newData.find(
              (item: ConfigurationInputInterface) =>
                item.key === NODE_TYPES.source.keys.filter_columns,
            )
            if (filterColrepository) {
              filterColrepository.dropdownData = []
              filterColrepository.value = []
            }
          }

          const dqMetricItem = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.dq_metrics,
          )
          if (dqMetricItem) {
            dqMetricItem.dropdownData = []
            dqMetricItem.value = []
          }
          const dq_checksItem = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.dq_checks,
          )
          if (dq_checksItem) {
            dq_checksItem.dropdownData = []
            dq_checksItem.value = []
          }
          return [...newData]
        })
        const inputTable = inputConfiguration.find(
          (item: any) => item.key === NODE_TYPES.source.keys.input_table,
        )
        const inputFolder = inputConfiguration.find(
          (item: any) =>
            item.key === NODE_TYPES.documentProcessing.keys.input_folder,
        )
        if (inputTable) {
          getInputTableData({
            dataRepositoryId: event.target.value,
            key: NODE_TYPES.source.keys.input_table,
          })
        }
        if (inputFolder) {
          getInputFolderData({
            dataRepositoryId: event.target.value,
            key: NODE_TYPES.documentProcessing.keys.input_folder,
          })
        }
      } else if (
        input.key === NODE_TYPES.documentProcessing.keys.master_index_repository
      ) {
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key ===
              NODE_TYPES.documentProcessing.keys.master_index_table,
          )
          if (repository) {
            repository.dropdownData = []
            repository.value = ''
          }
          return [...newData]
        })
        const inputTable = inputConfiguration.find(
          (item: any) =>
            item.key === NODE_TYPES.documentProcessing.keys.master_index_table,
        )

        if (inputTable) {
          getInputTableData({
            dataRepositoryId: event.target.value,
            key: NODE_TYPES.documentProcessing.keys.master_index_table,
          })
        }
      } else if (input.key === NODE_TYPES.source.keys.input_table) {
        const componentNodeTemplate =
          componentNodeDetailConfig?.component_node_template ||
          componentNodeDetailConfig?.component_node_template_id
            ?.componentNodeTemplate

        let dataRepositoryId = ''
        const sourceRepository = updateDrawerConfiguration.find(
          (item) => item.key === NODE_TYPES.source.keys.source_repository,
        )
        const filterColumns = updateDrawerConfiguration.find(
          (item) => item.key === NODE_TYPES.source.keys.filter_columns,
        )

        if (sourceRepository) dataRepositoryId = sourceRepository.value
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]

          const retainColrepository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.retain_columns,
          )
          if (retainColrepository) {
            retainColrepository.dropdownData = []
            retainColrepository.value = []
          }
          const dqMetricItem = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.dq_metrics,
          )

          if (componentDetailsData?.component_type === 'DATA_ACCESS_API') {
            const filterColrepository = newData.find(
              (item: ConfigurationInputInterface) =>
                item.key === NODE_TYPES.source.keys.filter_columns,
            )
            if (filterColrepository) {
              filterColrepository.dropdownData = []
              filterColrepository.value = []
            }
          }

          if (dqMetricItem) {
            dqMetricItem.dropdownData = []
            dqMetricItem.value = []
          }

          const dq_checksItem = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.dq_checks,
          )
          if (dq_checksItem) {
            dq_checksItem.dropdownData = []
            dq_checksItem.value = []
          }
          return [...newData]
        })

        setInputTable(event.target.value)
        if (
          componentNodeTemplate ===
          NODE_TEMPLATE_NAMES.API_TEMPLATE_NETWORK_GRAPHDB_BASED
        ) {
          getFilteredRetainColumnsData({
            dataRepositoryId,
            dataSets: event.target.value,
            key: NODE_TYPES.source.keys.retain_columns,
          })
          getFilteredColumnsToFilterData({
            dataRepositoryId,
            dataSets: event.target.value,
            key: NODE_TYPES.source.keys.filter_columns,
          })
        } else {
          getRetainColumnsData({
            dataRepositoryId,
            dataSets: event.target.value,
            key: NODE_TYPES.source.keys.retain_columns,
          })
          if (filterColumns) {
            getColumnsToFilterData({
              dataRepositoryId: dataRepositoryId,
              dataSets: event.target.value,
              key: NODE_TYPES.source.keys.filter_columns,
            })
          }
        }
        getRetainColumnsData({
          dataRepositoryId,
          dataSets: event.target.value,
          key: NODE_TYPES.source.keys.retain_columns_01,
        })
        getRetainColumnsData({
          dataRepositoryId,
          dataSets: event.target.value,
          key: NODE_TYPES.source.keys.retain_columns_02,
        })
        getRetainColumnsData({
          dataRepositoryId,
          dataSets: event.target.value,
          key: NODE_TYPES.source.keys.retain_columns_03,
        })

        getDQMetrices(dataRepositoryId, event.target.value)
        getDQCheckValidation(NODE_TYPES.source.keys.dq_checks, dataRepositoryId)
      } else if (input.key === NODE_TYPES.target.keys.target_repository) {
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.target.keys.output_table,
          )
          if (repository) {
            repository.dropdownData = []
            repository.value = ''
          }
          const retainColrepository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.target.keys.retain_columns,
          )
          if (retainColrepository) {
            retainColrepository.dropdownData = []
            retainColrepository.value = []
          }

          return [...newData]
        })

        const outputTable = inputConfiguration.find(
          (item: any) => item.key === NODE_TYPES.target.keys.output_table,
        )

        if (outputTable) {
          if (componentDetailsData?.component_type === 'DATA_PIPELINE') {
            const allRepositoryData = inputConfiguration.find(
              (item) => item.key === NODE_TYPES.target.keys.target_repository,
            )
            if (allRepositoryData) {
              const selectedRepository = allRepositoryData.dropdownData.find(
                (item: any) => item.id === event.target.value,
              )

              if (selectedRepository.db_type.includes('DOCUMENT')) {
                changeInputConfigurationOfInputTable(
                  true,
                  NODE_TYPES.target.keys.output_table,
                )
                getInputFolderData({
                  dataRepositoryId: event.target.value,
                  key: NODE_TYPES.target.keys.output_table,
                })
              } else {
                changeInputConfigurationOfInputTable(
                  false,
                  NODE_TYPES.target.keys.output_table,
                )
                getInputTableData({
                  dataRepositoryId: event.target.value,
                  key: NODE_TYPES.target.keys.output_table,
                })
              }
            }
          } else {
            getInputTableData({
              dataRepositoryId: event.target.value,
              key: NODE_TYPES.target.keys.output_table,
            })
          }
        }
      } else if (input.key === NODE_TYPES.transformation.keys.input_node) {
        setInputNodeError(false)
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.transformation.keys.input_data_frame,
          )
          if (repository) {
            repository.dropdownData = []
            repository.value = ''
          }
          const retainColrepository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.transformation.keys.retain_columns,
          )
          if (retainColrepository) {
            retainColrepository.dropdownData = []
            retainColrepository.value = []
          }
          const tranformationToApplyrepository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key ===
              NODE_TYPES.transformation.keys.transformations_to_apply,
          )
          if (tranformationToApplyrepository) {
            tranformationToApplyrepository.value = []
          }
          return [...newData]
        })
        if (
          componentNodeDetailConfig?.component_node_type ===
            'DOCUMENT_04_DATA_TARGET' ||
          componentNodeDetailConfig?.component_node_type_id
            ?.componentNodeType === 'DOCUMENT_04_DATA_TARGET'
        ) {
          getOutputNodes({
            inputNodeIds: event.target.value,
            key: NODE_TYPES.documentProcessing.keys.input_variables,
          })
          getVectorNodes({
            inputNodeIds: event.target.value,
            key: NODE_TYPES.documentProcessing.keys.input_vectors,
          })
          getOutputNodes({
            inputNodeIds: event.target.value,
            key: NODE_TYPES.documentProcessing.keys.master_index_variables,
          })
        } else {
          setOriginNodeDetail(event.target.value)
          getOutputNodes({
            inputNodeIds: event.target.value,
            key: NODE_TYPES.transformation.keys.input_data_frame,
            key2: NODE_TYPES.transformation.keys.retain_columns,
          })
        }
      } else if (input.key === NODE_TYPES.source.keys.data_acess_api) {
        if (
          componentNodeDetailConfig?.component_node_type ===
            NODE_TYPES_NAMES.VISUALIZATION_01_SUBCOMPONENT.componentNodeType ||
          componentNodeDetailConfig?.component_node_type_id
            ?.componentNodeType ===
            NODE_TYPES_NAMES.VISUALIZATION_01_SUBCOMPONENT.componentNodeType
        ) {
          getEndpointNodes({
            key: NODE_TYPES.source.keys.endpoint_node,
            id: event.target.value,
          })
        }
        getXYSeriesData({
          inputKeyX: NODE_TYPES.source.keys.retain_columns_01,
          outputKeyY: NODE_TYPES.source.keys.retain_columns_02,
          filterKey: NODE_TYPES.source.keys.filter_columns,
          id: event.target.value,
        })
      } else if (input.key === NODE_TYPES.source.keys.endpoint_node) {
        const componentId = inputConfiguration.find(
          (item: any) => item.key === NODE_TYPES.source.keys.data_acess_api,
        )
        if (componentId !== undefined) {
          getOutputData({
            retain_columns: NODE_TYPES.source.keys.retain_columns,
            filterKey: NODE_TYPES.source.keys.filter_columns,
            componentId: componentId.value,
            componentNodeId: event.target.value,
          })
        }
      }
    },
    [
      drawerConfiguration,
      NODE_TYPES,
      componentDetailsData,
      getInputTableData,
      getInputFolderData,
      getRetainColumnsData,
      getDQMetrices,
      getDQCheckValidation,
      getColumnsToFilterData,
      getOutputNodes,
      getXYSeriesData,
    ],
  )

  const onTextChange = useCallback(
    (arg: any) => {
      const { input, e: event } = arg
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        if (
          (input && input.key === 'output_dataframe') ||
          input.key === 'output_column'
        ) {
          const value = event.target.value
          const noSpacesValue = value.replace(/\s+/g, '')
          updateDrawerConfiguration[index].value = noSpacesValue
        } else {
          updateDrawerConfiguration[index].value = event.target.value
        }
      }
      setDrawerConfiguration(updateDrawerConfiguration)
    },
    [drawerConfiguration],
  )

  const onMultiColumnTextChange = useCallback(
    (arg: any) => {
      const { input, e: event, index: currentIndex, item, allData } = arg
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        if (updateDrawerConfiguration[index].value.length === 0) {
          const allValues = allData.map((data: any) => ({
            ...data,
            selected: false,
          }))
          updateDrawerConfiguration[index].value = allValues
        }
        const values: any = { ...item, ...input.value?.[currentIndex] }
        values[event.target.name] = event.target.value
        updateDrawerConfiguration[index].value[currentIndex] = values
      }
      setDrawerConfiguration(updateDrawerConfiguration)
    },
    [drawerConfiguration],
  )

  const onMultiColumnSingleTextChange = useCallback(
    (arg: any) => {
      const { input, e: event, index: currentIndex, item } = arg
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        const values: any = { ...item, ...input.value?.[currentIndex] }
        values[event.target.name] = event.target.value
        updateDrawerConfiguration[index].value[currentIndex] = values
      }
      setDrawerConfiguration(updateDrawerConfiguration)
    },
    [drawerConfiguration],
  )

  const onMultiColumnCheckboxChange = useCallback(
    (arg: any) => {
      const { input, e: event, index: currentIndex, item, allData } = arg

      const aliasValueKey = input.objectInput.find(
        (item: any) => item.key === 'alias' || item.key === 'label',
      )
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        if (updateDrawerConfiguration[index].value.length === 0) {
          const allValues = allData.map((data: any) => ({
            ...data,
            selected: false,
          }))
          updateDrawerConfiguration[index].value = allValues
        }
        const values: any = { ...item, ...input.value?.[currentIndex] }
        values[event.target.name] = event.target.checked
        if (values.alias || values.alias === '') {
          values.alias =
            input.value
              .filter((item: any) => item !== null)
              .find(
                (v: any) =>
                  v[aliasValueKey.value] === item[aliasValueKey.value],
              )?.alias ||
            input.dropdownData.find(
              (d: any) => d[aliasValueKey.value] === item[aliasValueKey.value],
            )[aliasValueKey.value]
        } else {
          values.label =
            input.value
              .filter((item: any) => item !== null)
              .find(
                (v: any) =>
                  v[aliasValueKey.value] === item[aliasValueKey.value],
              )?.label ||
            input.dropdownData.find(
              (d: any) => d[aliasValueKey.value] === item[aliasValueKey.value],
            )[aliasValueKey.value]
        }

        updateDrawerConfiguration[index].value[currentIndex] = values
      }
      setDrawerConfiguration(updateDrawerConfiguration)
    },
    [drawerConfiguration],
  )

  const onSingleColumnTextChange = useCallback(
    (arg: any) => {
      const { input, e: event, index: currentIndex, item } = arg
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        const values: any = { ...item, ...input.value?.[currentIndex] }
        values[event.target.name] = event.target.value
        updateDrawerConfiguration[index].value[currentIndex] = values
      }
      setDrawerConfiguration(updateDrawerConfiguration)
    },
    [drawerConfiguration],
  )

  const onSingleColumnCheckboxChange = useCallback(
    (arg: any) => {
      const { input, e: event, index: currentIndex, item, allData } = arg
      const aliasValueKey = input.objectInput.find(
        (item: any) => item.key === 'alias' || item.key === 'label',
      )
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        if (updateDrawerConfiguration[index].value.length === 0) {
          const allValues = allData.map((data: any) => ({
            ...data,
            selected: false,
          }))
          updateDrawerConfiguration[index].value = allValues
        } else {
          const allValues = allData.map((data: any) => ({
            ...data,
            selected: false,
          }))
          updateDrawerConfiguration[index].value = allValues
        }
        const values: any = { ...item }
        values[event.target.name] = event.target.checked
        values.alias =
          input.value.find(
            (v: any) => v[aliasValueKey.value] === item[aliasValueKey.value],
          )?.alias ||
          input.dropdownData.find(
            (d: any) => d[aliasValueKey.value] === item[aliasValueKey.value],
          )[aliasValueKey.value]

        values.label =
          input.value.find(
            (v: any) => v[aliasValueKey.value] === item[aliasValueKey.value],
          )?.label ||
          input.dropdownData.find(
            (d: any) => d[aliasValueKey.value] === item[aliasValueKey.value],
          )[aliasValueKey.value]

        updateDrawerConfiguration[index].value[currentIndex] = values
      }
      setDrawerConfiguration(updateDrawerConfiguration)
    },
    [drawerConfiguration],
  )

  const handleDelete = useCallback(
    (arg: any) => {
      const { input, value } = arg
      const updateDrawerConfiguration = [...drawerConfiguration]

      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )

      if (index > -1) {
        const updatedValues = _.without(
          updateDrawerConfiguration[index].value,
          value,
        )
        updateDrawerConfiguration[index].value = updatedValues
        setDrawerConfiguration(updateDrawerConfiguration)

        // Use updated values directly instead of relying on state
        const updatedNodeDetail = updatedValues
        getOutputNodes({
          inputNodeIds: updatedNodeDetail,
          key: NODE_TYPES?.transformation?.keys?.input_data_frame,
          key2: NODE_TYPES?.transformation?.keys?.retain_columns,
        })
      }
    },
    [drawerConfiguration, getOutputNodes],
  )

  const handleMultiObjDelete = (arg: any) => {
    const { input, e: event, value } = arg
    const updateDrawerConfiguration = [...drawerConfiguration]
    const index = updateDrawerConfiguration.findIndex(
      (item) => item.key === input.key,
    )
    if (index > -1) {
      updateDrawerConfiguration[index].value = _.reject(
        updateDrawerConfiguration[index].value,
        value,
      )
    }
    setDrawerConfiguration(updateDrawerConfiguration)
    event.preventDefault()
  }

  const onEditMultiNode = useCallback(
    ({ editItem, objectParams_Value, objectType }: Record<string, any>) => {
      handleTransformationPopup({
        isEdit: true,
        open: true,
        id: editItem.id,
        nodeName: editItem[objectParams_Value],
        objectType,
      })
    },
    [handleTransformationPopup],
  )

  const onDeleteMultiNode = useCallback(
    ({ editItem, key }: Record<string, any>) => {
      setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
        const newData = [...prev]
        const repository: any = newData.find(
          (item: ConfigurationInputInterface) => item.key === key,
        )
        const deleteTransformer = repository?.value.filter(
          (tranformerItem: any) => tranformerItem.id !== editItem.id,
        )
        repository.value = deleteTransformer
        return [...newData]
      })
    },
    [],
  )
  // console.log(drawerConfiguration);

  const updateComponent = useCallback(
    async (arg: any) => {
      const payload = {
        data: arg,
      }
      try {
        setLoading(true)
        await ApiServices.updateComponent(payload)
        setLoading(false)
        getProjectDetails()
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [getProjectDetails, setLoading],
  )

  const onUpdateComponentNodeApi = useCallback(
    async (payload: any, newNodeId: string) => {
      try {
        setLoading(true)
        await ApiServices.updateComponentNode(payload)
        setLoading(false)
        setNodePropertiesDrawer(DefaultNodeProperty)
        getProjectDetails()
        if (!showNodePropertiesDrawer.isEdit) {
          const defaultData = {
            project_id: projectId,
            component_id: componentId ? componentId : '',
            configuration: {
              input: {
                nodes: [],
                edges: [],
              },
            },
          }
          const newData = componentDetailsData
            ? { ...componentDetailsData }
            : defaultData
          const node = {
            data: {
              id: newNodeId,
              component_node_name: selectedNode.component_node_name,
              component_node_type_id: selectedNode.component_node_type_id,
            },
          }

          newData.configuration.input.nodes.push(node)
          if (
            showNodePropertiesDrawer.nodeAttached &&
            showNodePropertiesDrawer.nodeAttached.sourceId
          ) {
            const edge = {
              data: {
                source: showNodePropertiesDrawer.nodeAttached.sourceId,
                target: newNodeId,
              },
            }
            newData.configuration.input.edges.push(edge)
          }
          if (!newData.project_id) newData.project_id = projectId
          if (!newData.id) newData.id = componentId

          updateComponent(newData)

          // setComponentDetailsData(newData)
        } else if (showNodePropertiesDrawer.isEdit) {
          const extraedges: string[] =
            payload?.configuration?.input[0]?.value || []
          const targetedges: string = payload?.id
          // console.log(payload?.configuration?.input[0]?.value);
          const defaultData = {
            project_id: projectId,
            component_id: componentId ? componentId : '',
            configuration: {
              input: {
                nodes: [],
                edges: [],
              },
            },
          }
          const newData = componentDetailsData
            ? { ...componentDetailsData }
            : defaultData
          const node = {
            data: {
              id: newNodeId,
              component_node_name: selectedNode.component_node_name,
              component_node_type_id: selectedNode.component_node_type_id,
            },
          }

          // Remove existing edges to avoid duplicates
          const findSelectedNode = newData.configuration.input.nodes.findIndex(
            (node: any) =>
              node?.data.component_node_type_id ===
              selectedNode.component_node_type_id,
          )

          if (findSelectedNode === -1) {
            newData.configuration.input.nodes.push(node)
          }

          // newData.configuration.input.edges = [];
          newData.configuration.input.edges =
            newData.configuration.input.edges.filter(
              (edge: any) => edge?.data?.target !== targetedges,
            )

          extraedges?.forEach((sourceId: string) => {
            const edge: any = {
              data: {
                source: sourceId,
                target: targetedges,
              },
            }
            // newData.configuration.input.edges.push(edge);
            const edgeExists: any = newData?.configuration?.input?.edges?.some(
              (existingEdge: any) =>
                existingEdge?.data?.source === sourceId &&
                existingEdge?.data?.target === targetedges,
            )

            // Only push the edge if it doesn't already exist
            if (!edgeExists) {
              newData.configuration.input.edges.push(edge)
            }
          })

          if (!newData.project_id) newData.project_id = projectId
          if (!newData.id) newData.id = componentId

          updateComponent(newData)
          getProjectDetails()
        } else {
          getProjectDetails()
        }
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        setNodePropertiesDrawer(DefaultNodeProperty)
      }
    },
    [
      componentDetailsData,
      selectedNode,
      showNodePropertiesDrawer,
      getProjectDetails,
      updateComponent,
      projectId,
      componentId,
      setLoading,
      setNodePropertiesDrawer,
    ],
  )

  const changeInputConfigurationOfInputTable = (
    isDocument: boolean,
    key: string,
  ) => {
    if (isDocument) {
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === key,
      )
      if (index > -1) {
        updateDrawerConfiguration[index].type =
          CONFIGURATION_INPUT_TYPES.SINGLEFOLDER
      }
      setDrawerConfiguration(updateDrawerConfiguration)
    } else {
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === key,
      )
      if (index > -1) {
        updateDrawerConfiguration[index].type =
          CONFIGURATION_INPUT_TYPES.SINGLETEXT
      }
      setDrawerConfiguration(updateDrawerConfiguration)
    }
  }

  const onClickUpdateComponent = useCallback(() => {
    const inputData = [...drawerConfiguration].map(
      (item: ConfigurationInputInterface) => {
        if (item.dropdownData) delete item.dropdownData
        return item
      },
    )
    const newNodeId = getRandomNumber()
    const payload = {
      id: selectedNode.id ? selectedNode.id : newNodeId,
      component_node_template_id: componentNodeTemplateId,
      component_node_name: nodeName,
      project_id: projectId,
      // component_node_type_name:
      //   componentNodeDetailConfig?.component_node_type_name,
      component_id: componentId ? componentId : '',
      component_node_type_id: selectedNode.component_node_type_id,
      configuration: {
        input: inputData,
      },
      status: 'live',
    }

    onUpdateComponentNodeApi(payload, newNodeId)
  }, [
    drawerConfiguration,
    selectedNode,
    nodeName,
    componentId,
    onUpdateComponentNodeApi,
    componentNodeTemplateId,
  ])

  const inputConfigurationData = (
    input: ConfigurationInputInterface,
    onChangeArg: any,
  ) => {
    const DropdownMenuWidth = DrawerWidth - 100
    const objectParams_Id = input.objectParams ? input.objectParams.id : '#'
    const objectParams_Value = input.objectParams
      ? input.objectParams.value
      : '#'
    const isRequired = input.isRequired
    const {
      onTextChange,
      onDropDownChange,
      handleMultiObjDelete,
      handleDelete,
      onTextAddedForMultipleInput,
      onClickAdd,
      multiRowChangeMethods,

      onMultiColumnTextChange,
      onMultiColumnCheckboxChange,
      onMultiColumnSingleTextChange,

      setDrawerConfiguration,
      tranformationOperationData,
    } = onChangeArg

    const onTextChangeMultirow = multiRowChangeMethods?.onTextChange
    const onTextAddedForMultipleInputMultirow =
      multiRowChangeMethods?.onTextAddedForMultipleInput

    switch (input.type) {
      case CONFIGURATION_INPUT_TYPES.TEXTINPUT: {
        if (input.allowDisplay !== false) {
          return (
            <Box key={input.key} sx={{ mt: 2 }}>
              <Typography variant="body2">
                {input.isRequired ? `${input.label} *` : `${input.label}`}
              </Typography>
              <TextField
                placeholder={input.info || ''}
                fullWidth
                required={isRequired}
                variant="outlined"
                value={input.value}
                onChange={(e) => {
                  onTextChange({ input, e })
                }}
                disabled={
                  showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
                }
              />
            </Box>
          )
        } else {
          return null
        }
      }

      case CONFIGURATION_INPUT_TYPES.TEXTAREA: {
        if (input.allowDisplay !== false) {
          return (
            <Box key={input.key} sx={{ mt: 2 }}>
              <Typography variant="body2">
                {input.isRequired ? `${input.label} *` : `${input.label}`}
              </Typography>
              <TextareaAutosize
                required={isRequired}
                aria-label="SQL"
                minRows={10}
                placeholder={input.info || 'SQL'}
                style={{ width: '100%' }}
                value={input.value}
                onChange={(e) => {
                  onTextChange({ input, e })
                }}
                disabled={
                  showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
                }
              />
            </Box>
          )
        } else {
          return null
        }
      }

      case CONFIGURATION_INPUT_TYPES.MULTITEXTINPUT: {
        if (input.allowDisplay !== false) {
          return (
            <Box key={input.key} sx={{ mt: 2 }}>
              <Typography variant="body2">
                {input.isRequired ? `${input.label} *` : `${input.label}`}
              </Typography>
              <Autocomplete
                multiple
                freeSolo
                id="tags-outlined"
                options={[]}
                value={input.value}
                onChange={(e, newval, reason) => {
                  onTextAddedForMultipleInput({ input, value: newval })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={input.info || ''}
                  />
                )}
                disabled={
                  showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
                }
              />
            </Box>
          )
        } else {
          return null
        }
      }

      case CONFIGURATION_INPUT_TYPES.MULTICOLUMN: {
        const customDropDownData = input.dropdownData
          ? [...input.dropdownData]
          : []
        if (customDropDownData.length > 0) {
          customDropDownData?.forEach((obj2: any) => {
            input.objectInput?.forEach((obj1: any) => {
              if (!Object.prototype.hasOwnProperty.call(obj2, obj1.key)) {
                obj2[obj1.key] = ''
              }
            })
          })
        }
        const findValueByKey = (key: any) => {
          const currentObj = input.objectInput?.find(
            (item: any) => item.key === key,
          )
          return currentObj
        }

        const checkAll = (arg: any) => {
          const { input } = arg
          const aliasValueKey = input.objectInput.find(
            (item: any) => item.key === 'alias' || item.key === 'label',
          )
          const configuration =
            tranformationOperationData || drawerConfiguration
          const updateDrawerConfiguration = [...configuration]
          const index = updateDrawerConfiguration.findIndex(
            (item) => item.key === input.key,
          )
          if (index > -1) {
            updateDrawerConfiguration[index].value = input.dropdownData.map(
              (obj: any) => {
                return {
                  ...obj,
                  selected: true,
                  display: true,
                  label:
                    input.value.find(
                      (v: any) =>
                        v[aliasValueKey.value] === obj[aliasValueKey.value],
                    )?.label ||
                    input.dropdownData.find(
                      (d: any) =>
                        d[aliasValueKey.value] === obj[aliasValueKey.value],
                    )[aliasValueKey.value],
                  alias:
                    input.value.find(
                      (v: any) =>
                        v[aliasValueKey.value] === obj[aliasValueKey.value],
                    )?.alias ||
                    input.dropdownData.find(
                      (d: any) =>
                        d[aliasValueKey.value] === obj[aliasValueKey.value],
                    )[aliasValueKey.value],
                }
              },
            )
          }
          setDrawerConfiguration(updateDrawerConfiguration)
        }

        const clearAll = (arg: any) => {
          const { input } = arg
          const aliasValueKey = input.objectInput.find(
            (item: any) => item.key === 'alias' || item.key === 'label',
          )
          const configuration =
            tranformationOperationData || drawerConfiguration
          const updateDrawerConfiguration = [...configuration]

          const index = updateDrawerConfiguration.findIndex(
            (item) => item.key === input.key,
          )
          if (index > -1) {
            updateDrawerConfiguration[index].value = input.dropdownData.map(
              (obj: any) => {
                return {
                  ...obj,
                  selected: false,
                  display: false,
                  label:
                    input.value.find(
                      (v: any) =>
                        v[aliasValueKey.value] === obj[aliasValueKey.value],
                    )?.label || '',
                  alias:
                    input.value.find(
                      (v: any) =>
                        v[aliasValueKey.value] === obj[aliasValueKey.value],
                    )?.alias || '',
                }
              },
            )
          }
          setDrawerConfiguration(updateDrawerConfiguration)
        }
        if (input.allowDisplay !== false) {
          return (
            <Box key={input.key} sx={{ mt: 2 }}>
              <Typography variant="body2">
                {input.isRequired ? `${input.label} *` : `${input.label}`}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  // gap: '27px',
                  // justifyContent: 'space-between',

                  justifyContent: 'center',
                  gap: '5px',
                  mb: '5px',
                  mt: '5px',
                }}
              >
                {input.objectInput?.length > 0 &&
                  input.objectInput.map((item: any) => {
                    if (item.allowDisplay) {
                      return (
                        <Box
                          key={item.key}
                          sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: 'bold' }}
                            variant="body2"
                          >
                            {item.label}
                          </Typography>
                          {item.type === 'CHECKBOX' && item.value === true && (
                            <Box
                              sx={{ pt: '5px', display: 'flex', pl: '10px' }}
                            >
                              <FormControl component="fieldset">
                                <FormControlLabel
                                  value="end"
                                  control={
                                    <Checkbox
                                      size="small"
                                      onChange={(e: any) => {
                                        e.target.checked
                                          ? input.dropdownData &&
                                            checkAll({ input })
                                          : input.dropdownData &&
                                            clearAll({ input })
                                      }}
                                      disabled={
                                        showNodePropertiesDrawer?.isEdit &&
                                        !projectRole?.edit_nodes
                                      }
                                    />
                                  }
                                  disabled={
                                    showNodePropertiesDrawer?.isEdit &&
                                    !projectRole?.edit_nodes
                                  }
                                  label="Select All"
                                  labelPlacement="end"
                                />
                              </FormControl>
                            </Box>
                          )}
                        </Box>
                      )
                    }
                  })}
              </Box>

              {input.dropdownData?.length > 0 ? (
                input.dropdownData.map((item: any, index: number) => {
                  return (
                    <Box
                      key={`${item}_${index}`}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '5px',
                        mb: '5px',
                        mt: '5px',
                      }}
                    >
                      {Object.keys(item).map((childItem: any) => {
                        const currentObjInput = findValueByKey(childItem)
                        switch (currentObjInput?.type) {
                          case 'TEXTINPUT':
                            if (currentObjInput.allowDisplay) {
                              return (
                                <Box sx={{ flex: 1 }}>
                                  <TextField
                                    // required={isRequired}
                                    placeholder={currentObjInput.label}
                                    size="small"
                                    margin="none"
                                    sx={{ width: '120px' }}
                                    variant="outlined"
                                    defaultValue={item[currentObjInput.key]}
                                    value={
                                      input.value?.[index]?.[
                                        currentObjInput.key
                                      ]
                                    }
                                    InputProps={{
                                      readOnly: !currentObjInput.isEditable,
                                    }}
                                    name={currentObjInput.key}
                                    onChange={(e) => {
                                      onMultiColumnTextChange({
                                        input,
                                        e,
                                        index,
                                        item,
                                        allData: input.dropdownData,
                                      })
                                    }}
                                    disabled={
                                      showNodePropertiesDrawer?.isEdit &&
                                      !projectRole?.edit_nodes
                                    }
                                  />
                                </Box>
                              )
                            }
                            break
                          case 'CHECKBOX':
                            if (currentObjInput.allowDisplay) {
                              return (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flex: 1,
                                  }}
                                >
                                  <Checkbox
                                    // required={isRequired}
                                    key={input.value?.[index]}
                                    checked={
                                      input.value?.[index]?.[
                                        currentObjInput.key
                                      ]
                                    }
                                    size="small"
                                    name={currentObjInput.key}
                                    onChange={(e) => {
                                      onMultiColumnCheckboxChange({
                                        input,
                                        e,
                                        index,
                                        item,
                                        allData: input.dropdownData,
                                      })
                                    }}
                                    disabled={
                                      showNodePropertiesDrawer?.isEdit &&
                                      !projectRole?.edit_nodes
                                    }
                                  />
                                </Box>
                              )
                            }
                            break
                          case CONFIGURATION_INPUT_TYPES.SINGLETEXT:
                            if (currentObjInput.allowDisplay) {
                              return (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flex: 1,
                                  }}
                                >
                                  <DropdownComponent
                                    placeholder={currentObjInput.label}
                                    value={
                                      input.value?.[index]?.[
                                        currentObjInput.key
                                      ]
                                    }
                                    sx={{ width: '120px' }}
                                    onChange={(e) => {
                                      setSingleTextSearch('')
                                      onMultiColumnSingleTextChange({
                                        input,
                                        e,
                                        index,
                                        item,
                                      })
                                    }}
                                    name={currentObjInput.key}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          minWidth: DropdownMenuWidth, // Adjust the width as per your requirement
                                        },
                                      },
                                    }}
                                    disabled={
                                      showNodePropertiesDrawer?.isEdit &&
                                      !projectRole?.edit_nodes
                                    }
                                  >
                                    {!currentObjInput.dropdownData &&
                                      currentObjInput.options &&
                                      currentObjInput.options
                                        .filter((obj: any) =>
                                          obj.label
                                            .toLowerCase()
                                            .includes(
                                              singleTextSearch.toLowerCase(),
                                            ),
                                        )
                                        .map((item: any, idx: number) => {
                                          return (
                                            <MenuItem
                                              key={`${currentObjInput.key}_${item.id ? item.id : idx}`}
                                              value={item.id}
                                            >
                                              {item.label}
                                            </MenuItem>
                                          )
                                        })}
                                  </DropdownComponent>
                                </Box>
                              )
                            }

                            break
                        }
                      })}
                    </Box>
                  )
                })
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    m: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NoRecordsFoundComponent />
                </Box>
              )}
            </Box>
          )
        } else {
          return null
        }
      }

      case CONFIGURATION_INPUT_TYPES.SINGLECOLUMN: {
        const customDropDownData = input.dropdownData
          ? [...input.dropdownData]
          : []
        if (customDropDownData.length > 0) {
          customDropDownData?.forEach((obj2: any) => {
            input.objectInput?.forEach((obj1: any) => {
              if (!Object.prototype.hasOwnProperty.call(obj2, obj1.key)) {
                obj2[obj1.key] = ''
              }
            })
          })
        }
        const findValueByKey = (key: any) => {
          const currentObj = input.objectInput?.find(
            (item: any) => item.key === key,
          )
          return currentObj
        }
        if (input.allowDisplay !== false) {
          return (
            <Box key={input.key} sx={{ mt: 2 }}>
              <Typography variant="body2">
                {input.isRequired ? `${input.label} *` : `${input.label}`}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  // gap: '27px',
                  // justifyContent: 'space-between',

                  justifyContent: 'center',
                  gap: '5px',
                  mb: '5px',
                  mt: '5px',
                }}
              >
                {input.objectInput?.length > 0 &&
                  input.objectInput.map((item: any) => {
                    if (item.allowDisplay) {
                      return (
                        <Box
                          key={item.key}
                          sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: 'bold' }}
                            variant="body2"
                          >
                            {item.label}
                          </Typography>
                        </Box>
                      )
                    }
                  })}
              </Box>

              {input.dropdownData?.length > 0 ? (
                input.dropdownData.map((item: any, index: number) => {
                  return (
                    <Box
                      key={`${item}_${index}`}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '5px',
                        mb: '5px',
                        mt: '5px',
                      }}
                    >
                      {Object.keys(item).map((childItem: any) => {
                        const currentObjInput = findValueByKey(childItem)
                        switch (currentObjInput?.type) {
                          case 'TEXTINPUT':
                            if (currentObjInput.allowDisplay) {
                              return (
                                <Box sx={{ flex: 1 }}>
                                  <TextField
                                    // required={isRequired}
                                    placeholder={currentObjInput.label}
                                    size="small"
                                    margin="none"
                                    sx={{ width: '120px' }}
                                    variant="outlined"
                                    defaultValue={item[currentObjInput.key]}
                                    value={
                                      input.value?.[index]?.[
                                        currentObjInput.key
                                      ]
                                    }
                                    InputProps={{
                                      readOnly: !currentObjInput.isEditable,
                                    }}
                                    name={currentObjInput.key}
                                    onChange={(e) => {
                                      onSingleColumnTextChange({
                                        input,
                                        e,
                                        index,
                                        item,
                                      })
                                    }}
                                    disabled={
                                      showNodePropertiesDrawer?.isEdit &&
                                      !projectRole?.edit_nodes
                                    }
                                  />
                                </Box>
                              )
                            }
                            break
                          case 'CHECKBOX':
                            if (currentObjInput.allowDisplay) {
                              return (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flex: 1,
                                  }}
                                >
                                  <Checkbox
                                    // required={isRequired}
                                    key={input.value?.[index]}
                                    checked={
                                      input.value?.[index]?.[
                                        currentObjInput.key
                                      ]
                                    }
                                    size="small"
                                    name={currentObjInput.key}
                                    onChange={(e) => {
                                      onSingleColumnCheckboxChange({
                                        input,
                                        e,
                                        index,
                                        item,
                                        allData: input.dropdownData,
                                      })
                                    }}
                                    disabled={
                                      showNodePropertiesDrawer?.isEdit &&
                                      !projectRole?.edit_nodes
                                    }
                                  />
                                </Box>
                              )
                            }
                            break
                        }
                      })}
                    </Box>
                  )
                })
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    m: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NoRecordsFoundComponent />
                </Box>
              )}
            </Box>
          )
        } else {
          return null
        }
      }

      case CONFIGURATION_INPUT_TYPES.SINGLETEXT: {
        if (input.allowDisplay !== false) {
          return (
            <Box key={input.key} sx={{ mt: 2 }}>
              <Typography variant="body2">
                {input.isRequired ? `${input.label} *` : `${input.label}`}
              </Typography>
              <DropdownComponent
                placeholder={input.info || ''}
                value={input.value}
                onChange={(e) => {
                  setSingleTextSearch('')
                  onDropDownChange({ input, e })
                }}
                required={isRequired}
                MenuProps={{
                  PaperProps: {
                    style: {
                      minWidth: DropdownMenuWidth, // Adjust the width as per your requirement
                    },
                  },
                }}
                disabled={
                  showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
                }
              >
                {(!input.dropdownData || input.dropdownData.length === 0) &&
                  !input.options && (
                    <MenuItem disabled>No record found</MenuItem>
                  )}
                {input.dropdownData && input.dropdownData.length > 0 && (
                  <ListSubheader>
                    <TextField
                      size="small"
                      placeholder="Search..."
                      inputRef={singleTextSearchInputRef}
                      fullWidth
                      onChange={handleSearchChange}
                      value={singleTextSearch}
                      onKeyDown={(event) => event.stopPropagation()}
                      autoFocus
                    />
                  </ListSubheader>
                )}

                {input.dropdownData &&
                  input.dropdownData
                    .filter((obj: any) =>
                      objectParams_Id === '#'
                        ? obj
                            ?.toLowerCase()
                            ?.includes(singleTextSearch?.toLowerCase())
                        : obj[objectParams_Value]
                            ?.toLowerCase()
                            ?.includes(singleTextSearch?.toLowerCase()),
                    )
                    .map((item: any, idx: number) => {
                      return (
                        <MenuItem
                          key={`${input.key}_${item.id ? item.id : idx}`}
                          value={
                            objectParams_Id === '#'
                              ? item
                              : item[objectParams_Id]
                          }
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <ListItemText
                              primary={
                                objectParams_Id === '#'
                                  ? item
                                  : item[objectParams_Value]
                              }
                              style={{ flexGrow: 1 }} // Ensure it takes the remaining space
                            />
                            {input?.showTooltip && (
                              <HtmlTooltip
                                title={
                                  <GetTooltipData
                                    functionName={
                                      objectParams_Id === '#'
                                        ? item
                                        : item[objectParams_Value]
                                    }
                                  />
                                }
                                placement="right-start"
                                enterDelay={500}
                                leaveDelay={500}
                              >
                                <IconButton size="small">
                                  <Info color="disabled" />
                                </IconButton>
                              </HtmlTooltip>
                            )}
                          </Box>
                        </MenuItem>
                      )
                    })}
                {!input.dropdownData &&
                  input.options &&
                  input.options
                    .filter((obj: any) =>
                      obj.label
                        .toLowerCase()
                        .includes(singleTextSearch.toLowerCase()),
                    )
                    .map((item: any, idx: number) => {
                      return (
                        <MenuItem
                          key={`${input.key}_${item.id ? item.id : idx}`}
                          value={item.id}
                        >
                          {item.label}
                        </MenuItem>
                      )
                    })}
              </DropdownComponent>
            </Box>
          )
        } else {
          return null
        }
      }

      case CONFIGURATION_INPUT_TYPES.MULTITEXT: {
        if (input.allowDisplay !== false) {
          return (
            <Box key={input.key} sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body2">
                  {input.isRequired ? `${input.label} *` : `${input.label}`}
                </Typography>
              </Box>
              <DropdownComponent
                multiple
                placeholder={input.info || ''}
                value={input.value ? input.value : []}
                onChange={(e) => onDropDownChange({ input, e })}
                required={isRequired}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    sx={{ height: 'auto !important' }}
                  />
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      minWidth: DropdownMenuWidth, // Adjust the width as per your requirement
                    },
                  },
                }}
                disabled={
                  !input.isEditable ||
                  (showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes)
                }
                renderValue={(selected) => {
                  return (
                    <div className={classes.chips}>
                      {(selected as string[]).map((value) => {
                        let selectedVal = value
                        if (objectParams_Id !== '#') {
                          const selectedObj =
                            input.dropdownData &&
                            input.dropdownData.find((item: any) => {
                              return item[objectParams_Id] == value
                            })

                          if (selectedObj) {
                            selectedVal = selectedObj[objectParams_Value]
                          }
                        }
                        return (
                          <Chip
                            key={`chip_${input.key}${value}`}
                            label={selectedVal}
                            clickable
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                            className={classes.chip}
                            onDelete={(e) => handleDelete({ input, e, value })}
                          />
                        )
                      })}
                    </div>
                  )
                }}
              >
                {(!input.dropdownData || input.dropdownData.length === 0) && (
                  <MenuItem disabled>No record found</MenuItem>
                )}
                {input.dropdownData && input.dropdownData.length > 0 && (
                  <ListSubheader>
                    <TextField
                      size="small"
                      placeholder="Search..."
                      inputRef={multiTextSearchInputRef}
                      fullWidth
                      onChange={handleMultiTextSearchChange}
                      value={multiTextSearch}
                      onKeyDown={(event) => event.stopPropagation()}
                      autoFocus
                    />
                  </ListSubheader>
                )}
                {input.dropdownData && input.dropdownData.length > 1 && (
                  <Box sx={{ display: 'flex', gap: '10px', pl: '27px' }}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            onChange={(e: any) => {
                              e.target.checked
                                ? input.dropdownData &&
                                  onDropDownChange({
                                    input,
                                    e: {
                                      target: {
                                        value:
                                          objectParams_Id === '#'
                                            ? [...input.dropdownData]
                                            : input.dropdownData.map(
                                                (item: any) =>
                                                  item[objectParams_Id],
                                              ),
                                      },
                                    },
                                  })
                                : input.dropdownData &&
                                  onDropDownChange({
                                    input,
                                    e: { target: { value: [] } },
                                  })
                            }}
                          />
                        }
                        label="Select All"
                        labelPlacement="end"
                      />
                    </FormControl>
                  </Box>
                )}
                {input.dropdownData &&
                  input.dropdownData
                    .filter((obj: any) =>
                      objectParams_Id === '#'
                        ? obj
                            .toLowerCase()
                            .includes(multiTextSearch.toLowerCase())
                        : obj[objectParams_Value]
                            .toLowerCase()
                            .includes(multiTextSearch.toLowerCase()),
                    )
                    .map((item: any, idx: number) => {
                      return (
                        <MenuItem
                          key={`${input.key}_${item.id ? item.id : idx}`}
                          value={
                            objectParams_Id === '#'
                              ? item
                              : item[objectParams_Id]
                          }
                        >
                          <Checkbox
                            checked={
                              objectParams_Id === '#'
                                ? input.value.indexOf(item) > -1
                                : input.value.indexOf(item[objectParams_Id]) >
                                  -1
                            }
                          />
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <ListItemText
                              primary={
                                objectParams_Id === '#'
                                  ? item
                                  : item[objectParams_Value]
                              }
                              style={{ flexGrow: 1 }} // Ensure it takes the remaining space
                            />
                            {input?.showTooltip && (
                              <HtmlTooltip
                                title={
                                  <GetTooltipData
                                    functionName={
                                      objectParams_Id === '#'
                                        ? item
                                        : item[objectParams_Value]
                                    }
                                  />
                                }
                                placement="right-start"
                                enterDelay={500}
                                leaveDelay={500}
                              >
                                <IconButton size="small">
                                  <Info color="disabled" />
                                </IconButton>
                              </HtmlTooltip>
                            )}
                          </Box>
                        </MenuItem>
                      )
                    })}
              </DropdownComponent>
            </Box>
          )
        } else {
          return null
        }
      }

      // case CONFIGURATION_INPUT_TYPES.MULTIOBJECT: {
      //   return (
      //     <Box key={`${input.key}_${Math.random()}`} sx={{ mt: 2 }}>
      //       <Box
      //         sx={{
      //           display: 'flex',
      //           justifyContent: 'space-between',
      //           alignItems: 'center',
      //         }}
      //       >
      //         <Typography variant="body2">
      //           {input.isRequired ? `${input.label} *` : `${input.label}`}
      //         </Typography>
      //       </Box>
      //       <ButtonBase
      //         sx={{
      //           display: 'flex',
      //           minHeight: '50px',
      //           width: '100%',
      //           border: '1px solid #C4C4C4',
      //           borderRadius: '5px',
      //           justifyContent: 'flex-start',
      //           alignItems: 'center',
      //           padding: '5px',
      //           flexWrap: 'wrap',
      //         }}
      //         onClick={handleMultiObjClick}
      //       >
      //         {input.value.map((value: any) => (
      //           <Chip
      //             key={value}
      //             label={`${value?.column_name} - ${value?.data_type}`}
      //             clickable
      //             deleteIcon={
      //               <CancelIcon
      //                 onMouseDown={(event) => event.stopPropagation()}
      //               />
      //             }
      //             className={classes.chip}
      //             onDelete={(e) => handleMultiObjDelete({ input, e, value })}
      //           />
      //         ))}
      //       </ButtonBase>
      //       <Popover
      //         id={id}
      //         open={open}
      //         anchorEl={multiObjAnchorEl}
      //         onClose={handleMultiObjClose}
      //         anchorOrigin={{
      //           vertical: 'bottom',
      //           horizontal: 'left',
      //         }}
      //       >
      //         <Box
      //           sx={{
      //             backgroundColor: '#fff',
      //             display: 'flex',
      //             flexDirection: 'column',
      //             width: '361px',
      //             height: '400px',
      //             overflowY: 'scroll',
      //             justifyContent: 'flex-start',
      //           }}
      //         >
      //           {(!input.dropdownData || input.dropdownData.length === 0) && (
      //             <MenuItem disabled>No record found</MenuItem>
      //           )}

      //           {input.dropdownData && input.dropdownData.length > 0 && (
      //             <Box sx={{ padding: '10px' }}>
      //               <TextField
      //                 size="small"
      //                 placeholder="Search"
      //                 value={multiObjSearch}
      //                 onChange={(e) => setMultiObjSearch(e.target.value)}
      //               />
      //             </Box>
      //           )}

      //           {input.dropdownData && input.dropdownData.length > 0 && (
      //             <Box sx={{ display: 'flex', gap: '10px', pl: '27px' }}>
      //               <FormControl component="fieldset">
      //                 <FormControlLabel
      //                   value="end"
      //                   control={
      //                     <Checkbox
      //                       onChange={(e: any) => {
      //                         e.target.checked
      //                           ? input.dropdownData &&
      //                             onDropDownChange({
      //                               input,
      //                               e: {
      //                                 target: {
      //                                   value: [...input.dropdownData],
      //                                 },
      //                               },
      //                             })
      //                           : input.dropdownData &&
      //                             onDropDownChange({
      //                               input,
      //                               e: { target: { value: [] } },
      //                             })
      //                       }}
      //                     />
      //                   }
      //                   label="Select All"
      //                   labelPlacement="end"
      //                 />
      //               </FormControl>
      //             </Box>
      //           )}
      //           {input.dropdownData &&
      //             input.dropdownData
      //               .filter(
      //                 (obj: any) =>
      //                   obj.column_name.includes(multiObjSearch) ||
      //                   obj.data_type.includes(multiObjSearch),
      //               )
      //               .map((item: any) => {
      //                 return (
      //                   <MenuItem key={Math.random()} value={item}>
      //                     {/* {`${item[objectParams_Id]} - ${item[objectParams_Value]}`} */}
      //                     <Checkbox
      //                       checked={input.value.some((value: any) =>
      //                         _.isEqual(value, item),
      //                       )}
      //                       onChange={(e) => {
      //                         if (
      //                           input.value.some((value: any) =>
      //                             _.isEqual(value, item),
      //                           )
      //                         ) {
      //                           handleMultiObjDelete({ input, e, value: item })
      //                         } else {
      //                           onDropDownChange({
      //                             input,
      //                             e: {
      //                               target: {
      //                                 value: [...input.value, item],
      //                               },
      //                             },
      //                           })
      //                         }
      //                       }}
      //                     />
      //                     <ListItemText
      //                       primary={`${item[objectParams_Id]} - ${item[objectParams_Value]}`}
      //                     />
      //                   </MenuItem>
      //                 )
      //               })}
      //         </Box>
      //       </Popover>
      //     </Box>
      //   )
      // }

      case CONFIGURATION_INPUT_TYPES.MULTIOBJECT: {
        if (input.allowDisplay !== false) {
          return (
            <Box key={input.key} sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body2">
                  {input.isRequired ? `${input.label} *` : `${input.label}`}
                </Typography>
              </Box>
              <DropdownComponent
                multiple
                placeholder={input.info || ''}
                value={input.value}
                onChange={(e) => {
                  onDropDownChange({ input, e })
                }}
                required={isRequired}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    sx={{ height: 'auto !important' }}
                  />
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      minWidth: DropdownMenuWidth, // Adjust the width as per your requirement
                    },
                  },
                }}
                disabled={
                  !input.isEditable ||
                  (showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes)
                }
                renderValue={(selected: any) => {
                  return (
                    <div className={classes.chips}>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value: any) => (
                          <Chip
                            key={value}
                            label={`${value?.column_name} - ${value?.data_type}`}
                            clickable
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                            className={classes.chip}
                            onDelete={(e) =>
                              handleMultiObjDelete({ input, e, value })
                            }
                          />
                        ))}
                      </Box>
                    </div>
                  )
                }}
              >
                {(!input.dropdownData || input.dropdownData.length === 0) && (
                  <MenuItem disabled>No record found</MenuItem>
                )}

                {input.dropdownData && input.dropdownData.length > 0 && (
                  <ListSubheader>
                    <TextField
                      size="small"
                      placeholder="Search..."
                      inputRef={MultiObjTextSearchInputRef}
                      fullWidth
                      onChange={handleMultiSearchChange}
                      value={MultiObjTextSearch}
                      onKeyDown={(event) => event.stopPropagation()}
                      autoFocus
                    />
                  </ListSubheader>
                )}

                {/* {input.dropdownData && input.dropdownData.length > 0 && (
                  <Box sx={{ display: 'flex', gap: '10px', pl: '27px' }}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        value="end"
                        control={
                          <Checkbox
                            onChange={(e: any) => {
                              e.target.checked
                                ? input.dropdownData &&
                                  onDropDownChange({
                                    input,
                                    e: {
                                      target: { value: [...input.dropdownData] },
                                    },
                                  })
                                : input.dropdownData &&
                                  onDropDownChange({
                                    input,
                                    e: { target: { value: [...input.dropdownData] } },
                                  })
                            }}
                          />
                        }
                        label="Select All"
                        labelPlacement="end"
                      />
                    </FormControl>
                  </Box>
                )}
                {input.dropdownData &&
                  input.dropdownData.map((item: any) => {
                    return (
                      <MenuItem key={item} value={item}>
                        
                        <Checkbox
                          checked={input.value.some((value: any) =>
                            _.isEqual(value, item),
                          )}
                        />
                        <ListItemText
                          primary={`${item[objectParams_Id]} - ${item[objectParams_Value]}`}
                        />
                      </MenuItem>
                    )
                  })} */}
                {input.dropdownData && input.dropdownData.length > 0 && (
                  <>
                    {/* Select All Checkbox */}
                    <Box sx={{ display: 'flex', gap: '10px', pl: '27px' }}>
                      <FormControl component="fieldset">
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              checked={
                                input.value.length === input.dropdownData.length
                              } // Checks if all items are selected
                              indeterminate={
                                input.value.length > 0 &&
                                input.value.length < input.dropdownData.length // Shows indeterminate state if not all selected
                              }
                              onChange={(e: any) => {
                                e.target.checked
                                  ? onDropDownChange({
                                      input,
                                      e: {
                                        target: {
                                          value: [...input.dropdownData],
                                        },
                                      },
                                    })
                                  : onDropDownChange({
                                      input,
                                      e: { target: { value: [] } },
                                    })
                              }}
                            />
                          }
                          label="Select All"
                          labelPlacement="end"
                        />
                      </FormControl>
                    </Box>

                    {/* Individual Items with Checkboxes */}
                    {input.dropdownData
                      .filter(
                        (obj: any) =>
                          obj.column_name.includes(MultiObjTextSearch) ||
                          obj.data_type.includes(MultiObjTextSearch),
                      )
                      .map((item: any) => {
                        const isSelected = input.value.some((value: any) =>
                          _.isEqual(value, item),
                        )
                        return (
                          <MenuItem key={item} value={item}>
                            <Checkbox
                              checked={isSelected}
                              onChange={(e) => {
                                const isChecked = e.target.checked
                                const newValue = isChecked
                                  ? [...input.value, item]
                                  : input.value.filter(
                                      (val: any) => !_.isEqual(val, item),
                                    )

                                onDropDownChange({
                                  input,
                                  e: { target: { value: newValue } },
                                })
                              }}
                            />
                            <ListItemText
                              primary={`${item[objectParams_Id]} - ${item[objectParams_Value]}`}
                            />
                          </MenuItem>
                        )
                      })}
                  </>
                )}
              </DropdownComponent>
            </Box>
          )
        } else {
          return null
        }
      }

      case CONFIGURATION_INPUT_TYPES.SINGLENODE:
      case CONFIGURATION_INPUT_TYPES.MULTINODE: {
        const valueLength = input.value.length
        let buttonDisabled = false
        if (input.type === CONFIGURATION_INPUT_TYPES.SINGLENODE) {
          if (input.value.length > 0) buttonDisabled = true
        }
        if (input.allowDisplay !== false) {
          return (
            <Box key={input.key} sx={{ mt: 2 }}>
              <Accordion expanded={input.value.length > 0 ? true : false}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                >
                  {input.isRequired ? `${input.label} *` : `${input.label}`}
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    {input.value.map(
                      (item: Record<string, string>, idx: number) => {
                        return (
                          <div
                            key={`${input.key}_${CONFIGURATION_INPUT_TYPES.MULTINODE}_${item[objectParams_Id]}`}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography gutterBottom>
                                {item[objectParams_Value]}
                              </Typography>
                              <Box sx={{ marginLeft: 'auto' }}>
                                <IconButton
                                  onClick={() =>
                                    onEditMultiNode({
                                      editItem: item,
                                      objectParams_Value,
                                      objectType: input.objectType,
                                    })
                                  }
                                >
                                  {!projectRole?.edit_nodes ? (
                                    <RemoveRedEye />
                                  ) : (
                                    <EditIcon />
                                  )}
                                </IconButton>
                                <IconButton
                                  disabled={!projectRole?.edit_nodes}
                                  onClick={() =>
                                    onDeleteMultiNode({
                                      editItem: item,
                                      objectParams_Value,
                                      key: input.key,
                                    })
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </Box>
                            {valueLength - 1 !== idx && <Divider />}
                          </div>
                        )
                      },
                    )}
                  </>
                </AccordionDetails>
              </Accordion>
              <Box sx={{ mt: 2, mb: 3, textAlign: 'end' }}>
                <Button
                  variant="text"
                  startIcon={<AddIcon />}
                  onClick={() =>
                    handleTransformationPopup({
                      ...DefaultTranformerPopup,
                      open: true,
                      objectType: input.objectType,
                    })
                  }
                  disabled={
                    buttonDisabled ||
                    (showNodePropertiesDrawer?.isEdit &&
                      !projectRole?.edit_nodes)
                  }
                >
                  Add {input.label}
                </Button>
                {isInputNodeError && (
                  <Alert variant="filled" severity="error">
                    Please select input node
                  </Alert>
                )}
              </Box>
            </Box>
          )
        } else {
          return null
        }
      }

      case CONFIGURATION_INPUT_TYPES.MULTIROW: {
        let inputValue = input.value
        inputValue = inputValue.map((objItem: any, index: number) => {
          return objItem.map((nestedItem: any, nestedIndex: number) => {
            return {
              ...nestedItem,
              index,
              nestedIndex,
              parentKey: input.key,
            }
          })
        })
        if (input.allowDisplay !== false) {
          return (
            <Box key={input.key} sx={{ mt: 2 }}>
              <Box sx={{ textAlign: 'end' }}>
                <IconButton
                  disabled={
                    showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
                  }
                  onClick={() => onClickAdd({ input })}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Box>
              {inputValue.map((item: any) => {
                return item.map((nestedItem: any) => {
                  return inputConfigurationData(nestedItem, {
                    onTextChange: onTextChangeMultirow,
                    onTextAddedForMultipleInput:
                      onTextAddedForMultipleInputMultirow,
                  })
                })
              })}
            </Box>
          )
        } else {
          return null
        }
      }

      case CONFIGURATION_INPUT_TYPES.SINGLEFOLDER: {
        const getSelectedData = () => {
          if (input.value && input?.dropdownData?.length > 0) {
            const selectedItem =
              objectParams_Id === '#'
                ? input?.dropdownData?.find((data: any) => data === input.value)
                : input?.dropdownData?.find(
                    (data: any) => data[objectParams_Id] === input.value,
                  )?.[objectParams_Value]
            return <Typography>{selectedItem}</Typography>
          }
        }
        if (input.allowDisplay !== false) {
          return (
            <Box key={input.key} sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body2">
                  {input.isRequired ? `${input.label} *` : `${input.label}`}
                </Typography>
              </Box>
              <ButtonBase
                sx={{
                  display: 'flex',
                  minHeight: '50px',
                  width: '100%',
                  border: '1px solid #C4C4C4',
                  borderRadius: '5px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  padding: '5px',
                  flexWrap: 'wrap',
                }}
                onClick={() => handleSingleFolderClick(input)}
              >
                {getSelectedData()}
              </ButtonBase>
            </Box>
          )
        } else {
          return null
        }
      }

      default: {
        if (input.allowDisplay !== false) {
          return (
            <Box key={input.key} sx={{ mt: 2 }}>
              <Typography variant="body2">
                {input.isRequired
                  ? `${input.label} (only ${input.type}) *`
                  : `${input.label} (only ${input.type})`}
              </Typography>
              <TextField
                placeholder={input.info || ''}
                fullWidth
                required={isRequired}
                variant="outlined"
                value={input.value}
                onChange={(e) => {
                  const inputValue = e?.target?.value
                  if (input?.type && input.type.toLowerCase() === 'integer') {
                    if (
                      inputValue === '' ||
                      REGEX_EXPRESSION.INTEGER.test(inputValue)
                    ) {
                      onTextChange({ input, e })
                    }
                  } else if (
                    input?.type &&
                    input.type.toLowerCase() === 'float'
                  ) {
                    if (
                      inputValue === '' ||
                      REGEX_EXPRESSION.FLOAT.test(inputValue)
                    ) {
                      onTextChange({ input, e })
                    }
                  } else {
                    onTextChange({ input, e })
                  }
                }}
                disabled={
                  showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
                }
              />
            </Box>
          )
        } else {
          return null
        }
      }
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      setNodeName,
      getComponentNodeDetailsConfiguration,
      getComponentNodeTemplateConfiguration,
      setDrawerConfiguration,
    }),
    [
      getComponentNodeDetailsConfiguration,
      getComponentNodeTemplateConfiguration,
    ],
  )

  const updateTransformationApply = useCallback(
    (value: Record<string, string>, isEdit = false) => {
      setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
        const newData = [...prev]
        const repository = newData.find(
          (item: ConfigurationInputInterface) =>
            item.key ===
            NODE_TYPES.transformation.keys.transformations_to_apply,
        )
        if (repository) {
          if (!isEdit) {
            repository.value.push(value)
          } else {
            const findNode = repository.value.find(
              (item: Record<string, string>) => item.id === value.id,
            )
            if (findNode) {
              findNode.component_node_name = value.component_node_name
            }
          }
        }
        return [...newData]
      })
    },
    [NODE_TYPES],
  )

  const getDrawerHeading = useCallback(() => {
    if (showNodePropertiesDrawer.isEdit) {
      if (componentNodeDetailConfig?.component_node_name) {
        return `Edit ${componentNodeDetailConfig?.component_node_name}`
      } else {
        return `Edit Data Source Node`
      }
    } else {
      if (componentNodeDetailConfig?.component_node_type_name) {
        return `Add ${
          componentNodeDetailConfig?.component_node_type_name + ' Node'
        }`
      } else {
        return `Add Data Source Node`
      }
    }
  }, [showNodePropertiesDrawer, componentNodeDetailConfig])

  return (
    <>
      <DrawerComponent
        anchor={'right'}
        open={showNodePropertiesDrawer.show}
        width={`${DrawerWidth}px`}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.stopPropagation()
            event.preventDefault()
            onClickUpdateComponent()
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => {
              setNodePropertiesDrawer(DefaultNodeProperty)
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            {getDrawerHeading()}
          </Typography>
        </Box>
        <Container sx={{ mt: 5, p: '0 !important' }}>
          <Box>
            <Typography variant="body2">Node Name</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={nodeName}
              disabled={
                showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
              }
              onChange={(e) => {
                setNodeName(e.target.value)
              }}
            />
          </Box>
          {drawerConfiguration.map((item: ConfigurationInputInterface) => {
            return inputConfigurationData(item, {
              onTextChange,
              onDropDownChange,
              handleMultiObjDelete,
              handleDelete,

              onMultiColumnTextChange,
              onMultiColumnCheckboxChange,
              onMultiColumnSingleTextChange,
              setDrawerConfiguration,
            })
          })}
          <Box sx={{ mt: 2, mb: 3, textAlign: 'end' }}>
            <Button
              variant="contained"
              onClick={() => {
                setNodePropertiesDrawer(DefaultNodeProperty)
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={
                showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
              }
              variant="contained"
              type="submit"
              // onClick={onClickUpdateComponent}
              sx={{ marginLeft: 3 }}
            >
              Save
            </Button>
          </Box>
        </Container>

        {openTransformerPopup.open && (
          <DataDialog
            openTransformerPopup={openTransformerPopup}
            handleTransformationDialogClose={handleTransformationDialogClose}
            inputConfigurationData={inputConfigurationData}
            tranformationOperationConfiguration={
              tranformationOperationConfiguration
            }
            componentId={componentId}
            projectId={projectId}
            originNodeDetail={originNodeDetail}
            selectedNode={selectedNode}
            setLoading={setLoading}
            updateTransformationApply={updateTransformationApply}
            NODE_TYPES={NODE_TYPES}
            drawerConfiguration={drawerConfiguration}
            selectedTranformationTemplateId={selectedTranformationTemplateId}
            componentNodeTypeId={componentNodeTypeId}
            outputNodeData={outputNodeData}
            projectRole={projectRole}
            inputTable={inputTable}
          />
        )}
        {openFileExplorer.isOpen && (
          <Dialog
            open={openFileExplorer.isOpen}
            handleClose={handleFileExplorerCloseClick}
            fullwidth={true}
            maxWidth="xl"
          >
            <FolderExplorer
              onClose={handleFileExplorerCloseClick}
              metadata={openFileExplorer.data}
              type={openFileExplorer.type}
              onSelectItem={onDropDownChange}
              input={openFileExplorer.input}
            />
          </Dialog>
        )}
      </DrawerComponent>
    </>
  )
})

AllComponents.displayName = 'AllComponents'

export default AllComponents
