import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import React from 'react'

const ChangeComponentName = (props: any) => {
  const { open, setOpen, changeComponentName } = props

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            const formJson = Object.fromEntries((formData as any).entries())
            const project_name = formJson.project_name
            changeComponentName({ project_name })
            handleClose()
          },
        }}
      >
        <DialogTitle>Change Component Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="project_name"
            name="project_name"
            label="Enter Component Name"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Apply</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChangeComponentName
