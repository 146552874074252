/* eslint-disable no-unused-vars */
import { Close } from '@mui/icons-material'
import { Box, Button, MenuItem, Typography } from '@mui/material'
import { DQMetrics } from 'api/services'
import DropdownComponent from 'components/atoms/dropdown'
import React, { useCallback, useEffect, useState } from 'react'
import RowDataTableComponent from './RowDataTableComponent'
import LoaderComponent from 'components/atoms/loader-component'
import Text from 'components/atoms/Text'

interface AddDQFunProps {
  onClose: () => void
  metadata: any
}

function ShowDQRowData(props: AddDQFunProps) {
  const { onClose, metadata } = props

  const [allTables, setAllTables] = useState<any>([])
  const [selectedTable, setSelectedTable] = useState()
  const [customTableData, setCustomTableData] = useState<any>({
    data_repository_id: '',
    table_file_name: '',
    delimiter: ',',
    record_limit: 0,
  })
  const [delimiter, setDelimiter] = useState('')
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (metadata.id) {
      DQMetrics.getRepositoryMetaDataById({ id: metadata?.id })
        .then((res: any) => {
          setAllTables(res?.data?.dataset)
        })
        .catch((error) => {
          if (error.message) {
            console.log(error.message, 'error')
          } else {
            console.log('Something went wrong', 'error')
          }
        })
    }
  }, [metadata])

  const handleGetSampleData = useCallback(() => {
    if (selectedTable) {
      setCustomTableData({
        data_repository_id: metadata?.id,
        table_file_name: selectedTable,
        // delimiter: delimiter ? delimiter : ',',
        record_limit: 20,
      })
    }
  }, [selectedTable, metadata, delimiter])

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
        alignItems: 'center',
        // padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
          alignItems: 'center',
          height: '30px',
          padding: '20px',
          paddingTop: '30px',
          position: 'sticky',
          top: 0,
          zIndex: 100,
          // background: 'white',
        }}
      >
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'start' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            Sample Data
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '30px',
              height: '30px',
              background: 'red',
            }}
          >
            <Close
              sx={{
                color: 'white',
                cursor: 'pointer',
                border: '1px solid red',
              }}
              onClick={onClose}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          padding: '20px 20px 0px 20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <DropdownComponent
              sx={{ width: 361 }}
              size="small"
              label="Select Table"
              value={selectedTable}
              onChange={(e: any) => {
                setSelectedTable(e.target.value)
              }}
            >
              {allTables.length > 0 ? (
                allTables.map((item: any) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No Record Found</em>
                </MenuItem>
              )}
            </DropdownComponent>
          </Box>
          {/* <Box>
            <Text
              placeholder="Delimiter (If reading to file system)"
              value={delimiter}
              onChange={(e: any) => setDelimiter(e.target.value)}
            />
          </Box> */}
          <Button
            variant="contained"
            disabled={!selectedTable}
            onClick={() => handleGetSampleData()}
          >
            Get Sample Data
          </Button>
        </Box>
        {customTableData.table_file_name && (
          <Box sx={{ height: '60vh', overflowY: 'scroll' }}>
            <RowDataTableComponent
              customTableData={customTableData}
              setLoading={setLoading}
            />
          </Box>
        )}
      </Box>
      <LoaderComponent open={isLoading} />
    </Box>
  )
}

export default ShowDQRowData
