import React, { FC } from 'react'

import { Grid, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import DrawerComponent from 'components/atoms/drawer'
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined'
import AddToQueueIcon from '@mui/icons-material/AddToQueue'
import SettingsIcon from '@mui/icons-material/Settings'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import BookIcon from '@mui/icons-material/Book'
interface NavDrawerInterface {
  open?: boolean
}
const NavDrawerComponent: FC<NavDrawerInterface> = (props) => {
  const { open } = props
  return (
    <DrawerComponent open={open}>
      <>
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            paddingTop: '6px',
            background: grey[100],
            my: 1,
          }}
        >
          <Grid item md={2}>
            <CodeOutlinedIcon color="primary" />
          </Grid>
          <Grid item md={10}>
            <Typography color="primary" variant="body2">
              Projects
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            paddingTop: '6px',
            my: 1,
          }}
        >
          <Grid item md={2}>
            <AddToQueueIcon />
          </Grid>
          <Grid item md={10}>
            <Typography variant="body2">Add Request</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            paddingTop: '6px',
            my: 1,
          }}
        >
          <Grid item md={2}>
            <BookIcon />
          </Grid>
          <Grid item md={10}>
            <Typography variant="body2">Guides</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            paddingTop: '6px',
            my: 1,
          }}
        >
          <Grid item md={2}>
            <PersonAddIcon />
          </Grid>
          <Grid item md={10}>
            <Typography variant="body2">Invite team</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            paddingTop: '6px',
            my: 1,
          }}
        >
          <Grid item md={2}>
            <SettingsIcon />
          </Grid>
          <Grid item md={10}>
            <Typography variant="body2">Settings</Typography>
          </Grid>
        </Grid>
      </>
    </DrawerComponent>
  )
}

export default NavDrawerComponent
