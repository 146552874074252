/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { ArrowBack } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Typography,
} from '@mui/material'
import Text from 'components/atoms/Text'
import DropdownComponent from 'components/atoms/dropdown'
import DynamicForm from 'components/molecules/DynamicForm'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { AppDispatch } from 'store/store'
import { EditDataSourceProps } from 'types/props-types'
import lodash from 'lodash'
import { LLM } from 'api/services'
// import { CUSTOM_COLORS } from 'theme'
import { decryptSettings, encryptObject } from 'utility/enc-dec'
import { getProvidersList } from 'api/services/api-services'

function EditModel({
  heading,
  onClose,
  data,
  triggerReload,
  type,
  messageConfig,
  showMessage,
}: EditDataSourceProps) {
  const editedData = useMemo(() => lodash.cloneDeep(data), [data])

  const [dataSourceTypes, setDataSourceTypes] = useState([])
  const [dataSourceDesc, setDataSourceDesc] = useState('')
  const [selectedDataSource, setSelectedDataSource] = useState('')
  const [dataAlias, setDataAlias] = useState('')
  const [editConfiguration, setEditConfiguration] = useState<any>({})
  const [error, setError] = useState<any>({})
  const [providerList,setProviderList]=useState();
  // const [isTestLoading, setTestLoading] = useState(false)
  const [isSaveLoading, setSaveLoading] = useState(false)
  // const [isConnectionTested, setConnectionTested] = useState(false)

  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(LLM.getAllModelInstanceTypes({ page: 1, size: 10 }))
      .then((res: any) => {
        setDataSourceTypes(res?.payload?.data)
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
      })
      // getAllProviderList();
  }, [])
  const getAllProviderList = async () => {
    try {
      const response = await getProvidersList()
      setProviderList(response?.data || [])
     
    } catch (error: any) {
      console.error('Error fetching data platforms:', error)
    }
  }
  useEffect(() => {
    if (data) {
      setSelectedDataSource(data?.model_instance_type_id?._id)
      setDataAlias(data?.model_instance_name)
      setDataSourceDesc(data?.model_instance_description)
      setEditConfiguration(decryptSettings(data?.configuration?.input))
    }
  }, [data])

  const handleSelectDataSource = useCallback((e: any) => {
    setSelectedDataSource(e.target.value)
    setError({ ...error, config: '' })
  }, [])

  const handleDataAliasChange = useCallback((e: any) => {
    if (e.target.value !== '') {
      setDataAlias(e.target.value)
      setError({ ...error, dsName: '' })
    } else {
      setDataAlias(e.target.value)
    }
  }, [])

  const handleShowAlert = useCallback(
    (msg: string, type = 'success') => {
      showMessage({
        ...messageConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [messageConfig],
  )

  // const getDatabyUsingKey = useCallback(
  //   (key: any) => {
  //     const arr = editConfiguration
  //     const index = arr?.findIndex((item: any) => item?.key === key)
  //     if (index !== -1 && key !== 'port') {
  //       return arr[index].value
  //     } else if (index !== -1 && key === 'port') {
  //       return parseInt(arr[index].value)
  //     } else {
  //       return null
  //     }
  //   },
  //   [editConfiguration],
  // )

  const handleRequiredFieldValidation = useCallback(() => {
    let isValidationOk = true

    if (Object.keys(editConfiguration).length === 0) {
      setError({ ...error, config: 'Please Select Repository Type' })
      return false
    }

    if (dataAlias === '') {
      setError({ ...error, dsName: 'This is Mandatory Field' })
      return false
    }

    if (dataSourceDesc === '') {
      setError({ ...error, dsDesc: 'This is Mandatory Field' })
      return false
    }

    editConfiguration?.some((item: any) => {
      if (item?.value === '') {
        if (item?.isRequired === true) {
          setError({
            ...error,
            [item?.key]: 'This is Mandatory Field',
          })
          isValidationOk = false
          return true
        }
      }
    })

    return isValidationOk
  }, [
    dataAlias,
    dataSourceDesc,
    selectedDataSource,
    editConfiguration,
    error,
    isSaveLoading,
  ])

  //TODO: Implementing Later
  // const handleTestConnection = useCallback(() => {
  //   const isValidationOk = handleRequiredFieldValidation()

  //   if (!isValidationOk) {
  //     return
  //   }

  //   setTestLoading(true)
  //   const caseType = data?.data_repository_type_id?.dataRepositoryType

  //   const testObject: any = {
  //     repositoryType: caseType,
  //     databaseSettings: null,
  //     awsS3Settings: null,
  //     awsAthenaSettings: null,
  //     azureBlobStorageSettings: null,
  //   }

  //   switch (caseType) {
  //     case 'RELATIONAL_POSTGRESQL':
  //       testObject.databaseSettings = encryptObject({
  //         server: getDatabyUsingKey('server'),
  //         hostname: getDatabyUsingKey('hostname'),
  //         username: getDatabyUsingKey('username'),
  //         password: getDatabyUsingKey('password'),
  //         database: getDatabyUsingKey('database'),
  //         port: getDatabyUsingKey('port') | 0,
  //       })['data']
  //       break
  //     case 'AWS_ATHENA':
  //       testObject.awsAthenaSettings = encryptObject({
  //         regionName: getDatabyUsingKey('region_name'),
  //         awsAccessKeyId: getDatabyUsingKey('aws_access_key_id'),
  //         awsSecretAccessKey: getDatabyUsingKey('aws_secret_access_key'),
  //         s3StagingDir: getDatabyUsingKey('s3_staging_dir'),
  //         database: getDatabyUsingKey('database'),
  //       })['data']
  //       break
  //     case 'AWS_S3':
  //       testObject.awsS3Settings = encryptObject({
  //         regionName: getDatabyUsingKey('region_name'),
  //         awsAccessKeyId: getDatabyUsingKey('aws_access_key_id'),
  //         awsSecretAccessKey: getDatabyUsingKey('aws_secret_access_key'),
  //         bucketName: getDatabyUsingKey('bucket_name'),
  //         directoryAbsolutePath: getDatabyUsingKey('directory_absolute_path'),
  //       })['data']
  //       break
  //     case 'AZURE_BLOB_STORAGE':
  //       testObject.azureBlobStorageSettings = encryptObject({
  //         connectionString: getDatabyUsingKey('connection_string'),
  //         containerName: getDatabyUsingKey('container_name'),
  //       })['data']
  //       break
  //     case 'RELATIONAL_MSSQL':
  //       testObject.databaseSettings = encryptObject({
  //         server: getDatabyUsingKey('server'),
  //         hostname: getDatabyUsingKey('hostname'),
  //         username: getDatabyUsingKey('username'),
  //         password: getDatabyUsingKey('password'),
  //         database: getDatabyUsingKey('database'),
  //         port: getDatabyUsingKey('port') | 0,
  //       })['data']
  //       break
  //     case 'DGRAPH':
  //       testObject.dgraphSettings = encryptObject({
  //         dgraphEndpoint: getDatabyUsingKey('url'),
  //       })['data']
  //       break
  //   }

  //   LLM.testDataSourceConnection(testObject)
  //     .then((res: any) => {
  //       setTestLoading(false)
  //       handleShowAlert(
  //         res?.data?.message
  //           ? res.data.message
  //           : 'Data Repository Connected Successfully',
  //       )
  //       setConnectionTested(true)
  //     })
  //     .catch((error: any) => {
  //       const errorMessage = error.response
  //         ? error.response.data.message
  //         : error.message

  //       setTestLoading(false)
  //       handleShowAlert(errorMessage, 'error')
  //     })
  // }, [
  //   dataAlias,
  //   dataSourceDesc,
  //   selectedDataSource,
  //   editConfiguration,
  //   error,
  //   isSaveLoading,
  //   isTestLoading,
  //   data,
  //   editConfiguration,
  //   isTestLoading,
  // ])

  const handleSaveData = useCallback(() => {
    const isValidationOk = handleRequiredFieldValidation()

    if (!isValidationOk) {
      return
    }

    setSaveLoading(true)
    if (type === 'edit') {
      editedData.model_instance_name = dataAlias
      editedData.model_instance_type_id = data.model_instance_type_id._id
      editedData.model_instance_description = dataSourceDesc
      editedData.configuration.input = encryptObject(editConfiguration)['data']

      delete editedData.lastUpdatedByUser
      delete editedData.lastUpdatedDate
      delete editedData.status

      LLM.addEditModelInstances(editedData)
        .then((res: any) => {
          setSaveLoading(false)
          if (res?.status === 200 || res?.length > 0) {
            triggerReload?.()
            handleShowAlert(
              res?.data?.message
                ? res.data.message
                : 'Model Instance Updated Successfully',
            )
            onClose?.()
          } else {
            triggerReload?.()
            handleShowAlert(
              res?.error?.message
                ? res?.error?.message
                : 'Something Went Wrong',
              'error',
            )
            onClose?.()
          }
        })
        .catch((error: any) => {
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        })
    } else {
      editedData.id = null
      editedData.model_instance_name = dataAlias
      editedData.model_instance_type_id = data.model_instance_type_id._id
      editedData.model_instance_description = dataSourceDesc
      editedData.configuration.input = encryptObject(editConfiguration)['data']

      delete editedData.lastUpdatedByUser
      delete editedData.lastUpdatedDate
      delete editedData.status

      LLM.addEditModelInstances(editedData)
        .then((res: any) => {
          setSaveLoading(false)
          if (res?.status === 200 || res?.length > 0) {
            triggerReload?.()
            handleShowAlert(
              res?.data?.message
                ? res.data.message
                : 'Model Instance cloned Successfully',
            )
            onClose?.()
          } else {
            triggerReload?.()
            handleShowAlert(
              res?.error?.message
                ? res?.error?.message
                : 'Something Went Wrong',
              'error',
            )
            onClose?.()
          }
        })
        .catch((error: any) => {
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        })
    }
  }, [dataAlias, data, editConfiguration, dataSourceDesc, isSaveLoading])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '424px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '28px 0px 28px 0px',
          gap: '16px',
        }}
      >
        <ArrowBack sx={{ cursor: 'pointer' }} onClick={onClose} />
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          {heading}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '32px',
          overflowY: 'scroll',
          paddingTop: '20px',
        }}
      >
        {/* <SelectComponent /> */}
        <DropdownComponent
          sx={{ width: 361 }}
          size="small"
          label="Model Instance Type"
          value={selectedDataSource}
          onChange={(e) => handleSelectDataSource(e)}
          disabled={type === 'edit' || type === 'view'}
        >
          {dataSourceTypes?.length > 0 ? (
            dataSourceTypes.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.model_instance_type}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">
              <em>No Record Found</em>
            </MenuItem>
          )}
        </DropdownComponent>
        <Text
          label="Short Alias for the Data Repository *"
          value={dataAlias}
          onChange={handleDataAliasChange}
          disabled={type === 'view'}
          name="dsName"
          error={error}
        />

        <Text
          label="Model Instance Description *"
          value={dataSourceDesc}
          onChange={(e: any) => {
            setDataSourceDesc(e.target.value)
            setError({ ...error, dsDesc: '' })
          }}
          disabled={type === 'view'}
          name="dsDesc"
          error={error}
        />
        {editConfiguration?.length > 0 && (
          <Box>
            <DynamicForm
              formData={editConfiguration}
              handleChange={setEditConfiguration}
              type={type}
              error={error}
              setError={setError}
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            marginRight: '40px',
            justifyContent: 'flex-end',
            paddingBottom: '15%',
          }}
        >
          {/*TODO: Implementing Later */}
          {/* <Button
            sx={{
              color: '#42526E',
              // background: CUSTOM_COLORS.tableRowBgLightGray,
            }}
            variant="text"
            onClick={handleTestConnection}
            disabled={isTestLoading}
          >
            {isTestLoading ? <CircularProgress size={24} /> : 'Test Connection'}
          </Button> */}
          {type !== 'view' && (
            <Button
              sx={{
                color: '#42526E',
                // background: CUSTOM_COLORS.tableRowBgLightGray,
              }}
              variant="text"
              onClick={handleSaveData}
              disabled={isSaveLoading}
            >
              {isSaveLoading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default EditModel
