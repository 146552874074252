/* eslint-disable no-unused-vars */
import LineChartComponent from 'components/components/LineChartComponent'
import React from 'react'

const data: Record<string, number | string>[] = [
  { x: 0, y: 5 },
  { x: 1, y: 9 },
  { x: 2, y: 7 },
  { x: 3, y: 5 },
  { x: 4, y: 3 },
  { x: 5, y: 6 },
  { x: 6, y: 8 },
]
const GraphDashboard = () => {
  return (
    <>
      <LineChartComponent
        data={data}
        width={600}
        height={300}
        margin={{ top: 20, right: 20, bottom: 30, left: 50 }}
        id={'testlineChart'}
      />
    </>
  )
}
export default GraphDashboard
