import { Box, Divider, IconButton } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
// import DoneIcon from '@mui/icons-material/Done'

// Initialize zoom extension
const zoomOptions = {
  zoomFactor: 0.1, // Adjust as needed
  minZoom: 0.1,
  maxZoom: 3,
}
const GraphControl = (props: any) => {
  const { addNewSourceNode, cy, projectRole } = props

  useEffect(() => {
    if (cy) cy.zoom(zoomOptions)
  }, [cy])

  const zoomIn = useCallback(() => {
    if (cy) {
      cy.zoom({
        level: cy.zoom() + zoomOptions.zoomFactor,
      })
    }
  }, [cy])

  const zoomOut = useCallback(() => {
    if (cy) {
      cy.zoom({
        level: cy.zoom() - zoomOptions.zoomFactor,
      })
    }
  }, [cy])

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          bottom: 100,
          left: 40,
          zIndex: '1000',
        }}
      >
        <Box
          sx={{
            border: '1px solid #d3d3d3',
            display: 'flex',
            flexDirection: 'column',
            // background: '#fff',
          }}
        >
          <IconButton onClick={zoomIn}>
            <ZoomInIcon />
          </IconButton>
          <Divider />
          <IconButton onClick={zoomOut}>
            <ZoomOutIcon />
          </IconButton>
          <Divider />
          <IconButton
            disabled={!projectRole?.edit_nodes}
            onClick={addNewSourceNode}
          >
            <AddIcon />
          </IconButton>
          {/* <Divider />
          <IconButton>
            <DoneIcon />
          </IconButton> */}
        </Box>
      </Box>
    </>
  )
}

export default GraphControl
