import React, { useCallback } from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { FormHelperText } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const getStyles = (name: string, personName: string[], theme: Theme) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

interface MultiDropdownProps {
  label?: string
  data: Array<any>
  onChange: any
  error?: boolean
  setError?: any
  name?: string | any
  max_length?: number | any
  min_length?: number | any
  width?: number | any
  helperText?: string
  value?: Array<any>
}

const MultiDropdown = (props: MultiDropdownProps) => {
  const {
    label = '',
    data,
    onChange,
    error,
    setError,
    name,
    max_length,
    width = 361,
    helperText,
    value = [],
  } = props
  const theme = useTheme()
  const [personName, setPersonName] = React.useState<string[]>(value)

  const handleChange = useCallback(
    (event: SelectChangeEvent<typeof personName>) => {
      const {
        target: { value },
      } = event
      if (value.length <= max_length) {
        setPersonName(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        )
        onChange(typeof value === 'string' ? value.split(',') : value)
        setError?.((prevData: any) => ({ ...prevData, [name]: '' }))
      }
    },
    [personName, error, name, setError, max_length, value],
  )

  return (
    <div>
      <FormControl fullWidth size="small" sx={{ width: width }}>
        <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={value}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          MenuProps={MenuProps}
          error={error}
        >
          {data.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        {max_length && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  )
}

export default MultiDropdown
