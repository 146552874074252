/* eslint-disable prettier/prettier */
import React, { useCallback } from 'react'
import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { INFINITY_X_BREADCRUMBS } from 'utility/constants'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'

const Breadcrumb = () => {
  const projectName = useSelector(
    (state: RootState) => state.breadcrumbSlice.projectName,
  )
  const componentName = useSelector(
    (state: RootState) => state.breadcrumbSlice.componentName,
  )

  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { pathname } = location
  const pathnames = pathname.split('/').filter((x) => x)

  // Extract query parameters from URL
  const projectId = searchParams.get('projectId')
  const componentId = searchParams.get('componentId')
  const componentType = searchParams.get('componentType')

  const makeFirstLetterCap = useCallback((str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }, [])

  return (
    <Box role="presentation">
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
        id={INFINITY_X_BREADCRUMBS}
        sx={{ paddingLeft: '25px', paddingTop: '10px', paddingBottom: '10px' }}
      >
        <Link
          sx={{ cursor: 'pointer' }}
          underline="hover"
          // color="inherit"
          onClick={() => navigate(`/dashboard`)}
        >
          Dashboard
        </Link>

        {pathnames
          .filter((x) => x !== 'dashboard')
          .map((path: any, index: any) => {
            const isLast = index === pathnames.length - 1
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
            // Determine additional query parameters based on the path
            let query = ''
            if (path === 'projects' && projectId) {
              query = `?projectId=${projectId}`
            } else if (path === 'components' && projectId) {
              query = `?projectId=${projectId}`
            } else if (
              path === 'data-pipeline' &&
              projectId &&
              componentId &&
              componentType
            ) {
              query = `?projectId=${projectId}&componentId=${componentId}&componentType=${componentType}`
            } else if (path === 'apps-report-page' && projectId) {
              query = `?projectId=${projectId}`
            } else if (path === 'operation-detail-list-page' && projectId) {
              query = `?projectId=${projectId}`
            }

            const linkTo = `${routeTo}${query}`

            // Show project name instead of the project ID in breadcrumbs
            let displayName = ''
            if (path === 'components') {
              displayName =
                path === 'components' && projectName
                  ? makeFirstLetterCap(projectName?.toLowerCase())
                  : makeFirstLetterCap(path)
            } else if (path === 'data-pipeline') {
              displayName =
                path === 'data-pipeline' && componentName
                  ? makeFirstLetterCap(componentName?.toLowerCase())
                  : makeFirstLetterCap(path)
            } else if (path === 'apps-report-page') {
              displayName =
                path === 'apps-report-page' && projectName
                  ? makeFirstLetterCap(projectName?.toLowerCase())
                  : makeFirstLetterCap(path)
            } else if (path === 'operation-detail-list-page') {
              displayName =
                path === 'operation-detail-list-page' && projectName
                  ? makeFirstLetterCap(projectName?.toLowerCase())
                  : makeFirstLetterCap(path)
            } else if (path === 'apps-graph-dashboard') {
              displayName =
                path === 'apps-graph-dashboard' && componentName
                  ? makeFirstLetterCap(componentName?.toLowerCase())
                  : makeFirstLetterCap(path)
            } else if (path === 'operation-detail-table') {
              displayName =
                path === 'operation-detail-table' && componentName
                  ? makeFirstLetterCap(componentName?.toLowerCase())
                  : makeFirstLetterCap(path)
            } else {
              displayName = makeFirstLetterCap(path)
            }

            return isLast ? (
              <Typography key={index} color="textPrimary">
                {displayName}
              </Typography>
            ) : (
              <Link
                key={index}
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                onClick={() => navigate(linkTo)}
              >
                {displayName}
              </Link>
            )
          })}
      </Breadcrumbs>
    </Box>
  )
}

export default Breadcrumb
