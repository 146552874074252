/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CardComponent from 'components/atoms/card'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import LoaderComponent from 'components/atoms/loader-component'
import { ApiServices } from 'api/services'
import { getRandomNumber } from 'utility/utility'
import { getProfileInfo, removeProfileInfo, removeToken } from 'api/apiUtility'

const SelectTemplate = () => {
  const [isLoading, setLoading] = useState(true)
  const [templateData, setTemplateData] = useState<any>([])
  const navigate = useNavigate()
  const [projectId, setProjectId] = useState<string>()
  const [componentType, setComponentType] = useState<string>()
  const [searchParams] = useSearchParams()
  const [open, setOpen] = useState<boolean>(false)
  const [selectedTemplate, setSelectedTemplate] = useState<string>('')
  const handleClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    if (searchParams) {
      const project_Id = searchParams.get('projectId')
      const componentType = searchParams.get('componentType')
      project_Id && setProjectId(project_Id)
      componentType && setComponentType(componentType)
    }
  }, [searchParams])

  useEffect(() => {
    const componentType = searchParams.get('componentType')
    const getComponentTemplate = async () => {
      try {
        setLoading(true)
        const orgId = getProfileInfo().teamId
        const payload = {
          data: {
            org_id: orgId,
            componentType: componentType,
          },
        }
        const resp: any = await ApiServices.getComponentTemplate(payload)
        setLoading(false)
        setTemplateData(resp.data)
      } catch (error: any) {
        setTemplateData(null)
        setLoading(false)
        console.log('error', error)
      }
    }
    // const componentType = searchParams.get('componentType')
    getComponentTemplate()
  }, [])

  const createComponent = useCallback(
    async (componentName: string) => {
      const componentId = getRandomNumber()
      const payload = {
        data: {
          id: componentId.toString(),
          project_id: projectId,
          configuration: {
            input: {
              nodes: [],
              edges: [],
            },
          },
          component_type: componentType ? componentType : 'DATA_PIPELINE',
          component_type_name: 'Data Pipeline',
          component_template_code: selectedTemplate,
          component_name: componentName,
          component_description: '',
          active: true,
          status: 'draft',
        },
      }
      try {
        setLoading(true)
        await ApiServices.updateComponent(payload)
        setLoading(false)
        componentId &&
          navigate(
            `/${PAGE_ROUTE_CONSTANTS.PROJECTS}/${PAGE_ROUTE_CONSTANTS.DATA_PIPELINE_LIST}/${PAGE_ROUTE_CONSTANTS.DATA_PIPELINE}?projectId=${projectId}&componentId=${componentId}&componentType=${componentType}`,
          )
      } catch (error: any) {
        setLoading(false)
        console.log('error', error)
      }
    },
    [componentType, navigate, projectId, selectedTemplate],
  )

  const DialogForComopentName = useMemo(() => {
    return (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault()
              const formData = new FormData(event.currentTarget)
              const formJson = Object.fromEntries((formData as any).entries())
              const component_name = formJson.component_name
              createComponent(component_name)
              handleClose()
            },
          }}
        >
          <DialogTitle>New Component</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="component_name"
              name="component_name"
              label="Component Name"
              fullWidth
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Apply</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }, [createComponent, open])

  return (
    <Box>
      <Container sx={{ mt: 2, paddingBottom: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component={'span'}>
            List of templates
          </Typography>
        </Box>
        <Box sx={{ ml: 2, mt: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Feature Engineering Pipeline - Source to Destination (Batch)
          </Typography>
          <Grid container spacing={5}>
            {templateData &&
              templateData.map((item: any, idx: number) => {
                return (
                  <Grid
                    key={`template_${item.component_template_name}_${idx}`}
                    item
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    <CardComponent
                      sx={{
                        height: '100% !important',
                        // background: '#fff !important',
                        // border: '1px solid #000',
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="h6" sx={{ fontWeight: '600' }}>
                              {item.component_template_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: '600' }}
                            >
                              {item.component_template_description}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{ textAlign: 'start', marginTop: '15px' }}
                          >
                            <Button
                              variant="text"
                              sx={{
                                textTransform: 'none',
                                border: '2px solid #9FA8DA',
                                borderRadius: '8px',
                              }}
                              onClick={() => {
                                setSelectedTemplate(
                                  item.component_template_code,
                                )
                                setOpen(true)
                              }}
                            >
                              {`Select Template`}
                            </Button>
                          </Grid>
                        </Grid>
                        {/* <Box sx={{ mt: 2 }}>
                          <Typography variant="body2" gutterBottom>
                            {item.component_template_description}
                          </Typography>
                        </Box> */}
                      </CardContent>
                    </CardComponent>
                  </Grid>
                )
              })}
          </Grid>
        </Box>
      </Container>
      {DialogForComopentName}
      <LoaderComponent open={isLoading} />
    </Box>
  )
}

export default SelectTemplate
