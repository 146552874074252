/* eslint-disable no-unused-vars */
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import FolderIcon from '@mui/icons-material/Folder'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { CUSTOM_COLORS } from 'theme'
const STATUS = {
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
}

const checkFile = (text: string) => {
  return text.includes('.')
}

const FileExplorerComponent = (props: any) => {
  const {
    fileData,
    loadingStatus,
    prefixValue,
    onClickFolderText,
    onFileClick,
    absolutePath,
  } = props
  const [currentFolderName, setCurrentFolderName] = useState('')
  const [absolutePathLength, setAbsolutePathLength] = useState<any>()

  useEffect(() => {
    if (absolutePath) {
      const path = absolutePath
        .split('/')
        .slice(0, -1)
        .map((item: any, index: number, items: any) => ({
          name: `${item}/`,
          isCurrent: index == items.length - 1,
          url: `${items.slice(0, index + 1).join('/')}/`,
        }))

      setAbsolutePathLength(path?.length)
    }
  }, [absolutePath])

  const removePrefixFromValue = useCallback(
    (value: string) => {
      return value.replace(prefixValue, '')
    },
    [prefixValue],
  )

  const onClickFolder = useCallback(
    (prefix: string, folderName: string) => {
      onClickFolderText && onClickFolderText(prefix)
      setCurrentFolderName(folderName)
    },
    [onClickFolderText],
  )

  const BreadCrumpsNavigation = useCallback(() => {
    const folders = prefixValue
      .split('/')
      .slice(0, -1)
      .map((item: any, index: number, items: any) => ({
        name: `${item}/`,
        isCurrent: index == items.length - 1,
        url: `${items.slice(0, index + 1).join('/')}/`,
      }))

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          <IconButton
            disabled={true}
            // onClick={() => onClickFolder('', '')}
          >
            <HomeOutlinedIcon />
          </IconButton>
          {folders &&
            folders.map((item: any, index: number) => {
              return item.isCurrent || index <= absolutePathLength - 2 ? (
                <Box>
                  <Typography>{item.name}</Typography>
                </Box>
              ) : (
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => onClickFolder(item.url, item.name)}
                >
                  <Typography sx={{ color: 'black' }}>{item.name}</Typography>
                </Box>
              )
            })}
        </Breadcrumbs>
      </>
    )
  }, [onClickFolder, prefixValue, absolutePathLength])

  const fileList = useCallback(() => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Last Modified</TableCell>
              <TableCell align="right">Size</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(() => {
              switch (loadingStatus.status) {
                case STATUS.LOADING:
                  return (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )

                case STATUS.ERROR:
                  return (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        Failed to fetch data: {loadingStatus?.error?.message}
                      </TableCell>
                    </TableRow>
                  )

                case STATUS.SUCCESS:
                  return fileData.map((row: any, idx: number) => {
                    return (
                      <TableRow key={`${idx}_${row.name}`}>
                        <TableCell component="th" scope="row">
                          {checkFile(removePrefixFromValue(row.name)) ? (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Icon component={InsertDriveFileIcon} />
                              <Box
                                component={'span'}
                                sx={{
                                  color: CUSTOM_COLORS.primary,
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  onFileClick(row.name)
                                }}
                              >{`${removePrefixFromValue(row.name)}`}</Box>
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Icon component={FolderIcon} />
                              <Box
                                component={'span'}
                                onClick={() => {
                                  onClickFolder(
                                    row.name,
                                    `${removePrefixFromValue(row.name)}`,
                                  )
                                }}
                                sx={{ cursor: 'pointer' }}
                              >{`${removePrefixFromValue(row.name)}`}</Box>
                            </Box>
                          )}
                        </TableCell>
                        <TableCell align="right">{`${
                          row.last_modified ? row.last_modified : '-'
                        }`}</TableCell>
                        <TableCell align="right">{`${
                          row.size ? row.size : '-'
                        }`}</TableCell>
                      </TableRow>
                    )
                  })
              }
            })()}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }, [
    loadingStatus,
    fileData,
    removePrefixFromValue,
    onFileClick,
    onClickFolder,
  ])

  return (
    <Box sx={{ mt: 2 }}>
      {BreadCrumpsNavigation()}
      <Box sx={{ ml: 2 }}>
        <Typography variant="h5">{currentFolderName}</Typography>
      </Box>
      {fileList()}
    </Box>
  )
}

export default FileExplorerComponent
