export const styles = () => ({
  textAlignEnd: {
    textAlign: 'end',
  },
  cy: {
    height: '80vh',
    position: 'relative',
    background: '#fafafa',
    'background-image': 'radial-gradient(#bebebe 1px, transparent 0)',
    'background-size': '18px 18px',
  },
})
