/* eslint-disable prettier/prettier */
import { blue, grey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

export const CUSTOM_COLORS = {
  buttonBackground: '#9FA8DA',
  buttonBackgroundHover: '#3b50ca',
  backgroundColor: '#121212',
  backgroundGrey: grey[100],
  primary: blue[900],
  secondary: blue[100],
  textGrey: grey[500],
  black: '#000',
  tabOptionsBorder: 'rgba(255, 255, 255, 0.7)',
  tableRowBgLightGray: '#F4F5F7',
  tableRowBgWhite: '#FFFFFF',
}

// Light Theme
const lightTheme = createTheme({
  typography: {
    fontFamily: ['Inter', 'Roboto'].join(','),
  },
  palette: {
    mode: 'light',
    background: {
      default: CUSTOM_COLORS.backgroundGrey,
    },
    primary: {
      main: CUSTOM_COLORS.primary,
    },
    secondary: {
      main: CUSTOM_COLORS.secondary,
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: CUSTOM_COLORS.primary,
          },
        },
      },
    },
  },
})

const darkTheme = createTheme({
  typography: {
    fontFamily: ['Inter', 'Roboto'].join(','),
    // allVariants: {
    //   color: '#fff',
    //   backgroundColor:CUSTOM_COLORS.backgroundColor
    // },
  },
  
  palette: {
    mode: 'dark',
    background: {
      default: CUSTOM_COLORS.backgroundColor,
      paper: CUSTOM_COLORS.backgroundColor,
    },
    primary: {
      main: CUSTOM_COLORS.primary,
    },
    secondary: {
      main: CUSTOM_COLORS.secondary,
    },
    text: {
      primary: '#fff',
      secondary: CUSTOM_COLORS.textGrey,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: CUSTOM_COLORS.backgroundColor,
          color: '#fff',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: '#fff',
          backgroundColor: CUSTOM_COLORS.backgroundColor,
        },
        separator: {
          color: '#fff',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          // color:'blue',
          // color: 'rgba(255, 255, 255, 0.7) !important', // Button text color in normal state
          color: '#fff !important', // Button text color in normal state
          backgroundColor: CUSTOM_COLORS.buttonBackground, // Button background color
          border: '1px solid rgba(255, 255, 255, 0.7)',
          '&:hover': {
            backgroundColor: CUSTOM_COLORS.buttonBackgroundHover,
            color: '#fff',

            // Ensure the background color remains the same on hover
            opacity: 1, // Prevent any opacity change on hover
          },

          // Optionally, you can also reset the focus and active states
          // '&:focus, &:active': {
          //   backgroundColor: CUSTOM_COLORS.backgroundColor,
          //   color: '#fff',
          // },
        }, 
      },
    },
    // MuiBox: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: CUSTOM_COLORS.backgroundColor,
    //       color: '#fff',
    //     },
    //   },
    // },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#fff',
          '&.Mui-checked': {
            color: CUSTOM_COLORS.primary,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: CUSTOM_COLORS.backgroundColor,
          color: '#fff',
        },
      },
    },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: CUSTOM_COLORS.backgroundColor,
    //       color: '#fff',
    //     },
    //   },
    // },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: CUSTOM_COLORS.backgroundColor,
          color: '#fff',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: CUSTOM_COLORS.backgroundColor,
          color: '#fff',
          borderBottom: `1px solid ${CUSTOM_COLORS.tabOptionsBorder}`,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: CUSTOM_COLORS.backgroundColor,
          color: '#fff',

          // '&:nth-of-type(odd)': {
          //   backgroundColor: CUSTOM_COLORS.tableRowBgLightGray,
          // },
          // '&:nth-of-type(even)': {

          //   backgroundColor: CUSTOM_COLORS.tableRowBgWhite,
          // },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff',
          backgroundColor: 'transparent !important',
        },
      },
    },
    // MuiContainer: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: CUSTOM_COLORS.backgroundColor,
    //       color: '#fff',
    //     },
    //   },
    // },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#fff',
          backgroundColor: CUSTOM_COLORS.backgroundColor,
        },
        input: {
          color: '#fff',
          backgroundColor: CUSTOM_COLORS.backgroundColor,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: '#fff',
          backgroundColor: CUSTOM_COLORS.backgroundColor,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: '#fff',
          backgroundColor: CUSTOM_COLORS.backgroundColor,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#fff',
          // backgroundColor:CUSTOM_COLORS.primary,
          background: CUSTOM_COLORS.backgroundColor,
          // border:'none',
          '&:hover': {
            backgroundColor: CUSTOM_COLORS.backgroundColor, // Ensure the background color remains the same on hover
            
            opacity: 1, // Prevent any opacity change on hover
          },
          // Optionally, you can also reset the focus and active states
          // '&:focus, &:active': {
          //   backgroundColor: CUSTOM_COLORS.backgroundColor,
          // },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: CUSTOM_COLORS.backgroundColor,
          color: '#fff',

          '&:hover': {
            backgroundColor: CUSTOM_COLORS.primary,
          },
        },
      },
    },
  },
})
export const cytoscapeDarkTheme = [
  {
    selector: 'node',
    style: {
      'background-color': 'rgba(18, 18, 18, 1)',
      label: 'data(label)',
      color: '#fff',
      'text-outline-color': '#2e2e2e',
      'text-outline-width': 2,
    },
  },
  {
    selector: 'edge',
    style: {
      width: 2,
      'line-color': '#888',
      'target-arrow-color': '#888',
      'target-arrow-shape': 'triangle',
      'curve-style': 'bezier',
      label: 'data(label)',
      color: '#fff',
    },
  },
  {
    selector: ':selected',
    style: {
      'background-color': '#004080',
      'line-color': '#004080',
      'target-arrow-color': '#004080',
      'source-arrow-color': '#004080',
    },
  },
]

export { lightTheme, darkTheme }
