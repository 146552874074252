import React, { useEffect, useState } from 'react'
// import { ApiServices } from 'api/services'
import LoaderComponent from 'components/atoms/loader-component'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import OperationDetailsTableComponent from './components/OperationDetailsTableComponent'
import { ApiServices } from 'api/services'
import { useSearchParams } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store/store'
import { setComponentName } from 'store/breadcrumbSlice'
import OperationTableChart from './components/OperationTableChart'

const OperationDetailsTable = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [isLoading, setLoading] = useState(false)
  const [chartComponentData, setChartComponentData] = useState<any>()
  const [componentName, setCompName] = useState<string>()
  const [searchParams] = useSearchParams()
  const [projectId, setProjectId] = useState<string>()
  const [uid, setUid] = useState<string>()
  const [componentId, setComponentId] = useState<any>()

  useEffect(() => {
    if (searchParams) {
      //It need for future use
      const component_Name = searchParams.get('componentName')
      component_Name && setCompName(component_Name)
      component_Name && dispatch(setComponentName(component_Name))
      const u_id = searchParams.get('uid')
      u_id && setUid(u_id)
      const project_Id = searchParams.get('projectId')
      project_Id && setProjectId(project_Id)
      const component_Id = searchParams.get('componentId')
      component_Id && setComponentId(component_Id)
    }
  }, [searchParams])

  useEffect(() => {
    if (projectId) {
      if (projectId === '65d2326ebf15b1f7df493709') {
        //KYC
        ApiServices.getOperationTables('65ffe26e847eec1cf66b6544')
          .then((res: any) => {
            // console.log('Operation Tables =', res)
            setChartComponentData(res.data)
          })
          .catch((error: any) => {
            console.log(error)
          })
      } else if (projectId === '6608ff8b7758756b169a6c46') {
        //Zara
        ApiServices.getOperationTables('660908847758756b169a6f3d')
          .then((res: any) => {
            // console.log('Operation Tables =', res)
            setChartComponentData(res.data)
          })
          .catch((error: any) => {
            console.log(error)
          })
      } else if (projectId === '660669c97a751698259a7b69') {
        //Customer KYC:
        ApiServices.getOperationTables('65ffe26e847eec1cf66b6544')
          .then((res: any) => {
            // console.log('Operation Tables =', res)
            setChartComponentData(res.data)
          })
          .catch((error: any) => {
            console.log(error)
          })
      } else {
        //Default
        ApiServices.getOperationTables(componentId)
          .then((res: any) => {
            // console.log('Operation Tables =', res)
            setChartComponentData(res.data)
          })
          .catch((error: any) => {
            console.log(error)
          })
      }
    }
  }, [projectId])

  return (
    <>
      <Box sx={{ padding: '30px', backgroundColor: '#fff' }}>
        <Box>
          {componentName && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'left',
              }}
            >
              <Typography
                variant="button"
                gutterBottom
                sx={{ fontWeight: 'bold', fontSize: 24 }}
              >
                {componentName}
              </Typography>
            </Box>
          )}
        </Box>
        {chartComponentData &&
          chartComponentData.component_type === 'DATA_PIPELINE' &&
          chartComponentData.configuration &&
          chartComponentData.configuration.snapshot &&
          chartComponentData.configuration.snapshot.length &&
          chartComponentData.configuration.snapshot.map(
            (snap: any, index: any) => {
              if (
                snap.component_node_template ===
                'VISUALIZATION_TEMPLATE_BARCHART_DATA_REPO_BASED'
              ) {
                return (
                  <Box key={index} sx={{ my: '10px' }}>
                    {/* <OperationDetailsTableComponent
                      chartData={snap}
                      setLoading={setLoading}
                      tableName={'Job Execution Details'}
                      uid={uid}
                    /> */}
                    <OperationTableChart
                      index={index}
                      chartData={chartComponentData}
                      setLoading={setLoading}
                    />
                  </Box>
                )
              } else {
                return (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'left',
                        }}
                      >
                        <Typography
                          variant="button"
                          gutterBottom
                          sx={{ fontWeight: 'bold', fontSize: 18 }}
                        >
                          {snap.component_node_name || `Accordion ${index}`}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {snap.component_unique_key === 'jobexecutiondetails' ? (
                        <OperationDetailsTableComponent
                          chartData={snap}
                          setLoading={setLoading}
                          // tableName={'Job Execution Details'}
                          uid={uid}
                        />
                      ) : (
                        <OperationDetailsTableComponent
                          key={index}
                          chartData={snap}
                          setLoading={setLoading}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                )
              }
            },
          )}
        {chartComponentData &&
          chartComponentData.component_type === 'DQ_PIPELINE' &&
          chartComponentData.configuration &&
          chartComponentData.configuration.snapshot &&
          chartComponentData.configuration.snapshot.length &&
          chartComponentData.configuration.snapshot.map(
            (snap: any, index: any) => {
              if (
                snap.component_node_template ===
                'VISUALIZATION_TEMPLATE_BARCHART_DATA_REPO_BASED'
              ) {
                return (
                  <Box key={index} sx={{ my: '10px' }}>
                    {/* <OperationDetailsTableComponent
                      chartData={snap}
                      setLoading={setLoading}
                      tableName={'Job Execution Details'}
                      uid={uid}
                    /> */}
                    <OperationTableChart
                      index={index}
                      chartData={chartComponentData}
                      setLoading={setLoading}
                    />
                  </Box>
                )
              } else {
                return (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'left',
                        }}
                      >
                        <Typography
                          variant="button"
                          gutterBottom
                          sx={{ fontWeight: 'bold', fontSize: 18 }}
                        >
                          {snap.component_node_name || `Accordion ${index}`}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {snap.component_unique_key === 'jobexecutiondetails' ? (
                        <OperationDetailsTableComponent
                          chartData={snap}
                          setLoading={setLoading}
                          // tableName={'Job Execution Details'}
                          uid={uid}
                        />
                      ) : (
                        <OperationDetailsTableComponent
                          key={index}
                          chartData={snap}
                          setLoading={setLoading}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                )
              }
            },
          )}
        {chartComponentData &&
          chartComponentData.component_type === 'DOCUMENT_PROCESSING' &&
          chartComponentData.configuration &&
          chartComponentData.configuration.snapshot &&
          chartComponentData.configuration.snapshot.length &&
          chartComponentData.configuration.snapshot.map(
            (snap: any, index: any) => {
              if (
                snap.component_node_template ===
                'VISUALIZATION_TEMPLATE_BARCHART_DATA_REPO_BASED'
              ) {
                return (
                  <Box key={index} sx={{ my: '10px' }}>
                    {/* <OperationDetailsTableComponent
                      chartData={snap}
                      setLoading={setLoading}
                      tableName={'Job Execution Details'}
                      uid={uid}
                    /> */}
                    <OperationTableChart
                      index={index}
                      chartData={chartComponentData}
                      setLoading={setLoading}
                    />
                  </Box>
                )
              } else {
                return (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'left',
                        }}
                      >
                        <Typography
                          variant="button"
                          gutterBottom
                          sx={{ fontWeight: 'bold', fontSize: 18 }}
                        >
                          {snap.component_node_name || `Accordion ${index}`}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {snap.component_unique_key === 'jobexecutiondetails' ||
                      snap.component_unique_key === 'executiondetails' ? (
                        <OperationDetailsTableComponent
                          chartData={snap}
                          setLoading={setLoading}
                          // tableName={'Job Execution Details'}
                          uid={uid}
                        />
                      ) : (
                        <OperationDetailsTableComponent
                          key={index}
                          chartData={snap}
                          setLoading={setLoading}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                )
              }
            },
          )}
        {chartComponentData &&
          chartComponentData.component_type === 'MODEL_TRAINING' &&
          chartComponentData.configuration &&
          chartComponentData.configuration.snapshot &&
          chartComponentData.configuration.snapshot.length &&
          chartComponentData.configuration.snapshot.map(
            (snap: any, index: any) => {
              return (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'left',
                      }}
                    >
                      <Typography
                        variant="button"
                        gutterBottom
                        sx={{ fontWeight: 'bold', fontSize: 18 }}
                      >
                        {snap.component_node_name || `Accordion ${index}`}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {snap.component_unique_key === 'jobexecutiondetails' ? (
                      <OperationDetailsTableComponent
                        chartData={snap}
                        setLoading={setLoading}
                        // tableName={'Job Execution Details'}
                        uid={uid}
                      />
                    ) : (
                      <OperationTableChart
                        index={index}
                        chartData={chartComponentData}
                        setLoading={setLoading}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              )
            },
          )}
      </Box>

      <LoaderComponent open={isLoading} />
    </>
  )
}
export default OperationDetailsTable
