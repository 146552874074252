import React from 'react'
import { BarChart } from '@mui/x-charts/BarChart'

interface MuiBarChartProps {
  chartSetting: any
  dataset: any
  yAxis: any
  series: any
}

const MuiBarChart = (props: MuiBarChartProps) => {
  const { chartSetting, dataset, yAxis, series } = props

  // const yAxisMapping = JSON.parse(JSON.stringify(yAxis))
  // const chartSettings = JSON.parse(JSON.stringify(chartSetting))
  // yAxisMapping[0].label = chartSetting.xAxis[0].label
  // chartSettings.xAxis[0].label = yAxis[0].label

  // console.log('yAxis =', yAxis)
  // console.log('series =', chartSetting)

  return (
    <BarChart
      dataset={dataset}
      yAxis={yAxis}
      series={series}
      layout="horizontal"
      {...chartSetting}
    />
  )
}

export default MuiBarChart
