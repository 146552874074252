import { Box, Switch, Typography } from '@mui/material'
import ChartContainer from 'components/components/ChartContainer'
import TableChartComponent from 'components/components/TableChartComponent'
import React, { useState } from 'react'

const label = { inputProps: { 'aria-label': 'Switch demo' } }

interface TwoDChartProps {
  chartData: any
  setLoading?: any
}

// const data = [
//   {
//     values: [
//       { x: 0, y: 10 },
//       { x: 1, y: 20 },
//       // Add more points for the first line
//     ],
//   },
//   {
//     values: [
//       { x: 0, y: 15 },
//       { x: 1, y: 25 },
//       // Add more points for the second line
//     ],
//   },
//   // Add more objects for additional lines
// ]

// const yAxis = [
//   {
//     field: 'sales_volume',
//     headerName: 'Sales Volume',
//     width: 150,
//   },
//   {
//     field: 'price',
//     headerName: 'Price',
//     width: 150,
//   },
// ]

const TwoDChart = (props: TwoDChartProps) => {
  const { chartData, setLoading } = props
  const [chartTableToggle, setChartTableToggle] = useState(true)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '50px',
          justifyContent: 'end',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>Chart</Typography>
          <Switch
            {...label}
            checked={chartTableToggle}
            onChange={(e: any) => setChartTableToggle(e.target.checked)}
          />
          <Typography>Table</Typography>
        </Box>
      </Box>

      {chartTableToggle === true && (
        <Box sx={{ padding: '30px', backgroundColor: '#fff' }}>
          <TableChartComponent chartData={chartData} setLoading={setLoading} />
        </Box>
      )}

      {chartTableToggle === false && (
        <Box sx={{ padding: '30px', backgroundColor: '#fff' }}>
          <ChartContainer chartData={chartData} setLoading={setLoading} />
        </Box>
      )}
    </Box>
  )
}

export default TwoDChart
