/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { Close } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import { getProfileInfo, removeProfileInfo, removeToken } from 'api/apiUtility'
import { ApiServices } from 'api/services'
import CardDetailsMolecule from 'components/molecules/card-details'
import React, { useEffect, useState } from 'react'
import { changeDateFormat } from 'utility/utility'
import { ReactComponent as AppIcon } from 'assets/icons/application.svg'
import SearchInputComponent from 'components/atoms/search-input'
import { useNavigate } from 'react-router-dom'

interface ComponentList {
  onClose: () => void
  publishComponent: any
}

const ComponentList = (props: ComponentList) => {
  const { onClose, publishComponent } = props
  const [allApps, setAllApps] = useState<any>([])
  const [selectedCompId, setSelectedCompId] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const navigate = useNavigate()
  useEffect(() => {
    const getApps = async () => {
      const params = {
        search: {
          org_id: getProfileInfo().teamId,
          project_type: 'APP',
          search: '',
        },
      }
      try {
        const resp = await ApiServices.getProjectsAndApps(params)
        setAllApps(resp.data.data)
      } catch (error: any) {
        console.log(error)
      }
    }
    getApps()
  }, [])
  return (
    <Box
      sx={{
        display: 'flex',
        width: '50vw',
        minHeight: '300px',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
          alignItems: 'center',
          height: '30px',
          padding: '20px',
          paddingTop: '50px',
          marginBottom: '10px',
          position: 'sticky',
          // background: 'white',
          zIndex: 100,
          top: 0,
        }}
      >
        {' '}
        <Box sx={{ display: 'flex', flex: 2, justifyContent: 'center' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            Select Target App to Publish Component
          </Typography>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '30px',
                height: '30px',
                background: 'red',
              }}
            >
              <Close
                sx={{
                  color: 'white',
                  cursor: 'pointer',
                  border: '1px solid red',
                }}
                onClick={onClose}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '2%',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <SearchInputComponent
            value={searchInput}
            onChange={(e: any) => setSearchInput(e.target.value)}
          />
        </Box>
      </Box>
      <Box sx={{ overflowY: 'scroll' }}>
        <Grid container spacing={2}>
          {allApps &&
            allApps
              .filter((item: any) => item.project_name.includes(searchInput))
              .map((item: any) => {
                return (
                  <Grid
                    key={`recent_${item.sNo}`}
                    sx={{ m: '20px' }}
                    item
                    md={4}
                    sm={6}
                  >
                    <Box
                      sx={{
                        border:
                          selectedCompId === item.id
                            ? '3px solid #757FE1'
                            : null,
                      }}
                    >
                      <CardDetailsMolecule
                        label={`${item.project_name}`}
                        subLabel={`Last updated ${changeDateFormat(
                          item.last_updated_date,
                        )}`}
                        onClick={() => {
                          setSelectedCompId(item.id)
                        }}
                        handleDetails={(e) => {
                          // details drawer
                        }}
                        version="v2"
                        icon={<AppIcon />}
                      ></CardDetailsMolecule>
                    </Box>
                  </Grid>
                )
              })}
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          justifyContent: 'flex-end',
          padding: '20px',
          position: 'sticky',
          bottom: 0,
          zIndex: 100,
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            publishComponent?.(selectedCompId)
            onClose()
          }}
          disabled={selectedCompId === ''}
        >
          Publish
        </Button>
      </Box>
    </Box>
  )
}

export default ComponentList
