/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import { Box, Grid, Card, CardContent, Typography, Chip, IconButton } from '@mui/material'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { getAllOrganizations } from 'api/services/api-services'
import UserManagement from 'pages/Admin/UserManagement/UserManagement'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
interface Organization {
  id: string
  name: string
  status: 'LIVE' | 'DISABLED' | 'PENDING'
  start_date: string | null
  noOfUsers: number
}

const SuperAdminLanding = () => {
  const [rows, setRows] = useState<Organization[]>([])
  const [userFlag, setUserFlag] = useState(false)
  const tableReload = () => {
    const payload = {
      search: '',
      status: 'SHOW_ALL',
    }

    getAllOrganizations(payload)
      .then((response) => {
        setRows(response?.data?.data || [])
      })
      .catch((error) => {
        console.error('Error fetching organizations:', error)
      })
  }


  useEffect(() => {
    // Get the current window href
    const currentUrl = window.location.href;
    
    // Check if the URL contains the id parameter
    if (currentUrl.includes('id=')) {
      setUserFlag(true);
    }
  }, []);



  useEffect(() => {
    tableReload()
  }, [])
  const handleBackClick = () => {
    setUserFlag(false)
  }
  useEffect(() => {}, [userFlag])
  const handleUserManagement = (id: string) => {
    const newUrl = `${window.location.origin}/sysadmin/organizations?id=${id}`

    // Update the URL in the browser
    window.history.pushState({}, '', newUrl)
    // window.location.reload()
    setUserFlag(!userFlag)
    // navigate(`/${PAGE_ROUTE_CONSTANTS.ADMIN}?id=${id}`)
  }

  return (
    <Box sx={{ padding: 4 }}>
      {!userFlag && (  <>
      <Box sx={{ fontSize: '28px', fontWeight: '700', marginBottom: '20px' }}>
     Dashboard
      </Box>
      <Grid container spacing={3}>
        {rows.map((row) => (
          <Grid item xs={12} sm={6} md={3} key={row.id}>
            <Card
              sx={{
                minHeight: '80px',
                borderRadius: '16px',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                background: `linear-gradient(135deg, ${
                  row.status === 'LIVE'
                    ? '#d4edda'
                    : row.status === 'PENDING'
                      ? '#fff3cd'
                      : '#f8d7da'
                }, #fff)`,
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)',
                  cursor:'pointer'
                },
              }}
              onClick={() => handleUserManagement(row.id)}
            >
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <CorporateFareIcon sx={{ color: '#3b3b3b', mr: 1 }} />
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      fontWeight: 'bold',
                      color: '#3b3b3b',
                    }}
                  >
                    {row.name || 'No Name'}
                  </Typography>
                </Box>

                <Typography
                  variant="body1"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#616161',
                    marginBottom: '8px',
                  }}
                >
                  Status:
                  <Chip
                    label={row.status}
                    sx={{
                      ml: 1,
                      background: `linear-gradient(135deg, ${
                        row.status === 'LIVE'
                          ? '#d4edda'
                          : row.status === 'PENDING'
                            ? '#fff3cd'
                            : '#f8d7da'
                      }, #fff)`,
                      borderRadius: '8px',
                      fontSize: '12px',
                      fontWeight: '600',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                  />
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ color: '#757575', marginBottom: '8px' }}
                >
                  Start Date:{' '}
                  {row.start_date
                    ? new Date(row.start_date).toLocaleDateString()
                    : 'N/A'}
                </Typography>

                <Typography variant="body2" sx={{ color: '#757575' }}>
                  Number of Users: {row.noOfUsers}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      </>
      )}
      {userFlag && (
        <Box>
          <IconButton
            onClick={handleBackClick}
            sx={{ position: 'relative', left: 16 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box ml={8} mt={'-70px'}>
            {' '}
            {/* Adjust the margin-top to avoid overlap with the back button */}
            <UserManagement />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default SuperAdminLanding
