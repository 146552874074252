import React, { useCallback, useMemo } from 'react'
import lodash from 'lodash'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'

import { DynamicFormProps } from 'types/props-types'

function RolePermissionDynamicForm({
  formData,
  handleChange,
  type,
}: DynamicFormProps) {
  // const { formData, handleChange } = prop
  const state = useMemo(() => lodash.cloneDeep(formData), [formData])

  const handleCheckBoxChange = useCallback(
    (e: any, section: any, key: any) => {
      state[section][key] = e.target.checked
      handleChange(state)
    },
    [formData],
  )

  const formatSections = useCallback((input: string) => {
    let result = input.replace(/_/g, ' ')
    let words = result.split(' ')
    words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    result = words.join(' ')
    return result
  }, [])

  return (
    <Stack gap={'16px'}>
      {formData &&
        Object.keys(formData).map((section: any) => {
          return (
            <Box key={section}>
              <Typography variant="body1" sx={{ fontWeight: '600' }}>
                {formatSections(section)}
              </Typography>
              {Object.keys(formData[section]).map(
                (item: any, index: number) => {
                  return (
                    <Stack key={`${section}_${index}`}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={item}
                        checked={formData[section][item]}
                        onChange={(e: any) =>
                          handleCheckBoxChange(e, section, item)
                        }
                        disabled={type === 'view'}
                      />
                    </Stack>
                  )
                },
              )}
            </Box>
          )
        })}
    </Stack>
  )
}

export default RolePermissionDynamicForm
