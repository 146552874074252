/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import './DocViewer.css'
import '@cyntler/react-doc-viewer/dist/index.css'

const customStyle = {
  height: '100%', // Adjust the height as needed
  border: '1px solid #ccc', // Optional: Add border or other styles
}

const FileViewer = () => {
  const [fileDetail, setFileDetail] = useState<any>()

  useEffect(() => {
    const fileUrl = new URL(window.location.href).searchParams.get('fileUrl')
    const Signature: any = new URL(window.location.href).searchParams.get(
      'Signature',
    )
    const Expires: any = new URL(window.location.href).searchParams.get(
      'Expires',
    )
    const finalFileUrl = `${fileUrl}&Signature=${encodeURIComponent(
      Signature,
    )}&Expires=${encodeURIComponent(Expires)}`
    const fileType = new URL(window.location.href).searchParams.get('fileType')
    setFileDetail({
      url: fileUrl,
      type: fileType,
    })
  }, [])

  return (
    <>
      {fileDetail && (
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={[
            {
              uri: fileDetail.url,
              fileType: fileDetail.type.replace('.', ''),
            },
          ]}
          style={customStyle}
        />
      )}
    </>
  )
}

export default FileViewer
