/* eslint-disable prettier/prettier */
import React from 'react'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import Home from 'pages/home'
import PrivateRoutes from 'routes/private-route'
import LoginAccount from 'pages/loginAccount'
import DsDashboard from 'pages/DataSource/DsDashboard'
import ProjectComponent from 'pages/projects'
import PublicRoutes from 'routes/public-route'
import DataPipeline from 'pages/projects/data-pipeline/data-pipeline'
import SelectTemplate from 'pages/projects/select-template'
import DataPipelineList from 'pages/projects/data-pipeline-list'
import GraphDashboard from 'pages/graph-dahboard/GraphDashboard'
import AppsPage from 'pages/apps'
import ReportsAppPage from 'pages/apps/reports-page'
import LandingApps from 'pages/apps/landing-apps'
import AppGraphDashboard from 'pages/apps/app-graph-dashboard'
import AppFilteredGraph from 'pages/apps/app-filtered-graph-dashboard/AppFilteredGraph'
import AllAppsList from 'pages/apps/all-apps'
import OperationsPage from 'pages/operations'
import LandingOperation from 'pages/operations/landing-operation'
import OperationDetailListPage from 'pages/operations/operation-detail-list'
import AllOperationsList from 'pages/operations/all-operation'
import OperationDetailsTable from 'pages/operations/operation-detail-table/OperationDetailsTable'
import AppKnowledgeGraphVisualization from 'pages/apps/knowledge-graph'
import AdminDashboard from 'pages/Admin/AdminDashboard'
import FileViewer from 'pages/FileViewer'
import AccountSignUp from 'pages/SignUp'
import SuperAdminDashboard from 'pages/SuperAdmin/SuperAdminDashboard'
import CustomFunctions from 'pages/CustomFunction/CustomFunctions'
import Projects from 'pages/projects/Projects'
import SuperAdminLanding from 'pages/SuperAdmin/SuperAdminLanding'
import Orgpreference from 'pages/OrgPreference/Orgpreference'

const RoutesPages = () => {
  return (
    <Router>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path={PAGE_ROUTE_CONSTANTS.LOGIN} element={<LoginAccount />} />
          <Route
            path={PAGE_ROUTE_CONSTANTS.SIGNUP}
            element={<AccountSignUp />}
          />
        </Route>
        <Route
          path={PAGE_ROUTE_CONSTANTS.FileViewer}
          element={<FileViewer />}
        ></Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/"
            element={<Navigate to={PAGE_ROUTE_CONSTANTS.HOME} />}
          />
          <Route path={PAGE_ROUTE_CONSTANTS.HOME} element={<Home />}></Route>

          {/** Project Routes */}
          <Route
            path={PAGE_ROUTE_CONSTANTS.PROJECTS}
            element={<ProjectComponent />}
          >
            <Route index element={<Projects />} />
            <Route
              path={PAGE_ROUTE_CONSTANTS.DATA_PIPELINE_LIST}
              element={<DataPipelineList />}
            ></Route>
            <Route
              path={`${PAGE_ROUTE_CONSTANTS.DATA_PIPELINE_LIST}/${PAGE_ROUTE_CONSTANTS.DATA_PIPELINE}`}
              element={<DataPipeline />}
            ></Route>
            <Route
              path={`${PAGE_ROUTE_CONSTANTS.DATA_PIPELINE_LIST}/${PAGE_ROUTE_CONSTANTS.SELECT_TEMPLATE}`}
              element={<SelectTemplate />}
            ></Route>
          </Route>
          <Route
            path={PAGE_ROUTE_CONSTANTS.GRAPH_DASHBOARD}
            element={<GraphDashboard />}
          ></Route>
          {/** Apps Routes */}
          <Route path={PAGE_ROUTE_CONSTANTS.APPS_PAGE} element={<AppsPage />}>
            <Route index element={<LandingApps />} />

            <Route
              path={PAGE_ROUTE_CONSTANTS.APPS_REPORT_PAGE}
              element={<ReportsAppPage />}
            ></Route>
            <Route
              path={PAGE_ROUTE_CONSTANTS.ALL_APPS_LIST}
              element={<AllAppsList />}
            ></Route>
            <Route
              path={`${PAGE_ROUTE_CONSTANTS.APPS_REPORT_PAGE}/${PAGE_ROUTE_CONSTANTS.APPS_GRAPH_DASHBOARD}`}
              element={<AppGraphDashboard />}
            ></Route>
            <Route
              path={`${PAGE_ROUTE_CONSTANTS.APPS_REPORT_PAGE}/${PAGE_ROUTE_CONSTANTS.APPS_FILTERED_GRAPH_DASHBOARD}`}
              element={<AppFilteredGraph />}
            ></Route>
            <Route
              path={PAGE_ROUTE_CONSTANTS.APPS_KNOWLEDGE_GRAPH}
              element={<AppKnowledgeGraphVisualization />}
            ></Route>
          </Route>
          {/** Operations Routes */}
          <Route
            path={PAGE_ROUTE_CONSTANTS.OPERATIONS_PAGE}
            element={<OperationsPage />}
          >
            <Route index element={<LandingOperation />} />
            <Route
              path={PAGE_ROUTE_CONSTANTS.OPERATION_DETAIL_LIST_PAGE}
              element={<OperationDetailListPage />}
            ></Route>
            <Route
              path={PAGE_ROUTE_CONSTANTS.ALL_OPERATIONS_LIST}
              element={<AllOperationsList />}
            ></Route>
            <Route
              path={`${PAGE_ROUTE_CONSTANTS.OPERATION_DETAIL_LIST_PAGE}/${PAGE_ROUTE_CONSTANTS.OPERATION_DETAIL_TABLE}`}
              element={<OperationDetailsTable />}
            ></Route>
          </Route>
          {/** Sources Routes */}
          <Route
            path={PAGE_ROUTE_CONSTANTS.DATA_SOURCE}
            element={<DsDashboard />}
          ></Route>
          {/** Admin Routes */}
          <Route
            path={PAGE_ROUTE_CONSTANTS.ADMIN}
            element={<AdminDashboard />}
          ></Route>
          <Route
            path={PAGE_ROUTE_CONSTANTS.SUPERADMIN}
            element={<SuperAdminDashboard />}
          ></Route>
          <Route
            path={PAGE_ROUTE_CONSTANTS.SUPERADMINDASHBOARD}
            element={<SuperAdminLanding />}
          ></Route>
          <Route
            path={PAGE_ROUTE_CONSTANTS.CUSTOMFUNCTIONS}
            element={<CustomFunctions />}
          ></Route>
          <Route
            path={PAGE_ROUTE_CONSTANTS.ORGPREFERENCE}
            element={<Orgpreference />}
          ></Route>
        </Route>
      </Routes>
    </Router>
  )
}

export default RoutesPages
