import React, { useCallback } from 'react'
// import _ from 'lodash'
import {
  Select,
  MenuItem,
  //   Checkbox,
  ListItemText,
  FormControl,
  OutlinedInput,
  InputLabel,
  Checkbox,
} from '@mui/material'
import _ from 'lodash'

interface Option {
  key: string
  label: string
}

// const options: any[] = [
//   { key: 'TEXT', label: 'Text' },
//   { key: 'IMAGE', label: 'Images' },
//   { key: 'AUDIO', label: 'Audio' },
//   { key: 'VIDEO', label: 'Video' },
// ]

interface MultiObjDropdownProps {
  value: any
  onChange: any
  options: any
  label?: string
  width?: number
  isRequired?: boolean
  name?: string
  disabled?: boolean
  state?: any
}

const MultiObjDropdown = ({
  label = 'Select Options',
  value,
  onChange,
  width = 361,
  isRequired = false,
  name,
  disabled,
  options,
}: MultiObjDropdownProps) => {
  const handleChange = useCallback((event: any) => {
    const selectedKeys = event.target.value as string[]

    // Count occurrences of each option
    const countMap: Record<string, number> = {}
    selectedKeys.forEach((option: any) => {
      const key = option.key
      countMap[key] = (countMap[key] || 0) + 1
    })

    // Filter out options occurring twice
    const uniqueSelectedOptions = selectedKeys.filter(
      (option: any) => countMap[option.key] === 1,
    )

    onChange(uniqueSelectedOptions, name)
  }, [])

  return (
    <FormControl size="small" fullWidth variant="outlined">
      <InputLabel id="multi-select-label">
        {isRequired ? `${label} *` : `${label}`}
      </InputLabel>
      <Select
        labelId="multi-select-label"
        size="small"
        sx={{ width: width }}
        name={name}
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label={isRequired ? `${label} *` : `${label}`} />}
        renderValue={(selected) =>
          (selected as Option[]).map((option) => option.label).join(', ')
        }
        disabled={disabled}
      >
        {options?.map((option: any) => (
          <MenuItem key={option.key} value={option}>
            <Checkbox
              checked={value.some((value: any) => _.isEqual(value, option))}
            />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultiObjDropdown
