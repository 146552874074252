/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { BarChart } from '@mui/x-charts/BarChart'
import { ScatterChart } from '@mui/x-charts'

interface MuiBarChartProps {
  chartSetting: any
  dataset: any
  yAxis: any
  series: any
}

const MuiScatterChart = (props: MuiBarChartProps) => {
  const { chartSetting, dataset, yAxis, series } = props
  const [scatterSeries, setScatterSeries] = useState([])

  useEffect(() => {
    const scatterData: any = []

    series.forEach((s: any) => {
      const obj = {
        label: s.label,
        data: dataset.map((d: any) => ({
          x: d[s.field],
          y: getMonthFromString(d.month),
          id: d.id,
        })),
      }
      scatterData.push(obj)
    })
    setScatterSeries(scatterData)
  }, [])

  function getMonthFromString(mon: string) {
    const d = Date.parse(mon + '1, 2012')
    if (!isNaN(d)) {
      return new Date(d).getMonth() + 1
    }
    return -1
  }

  /*
  {
    id: 'data-22',
    x1: 390.62,
    x2: 10.01,
    y1: 330.72,
    y2: 488.06,
  },

  series={[
    {
      label: 'Series A',
      data: data.map((v) => ({ x: v.x1, y: v.y1, id: v.id })),
    },
    {
      label: 'Series B',
      data: data.map((v) => ({ x: v.x1, y: v.y2, id: v.id })),
    },
  ]}*/

  // console.log('series=', series)
  // console.log('dataset=', dataset)
  // console.log('yAxis=', yAxis)
  // console.log('Scatter Series=', scatterSeries)

  return (
    <ScatterChart
      width={chartSetting.width}
      height={chartSetting.height}
      series={scatterSeries}
    />
  )
}

export default MuiScatterChart
