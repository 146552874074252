/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { ApiServices } from 'api/services'
import PieCharts from './PieChart'
import { Box, Typography } from '@mui/material'
import MuiBarChart from './MuiBarChart'
import { axisClasses } from '@mui/x-charts'
import MuiLineChart from './MuiLineChart'
import MuiScatterChart from './MuiScatterChart'

import { useNavigate } from 'react-router-dom'

// // Sample data
// const data: any[] = [
//   // { category: 'A1', value1: 'value1', value2: 'value2' },
//   { category: 'A', value1: 10.5, value2: 15 },
//   { category: 'B', value1: 20, value2: 25.9 },
//   { category: 'C', value1: 30.5, value2: 35 },
// ]

interface BarChartProps {
  chartData: any
  setLoading?: any
  index?: any
  selectedChart?: any
}

interface Product {
  [key: string]: string | number
}

// Sample usage
const ChartContainer = (props: BarChartProps) => {
  const {
    chartData,
    setLoading,
    index = 0,
    selectedChart = 'Bar Chart',
  } = props

  // const [chartTypes] = useState(['Bar Chart', 'Line Chart'])
  // const [selectedChartType, setSelectedChartType] = useState('Bar Chart')

  // const margin = { top: 20, right: 30, bottom: 60, left: 250 }

  const [tableDetails, setTableDetails] = useState<any>()
  const [tableRowData, setTableRowData] = useState<any>()
  const [xAxis, setXAxis] = useState<any>([])
  const [yAxis, setYAxis] = useState<any>([])
  const [xAxisLabel, setXAxisLabel] = useState('')
  const [yAxisLabel, setYAxisLabel] = useState('')

  // console.log('Chart Data =', chartData)
  console.log('Chart Data =', chartData)
  useEffect(() => {
    if (chartData) {
      if (
        chartData.configuration.snapshot[index].configuration.snapshot
          .data_repository_binding
      ) {
        setTableDetails(
          chartData.configuration.snapshot[index].configuration.snapshot
            .data_repository_binding,
        )
      } else {
        setTableDetails({
          table_file_name:
            chartData.configuration.snapshot[index].configuration.snapshot
              .input_table,
          data_repository_id:
            chartData.configuration.snapshot[index].configuration.snapshot
              .source_repository,
          record_limit: 20,
        })
      }

      if (
        chartData.configuration.snapshot[index].configuration.snapshot
          .retain_columns_01
      ) {
        const xAxisArray: any = []
        chartData.configuration.snapshot[
          index
        ].configuration.snapshot.retain_columns_01.forEach((item: any) => {
          if (item && item !== null && item.display === true) {
            const tmpObj = {
              field: item.column_name,
              headerName: item.label,
              scaleType: 'band',
              dataKey: item.column_name,
              label:
                chartData.configuration.snapshot[index].configuration.snapshot
                  .series_label_01,
            }
            xAxisArray.push(tmpObj)
          }
        })
        setXAxis(xAxisArray)
      }

      if (
        chartData.configuration.snapshot[index].configuration.snapshot
          .retain_columns_02
      ) {
        const yAxisArray: any = []
        chartData.configuration.snapshot[
          index
        ].configuration.snapshot.retain_columns_02.forEach((item: any) => {
          if (item && item !== null && item.display === true) {
            const tmpObj = {
              field: item.column_name,
              headerName: item.label,
              dataKey: item.column_name,
              label: item.label,
            }
            yAxisArray.push(tmpObj)
          }
        })
        setYAxis(yAxisArray)
      }

      if (
        chartData.configuration.snapshot[index].configuration.snapshot
          .series_label_01
      ) {
        setXAxisLabel(
          chartData.configuration.snapshot[index].configuration.snapshot
            .series_label_01,
        )
      }
      if (
        chartData.configuration.snapshot[index].configuration.snapshot
          .series_label_02
      ) {
        setYAxisLabel(
          chartData.configuration.snapshot[index].configuration.snapshot
            .series_label_02,
        )
      }
    }
  }, [chartData])

  const convertStringNumbersToArrayNumbers = useCallback(
    (array: Product[]): Product[] => {
      return array.map((obj) => {
        const newObj: Product = {}
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key]
            newObj[key] = isNaN(Number(value)) ? value : Number(value)
          }
        }
        return newObj
      })
    },
    [],
  )

  const callGetAllColumnsApi = useCallback(
    async (tableDetails: any) => {
      try {
        setLoading?.(true)
        const resp = await ApiServices.getDetailsColumnData({
          data_repository_id: tableDetails?.data_repository_id,
          table_file_name: tableDetails?.table_file_name,
          record_limit: tableDetails?.record_limit,
        })
        setLoading(false)
        setTableRowData(
          convertStringNumbersToArrayNumbers(
            JSON.parse(resp.data.dataset_records).map(
              (item: any, index: number) => ({ ...item, id: index }),
            ),
          ),
        )
      } catch (error: any) {
        setLoading?.(false)
        console.log('error', error)
      }
    },
    [tableDetails],
  )

  useEffect(() => {
    if (tableDetails) {
      callGetAllColumnsApi(tableDetails)
    }
  }, [tableDetails])

  // const yAxes: any[] = [
  //   { field: 'value1', headerName: 'Value 1' },
  //   { field: 'value2', headerName: 'Value 2' },
  // ]

  //MUI Bar Chart Data
  const chartSetting = {
    xAxis: [
      {
        // label: 'rainfall (mm)',
        label: yAxisLabel,
      },
    ],
    width: 700,
    height:
      (tableRowData &&
        (tableRowData.length > 10
          ? tableRowData.length * 50
          : tableRowData.length * 100)) ||
      700,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-20px, 0)',
      },
    },
  }

  return (
    <div>
      <Box
        sx={{
          width: '100%',
          mb: '20px',
        }}
      >
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <Box>
            <Typography variant="h6">
              {chartData?.component_name
                ? `${chartData?.component_name} ${selectedChart}`
                : `${selectedChart}`}
            </Typography>
          </Box>
        </Box>
        {/* <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          <Box>
            <DropdownComponent
              sx={{ width: 361 }}
              size="small"
              label="Select Chart Type"
              value={selectedChartType}
              onChange={(e: any) => {
                setSelectedChartType(e.target.value)
              }}
            >
              {chartTypes?.length > 0 ? (
                chartTypes.map((item: any) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No Record Found</em>
                </MenuItem>
              )}
            </DropdownComponent>
          </Box>
        </Box> */}
      </Box>
      <Box sx={{ borderStyle: 'groove', padding: '10px' }}>
        {tableRowData && selectedChart === 'Bar Chart' && (
          <Box>
            {/* If You want to render D3 Chart this need to uncomment */}
            {/* <D3BarChart
              data={tableRowData}
              width={1000}
              height={1000}
              margin={margin}
              yAxes={yAxis}
              xAxisLabel={yAxisLabel}
              yAxisLabel={xAxisLabel}
              yAxisDataKey={xAxis[0].field}
            /> */}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <MuiBarChart
                chartSetting={chartSetting}
                dataset={tableRowData}
                series={yAxis}
                yAxis={xAxis}
              />
            </Box>
          </Box>
        )}

        {tableRowData && selectedChart === 'Pie Chart' && (
          <PieCharts
            data={tableRowData}
            fields={yAxis}
            dataName={xAxis[0].field}
            legendName={xAxisLabel}
          />
        )}
        {tableRowData && selectedChart === 'Scatter Chart' && (
          <MuiScatterChart
            chartSetting={chartSetting}
            dataset={tableRowData}
            series={yAxis}
            yAxis={xAxis}
          />
        )}
        {tableRowData && selectedChart === 'Line Chart' && (
          <MuiLineChart
            chartSetting={chartSetting}
            dataset={tableRowData}
            series={yAxis}
            yAxis={xAxis}
          />
        )}
      </Box>
    </div>
  )
}

export default ChartContainer
