/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { memo, useCallback, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material'

import SearchInputComponent from 'components/atoms/search-input'
import CardDetailsMolecule from 'components/molecules/card-details'

import { ApiServices } from 'api/services'
import LoaderComponent from 'components/atoms/loader-component'
import { useNavigate } from 'react-router-dom'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import { changeDateFormat } from 'utility/utility'
import NewProjectComponent from 'pages/home/new-project'
import NoRecordsFoundComponent from 'components/atoms/no-record'
import { getProfileInfo, removeProfileInfo, removeToken } from 'api/apiUtility'
import ProjectPagination from 'components/molecules/ProjectPagination'
import { ReactComponent as AppIcon } from 'assets/icons/application.svg'

const LandingAppPage = () => {
  const timer: any = React.useRef(null)

  const profileInfo = getProfileInfo()

  //Pagination
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)

  const [appRules, setAppRules] = useState<any>()
  const [isLoading, setLoading] = useState(true)
  const [recentAppsData, setRecentAppsData] = useState([])
  const [open, setOpen] = React.useState(false)
  const [reloadPage, setReloadPage] = useState<any>(false)
  const [searchInput, setSearchInput] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const profileInfoRoles = profileInfo
    if (profileInfoRoles && !appRules) {
      setAppRules(profileInfoRoles?.orgRole?.permissions?.apps)
    }
  }, [profileInfo])

  const handlePageChange = useCallback((params: any) => {
    setPage(params)
  }, [])

  const callRecentProjectDataApi = useCallback(async () => {
    const params = {
      pageNumber: page,
      pageSize: 12,
      search: {
        org_id: getProfileInfo().teamId,
        project_type: 'APP',
        search: searchInput,
      },
    }
    try {
      setLoading(true)

      const resp = await ApiServices.getProjectsAndApps(params)
      setLoading(false)
      setRecentAppsData(resp.data.data)
      setTotalCount(resp.data.totalRecords)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [page])

  useEffect(() => {
    callRecentProjectDataApi()
  }, [callRecentProjectDataApi, reloadPage, page])

  const onSearchInputChange = useCallback(async () => {
    const params = {
      pageNumber: page,
      pageSize: 12,
      search: {
        org_id: getProfileInfo().teamId,
        project_type: 'APP',
        search: searchInput,
      },
    }
    try {
      setLoading(true)

      const resp = await ApiServices.getProjectsAndApps(params)
      setLoading(false)
      setRecentAppsData(resp.data.data)
      setTotalCount(resp.data.totalRecords)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [searchInput, page])

  const inputHandler = useCallback(() => {
    onSearchInputChange()
  }, [searchInput, onSearchInputChange])

  const debounce: any =
    (func: () => void, timeout = 500) =>
    (...args: any) => {
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        func.apply(this, args)
      }, timeout)
    }

  const processChange = debounce(inputHandler)

  const navigateToGraphDashboard = useCallback(
    (id: string) => {
      navigate(
        `/${PAGE_ROUTE_CONSTANTS.APPS_PAGE}/${PAGE_ROUTE_CONSTANTS.APPS_REPORT_PAGE}?projectId=${id}`,
      )
    },
    [navigate],
  )

  const addNewProject = useCallback(
    async ({
      project_name,
      project_user,
      project_description,
      projectType,
    }: any) => {
      const payload: any = {
        data: {
          source: '10_org',
          project_type: 'APP',
          project_items: [
            {
              project_name,
              project_description,
              configuration: {},
              active: true,
              project_user_id: project_user,
            },
          ],
        },
      }
      try {
        setLoading(true)
        const res: any = ApiServices.addNewProject(payload)
        setLoading(false)
        callRecentProjectDataApi()
        setTimeout(() => {
          setReloadPage(Math.random())
        }, 1000)
        // console.log('res', res.data)
      } catch (error: any) {
        setLoading(false)
        console.log(error)
      }
    },
    [callRecentProjectDataApi],
  )

  return (
    <>
      <Box sx={{ margin: '16px' }}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Container
              sx={{
                '& .MuiContainer-root': {
                  paddingLeft: '0px',
                  paddingRight: '0px',
                },
                // width:'70%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '2%',
                }}
                // sx={{

                //   display: 'flex',
                //   alignItems: 'center',
                //   width: '100%',
                //   justifyContent: 'space-between',
                //   marginBottom: '1rem',
                // }}
              >
                <SearchInputComponent
                  value={searchInput}
                  onChange={(e: any) => setSearchInput(e.target.value)}
                  onKeyUp={processChange}
                />

                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpen(true)
                      }}
                      disabled={!appRules?.create}
                    >
                      New App
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Container>
          </Grid>
          {/* <Grid item sm={12}>
            <Container sx={{ mt: 2, paddingBottom: 2 }}>
            
             
            </Container>
          </Grid> */}
          <Grid item sm={12}>
            <Container sx={{ paddingBottom: 2 }}>
              <Container>
                <Box sx={{ mb: 2 }}>
                  <Typography
                    sx={{ fontWeight: 'bold' }}
                    variant="button"
                    gutterBottom
                  >
                    {`All Apps`}
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  {recentAppsData &&
                    recentAppsData.map((item: any) => {
                      return (
                        <Grid key={`recent_${item.sNo}`} item md={4} sm={6}>
                          <CardDetailsMolecule
                            label={`${item.project_name}`}
                            subLabel={`Last updated ${changeDateFormat(
                              item.last_updated_date,
                            )}`}
                            version="v2"
                            onClick={() => navigateToGraphDashboard(item.id)}
                            icon={<AppIcon />}
                            handleDetails={(e) => {
                              // details drawer
                            }}
                          ></CardDetailsMolecule>
                        </Grid>
                      )
                    })}
                  {recentAppsData && recentAppsData.length === 0 && (
                    <Box sx={{ pl: '15px', width: '100%' }}>
                      <NoRecordsFoundComponent />
                    </Box>
                  )}
                </Grid>
                {recentAppsData && recentAppsData.length > 0 && (
                  <Box sx={{ marginTop: '20px' }}>
                    <ProjectPagination
                      totalCount={totalCount}
                      onPageChange={handlePageChange}
                      page={page}
                    />
                  </Box>
                )}
                {/* <Divider sx={{ mt: 2 }} /> */}
              </Container>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <NewProjectComponent
        open={open}
        setOpen={setOpen}
        projectType="app"
        addNewProject={({
          project_name,
          project_user,
          project_description,
        }: any) =>
          addNewProject({ project_name, project_user, project_description })
        }
      />
      {isLoading && <LoaderComponent open={isLoading} />}
    </>
  )
}

export default memo(LandingAppPage)
