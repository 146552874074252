/* eslint-disable prettier/prettier */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import DataSourceHeader from 'components/templates/DataSourceHeader'
import DataSourceTable from 'components/DataSourceTable'
import { GridColDef } from '@mui/x-data-grid'
import { Button } from '@mui/material'
// import { Button, TableCell, Typography } from '@mui/material'
import { ContentCopy, RemoveRedEye } from '@mui/icons-material'
// import tableRows from 'api/DataQualityMetrics.json'
import DrawerComponent from 'components/atoms/drawer'
import AddDataMetrics from './AddDataMetrics'
import SnackbarComponent, {
  snackbarConfigTypes,
} from 'components/atoms/snackbar-component'
// import { CUSTOM_COLORS } from 'theme'
import { DQMetrics } from 'api/services'
import { Variant } from 'types/props-types'
import ViewDataMetrics from './ViewDataMetrics'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store/store'
import { setPagination } from 'store/dataSourceTypesSlice'
import { setPage } from 'store/dataSourceTypesSlice'
import { getProfileInfo } from 'api/apiUtility'

const DataQualityMetrics = () => {
  const dispatch = useDispatch<AppDispatch>()
  const dsState = useSelector((state: RootState) => state.dataSourceSlice)
  const profileInfo = getProfileInfo()

  const [repoRules, setRepoRules] = useState<any>()
  const [selectedRows, setSelectedRows] = useState<any>()
  const [showAddDrawerForm, setShowAddDrawerForm] = useState(false)
  const [reloadDSTable, setReloadDSTable] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [dqmStatus, setDqmStatus] = useState('LIVE')
  const [alertConfig, setAlertConfig] = useState<snackbarConfigTypes>({
    isOpen: false,
    type: 'success',
    autoHideDuration: 4000,
    message: '',
  })
  const [tableRows, setTableRows] = useState<any>([])
  const [showViewDQMForm, setShowDQMForm] = useState<{
    isVisible: boolean
    data: any
    type: 'edit' | 'view' | 'clone' | ''
  }>({
    isVisible: false,
    data: {},
    type: '',
  })

  const [totalRecords, setTotalRecords] = useState(0)
  const [page, setApiPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const [drawerKey, setDrawerKey] = useState(0)

  const dqColumns = useMemo(() => {
    const columns: GridColDef[] = [
      { field: 'dq_meterics_name', headerName: 'Alias', flex: 2 },
      {
        field: 'data_repository_name',
        headerName: 'Data Repository',
        flex: 2,
      },
      { field: 'dataset', headerName: 'Table/File', flex: 2 },
      {
        field: 'dq_metrics_profiling_function_type_label',
        headerName: 'Profiling Function Type',
        flex: 2,
      },
      {
        field: 'total_functions_applied',
        headerName: 'No. Function Applied',
        flex: 1,
      },
      {
        field: 'configuration',
        headerName: '',
        renderCell: (params) => {
          return (
            <Button
              sx={{
                color: '#42526E',
                padding: '5px !important',
                border: 'none !important',
                background: 'transparent !important',
              }}
              variant="text"
              startIcon={<RemoveRedEye />}
              onClick={(event) => handleRowViewClick(event, params)}
              disabled={!repoRules?.view_record}
            ></Button>
          )
        },
        flex: 1,
      },
      {
        field: 'clone',
        headerName: '',
        renderCell: (params) => {
          return (
            <Button
              sx={{
                color: '#42526E',
                padding: '5px !important',
                border: 'none !important',
                background: 'transparent !important',
              }}
              variant="text"
              startIcon={<ContentCopy />}
              onClick={(event) => handleRowCloneClick(event, params)}
              disabled={!repoRules?.clone}
            >
              {/* Clone */}
            </Button>
          )
        },
        flex: 1,
      },
    ]
    return columns
  }, [repoRules])

  useEffect(() => {
    const profileInfoRoles = profileInfo
    if (profileInfoRoles && !repoRules) {
      setRepoRules(profileInfoRoles?.orgRole?.permissions?.data_quality_metrics)
    }
  }, [profileInfo])

  useEffect(() => {
    setApiPage(dsState.paginationData.page)
  }, [dsState.paginationData.page])

  useEffect(() => {
    const newPage =
      Math.ceil(parseFloat(`${totalRecords / pageSize}`)) >= page ? page : 1
    setApiPage(newPage)
  }, [pageSize])

  useEffect(() => {
    DQMetrics.getAllDQMatrices({
      pageNumber: page,
      pageSize: pageSize,
      search: {
        dq_metrics_profiling_function_type: null,
        // search: '',
        status: dqmStatus,
        search: searchInput?.toLocaleLowerCase()?.toString(),
      },
    })
      .then((res: any) => {
        if (res?.data?.totalRecords) {
          const pagination = {
            totalRecords: res?.data?.totalRecords,
            pageSize: pageSize,
          }
          dispatch(setPagination(pagination))
        }
        setTableRows(res?.data?.data)
        setTotalRecords(res?.data?.totalRecords)
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
      })
  }, [reloadDSTable, page, pageSize])

  useEffect(() => {
    dispatch(setPage(1))
    setTableRows([])
    DQMetrics.getAllDQMatrices({
      pageNumber: 1,
      pageSize: pageSize,
      search: {
        dq_metrics_profiling_function_type: null,
        // search: '',
        status: dqmStatus,
        search: searchInput?.toLocaleLowerCase()?.toString(),
      },
    })
      .then((res: any) => {
        if (res?.data?.totalRecords) {
          const pagination = {
            totalRecords: res?.data?.totalRecords,
            pageSize: pageSize,
          }
          dispatch(setPagination(pagination))
        }
        setTableRows(res?.data?.data)
        setTotalRecords(res?.data?.totalRecords)
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
      })
  }, [dqmStatus])

  const handleAddButtonClick = useCallback(() => {
    setShowAddDrawerForm(!showAddDrawerForm)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setShowAddDrawerForm(false)
    setDrawerKey((prevKey) => prevKey + 1)
  }, [])

  const handleTableReload = useCallback(() => {
    setReloadDSTable(!reloadDSTable)
  }, [reloadDSTable])

  const handleShowAlert = useCallback(
    (msg: string, type: Variant = 'success') => {
      setAlertConfig({
        ...alertConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [alertConfig],
  )

  const handleRowViewClick = useCallback((event: any, data: any) => {
    event.stopPropagation()

    setShowDQMForm({ isVisible: true, data: data?.row, type: 'view' })
  }, [])

  const handleRowCloneClick = useCallback((event: any, data: any) => {
    event.stopPropagation()

    setShowDQMForm({ isVisible: true, data: data?.row, type: 'clone' })
  }, [])

  const handleCloseEditDrawer = useCallback(() => {
    setShowDQMForm({ isVisible: false, data: {}, type: '' })
    setDrawerKey((prevKey) => prevKey + 1)
  }, [])

  const handleDrawerHeading = useCallback((type: any) => {
    switch (type) {
      case 'edit':
        return 'Edit Data Quality Metrics'
      case 'view':
        return 'View Data Quality Metrics'
      case 'clone':
        return 'Clone Data Quality Metrics'
      default:
        return 'No Title'
    }
  }, [])

  const handleSearchQuery = useCallback(() => {
    dispatch(setPage(1))
    setTableRows([])
    DQMetrics.getAllDQMatrices({
      pageNumber: page,
      pageSize: pageSize,
      search: {
        dq_metrics_profiling_function_type: null,
        search: searchInput?.toLocaleLowerCase()?.toString(),
        status: dqmStatus,
      },
    })
      .then((res: any) => {
        if (res?.data?.totalRecords) {
          const pagination = {
            totalRecords: res?.data?.totalRecords,
            pageSize: pageSize,
          }
          dispatch(setPagination(pagination))
        }
        setTableRows(res?.data?.data)
        setTotalRecords(res?.data?.totalRecords)
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
      })
  }, [searchInput, dqmStatus])

  const handleRemoveDataSource = useCallback(() => {
    const dsIds = {
      ids: selectedRows,
    }
    if (dsIds.ids?.length > 0) {
      DQMetrics.disableDQMatrices(dsIds)
        .then((res: any) => {
          handleShowAlert(
            res?.data?.message || 'Data Quality Metrics Disabled Successfully',
          )
          handleTableReload()
        })
        .catch((error: any) => {
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        })
    }
  }, [selectedRows])

  const handleTablePageChange = useCallback(
    (params: any) => {
      setPageSize(params.pageSize)

      const pagination = {
        totalRecords: totalRecords,
        pageSize: params.pageSize,
      }
      dispatch(setPagination(pagination))
    },
    [totalRecords],
  )

  return (
    <Fragment>
      <DataSourceHeader
        heading="Data Quality Metrics"
        addButtonClick={handleAddButtonClick}
        removeButtonClick={handleRemoveDataSource}
        search={searchInput}
        setSearch={setSearchInput}
        handleSearchQuery={handleSearchQuery}
        setFilterStatus={setDqmStatus}
        userRoles={repoRules}
      />
      <DataSourceTable
        rows={tableRows}
        columns={dqColumns}
        getSelectedRows={setSelectedRows}
        handlePageChange={handleTablePageChange}
        totalRecords={totalRecords}
      />
      <DrawerComponent
        displayDrawer={showAddDrawerForm}
        width="424px"
        key={drawerKey}
      >
        <AddDataMetrics
          heading="Add Data Quality Metrics"
          onClose={handleCloseDrawer}
          triggerReload={handleTableReload}
          messageConfig={alertConfig}
          showMessage={setAlertConfig}
        />
      </DrawerComponent>
      {Object.keys(showViewDQMForm.data)?.length > 0 && (
        <DrawerComponent
          displayDrawer={showViewDQMForm.isVisible}
          width="424px"
          key={drawerKey}
        >
          <ViewDataMetrics
            heading={handleDrawerHeading(showViewDQMForm.type)}
            onClose={handleCloseEditDrawer}
            data={showViewDQMForm.data}
            triggerReload={handleTableReload}
            type={showViewDQMForm.type}
            messageConfig={alertConfig}
            showMessage={setAlertConfig}
          />
        </DrawerComponent>
      )}
      <SnackbarComponent
        open={alertConfig.isOpen}
        setOpen={setAlertConfig}
        type={alertConfig.type}
        autoHideDuration={alertConfig.autoHideDuration}
        message={alertConfig.message}
        alertConfig={alertConfig}
      />
    </Fragment>
  )
}

export default DataQualityMetrics
