import React from 'react'
import { Outlet } from 'react-router-dom'

const ProjectComponent = () => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default ProjectComponent
