/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
// import React from 'react'
// import './App.css'
// import RoutesPages from './routes/routespages'
// // import { Box } from '@mui/material'

// function App() {
//   return (
//     <>
//       {/* <Box>Testing </Box> */}
//       <RoutesPages />
//     </>
//   )
// }

// export default App
import React, { useState } from 'react';
import './App.css';
import RoutesPages from './routes/routespages';
// import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Switch, Container, Box, Typography } from '@mui/material';
import { lightTheme, darkTheme } from './theme'; // Adjust the import path as necessary
import { ThemeProvider } from 'ThemeContext';

function App() {


  return (
    <ThemeProvider >
      <CssBaseline />
      {/* <Container> */}
      
        <RoutesPages />
      {/* </Container> */}
    </ThemeProvider>
  );
}

export default App
