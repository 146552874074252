import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Typography,
} from '@mui/material'
import { DQMetrics } from 'api/services'
import Text from 'components/atoms/Text'
import DropdownComponent from 'components/atoms/dropdown'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { REGEX_EXPRESSION } from 'utility/constants'
import DynamicParams from '../components/DynamicParams'
import MultiDropdown from 'components/atoms/MultiDropdown'
import lodash from 'lodash'

interface AddDQFunProps {
  selectedProFun: string | any
  onClose: () => void
  metaData?: {
    dataRepository: string | any
    objTable: string | any
    profilingFun: string | any
  }
  showAlert?: any
  setDQFunData?: any
  type?: 'edit' | 'view' | 'clone' | ''
  editData?: any
  editDataIndex?: any
  dqmAllData?: any
  delimiter?: any
}

function AddDQMetricsFun(props: AddDQFunProps) {
  const {
    selectedProFun,
    onClose,
    metaData,
    showAlert,
    setDQFunData,
    type,
    editData,
    editDataIndex,
    dqmAllData,
    delimiter,
  } = props

  const dqmAllDataClone = useMemo(
    () => lodash.cloneDeep(dqmAllData),
    [dqmAllData],
  )
  // console.log('Edit Data =', editData)

  const [dQMetricsFunctions, setDQMetricsFunctions] = useState<any>([])
  const [selectedDQMetricsFun, setSelectedDQMetricsFun] = useState<any>('')

  const [dQMetricsFunctionsDetails, setDQMetricsFunctionsDetails] =
    useState<any>('')

  const [isPositionRequired, setIsPositionRequired] = useState<any>({})
  const [dqFunTypes, setDqFunTypes] = useState<any>([])
  const [selectedDqFunTypes, setSelectedDqFunTypes] = useState<Array<any>>([])
  const [dqParams, setDQParams] = useState<any>([])
  const [error, setError] = useState<any>({})

  //DropDown Pagination
  const [isDropDownLoading, setIsDropDownLoading] = useState(false)
  const [page, setPage] = useState(2)
  const [totalDQFunctions, setTotalDQFunctions] = useState(0)

  useEffect(() => {
    if (selectedProFun !== '') {
      DQMetrics.getDQMatricsFunctions({
        pageNumber: 1,
        pageSize: 10,
        funType: selectedProFun,
      })
        .then((res: any) => {
          setDQMetricsFunctions(res?.data?.data)
          setTotalDQFunctions(res?.data?.totalRecords)
        })
        .catch((error: any) => {
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          showAlert(errorMessage || 'Something Went Wrong', 'error')
        })
    }
  }, [selectedProFun])

  useEffect(() => {
    if (selectedDQMetricsFun && selectedDQMetricsFun !== '') {
      DQMetrics.getDQMatricsFunctionDetailsbyId({
        id: selectedDQMetricsFun,
      })
        .then((res: any) => {
          setDQMetricsFunctionsDetails(res?.data)
          if (res?.data?.params?.length > 0) {
            const params = res?.data?.params
            const updatedParams = params?.map((param: any, index: number) => ({
              ...param,
              value: type === 'clone' ? editData?.params?.[index]?.value : '',
              fieldType: 'TEXTINPUT',
            }))
            setDQParams(updatedParams)
            return res
          } else {
            setDQParams([])
            return res
          }
        })
        .then((res: any) => {
          if (res?.data?.max_column_allowed !== 0) {
            DQMetrics.getDQMatricsFunctionColumnsType({
              repositoryId: metaData?.dataRepository,
              objectTable: metaData?.objTable,
              profilingFun: metaData?.profilingFun,
              delimiter: delimiter !== '' ? delimiter : ',',
            })
              .then((res: any) => {
                setDqFunTypes(res?.data?.columns)
              })
              .catch((error: any) => {
                const errorMessage = error.response
                  ? error.response.data.message
                  : error.message
                showAlert(errorMessage, 'error')
              })
          }
        })
        .catch((error: any) => {
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          showAlert(errorMessage || 'Something Went Wrong', 'error')
        })
    }
  }, [selectedDQMetricsFun])

  useEffect(() => {
    if (type === 'clone') {
      setSelectedDQMetricsFun(editData?.id)
      setSelectedDqFunTypes(editData?.columns)
    }
  }, [])

  const handleIsPositionRequired = useCallback(
    (e: any, key: number) => {
      const inputValue = e.target.value

      if (inputValue === '' || REGEX_EXPRESSION.INTEGER.test(inputValue)) {
        const positionArray = { ...isPositionRequired }
        positionArray[key] = inputValue
        setIsPositionRequired(positionArray)
        setError({ ...error, position: '' })
      }
    },
    [isPositionRequired],
  )

  const handleRequiredFieldValidation = useCallback(() => {
    let isValidationOk = true

    if (selectedDQMetricsFun === '') {
      setError({ ...error, dmf: 'Please Select Data Metrics Function' })
      return false
    }

    if (dQMetricsFunctionsDetails?.max_column_allowed !== 0) {
      if (selectedDqFunTypes?.length === 0) {
        setError({
          ...error,
          dqfTypes: 'Please Select Data Metrics Function Types',
        })
        return false
      }
      if (
        selectedDqFunTypes?.length <
        dQMetricsFunctionsDetails?.min_column_allowed
      ) {
        setError({
          ...error,
          dqfTypes: `Please select at least ${dQMetricsFunctionsDetails?.min_column_allowed} ${
            dQMetricsFunctionsDetails?.min_column_allowed > 1
              ? 'fields'
              : 'field'
          }`,
        })
        return false
      }
    }

    if (dQMetricsFunctionsDetails?.is_positions_required === true) {
      if (
        Object.keys(isPositionRequired).length === selectedDqFunTypes?.length
      ) {
        const notAllValues = Object.keys(isPositionRequired).some(
          (item: any) => {
            if (isPositionRequired[item] === '') {
              return true
            }
          },
        )
        if (notAllValues) {
          setError({ ...error, position: 'This is Mandatory Field' })
          return false
        }
      } else {
        setError({ ...error, position: 'This is Mandatory Field' })
        return false
      }
    }

    dqParams?.some((item: any) => {
      if (item?.value === '') {
        if (item?.isRequired === true) {
          setError({
            ...error,
            [item?.key]: 'This is Mandatory Field',
          })
          isValidationOk = false
          return true
        }
      }
    })

    return isValidationOk
  }, [
    selectedDQMetricsFun,
    isPositionRequired,
    dqParams,
    dqFunTypes,
    selectedDqFunTypes,
    dQMetricsFunctionsDetails,
  ])

  const loadRecords = useCallback(() => {
    setIsDropDownLoading(true)
    setTimeout(() => {
      DQMetrics.getDQMatricsFunctions({
        pageNumber: page,
        pageSize: 10,
        funType: selectedProFun,
      })
        .then((res: any) => {
          setDQMetricsFunctions((prevRecords: any) => [
            ...prevRecords,
            ...res.data.data,
          ])
          setIsDropDownLoading(false)
        })
        .catch(() => {
          setIsDropDownLoading(false)
        })
      setPage((prevPage) => prevPage + 1)
    }, 1000) // Simulating delay
  }, [page])

  const handleLoadMore = useCallback(
    (event: any) => {
      event.stopPropagation()
      loadRecords()
    },
    [page, loadRecords],
  )

  const handleDropDownErrors = useCallback(
    (errorObj: any) => {
      if (typeof errorObj !== 'undefined') {
        if (errorObj !== '') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    [error],
  )

  const handleSaveData = useCallback(() => {
    const isValidationOk = handleRequiredFieldValidation()

    if (!isValidationOk) {
      return
    }

    const dqmFunName: any = dQMetricsFunctions.find(
      (item: any) => item?.id === selectedDQMetricsFun,
    )
    const data = {
      id: selectedDQMetricsFun,
      name: dqmFunName?.dq_metrics_fuction,
      columns: selectedDqFunTypes,
      params: dqParams,
      position: Object.values(isPositionRequired),
    }
    if (type !== 'clone') {
      setDQFunData((prevData: any) => [...prevData, data])
    } else {
      dqmAllDataClone[editDataIndex] = data
      setDQFunData(dqmAllDataClone)
    }
    onClose?.()
  }, [
    selectedDQMetricsFun,
    isPositionRequired,
    dqParams,
    dqFunTypes,
    selectedDqFunTypes,
    dQMetricsFunctionsDetails,
  ])

  return (
    <Box
      sx={{
        display: 'flex',
        width: '550px',
        height: '600px',
        flexDirection: 'column',
        alignItems: 'center',
        // padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
          alignItems: 'center',
          height: '30px',
          padding: '20px',
          paddingTop: '50px',
          marginBottom: '10px',
          position: 'sticky',
        }}
      >
        <Box sx={{ display: 'flex', flex: 2, justifyContent: 'center' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            Add DQ Metrics Functions
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '30px',
              height: '30px',
              background: 'red',
            }}
          >
            <Close
              sx={{
                color: 'white',
                cursor: 'pointer',
                border: '1px solid red',
              }}
              onClick={onClose}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          padding: '20px 20px 0px 20px',
        }}
      >
        <Box>
          <DropdownComponent
            sx={{ width: 361 }}
            size="small"
            label="Data Metrics Function"
            value={selectedDQMetricsFun}
            onChange={(e) => {
              setSelectedDQMetricsFun(e.target.value)
              setSelectedDqFunTypes([])
              setError({ ...error, dmf: '' })
            }}
            error={handleDropDownErrors(error?.dmf) || false}
            disabled={type === 'clone'}
          >
            {dQMetricsFunctions?.length > 0 ? (
              dQMetricsFunctions.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.dq_metrics_fuction}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>No Record Found</em>
              </MenuItem>
            )}
            {isDropDownLoading && (
              <MenuItem disabled>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress size={24} />
                </Box>
              </MenuItem>
            )}
            {!isDropDownLoading &&
              dQMetricsFunctions.length < totalDQFunctions && (
                <MenuItem disabled={isDropDownLoading}>
                  <Button
                    sx={{ width: '100%' }}
                    onClick={handleLoadMore}
                    variant="text"
                    color="primary"
                  >
                    Load More
                  </Button>
                </MenuItem>
              )}
          </DropdownComponent>
          <Typography
            sx={{
              fontSize: '0.75rem',
              margin: '4px 0px 0px 14px',
              fontWeight: '400',
              color: '#d32f2f',
            }}
          >
            {error?.dmf}
          </Typography>
        </Box>
        {dQMetricsFunctionsDetails !== '' && (
          <Box sx={{ width: 361 }}>
            <Typography sx={{ fontWeight: '600' }}>Function Details</Typography>{' '}
            <Typography>
              {dQMetricsFunctionsDetails?.dq_Metrics_description}
            </Typography>
          </Box>
        )}

        {dQMetricsFunctionsDetails !== '' &&
          dQMetricsFunctionsDetails?.max_column_allowed !== 0 && (
            <Box>
              <MultiDropdown
                data={dqFunTypes}
                label="Data Metrics Fields *"
                onChange={setSelectedDqFunTypes}
                value={selectedDqFunTypes}
                name={'dqfTypes'}
                error={handleDropDownErrors(error?.dqfTypes) || false}
                setError={setError}
                max_length={dQMetricsFunctionsDetails?.max_column_allowed}
                min_length={dQMetricsFunctionsDetails?.min_column_allowed}
                helperText={`Please select at least ${
                  dQMetricsFunctionsDetails?.min_column_allowed || 1
                } field, more than ${dQMetricsFunctionsDetails?.max_column_allowed} field not allowed`}
              />
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  margin: '4px 0px 0px 14px',
                  fontWeight: '400',
                  color: '#d32f2f',
                }}
              >
                {error?.dqfTypes}
              </Typography>
            </Box>
          )}

        {dQMetricsFunctionsDetails?.is_positions_required === true && (
          <Box>
            {selectedDqFunTypes.length > 0 &&
              selectedDqFunTypes.map((item: any) => (
                <Text
                  key={item}
                  value={isPositionRequired[item]}
                  onChange={(e: any) => handleIsPositionRequired(e, item)}
                  label={`Enter Position (Only Integer) for ${item}`}
                  name="position"
                  error={error}
                />
              ))}
          </Box>
        )}
        {dqParams.length > 0 && (
          <DynamicParams
            formData={dqParams}
            handleChange={setDQParams}
            error={error}
            setError={setError}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            justifyContent: 'flex-end',
            paddingBottom: '20px',
          }}
        >
          <Button
            variant="contained"
            onClick={handleSaveData}
            // disabled={isSaveLoading || !isConnectionTested}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AddDQMetricsFun
