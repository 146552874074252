import { Snackbar } from '@mui/material'
import React, { useCallback } from 'react'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { Snackbar_Props, Variant } from 'types/props-types'

export type snackbarConfigTypes = {
  isOpen: boolean
  type: Variant
  autoHideDuration: number
  message: string
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  },
)

const SnackbarComponent = (props: Snackbar_Props) => {
  const {
    setOpen,
    type = 'success',
    autoHideDuration = 4000,
    message,
    alertConfig,
  } = props
  const handleClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return
      }

      setOpen({ ...alertConfig, isOpen: false })
    },
    [alertConfig, setOpen],
  )
  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      {...props}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default SnackbarComponent
