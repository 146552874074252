/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiServices } from 'api/services'
import React, { useCallback, useEffect, useState } from 'react'
import ChartTable from 'components/components/ChartTable'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { environment } from 'environments/environment'
import { removeProfileInfo, removeToken } from 'api/apiUtility'

// import DataSourceTable from 'components/DataSourceTable'

interface TableChartComponentProps {
  chartData?: any
  setLoading?: any
  tableName?: any
  uid?: string
}

function OperationDetailsTableComponent(props: TableChartComponentProps) {
  const { chartData, setLoading, tableName, uid } = props

  const [tableDetails, setTableDetails] = useState<any>()
  const [tableColumns, setTableColumns] = useState<any>([])
  const [tableRowData, setTableRowData] = useState<any>()
  const navigate = useNavigate()
  const [componentId, setComponentId] = useState<string>()
  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams) {
      //It need for future use
      if (uid) {
        setComponentId(uid)
      } else {
        const project_Id = searchParams.get('componentId')
        project_Id && setComponentId(project_Id)
      }
    }
  }, [searchParams, chartData])

  useEffect(() => {
    if (chartData) {
      setTableDetails({
        table_file_name: chartData.configuration.snapshot.input_table,
        data_repository_id: chartData.configuration.snapshot.source_repository,
        record_limit: 20,
      })
    } else {
      const table = {
        data_repository_id: environment.JOB_EXECUTION_REPOSITORY,
        table_file_name: 'jobexecutiondetails',
        record_limit: 20,
      }
      setTableDetails(table)
    }
    if (
      chartData &&
      chartData.configuration.snapshot.retain_columns &&
      chartData.configuration.snapshot.retain_columns.length > 0
    ) {
      const columns: any = []
      const dataColumns = chartData.configuration.snapshot.retain_columns
      dataColumns.map((col: any) => {
        if (col.display) {
          const colData = {
            field: col.column_name,
            headerName: col.label,
            width: 150,
          }
          columns.push(colData)
        }
      })
      setTableColumns(columns)
    }
  }, [chartData])

  useEffect(() => {
    if (
      (!chartData ||
        !chartData.configuration.snapshot.retain_columns ||
        chartData.configuration.snapshot.retain_columns.length === 0) &&
      tableRowData &&
      tableRowData.length &&
      tableRowData?.length > 0
    ) {
      const columns: any = []
      Object.keys(tableRowData[0]).map((item: any) => {
        const colData = {
          field: item,
          headerName: item,
          width: 150,
        }
        columns.push(colData)
      })
      setTableColumns(columns)
    }
  }, [tableRowData, chartData])

  const callGetAllColumnsApi = useCallback(
    async (tableDetails: any) => {
      try {
        setLoading?.(true)
        const resp = await ApiServices.getDetailsColumnData({
          data_repository_id: tableDetails?.data_repository_id,
          table_file_name: tableDetails?.table_file_name,
          record_limit: tableDetails?.record_limit,
          componentId: componentId,
        })
        setLoading(false)
        setTableRowData(
          JSON.parse(resp.data.dataset_records).map(
            (item: any, index: number) => ({ ...item, id: index }),
          ),
        )
      } catch (error: any) {
        setLoading?.(false)
        console.log('error', error)
      }
    },
    [tableDetails, componentId, setLoading],
  )

  useEffect(() => {
    if (tableDetails) {
      callGetAllColumnsApi(tableDetails)
    }
  }, [tableDetails])

  return (
    <div>
      {tableColumns && (
        <Box>
          <Box>
            {tableName && (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'left',
                }}
              >
                <Typography
                  variant="button"
                  gutterBottom
                  sx={{ fontWeight: 'bold', fontSize: 18 }}
                >
                  {tableName}
                </Typography>
              </Box>
            )}
          </Box>
          <ChartTable columns={tableColumns} rows={tableRowData} />
        </Box>
      )}
    </div>
  )
}

export default OperationDetailsTableComponent
