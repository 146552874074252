import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
  MenuItem,
} from '@mui/material'
import { Search, Add } from '@mui/icons-material'
import { RolePermissionHeaderProps } from 'types/props-types'
import { CUSTOM_COLORS } from 'theme'
import DropdownComponent from 'components/atoms/dropdown'
import { UserStatus } from 'utility/constants'
const getQueryParam = (param: string) => {
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.get(param)
}
const UserManagementHeader = ({
  heading,
  addButtonClick,
  search,
  setSearch,
  handleSearchQuery,
  setRoleStatus,
  userRoles,
}: RolePermissionHeaderProps) => {
  const timer: any = React.useRef(null)

  const [userRoleStatuses, setUserRoleStatuses] = useState<any>([])
  const id = getQueryParam('id')
  const [selectedRoleStatus, setSelectedRoleStatus] = useState(
    id ? 'SHOW_ALL' : 'LIVE',
  )
  useEffect(() => {
    setUserRoleStatuses(UserStatus)
  }, [])

  const debounce: any =
    (func: () => void, timeout = 500) =>
    (...args: any) => {
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        func.apply(this, args)
      }, timeout)
    }

  const inputHandler = useCallback(() => {
    handleSearchQuery?.()
  }, [search])

  const processChange = debounce(inputHandler)

  const handleRoleStatusChange = useCallback((e: any) => {
    setSelectedRoleStatus(e.target.value)
    setRoleStatus?.(e.target.value)
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', width: '100%', marginTop: '37px' }}>
        <Typography
          sx={{ fontWeight: '500', fontSize: '24px', lineHeight: '28px' }}
        >
          {heading}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', marginTop: '37px' }}>
        <OutlinedInput
          sx={{ height: '34px' }}
          id="search"
          size="small"
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          onKeyUp={processChange}
          value={search}
          onChange={(e: any) => setSearch?.(e.target.value)}
        />
        <Box sx={{ display: 'flex', gap: '20px', marginLeft: '40px' }}>
          {/* <Button
            sx={{ color: '#42526E' }}
            variant="text"
            startIcon={<FilterList />}
          >
            Filter
          </Button> */}
          <Box>
            <DropdownComponent
              sx={{ width: 150 }}
              size="small"
              label="User Status"
              value={selectedRoleStatus}
              onChange={(e) => handleRoleStatusChange(e)}
            >
              {userRoleStatuses?.length > 0 ? (
                userRoleStatuses.map((item: any) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No Record Found</em>
                </MenuItem>
              )}
            </DropdownComponent>
          </Box>
          <Button
            sx={{
              color: CUSTOM_COLORS.backgroundColor,
              textTransform: 'none',
              // background: CUSTOM_COLORS.tableRowBgLightGray,
            }}
            variant="text"
            startIcon={<Add />}
            onClick={addButtonClick}
            disabled={!userRoles?.create && !id}
          >
            Add
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default UserManagementHeader
