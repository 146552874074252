import { TOKEN_INFINITIX, PROFILEINFO } from 'utility/constants'

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_INFINITIX, token)
}

export const getToken = () => {
  return localStorage.getItem(TOKEN_INFINITIX)
}

export const removeToken = () => {
  return localStorage.removeItem(TOKEN_INFINITIX)
}

export const setProfileInfo = (profile: any) => {
  localStorage.setItem(PROFILEINFO, JSON.stringify(profile))
}

export const getProfileInfo = () => {
  return JSON.parse(localStorage.getItem(PROFILEINFO)!)
}

export const removeProfileInfo = () => {
  return localStorage.removeItem(PROFILEINFO)
}

export function getHeadersWithToken() {
  const token = getToken()

  const headers: any = {
    'Content-Type': 'application/json',
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  return headers
}
