import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { environment } from 'environments/environment'
import { setDataRepo } from 'store/dataSourceTypesSlice'

export const getAllDataSourceTypes = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.get(
      `/data_repository_types/?pageNumber=${projectContent.page}&pageSize=${projectContent.size}&status=ACTIVE`,
    )

    const data: any = response.data
    return data
  },
)

export const getDataSourceTypesById = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.get(
      `/data_repository_types/configuration?id=${projectContent.id}`,
    )

    const data: any = response.data
    return data
  },
)

export const getAllDataSource = createAsyncThunk(
  'project',
  async (projectContent: any, { dispatch }) => {
    const response: any = await axiosInstance.post(
      `/data_repositories/_search?pageNumber=${projectContent.page}&pageSize=${projectContent.size}`,
      projectContent.search,
    )

    const data: any = response.data
    dispatch(setDataRepo(data))
    return data
  },
)

export const searchDataSources = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.post(
      `/data_repositories/_search?pageNumber=${projectContent.page}&pageSize=${projectContent.size}`,
      projectContent.search,
    )

    const data: any = response.data
    return data
  },
)

export const addEditDataSource = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.post(
      `/data_repositories/`,
      projectContent,
    )

    const data: any = JSON.parse(JSON.stringify(response))
    return data
  },
)

export const removeSelectedDataSource = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.patch(
      `/data_repositories/disabled`,
      projectContent,
    )

    const data: any = JSON.parse(JSON.stringify(response))
    return data
  },
)

export const testDataSourceConnection = async (projectContent: any) => {
  const response: any = await axiosInstance.post(
    `${environment.TEST_CONNECTION}/data_repository_status/`,
    projectContent,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const getStatusFilterTypes = async () => {
  const response: any = await axiosInstance.get(`/status_filters`)

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

/** Get Data Repository File Explorer Details */
export const get_data_repository_file_explorer = (payload: any) => {
  return axiosInstance.post(
    `${environment.loadBalancerPython}/data_repository_metadata/file_explorer/`,
    payload,
  )
}

/**  Get File Pre-Signed URL */
export const get_file_pre_signed_url = (payload: any) => {
  return axiosInstance.post(
    `${environment.loadBalancerPython}/data_repository_metadata/file_pre_signed_url`,
    payload,
  )
}

/** Get Blob Data */
export const get_file_blob_data = (payload: any, responseType?: any) => {
  if (responseType) {
    return axiosInstance.get(
      `${environment.loadBalancerPython}/data_repository_metadata/${payload.data_repository_id}/file?file_name=${payload.file_name}`,
      { responseType: 'arraybuffer' },
    )
  } else {
    return axiosInstance.get(
      `${environment.loadBalancerPython}/data_repository_metadata/${payload.data_repository_id}/file?file_name=${payload.file_name}`,
    )
  }
}
