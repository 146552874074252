// /* eslint-disable prettier/prettier */
// import React, { useState } from 'react'
// import { Box, Grid, Typography } from '@mui/material'
// import { SIGN_IN } from 'utility/constants'
// // import LandingImage from 'components/templates/landingImage'
// import SignIn from './signin'
// import SignUp from './signup'
// import logo from 'assets/img/Infinitix_logo192.png'
// const CreateAccount = () => {
//   const [showSignInSignUp, setShowSignInSignUp] = useState(SIGN_IN)
//   return (
//     <>
//       <Grid container>
//         {/* <Grid
//           item
//           md={7}
//           xs={12}
//           sx={{
//             height: '100vh',
//           }}
//         >
//           <LandingImage />
//         </Grid> */}
//         <Grid item md={5} xs={12}>
//           <Box
//             sx={{
//               height: '100%',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               flexDirection: 'column',
//               // marginLeft: '8rem',
//             }}
//           >
// <Box
//   sx={{
//     display: 'flex',
//     gap: '10px',
//     alignItems: 'center',
//     marginBottom: '30px',
//     flexDirection: 'row',
//   }}
// >
//   <img
//     src={logo}
//     alt="logo"
//     width={50}
//     height={50}
//     style={{ borderRadius: '5px' }}
//   />
//   <Box>
//     <Typography
//       variant="subtitle2"
//       noWrap
//       sx={{ fontSize: '30px', fontWeight: 600, color: 'black' }}
//     >
//       InfinitiX
//     </Typography>
//     <Typography
//       sx={{ fontSize: '20px', fontWeight: 400, color: 'black' }}
//     >
//       AI Platform
//     </Typography>
//   </Box>
// </Box>
//             <>
//               <Typography variant="h5" gutterBottom>
//                 Enter Your Information
//               </Typography>
//             </>
//             <Box sx={{ width: '80%' }}>
//               {showSignInSignUp === SIGN_IN ? (
//                 <SignIn changePage={setShowSignInSignUp} />
//               ) : (
//                 <SignUp changePage={setShowSignInSignUp} />
//               )}
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>
//     </>
//   )
// }

// export default CreateAccount
/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { SIGN_IN } from 'utility/constants'
import LandingImage from 'components/templates/landingImage'
import SignIn from './signin'
import SignUp from './signup'
import logo from 'assets/img/Infinitix_logo192.png'

const LoginAccount = () => {
  const [showSignInSignUp, setShowSignInSignUp] = useState(SIGN_IN)
  return (
    <>
      <Grid container>
        <Grid
          item
          md={7}
          xs={12}
          sx={{
            height: '100vh',
            background: '#fff',
          }}
        >
          <LandingImage />
        </Grid>
        <Grid item md={5} xs={12} sx={{ background: '#FFF' }}>
          <Box
            sx={{
              height: '100vh', // Make sure this takes full height for centering
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'left',
              flexDirection: 'column',
              background: '#FFF',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'left',
                marginBottom: '60px',
                flexDirection: 'row',
                justifyContent: 'left', // Center horizontally
                background: '#FFF',
              }}
            >
              <img
                src={logo}
                alt="logo"
                width={50}
                height={50}
                style={{ borderRadius: '5px' }}
              />
              <Box>
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{
                    fontSize: '26px',
                    fontWeight: 700,
                    color: 'black',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  InfinitiX
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontWeight: 550,
                      color: 'black',
                      marginLeft: '10px',
                      marginTop: '10px',
                    }}
                  >
                    AI Platform
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: 'black',
                    marginLeft: '2px',
                  }}
                >
                  Build 2x faster
                </Typography>
              </Box>
            </Box>

            <>
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: '500',
                  marginBottom: '30px',
                }}
                gutterBottom
              >
                Enter Your Information
              </Typography>
            </>
            <Box sx={{ width: '80%', background: '#FFF' }}>
              {showSignInSignUp === SIGN_IN ? (
                <SignIn />
              ) : (
                <SignUp changePage={setShowSignInSignUp} />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default LoginAccount
