/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import styled from '@emotion/styled'
import { Card } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useTheme } from 'ThemeContext'


const CardComponent = (props: any) => {
  const { isDarkMode, handleThemeChange } = useTheme();
  const StyledCard = styled(Card)(() => ({
    height: 150,
    padding: 5,
    background:isDarkMode? '#1E1E1E':'#fff',
    // backgroundImage: `url('../../Infinitix_logo192.png')`,
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center',
    // backgroundSize: '50px 50px',
    // border:'2px solid white'
  
  }))
  const { children } = props
  return <StyledCard {...props}>{children}</StyledCard>
}

export default CardComponent
