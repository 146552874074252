/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import DataSourceTable from 'components/DataSourceTable'
import { GridColDef } from '@mui/x-data-grid'
import { Box, Button, Chip, Stack, Switch } from '@mui/material'
import { Edit, RemoveRedEye, Reply } from '@mui/icons-material'
// import rowsData from 'api/DataSource.json'
import AddUser from './AddUser'
import DrawerComponent from 'components/atoms/drawer'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store/store'
import { Admin } from 'api/services'
import SnackbarComponent, {
  snackbarConfigTypes,
} from 'components/atoms/snackbar-component'
import { Variant } from 'types/props-types'
import { setPagination } from 'store/dataSourceTypesSlice'

import { setPage } from 'store/dataSourceTypesSlice'
import UserRoleHeader from './UserManagementHeader'
import EditProjUserRole from './EditUser'
import { getProfileInfo } from 'api/apiUtility'
const getQueryParam = (param: string) => {
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.get(param)
}
const UserManagement = () => {
  const dispatch = useDispatch<AppDispatch>()
  const profileInfo = getProfileInfo()

  const [userRoles, setUserRoles] = useState<any>()
  const id = getQueryParam('id')
  const [showAddDrawerForm, setShowAddDrawerForm] = useState(false)
  const [reloadDSTable, setReloadDSTable] = useState<any>(false)
  const [showEditDSForm, setShowEditDSForm] = useState<{
    isVisible: boolean
    data: any
    type: 'edit' | 'view' | 'delete' | ''
  }>({
    isVisible: false,
    data: {},
    type: '',
  })

  const [searchInput, setSearchInput] = useState('')
  const [alertConfig, setAlertConfig] = useState<snackbarConfigTypes>({
    isOpen: false,
    type: 'success',
    autoHideDuration: 4000,
    message: '',
  })
  const [selectedRows, setSelectedRows] = useState<any>()
  const [roleStatus, setRoleStatus] = useState(id ? 'SHOW_ALL' : 'LIVE')

  const [totalRecords, setTotalRecords] = useState(0)
  const [page, setApiPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const [drawerKey, setDrawerKey] = useState(0)

  const dsState = useSelector((state: RootState) => state.dataSourceSlice)

  const [tableRows, setTableRows] = useState([])
  // const [liveStatus, setLiveStatus] = useState(true)

  const dsColumns = useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: 'first_name',
        headerName: 'First Name',
        flex: 1.5,
      },
      {
        field: 'last_name',
        headerName: 'Last Name',
        flex: 1.5,
      },
      {
        field: 'user_id',
        headerName: 'Email',
        flex: 1.5,
      },
      {
        field: 'delete',
        headerName: 'Status',
        renderCell: (params) => {
          return (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              minWidth={'182px'}
              sx={{ display: '-webkit-box' }}
            >
              {params.row.status === 'PENDING' ? (
                <Chip
                  label={params.row.status}
                  sx={{
                    backgroundColor: '#f8d7da', // Red background
                    color: '#721c24', // Red text
                    borderRadius: '4px',
                    fontSize: '14px',

                    // justifyContent:'center',
                    // margin:'auto !important',
                    fontWeight: '600',
                  }}
                />
              ) : (
                <Switch
                  checked={params.row.status === 'LIVE'}
                  onChange={(e: any) => {
                    handleDisableUser({
                      userId: params.row._id,
                      status: e.target.checked ? 'LIVE' : 'DISABLED',
                    })
                  }}
                  disabled={!userRoles?.status_update && !id}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="primary"
                />
              )}
            </Stack>
          )
        },
        flex: 1.5,
      },
      {
        field: 'configuration',
        headerName: 'Configuration',
        renderCell: (params) => {
          return (
            <Button
              sx={{ color: '#42526E', textTransform: 'none' ,padding: '5px !important',
                border: 'none !important',
                background: 'transparent !important' }}
              variant="text"
              startIcon={<RemoveRedEye />}
              onClick={(event) => handleRowViewClick(event, params)}
              disabled={!userRoles?.view_configuration && !id}
            >
              {/* Configuration */}
            </Button>
          )
        },
        flex: 1,
      },

      {
        field: 'edit',
        headerName: 'Edit',
        renderCell: (params) => {
          return (
            <Button
              sx={{ color: '#42526E', textTransform: 'none',padding: '5px !important',
                border: 'none !important',
                background: 'transparent !important' }}
              variant="text"
              startIcon={<Edit />}
              onClick={(event) => handleRowEditClick(event, params)}
              disabled={!userRoles?.edit && !id}
            >
              {/* Edit */}
            </Button>
          )
        },

        flex: 1,
      },
      {
        field: 'resend',
        headerName: 'Resend Invitation',
        renderCell: (params) => {
          return (
            <Box
              sx={{
                position: 'relative',
                visibility: 'visible',
                // '&:hover .resend-button': {
                //   visibility: 'visible',
                // },
              }}
            >
              <Button
                className="resend-button"
                sx={{
                  color: '#42526E',
                  textTransform: 'none',padding: '5px !important',
                  border: 'none !important',
                  background: 'transparent !important'
                  // visibility: 'hidden', // Initially hidden
                }}
                variant="text"
                startIcon={<Reply />}
                onClick={(event) => handleReInviteClick(event, params)}
                disabled={
                  params.row?.status !== 'PENDING' &&
                  !userRoles?.reinvite &&
                  !id
                }
              ></Button>
            </Box>
          )
        },

        flex: 1,
      },
    ]
    return columns
  }, [userRoles])

  useEffect(() => {
    const profileInfoRoles = profileInfo
    if (profileInfoRoles && !userRoles) {
      setUserRoles(profileInfoRoles?.orgRole?.permissions?.users)
    }
  }, [profileInfo, userRoles])

  useEffect(() => {
    setApiPage(dsState.paginationData.page)
  }, [dsState.paginationData.page])

  useEffect(() => {
    const newPage =
      Math.ceil(parseFloat(`${totalRecords / pageSize}`)) >= page ? page : 1
    setApiPage(newPage)
  }, [pageSize])

  const handleTableReload = useCallback(() => {
    setReloadDSTable(!reloadDSTable)
  }, [reloadDSTable])

  const handleDisableUser = useCallback((data: any) => {
    const params = {
      user_id: data.userId,
      status: data.status,
    }

    Admin.handleStatusOfUser(params)
      .then((res: any) => {
        if (res?.status === 200) {
          setReloadDSTable(Math.random())
          handleShowAlert(
            res?.data?.msg ? res.data.msg : 'User Status Updated Successfully',
          )
        } else {
          setReloadDSTable(Math.random())
          handleShowAlert(
            res?.error?.message ? res?.error?.message : 'Something Went Wrong',
            'error',
          )
        }
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
      })
  }, [])

  useEffect(() => {
    setTableRows([])
    if (id) {
      dispatch(
        Admin.getAllRegisteredUsers({
          search: searchInput,
          is_fetch_all: true,
          status: roleStatus,
          orgId: id,
        }),
      )
        .then((res: any) => {
          const users = res?.payload?.data?.map((user: any) => ({
            ...user,
            id: user._id,
          }))
          setTableRows(users)
          setTotalRecords(res?.payload?.totalRecords)
        })
        .catch((error) => {
          if (error.message) {
            handleShowAlert(error.message, 'error')
          } else {
            handleShowAlert('Something went wrong', 'error')
          }
        })
    } else {
      dispatch(
        Admin.getAllRegisteredUsers({
          search: searchInput,
          is_fetch_all: false,
          status: roleStatus,
        }),
      )
        .then((res: any) => {
          const users = res?.payload?.data?.map((user: any) => ({
            ...user,
            id: user._id,
          }))
          setTableRows(users)
          setTotalRecords(res?.payload?.totalRecords)
        })
        .catch((error) => {
          if (error.message) {
            handleShowAlert(error.message, 'error')
          } else {
            handleShowAlert('Something went wrong', 'error')
          }
        })
    }
  }, [reloadDSTable, roleStatus])

  const handleSearchQuery = useCallback(() => {
    dispatch(setPage(1))
    setTableRows([])
    dispatch(
      Admin.getAllRegisteredUsers({
        search: searchInput,
        is_fetch_all: true,
        status: roleStatus,
      }),
    )
      .then((res: any) => {
        const users = res?.payload?.data?.map((user: any) => ({
          ...user,
          id: user._id,
        }))
        setTableRows(users)
        setTotalRecords(res?.payload?.totalRecords)
      })
      .catch((error) => {
        if (error.message) {
          handleShowAlert(error.message, 'error')
        } else {
          handleShowAlert('Something went wrong', 'error')
        }
      })
  }, [searchInput, roleStatus])

  const handleAddButtonClick = useCallback(() => {
    setShowAddDrawerForm(!showAddDrawerForm)
  }, [])

  const handleRowViewClick = useCallback((event: any, data: any) => {
    event.stopPropagation()

    setShowEditDSForm({ isVisible: true, data: data?.row, type: 'view' })
  }, [])

  const handleRowEditClick = useCallback((event: any, data: any) => {
    event.stopPropagation()

    setShowEditDSForm({ isVisible: true, data: data?.row, type: 'edit' })
  }, [])

  const handleReInviteClick = useCallback((event: any, data: any) => {
    event.stopPropagation()

    Admin.reInviteUser({ username: data.row?.user_id })
      .then((resp: any) => {
        if (resp?.status === 200) {
          handleShowAlert('Invitation sent successfully')
        }
      })
      .catch((error: any) => {
        console.log('reInvite Error=', error)
      })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setShowAddDrawerForm(false)
    setDrawerKey((prevKey) => prevKey + 1)
  }, [])

  const handleCloseEditDrawer = useCallback(() => {
    setShowEditDSForm({ isVisible: false, data: {}, type: '' })
    setDrawerKey((prevKey) => prevKey + 1)
  }, [])

  const handleShowAlert = useCallback(
    (msg: string, type: Variant = 'success') => {
      setAlertConfig({
        ...alertConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [alertConfig],
  )

  const handleRemoveDataSource = useCallback(() => {
    const dsIds = {
      ids: selectedRows,
    }
    if (dsIds.ids?.length > 0) {
      Admin.removeSelectedUserRole(dsIds)
        .then((res: any) => {
          if (res?.status === 200) {
            handleTableReload()
            handleShowAlert('Role Permissions disabled Successfully')
          } else {
            handleTableReload()
            handleShowAlert('Something Went Wrong', 'error')
          }
        })
        .catch((error: any) => {
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        })
    }
  }, [selectedRows])

  const handleTablePageChange = useCallback(
    (params: any) => {
      setPageSize(params.pageSize)

      const pagination = {
        totalRecords: totalRecords,
        pageSize: params.pageSize,
      }
      dispatch(setPagination(pagination))
    },
    [totalRecords],
  )

  const handleDrawerHeading = useCallback((type: any) => {
    switch (type) {
      case 'edit':
        return 'Edit Users'
      case 'view':
        return 'View Users'
      case 'clone':
        return 'Clone Users'
      case 'delete':
        return 'Delete Users'
      default:
        return 'No Title'
    }
  }, [])

  // useEffect(()=>{

  // },[])
  console.log(id)

  return (
    <Fragment>
      <UserRoleHeader
        heading="Users"
        addButtonClick={handleAddButtonClick}
        removeButtonClick={handleRemoveDataSource}
        search={searchInput}
        setSearch={setSearchInput}
        handleSearchQuery={handleSearchQuery}
        userRoles={userRoles}
        setRoleStatus={setRoleStatus}
      />
      <DataSourceTable
        rows={tableRows}
        columns={dsColumns}
        getSelectedRows={setSelectedRows}
        handlePageChange={handleTablePageChange}
        totalRecords={totalRecords}
        checkboxSelection={false}
        // disableRowSelectionOnClick={true}
      />
      <DrawerComponent
        key={drawerKey}
        displayDrawer={showAddDrawerForm}
        width="424px"
      >
        <AddUser
          heading="Add Users"
          onClose={handleCloseDrawer}
          triggerReload={handleTableReload}
          messageConfig={alertConfig}
          showMessage={setAlertConfig}
        />
      </DrawerComponent>
      {Object.keys(showEditDSForm.data).length > 0 && (
        <DrawerComponent
          key={drawerKey}
          displayDrawer={showEditDSForm.isVisible}
          width="424px"
        >
          <EditProjUserRole
            heading={handleDrawerHeading(showEditDSForm.type)}
            onClose={handleCloseEditDrawer}
            data={showEditDSForm.data}
            triggerReload={handleTableReload}
            type={showEditDSForm.type}
            messageConfig={alertConfig}
            showMessage={setAlertConfig}
          />
        </DrawerComponent>
      )}
      <SnackbarComponent
        open={alertConfig.isOpen}
        setOpen={setAlertConfig}
        type={alertConfig.type}
        autoHideDuration={alertConfig.autoHideDuration}
        message={alertConfig.message}
        alertConfig={alertConfig}
      />
    </Fragment>
  )
}

export default UserManagement
