/* eslint-disable react/jsx-no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  styled,
  Typography,
} from '@mui/material'
import CardDetailsMolecule from 'components/molecules/card-details'
import React, { useCallback, useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import { ApiServices } from 'api/services'
import LoaderComponent from 'components/atoms/loader-component'
import { changeDateFormat } from 'utility/utility'
import SmartToyIcon from 'assets/img/boticon1.png'
import NoRecordsFoundComponent from 'components/atoms/no-record'
// import ChatWindow from './ChatWindow'
import Chatbot from './Chatbot'
import { setProjectName } from 'store/breadcrumbSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store/store'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
// import BotIcon from 'assets/icons/Chatbot.svg'
// import { setProjectName } from 'store/breadcrumbSlice'
const BotIconButton = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: 'background: var(--secondary-main, rgba(128, 203, 196, 1))',
  borderRadius: '50%',
  cursor: 'pointer',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#1a2a4b',
  },
}))

const ReportsAppPage = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [isLoading, setLoading] = useState(false)
  const [recentAppsData, setRecentAppData] = useState<any>()
  const [projectData, setProjectData] = useState<any>()
  const [knowledgeAsistantData, setKnowledgeAsistantData] = useState<any>()
  const [projectId, setProjectId] = useState<string>()
  const [searchParams] = useSearchParams()
  const [isChatbotOpen, setIsChatbotOpen] = useState(true)

  const toggleChatbot = () => {
    setIsChatbotOpen((prevState) => !prevState)
  }
  useEffect(() => {
    if (searchParams) {
      const project_Id = searchParams.get('projectId')
      project_Id && setProjectId(project_Id)
    }
  }, [searchParams])
  const navigate = useNavigate()

  const callRecentAppsDataApi = useCallback(async (projectId: string) => {
    try {
      setLoading(true)
      const resp = await ApiServices.getAppReports(projectId)
      setLoading(false)
      setRecentAppData(resp.data)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [])

  useEffect(() => {
    if (projectId) {
      const getProjectDetails = async () => {
        try {
          setLoading(true)
          const resp: any = await ApiServices.getProjectDetails(projectId)
          setLoading(false)
          setProjectData(resp.data)
          dispatch(setProjectName(resp?.data?.project_name))
        } catch (error: any) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
          setProjectData(null)
          setLoading(false)
          console.log('error', error)
        }
      }
      const getKnowledgeAsistant = async () => {
        try {
          setLoading(true)
          const resp: any = await ApiServices.getKnowledgeAsistant(projectId)
          setLoading(false)
          setKnowledgeAsistantData(resp.data)
        } catch (error: any) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
          // setProjectData(null)
          setLoading(false)
          console.log('error', error)
        }
      }

      getProjectDetails()
      getKnowledgeAsistant()
    }
  }, [projectId])

  useEffect(() => {
    if (projectId) {
      callRecentAppsDataApi(projectId)
    }
  }, [callRecentAppsDataApi, projectId])

  const navigateToDataPipelineList = useCallback(
    (id: string) => {
      navigate(
        `/${PAGE_ROUTE_CONSTANTS.APPS_PAGE}/${PAGE_ROUTE_CONSTANTS.APPS_REPORT_PAGE}/${PAGE_ROUTE_CONSTANTS.APPS_GRAPH_DASHBOARD}?projectId=${projectId}&componentId=${id}`,
      )
    },
    [navigate, projectId],
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
        }}
      >
        <Container
          sx={{
            mt: 2,
            paddingBottom: 2,
            width: isChatbotOpen ? '70%' : '100%',
          }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Container>
            {projectData && (
              <Box>
                <Typography
                  variant="button"
                  gutterBottom
                  sx={{ fontWeight: 'bold', fontSize: 24 }}
                >
                  {projectData.project_name}
                </Typography>
              </Box>
            )}
            <Box sx={{ mb: 2, py: 2 }}>
              <Typography
                sx={{ fontWeight: 'bold' }}
                variant="button"
                gutterBottom
              >
                {`All Your Reports`}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {recentAppsData &&
                recentAppsData.map((item: any) => {
                  return (
                    <Grid key={`recent_${item.id}`} item md={4} sm={6}>
                      <CardDetailsMolecule
                        label={`${item.component_name}`}
                        subLabel={`Last updated ${changeDateFormat(
                          item.last_updated_date,
                        )}`}
                        version="v2"
                        onClick={() => navigateToDataPipelineList(item.id)}
                        handleDetails={(e) => {
                          // details drawer
                        }}
                      ></CardDetailsMolecule>
                    </Grid>
                  )
                })}
            </Grid>
            {recentAppsData && recentAppsData.length === 0 && (
              <NoRecordsFoundComponent />
            )}
            <Divider sx={{ mt: 2 }} />
          </Container>
        </Container>

        {knowledgeAsistantData?.length > 0 && (
          <Container
            sx={{
              paddingBottom: 2,
              width: { xs: '80%', md: '40%' }, // 80% on small screens, 40% on medium and larger screens
              justifyContent: 'right',
            }}
          >
            {/* <Chatbot selectedCompany="selected" /> */}
            {isChatbotOpen && (
              <Grid item xs={3}>
                <Chatbot
                  selectedComponent={knowledgeAsistantData}
                  open={isChatbotOpen}
                  onClose={() => setIsChatbotOpen(false)}
                />
              </Grid>
            )}

            {!isChatbotOpen && (
              <BotIconButton onClick={toggleChatbot}>
                <img
                  src={SmartToyIcon}
                  alt="ChatBot"
                  style={{
                    height: '60px', // Adjust size of the icon
                    borderRadius: '50%',
                    // marginRight: '12px', // Space between icon and text
                  }}
                />
                {/* <SmartToyIcon sx={{height:18"40px", width:'40px',margin:'8px'}}/> */}
              </BotIconButton>
            )}
          </Container>
        )}
      </Box>

      <LoaderComponent open={isLoading} />
    </>
  )
}
export default ReportsAppPage
