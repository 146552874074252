/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
// ThemeContext.js
// ThemeContext.js
// ThemeContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  FC,
} from 'react'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  Theme,
} from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { lightTheme, darkTheme } from './theme' // Adjust the import path as necessary

interface ThemeContextProps {
  isDarkMode: boolean
  handleThemeChange: () => void
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined)

export const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false)

  const handleThemeChange = () => {
    setIsDarkMode(!isDarkMode)
  }

  const theme: Theme = isDarkMode ? darkTheme : lightTheme

  return (
    <ThemeContext.Provider value={{ isDarkMode, handleThemeChange }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}
