import navDraweSlice from './navDrawerSlice'
import { configureStore } from '@reduxjs/toolkit'
import dataSourceSlice from './dataSourceTypesSlice'
import breadcrumbSlice from './breadcrumbSlice'
import profileInfoSlice from './profileInfoSlice'

export const store = configureStore({
  reducer: {
    dataSourceSlice,
    navDraweSlice,
    breadcrumbSlice,
    profileInfoSlice,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
