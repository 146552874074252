import React, { useEffect, useMemo, useState } from 'react'
import { Navigate, Outlet, useSearchParams } from 'react-router-dom'
import {
  DrawerWidth,
  PAGE_ROUTE_CONSTANTS,
  TOKEN_INFINITIX,
} from 'utility/constants'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { styled } from '@mui/material/styles'
import Breadcrumb from 'components/components/Breadcrumb'
import NewHeader from 'components/templates/newHeader'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 50,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${DrawerWidth}`,
  }),
}))

const PrivateRoutes = () => {
  const auth = useMemo(() => {
    return localStorage.getItem(TOKEN_INFINITIX) ? true : false
  }, [])
  const [searchParams] = useSearchParams()
  const [tableIndex, setTableIndex] = useState<any>(false)
  const open = useSelector((state: RootState) => state.navDraweSlice.open)
  const id = window.location.href.includes('sysadmin') ? '123' : null

  useEffect(() => {
    if (searchParams) {
      const index = searchParams.get('index')
      index && setTableIndex(true)
    }
  }, [])

  return auth ? (
    <>
      <NewHeader />
      <Main open={open}>
        {!id && !tableIndex && <Breadcrumb />}
        <Outlet />
      </Main>
    </>
  ) : (
    <Navigate to={PAGE_ROUTE_CONSTANTS.LOGIN} />
  )
}

export default PrivateRoutes
