import { Check, Close } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Typography,
} from '@mui/material'
import { DQChecks } from 'api/services'
import Text from 'components/atoms/Text'
import DropdownComponent from 'components/atoms/dropdown'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
// import CheckIcon from '@mui/icons-material/Check'

import DynamicParams from '../components/DynamicParams'

import lodash from 'lodash'

interface AddDQFunProps {
  selectedBasicCheck: string | any
  onClose: () => void
  metaData?: {
    dataRepository: string | any
    objTable: string | any
    checkType: string | any
    allCheckType: string | any
  }
  showAlert?: any
  setDQFunData?: any
  type?: 'edit' | 'view' | 'clone' | ''
  editData?: any
  editDataIndex?: any
  dqmAllData?: any
  repoDetails?: any
}

function AddDataCheckFun(props: AddDQFunProps) {
  const {
    selectedBasicCheck,
    onClose,
    metaData,
    // showAlert,
    setDQFunData,
    type,
    editData,
    editDataIndex,
    dqmAllData,
    repoDetails,
    showAlert,
  } = props

  const dqmAllDataClone = useMemo(
    () => lodash.cloneDeep(dqmAllData),
    [dqmAllData],
  )

  const [dQCheckFunctions, setDQCheckFunctions] = useState<any>([])
  const [selectedDQCheckFun, setSelectedDQCheckFun] = useState<any>('')

  const [dQMetricsFunctionsDetails, setDQMetricsFunctionsDetails] =
    useState<any>('')

  const [trendRules, setTrendRules] = useState<any>([])
  const [selectedTrendRule, setSelectedTrendRule] = useState('')

  const [sqlQuery, setSqlQuery] = useState('')
  const [queryValidation, setQueryValidation] = useState<any>('')
  const [queryValidationLoader, setQueryValidationLoader] = useState(false)
  const [isOptionRequired, setIsOptionRequired] = useState('')

  const [dqMetricsDetails, setDQMetricsDetails] = useState<any>([])
  const [selectedDQMetrics, setSelectedDQMetrics] = useState<any>('')
  const [isMetricsRequired, setIsMetricsRequired] = useState<any>('')

  const [dqParams, setDQParams] = useState<any>([])
  const [error, setError] = useState<any>({})

  //DropDown Pagination
  const [isDropDownLoading, setIsDropDownLoading] = useState(false)
  const [page, setPage] = useState(2)
  const [totalDQFunctions, setTotalDQFunctions] = useState(0)

  useEffect(() => {
    if (selectedBasicCheck !== '') {
      const param = {
        pageNumber: 1,
        pageSize: 10,
        body: {
          dq_check_basic_type: selectedBasicCheck,
          dq_check_type: metaData?.allCheckType || null,
          db_type: repoDetails?.db_type,
        },
      }
      DQChecks.getDQCheckFunctions(param).then((res: any) => {
        setDQCheckFunctions(res?.data?.data)
        setTotalDQFunctions(res?.data?.totalRecords)
      })
    }
  }, [selectedBasicCheck])

  useEffect(() => {
    const param = {
      search: {
        data_repository_id: metaData?.dataRepository,
        dataset: metaData?.objTable,
      },
    }
    DQChecks.getAllDQMatrices(param).then((res: any) => {
      setDQMetricsDetails(res?.data?.data)
    })
  }, [])

  useEffect(() => {
    DQChecks.getDQCheckTrendRules().then((res: any) => {
      setTrendRules(res?.data)
    })
  }, [])

  useEffect(() => {
    if (selectedDQCheckFun && selectedDQCheckFun !== '') {
      setDQMetricsFunctionsDetails(selectedDQCheckFun)
      if (selectedDQCheckFun?.params?.length > 0) {
        const params = selectedDQCheckFun?.params
        const updatedParams = params?.map((param: any, index: number) => ({
          ...param,
          value: type === 'clone' ? editData?.params?.[index]?.value : '',
          fieldType: 'TEXTINPUT',
        }))
        setDQParams(updatedParams)
      }
    }
  }, [selectedDQCheckFun])

  const loadRecords = useCallback(() => {
    setIsDropDownLoading(true)
    setTimeout(() => {
      DQChecks.getDQCheckFunctions({
        pageNumber: page,
        pageSize: 10,
        body: {
          dq_check_basic_type: selectedBasicCheck,
          dq_check_type: metaData?.allCheckType || null,
          db_type: repoDetails?.db_type,
        },
      })
        .then((res: any) => {
          setDQCheckFunctions((prevRecords: any) => [
            ...prevRecords,
            ...res.data.data,
          ])
          setIsDropDownLoading(false)
        })
        .catch(() => {
          setIsDropDownLoading(false)
        })
      setPage((prevPage) => prevPage + 1)
    }, 1000) // Simulating delay
  }, [page])

  const handleLoadMore = useCallback(
    (event: any) => {
      event.stopPropagation()
      loadRecords()
    },
    [page, loadRecords],
  )

  useEffect(() => {
    if (type === 'clone') {
      if (dQCheckFunctions?.length > 0) {
        const dqCheckFun = dQCheckFunctions?.find(
          (item: any) => item?.id === editData?.id,
        )
        const metricsDetails = dqMetricsDetails?.find(
          (item: any) => item?.id === editData?.dq_metrics_id,
        )
        const metrics =
          metricsDetails?.dq_metrics_functions_configuration?.find(
            (item: any) => item?._id === editData?.metrics[0],
          )
        setSqlQuery(editData?.query)
        setIsOptionRequired(editData?.option)
        setSelectedDQMetrics(metricsDetails)
        setIsMetricsRequired(metrics)
        setSelectedDQCheckFun(dqCheckFun)
        setSelectedTrendRule(editData?.rules)
      }
    }
  }, [dQCheckFunctions, dqMetricsDetails, trendRules])

  const handleQueryValidation = useCallback(() => {
    setQueryValidationLoader(true)
    const params = {
      repository_id: metaData?.dataRepository,
      body: {
        query: sqlQuery,
      },
    }
    DQChecks.checkQueryValidation(params)
      .then((res: any) => {
        setQueryValidation(res?.data?.verified)
        setQueryValidationLoader(false)
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        showAlert(errorMessage || 'Something Went Wrong', 'error')
        setQueryValidationLoader(false)
      })
  }, [sqlQuery, metaData, queryValidationLoader])

  const handleRequiredFieldValidation = useCallback(() => {
    let isValidationOk = true

    if (selectedDQCheckFun === '') {
      setError({ ...error, dmf: 'Please Select DQ Check Function' })
      return false
    }

    if (selectedDQCheckFun?.is_query_required === true) {
      if (sqlQuery === '') {
        setError({ ...error, sqlQuery: 'This is Mandatory Field' })
        return false
      }

      if (queryValidation === false || queryValidation === '') {
        setError({ ...error, sqlQuery: 'Database Query is not Valid' })
        return false
      }
    }

    if (dQMetricsFunctionsDetails?.is_option_required === true) {
      if (isOptionRequired === '') {
        setError({ ...error, option: 'This is Mandatory Field' })
        return false
      }
    }

    if (dQMetricsFunctionsDetails?.is_metrics_required === true) {
      if (selectedDQMetrics === '') {
        setError({ ...error, metrics_details: 'This is Mandatory Field' })
        return false
      }

      if (isMetricsRequired === '') {
        setError({ ...error, metrics: 'This is Mandatory Field' })
        return false
      }
    }

    if (dQMetricsFunctionsDetails?.is_rule_required === true) {
      if (selectedTrendRule === '') {
        setError({ ...error, rule: 'This is Mandatory Field' })
        return false
      }
    }

    dqParams?.some((item: any) => {
      if (item?.value === '') {
        if (item?.isRequired === true) {
          setError({
            ...error,
            [item?.key]: 'This is Mandatory Field',
          })
          isValidationOk = false
          return true
        }
      }
    })

    return isValidationOk
  }, [
    selectedDQCheckFun,
    isOptionRequired,
    dqParams,
    selectedDQMetrics,
    isMetricsRequired,
    dQMetricsFunctionsDetails,
    sqlQuery,
    queryValidation,
  ])

  const handleDropDownErrors = useCallback(
    (errorObj: any) => {
      if (typeof errorObj !== 'undefined') {
        if (errorObj !== '') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    [error],
  )

  const handleSaveData = useCallback(() => {
    const isValidationOk = handleRequiredFieldValidation()

    if (!isValidationOk) {
      return
    }

    const data = {
      id: selectedDQCheckFun?.id,
      name: selectedDQCheckFun?.dq_check_function,
      query: sqlQuery || '',
      option: isOptionRequired || '',
      rule: selectedTrendRule || '',
      metrics_name: isMetricsRequired?.custom_name || '',
      dq_metrics_id: selectedDQMetrics?.id || null,
      metrics: isMetricsRequired?._id ? [isMetricsRequired?._id] : [],
      params: dqParams || [],
    }
    if (type !== 'clone') {
      setDQFunData((prevData: any) => [...prevData, data])
    } else {
      dqmAllDataClone[editDataIndex] = data
      setDQFunData(dqmAllDataClone)
    }
    onClose?.()
  }, [
    selectedDQCheckFun,
    isOptionRequired,
    selectedTrendRule,
    dqParams,
    dQMetricsFunctionsDetails,
    sqlQuery,
    selectedDQMetrics,
    isMetricsRequired,
    queryValidation,
  ])

  return (
    <Box
      sx={{
        display: 'flex',
        width: '550px',
        height: '600px',
        flexDirection: 'column',
        alignItems: 'center',
        // padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
          alignItems: 'center',
          height: '30px',
          padding: '20px',
          paddingTop: '50px',
          marginBottom: '10px',
          position: 'sticky',
        }}
      >
        <Box sx={{ display: 'flex', flex: 2, justifyContent: 'center' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            Add DQ Check Functions
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '30px',
              height: '30px',
              background: 'red',
            }}
          >
            <Close
              sx={{
                color: 'white',
                cursor: 'pointer',
                border: '1px solid red',
              }}
              onClick={onClose}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          padding: '20px 20px 0px 20px',
        }}
      >
        <Box>
          <DropdownComponent
            sx={{ width: 361 }}
            size="small"
            label="Data Check Function *"
            value={selectedDQCheckFun}
            onChange={(e: any) => {
              setSelectedDQCheckFun(e.target.value)
              setIsOptionRequired('')
              setSelectedDQMetrics('')
              setIsMetricsRequired('')
              setSelectedTrendRule('')
              setError({ ...error, dmf: '' })
            }}
            error={handleDropDownErrors(error?.dmf) || false}
            disabled={type === 'clone'}
          >
            {dQCheckFunctions?.length > 0 ? (
              dQCheckFunctions.map((item: any) => (
                <MenuItem key={item.id} value={item}>
                  {item.dq_check_function}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>No Record Found</em>
              </MenuItem>
            )}
            {isDropDownLoading && (
              <MenuItem disabled>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress size={24} />
                </Box>
              </MenuItem>
            )}
            {!isDropDownLoading &&
              dQCheckFunctions.length < totalDQFunctions && (
                <MenuItem disabled={isDropDownLoading}>
                  <Button
                    sx={{ width: '100%' }}
                    onClick={handleLoadMore}
                    variant="text"
                    color="primary"
                  >
                    Load More
                  </Button>
                </MenuItem>
              )}
          </DropdownComponent>
          <Typography
            sx={{
              fontSize: '0.75rem',
              margin: '4px 0px 0px 14px',
              fontWeight: '400',
              color: '#d32f2f',
            }}
          >
            {error?.dmf}
          </Typography>
        </Box>
        {dQMetricsFunctionsDetails !== '' && (
          <Box sx={{ width: 361, pb: '10px' }}>
            <Typography sx={{ fontWeight: '600' }}>Function Details</Typography>{' '}
            <Typography>
              {dQMetricsFunctionsDetails?.dq_check_description}
            </Typography>
          </Box>
        )}

        {selectedDQCheckFun?.is_query_required === true && (
          <Box sx={{ position: 'relative' }}>
            <Text
              label="Enter DB Query *"
              multiline={true}
              value={sqlQuery}
              onChange={(e: any) => {
                setSqlQuery(e.target.value)
                setQueryValidation('')
                setError({ ...error, sqlQuery: '' })
              }}
              onBlur={handleQueryValidation}
              name="sqlQuery"
              error={error}
            />
            {queryValidationLoader && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '4px',
                  right: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={'24px'} />
              </Box>
            )}
            {queryValidation !== '' && (
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  border: `1px solid ${queryValidation ? 'green' : 'red'}`,
                  borderRadius: '12px',
                  background: queryValidation ? 'green' : 'red',
                  position: 'absolute',
                  bottom: error?.sqlQuery === '' ? '4px' : '28px', // Adjust the bottom position as needed
                  right: '4px', // Adjust the right position as needed

                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {queryValidation ? (
                  <Check sx={{ color: 'white', fontSize: '20px' }} />
                ) : (
                  <Close sx={{ color: 'white', fontSize: '20px' }} />
                )}
              </Box>
            )}
          </Box>
        )}

        {dQMetricsFunctionsDetails?.is_option_required === true && (
          <Text
            value={isOptionRequired}
            onChange={(e) => {
              setIsOptionRequired(e.target.value)
              setError({ ...error, option: '' })
            }}
            label="Option *"
            name="option"
            error={error}
          />
        )}
        {dQMetricsFunctionsDetails?.is_metrics_required === true && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <Box>
              <DropdownComponent
                sx={{ width: 361 }}
                size="small"
                label="Metrics Details *"
                value={selectedDQMetrics}
                onChange={(e: any) => {
                  setSelectedDQMetrics(e.target.value)
                  setIsMetricsRequired('')
                  setError({ ...error, metrics_details: '' })
                }}
                error={handleDropDownErrors(error?.metrics_details) || false}
                // disabled={type === 'clone'}
              >
                {dqMetricsDetails?.length > 0 ? (
                  dqMetricsDetails.map((item: any) => (
                    <MenuItem key={item.id} value={item}>
                      {item.dq_meterics_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <em>No Record Found</em>
                  </MenuItem>
                )}
              </DropdownComponent>
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  margin: '4px 0px 0px 14px',
                  fontWeight: '400',
                  color: '#d32f2f',
                }}
              >
                {error?.metrics_details}
              </Typography>
            </Box>
            <Box>
              <DropdownComponent
                sx={{ width: 361 }}
                size="small"
                label="Metrics *"
                value={isMetricsRequired}
                onChange={(e: any) => {
                  setIsMetricsRequired(e.target.value)
                  setError({ ...error, metrics: '' })
                }}
                error={handleDropDownErrors(error?.metrics) || false}
                // disabled={type === 'clone'}
              >
                {selectedDQMetrics?.dq_metrics_functions_configuration?.length >
                0 ? (
                  selectedDQMetrics?.dq_metrics_functions_configuration.map(
                    (item: any) => (
                      <MenuItem key={item.id} value={item}>
                        {item.custom_name}
                      </MenuItem>
                    ),
                  )
                ) : (
                  <MenuItem value="">
                    <em>No Record Found</em>
                  </MenuItem>
                )}
              </DropdownComponent>
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  margin: '4px 0px 0px 14px',
                  fontWeight: '400',
                  color: '#d32f2f',
                }}
              >
                {error?.metrics}
              </Typography>
            </Box>
          </Box>
        )}
        {dQMetricsFunctionsDetails?.is_rule_required === true && (
          <Box>
            <DropdownComponent
              sx={{ width: 361 }}
              size="small"
              label="Trend Rules *"
              value={selectedTrendRule}
              onChange={(e: any) => {
                setSelectedTrendRule(e.target.value)
                setError({ ...error, rule: '' })
              }}
              error={handleDropDownErrors(error?.rule) || false}
              // disabled={type === 'clone'}
            >
              {trendRules?.length > 0 ? (
                trendRules.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  <em>No Record Found</em>
                </MenuItem>
              )}
            </DropdownComponent>
            <Typography
              sx={{
                fontSize: '0.75rem',
                margin: '4px 0px 0px 14px',
                fontWeight: '400',
                color: '#d32f2f',
              }}
            >
              {error?.rule}
            </Typography>
          </Box>
        )}
        {dqParams.length > 0 && (
          <DynamicParams
            formData={dqParams}
            handleChange={setDQParams}
            error={error}
            setError={setError}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            justifyContent: 'flex-end',
            paddingBottom: '20px',
          }}
        >
          <Button
            variant="contained"
            onClick={handleSaveData}
            // disabled={isSaveLoading || !isConnectionTested}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AddDataCheckFun
