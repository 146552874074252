/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Close } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
import { ApiServices } from 'api/services'
import CardDetailsMolecule from 'components/molecules/card-details'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface ComponentList {
  onClose: () => void
  selectOutputTemplate: any
  nodeTypeId: any
  title?: string
}

const DialogTitle = 'Select Output Template'

const OutputTemplateList = (props: ComponentList) => {
  const {
    onClose,
    selectOutputTemplate: publishComponent,
    nodeTypeId,
    title = DialogTitle,
  } = props
  const [allTemplates, setAllTemplates] = useState<any>([])
  const [selectedTemplateId, setSelectedTemplateId] = useState('')
  const navigate = useNavigate()
  useEffect(() => {
    const getApps = async () => {
      try {
        const resp = await ApiServices.getAllOutputTemplateTypes(nodeTypeId)
        setAllTemplates(resp.data.data)
      } catch (error: any) {
        console.log(error)
      }
    }
    getApps()
  }, [nodeTypeId])
  return (
    <Box
      sx={{
        display: 'flex',
        width: '50vw',
        minHeight: '300px',
        height: '100%',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1, // Ensure the header stays above the content
          backgroundColor: '#ffffff', // Background color of the header
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', flex: 2, justifyContent: 'center' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            {title}
          </Typography>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '30px',
                height: '30px',
                background: 'red',
              }}
            >
              <Close
                sx={{
                  color: 'white',
                  cursor: 'pointer',
                  border: '1px solid red',
                }}
                onClick={onClose}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {allTemplates &&
          allTemplates.map((item: any) => {
            return (
              <Grid
                key={`recent_${Math.random()}`}
                sx={{ m: '20px' }}
                item
                md={4}
                sm={6}
              >
                <Box
                  sx={{
                    border:
                      selectedTemplateId === item.id
                        ? '3px solid #757FE1'
                        : null,
                  }}
                >
                  <CardDetailsMolecule
                    label={`${item.component_node_template_name}`}
                    onClick={() => {
                      setSelectedTemplateId(item.id)
                    }}
                    handleDetails={(e) => {
                      // details drawer
                    }}
                    version="v2"
                    template={true}
                  ></CardDetailsMolecule>
                </Box>
              </Grid>
            )
          })}
      </Grid>
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          zIndex: 1, // Ensure the header stays above the content
          backgroundColor: '#ffffff', // Background color of the header
          padding: '20px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            publishComponent?.(selectedTemplateId)
            onClose()
          }}
          disabled={selectedTemplateId === ''}
        >
          Select
        </Button>
      </Box>
    </Box>
  )
}

export default OutputTemplateList
