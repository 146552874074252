export const getRandomNumber = () => {
  const characters = '0123456789abcdef'
  let result = ''

  for (let i = 0; i < 24; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    result += characters.charAt(randomIndex)
  }

  return result
}

export const changeDateFormat = (date: string) => {
  const originalDate = new Date(date) // Replace this with your actual Date object

  const day = originalDate.getDate().toString().padStart(2, '0')
  const month = (originalDate.getMonth() + 1).toString().padStart(2, '0')
  const year = originalDate.getFullYear()

  const formattedDate = `${day}-${month}-${year}`
  return formattedDate
}

export const removeDuplicates = (array: any, keys: any) => {
  return Object.values(
    array.reduce((acc: any, obj: any) => {
      const key = keys.map((k: any) => obj[k]).join('|')
      acc[key] = obj
      return acc
    }, {}),
  )
}

export const truncateText = (text: string, limit = 20) => {
  if (text.length > 20) {
    return text.substring(0, limit) + '...'
  } else {
    return text
  }
}
