/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { ConfigurationInputInterface } from './data-pipeline-types'
import React, { useCallback, useEffect, useState } from 'react'
import { ApiServices } from 'api/services'
import {
  CONFIGURATION_INPUT_TYPES,
  TransformationConfigurationId,
  TransformationConfigurationIdDataFrame,
  TransformationTemplateColumnLevelId,
  TransformationTemplateDataFrameLevelId,
} from './data-pipeline-constants'
import _without from 'lodash/without'
import _ from 'lodash'
import { getRandomNumber } from 'utility/utility'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
import { useNavigate } from 'react-router-dom'

const TransformOperationReact = (props: any) => {
  const {
    openTransformerPopup,
    handleTransformationDialogClose: handleClose,
    tranformationOperationConfiguration,
    inputConfigurationData,
    componentId,
    setLoading,
    updateTransformationApply,
    NODE_TYPES,
    drawerConfiguration,
    selectedTranformationTemplateId,
    componentNodeTemplateId,
    projectRole,
    projectId,
  } = props

  const [nodeName, setNodeName] = useState('')
  const [dialogTitle, setDialogTitle] = useState('')

  const [paramsOfTransformationFunction, setParamsOfTransformationFunction] =
    useState<any>([])
  const [tranformationOperationData, setTranformationOperationData] = useState<
    ConfigurationInputInterface[]
  >([])
  const navigate = useNavigate()
  const transformationFunctions = useCallback(
    async (arg: any) => {
      const { key } = arg
      const check =
        componentNodeTemplateId === TransformationTemplateColumnLevelId
          ? false
          : true
      try {
        setLoading(true)
        const resp: any = await ApiServices.getTransformationFunctions({
          is_higer_order_function: check,
          visibilities: ['ORG', 'PUBLIC'],
        })
        setLoading(false)
        const sourceList = resp.data.data
        setTranformationOperationData((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )

          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading, componentNodeTemplateId],
  )

  const transformationFunctionsDetail = useCallback(
    async (arg: any) => {
      const { id } = arg

      try {
        setLoading(true)
        const resp: any = await ApiServices.getTransformationFunctionsDetail(id)
        setLoading(false)
        setParamsOfTransformationFunction(resp.data.params)
      } catch (error: any) {
        // setParamsOfTransformationFunction([])
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getComponentNode = useCallback(
    async (arg: any) => {
      const { id } = arg

      try {
        setLoading(true)
        const resp: any = await ApiServices.getComponentNodeDetails(id)
        setLoading(false)
        const inputData = resp.data.configuration.input
        inputData &&
          inputData.length > 0 &&
          setTranformationOperationData(
            (prev: ConfigurationInputInterface[]) => {
              const newData = [...prev]
              const findItemForTranformation = inputData.find(
                (item: ConfigurationInputInterface) =>
                  item.key ===
                  NODE_TYPES.transformation.keys.transform_functions,
              )
              if (findItemForTranformation) {
                const findItemForTranformationInPrevData = newData.find(
                  (item: ConfigurationInputInterface) =>
                    item.key ===
                    NODE_TYPES.transformation.keys.transform_functions,
                )
                if (findItemForTranformationInPrevData) {
                  findItemForTranformationInPrevData.value =
                    findItemForTranformation.value

                  if (
                    findItemForTranformationInPrevData.type ===
                    CONFIGURATION_INPUT_TYPES.SINGLETEXT
                  ) {
                    findItemForTranformationInPrevData.params =
                      findItemForTranformation.params
                    setParamsOfTransformationFunction(
                      findItemForTranformation.params,
                    )
                  }
                }
              }
              const findItemForInputColumn = inputData.find(
                (item: ConfigurationInputInterface) =>
                  item.key === NODE_TYPES.transformation.keys.input_columns,
              )
              if (findItemForInputColumn) {
                const findItemForInputColumnInPrevData = newData.find(
                  (item: ConfigurationInputInterface) =>
                    item.key === NODE_TYPES.transformation.keys.input_columns,
                )
                if (findItemForInputColumnInPrevData) {
                  findItemForInputColumnInPrevData.value =
                    findItemForInputColumn.value
                }
              }
              const findItemForOutputColumn = inputData.find(
                (item: ConfigurationInputInterface) =>
                  item.key === NODE_TYPES.transformation.keys.output_column,
              )
              if (findItemForOutputColumn) {
                const findItemForOutputColumnInPrevData = newData.find(
                  (item: ConfigurationInputInterface) =>
                    item.key === NODE_TYPES.transformation.keys.output_column,
                )
                if (findItemForOutputColumnInPrevData) {
                  findItemForOutputColumnInPrevData.value =
                    findItemForOutputColumn.value
                }
              }
              const findItemForOutputVariable = inputData.find(
                (item: ConfigurationInputInterface) =>
                  item.key ===
                  NODE_TYPES.documentProcessing.keys.output_variable,
              )
              if (findItemForOutputVariable) {
                const findItemForOutputVariableInPrevData = newData.find(
                  (item: ConfigurationInputInterface) =>
                    item.key ===
                    NODE_TYPES.documentProcessing.keys.output_variable,
                )
                if (findItemForOutputVariableInPrevData) {
                  findItemForOutputVariableInPrevData.value =
                    findItemForOutputVariable.value
                }
              }
              return [...newData]
            },
          )
      } catch (error: any) {
        setLoading(false)
        console.log('error', error)
      }
    },
    [NODE_TYPES, setLoading],
  )

  const updateDropdownValuesForRetainColumnAndOutpuColumn = useCallback(() => {
    setTranformationOperationData((prev: ConfigurationInputInterface[]) => {
      const newData = [...prev]
      const key1 = NODE_TYPES.transformation.keys.retain_columns
      const key2 = NODE_TYPES.transformation.keys.transformations_to_apply
      const checkOutputColumnn = drawerConfiguration.find(
        (item: any) => item.key === key1,
      )

      const dropdownOutpulCol =
        checkOutputColumnn && checkOutputColumnn.dropdownData
          ? checkOutputColumnn.dropdownData
          : []

      const transformationToApplyItem = drawerConfiguration.find(
        (item: any) => item.key === key2,
      )

      const dropdownFronTransformationToApply =
        transformationToApplyItem && transformationToApplyItem.value
          ? transformationToApplyItem.value
              .filter((item: any) => item.column_name)
              .map((item: any) => {
                return {
                  column_name: item.column_name,
                  data_type: item.data_type,
                }
              })
          : []

      const finaldropdown = [
        ...dropdownOutpulCol,
        ...dropdownFronTransformationToApply,
      ]
      const repository = newData.find(
        (item: ConfigurationInputInterface) =>
          item.key === NODE_TYPES.transformation.keys.input_columns,
      )

      if (repository) {
        repository.dropdownData = finaldropdown
      }

      return [...newData]
    })
  }, [NODE_TYPES, drawerConfiguration])

  useEffect(() => {
    if (
      openTransformerPopup.open &&
      tranformationOperationConfiguration?.configuration?.input?.length > 0
    ) {
      const newTranformationOperationConfiguration = _.cloneDeep(
        tranformationOperationConfiguration.configuration.input,
      )
      setTranformationOperationData([...newTranformationOperationConfiguration])
      transformationFunctions({
        key: NODE_TYPES.transformation.keys.transform_functions,
      })
      updateDropdownValuesForRetainColumnAndOutpuColumn()
      if (openTransformerPopup.isEdit) {
        setNodeName(openTransformerPopup.nodeName)
        getComponentNode({
          id: openTransformerPopup.id,
          key: NODE_TYPES.transformation.keys.transform_functions,
        })
      }
    }
    setDialogTitle(
      tranformationOperationConfiguration?.component_node_type_name,
    )
  }, [
    openTransformerPopup,
    tranformationOperationConfiguration,
    transformationFunctions,
    updateDropdownValuesForRetainColumnAndOutpuColumn,
    getComponentNode,
    NODE_TYPES,
  ])

  const onDropDownChange = useCallback(
    (arg: any) => {
      const { input, e: event } = arg
      const updateDrawerConfiguration = [...tranformationOperationData]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )

      if (index > -1) {
        updateDrawerConfiguration[index].value = event.target.value
      }
      setTranformationOperationData(updateDrawerConfiguration)
      if (
        input.key === NODE_TYPES.transformation.keys.transform_functions &&
        input.type === CONFIGURATION_INPUT_TYPES.SINGLETEXT
      ) {
        transformationFunctionsDetail({ id: event.target.value })
      }
    },
    [tranformationOperationData, NODE_TYPES, transformationFunctionsDetail],
  )

  const onTextChange = useCallback(
    (arg: any) => {
      const { input, e: event } = arg
      const updateDrawerConfiguration = [...tranformationOperationData]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        if (
          (input && input.key === 'output_dataframe') ||
          input.key === 'output_column'
        ) {
          const value = event.target.value
          const noSpacesValue = value.replace(/\s+/g, '')
          updateDrawerConfiguration[index].value = noSpacesValue
        } else {
          updateDrawerConfiguration[index].value = event.target.value
        }
      }
      setTranformationOperationData(updateDrawerConfiguration)
    },
    [tranformationOperationData],
  )

  const handleMultiObjDelete = useCallback(
    (arg: any) => {
      const { input, e: event, value } = arg
      const updateDrawerConfiguration = [...tranformationOperationData]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        updateDrawerConfiguration[index].value = _.reject(
          updateDrawerConfiguration[index].value,
          value,
        )
      }
      setTranformationOperationData(updateDrawerConfiguration)
      event.preventDefault()
    },
    [tranformationOperationData],
  )

  const handleDelete = (arg: any) => {
    const { input, e: event, value } = arg
    const updateDrawerConfiguration = [...tranformationOperationData]
    const index = updateDrawerConfiguration.findIndex(
      (item) => item.key === input.key,
    )
    if (index > -1) {
      updateDrawerConfiguration[index].value = _without(
        updateDrawerConfiguration[index].value,
        value,
      )
    }
    setTranformationOperationData(updateDrawerConfiguration)
    event.preventDefault()
  }

  const handleDialogClose = useCallback(() => {
    setNodeName('')
    setTranformationOperationData([])
    handleClose()
  }, [handleClose])

  const onUpdateComponentNodeApi = useCallback(
    async (args: any) => {
      const { payload, isEdit = false, newOperation } = args
      try {
        setLoading(true)
        await ApiServices.updateComponentNode(payload)
        setLoading(false)
        updateTransformationApply(newOperation, isEdit)
        handleDialogClose()
      } catch (error: any) {
        console.log('error', error)
      }
    },
    [handleDialogClose, setLoading, updateTransformationApply],
  )

  const onClickUpdateComponent = useCallback(() => {
    let inputData = [...tranformationOperationData]

    let column_name = ''
    let data_type = ''

    const transformFunctionsItem = inputData.find(
      (item) => item.key === NODE_TYPES.transformation.keys.transform_functions,
    )

    if (transformFunctionsItem) {
      const transformFunctionsValue = transformFunctionsItem.value
      const lastValueId =
        transformFunctionsValue[transformFunctionsValue.length - 1]

      data_type = transformFunctionsItem.dropdownData.find(
        (item: any) => item.id === lastValueId,
      )?.return_type

      if (
        transformFunctionsItem.type === CONFIGURATION_INPUT_TYPES.SINGLETEXT
      ) {
        transformFunctionsItem.params = paramsOfTransformationFunction
      }
    }

    const outputColumnItem = inputData.find(
      (item) => item.key === NODE_TYPES.transformation.keys.output_column,
    )

    if (outputColumnItem) {
      column_name = outputColumnItem.value
    }

    inputData = inputData.map((item: ConfigurationInputInterface) => {
      if (item.dropdownData) delete item.dropdownData
      return item
    })

    const newNodeId = openTransformerPopup.isEdit
      ? openTransformerPopup.id
      : getRandomNumber()

    const component_node_type_id =
      selectedTranformationTemplateId &&
      selectedTranformationTemplateId === TransformationTemplateDataFrameLevelId
        ? TransformationConfigurationIdDataFrame
        : TransformationConfigurationId
    const payload = {
      id: newNodeId,
      component_node_name: nodeName,
      component_id: componentId,
      project_id: projectId,
      component_node_type_id: component_node_type_id,
      configuration: {
        input: inputData,
      },
      status: 'live',
    }

    const newOperation = {
      id: newNodeId,
      component_node_name: nodeName,
      column_name,
      data_type,
    }

    onUpdateComponentNodeApi({
      payload,
      isEdit: openTransformerPopup.isEdit,
      newOperation,
    })
  }, [
    tranformationOperationData,
    nodeName,
    componentId,
    onUpdateComponentNodeApi,
    openTransformerPopup,
    NODE_TYPES,
    selectedTranformationTemplateId,
    paramsOfTransformationFunction,
  ])

  const onTextParamsChange = useCallback(({ item, event }: any) => {
    const value = event.target.value
    setParamsOfTransformationFunction((prev: any) => {
      const newData = [...prev]
      const input = newData.find(
        (item2: ConfigurationInputInterface) => item2.key === item.key,
      )
      if (input) {
        input.value = value
      }

      return [...newData]
    })
  }, [])

  return (
    <React.Fragment>
      <Dialog
        open={openTransformerPopup.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            onClickUpdateComponent()
          },
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="body2">{dialogTitle} Name</Typography>
            <TextField
              required
              fullWidth
              variant="outlined"
              value={nodeName}
              onChange={(e) => {
                setNodeName(e.target.value)
              }}
            />
          </Box>
          {tranformationOperationData.map(
            (item: ConfigurationInputInterface) => {
              return inputConfigurationData(item, {
                onTextChange,
                onDropDownChange,
                handleMultiObjDelete,
                handleDelete,
              })
            },
          )}
          {paramsOfTransformationFunction.map((input: any) => {
            return (
              <Box key={input.key} sx={{ mt: 2 }}>
                <Typography variant="body2">
                  {input.isRequired ? `${input.label} *` : `${input.label}`}
                </Typography>
                <TextField
                  placeholder={input.info || ''}
                  fullWidth
                  required={input.isRequired}
                  variant="outlined"
                  value={input.value}
                  onChange={(e) => {
                    onTextParamsChange({ item: input, event: e })
                  }}
                />
              </Box>
            )
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button disabled={!projectRole?.edit_nodes} type="submit">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default TransformOperationReact
