/* eslint-disable no-unused-vars */
import { Box, Button, Container, TextField, Typography } from '@mui/material'
import DrawerComponent from 'components/atoms/drawer'
import React, { useCallback, useState } from 'react'

const AppNetworkFilter = (props: any) => {
  const { getAppsDGraphNetworkData } = props
  const [mobileNumber, setMobileNumber] = useState('')
  const onSubmit = useCallback(
    (event: any) => {
      event.preventDefault()
      getAppsDGraphNetworkData(mobileNumber)
    },
    [getAppsDGraphNetworkData, mobileNumber],
  )

  return (
    <DrawerComponent anchor={'left'} open={true} width={'300px'}>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
        Configure Graph
      </Typography>
      <Container sx={{ mt: 5, p: '0 !important' }}>
        <form onSubmit={onSubmit}>
          <TextField
            required
            fullWidth
            variant="outlined"
            value={mobileNumber}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '')
              setMobileNumber(value)
            }}
            label="Mobile number"
            type="text"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Specify numeric input mode and pattern
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}>
            {/* <Button variant="contained">Cancel</Button> */}
            <Button
              type="submit"
              variant="contained"
              disabled={mobileNumber ? false : true}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Container>
    </DrawerComponent>
  )
}

export default AppNetworkFilter
