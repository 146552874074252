export const environment = {
  BASE_URL: 'http://172.31.26.60:3001',
  TEST_CONNECTION: 'https://goinfinitix.com:446',
  loadBalancerPython: 'https://goinfinitix.com:446',
  // loadBalancerNode:
  //   'http://infinitix-1901288945.us-east-1.elb.amazonaws.com:81',
  loadBalancerNode: 'https://goinfinitix.com:444',
  CHATBOT_PYTHON: 'https://goinfinitix.com:447',
  JOB_EXECUTION_REPOSITORY: '65f290ff29494eb7e7b5b859',
}
