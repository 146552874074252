import React, { useCallback } from 'react'
import {
  Box,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@mui/material'
import { Search, Add } from '@mui/icons-material'
import { DataSourceHeaderProps } from 'types/props-types'
import { CUSTOM_COLORS } from 'theme'

const UserRoleHeader = ({
  heading,
  addButtonClick,
  userRoles,
  search,
  setSearch,
  handleSearchQuery,
}: DataSourceHeaderProps) => {
  const timer: any = React.useRef(null)

  const debounce: any =
    (func: () => void, timeout = 500) =>
    (...args: any) => {
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        func.apply(this, args)
      }, timeout)
    }

  const inputHandler = useCallback(() => {
    handleSearchQuery?.()
  }, [search])

  const processChange = debounce(inputHandler)

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', width: '100%', marginTop: '37px' }}>
        <Typography
          sx={{ fontWeight: '500', fontSize: '24px', lineHeight: '28px' }}
        >
          {heading}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', marginTop: '37px' }}>
        <OutlinedInput
          sx={{ height: '34px' }}
          id="search"
          size="small"
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          onKeyUp={processChange}
          value={search}
          onChange={(e: any) => setSearch?.(e.target.value)}
        />
        <Box sx={{ display: 'flex', gap: '20px', marginLeft: '40px' }}>
          {/* <Button
            sx={{ color: '#42526E' }}
            variant="text"
            startIcon={<FilterList />}
          >
            Filter
          </Button> */}

          <Button
            sx={{
              color: CUSTOM_COLORS.backgroundColor,
              textTransform: 'none',
              // background: CUSTOM_COLORS.tableRowBgLightGray,
            }}
            variant="text"
            startIcon={<Add />}
            onClick={addButtonClick}
            disabled={!userRoles?.create}
          >
            Add
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default UserRoleHeader
