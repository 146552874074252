/* eslint-disable prettier/prettier */


import { grey } from '@mui/material/colors'

export const darkStyles = () => ({
  textAlignEnd: {
    textAlign: 'end',
  },
  cy: {
    width: '100vw',
    height: '90vh',
    position: 'relative',
    margin: 'auto',
    backgroundColor: '#121212', // Dark background
    
    'background-image': 'radial-gradient(#2a3a65 1px, transparent 0)', // Darker gradient
    'background-size': '18px 18px',
  },
  nodeIconStyle: {
    width: '30%',
    background: '#1E1E1E',
    borderTopLeftRadius: '9px',
    borderBottomLeftRadius: '9px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    
  },
  nodeTextStyle: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // marginLeft: '15px',
    // fontSize:'24px',
    paddingLeft: '15px',
    color: '#FFF', // Light text color for better contrast
    background: '#1E1E1E',
    Border:'2px solid #808080'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '2px !important',
    backgroundColor: grey[800], // Darker background color for chips
    color: grey[300], // Light text color on chips
  },
})

export const styles = () => ({
  textAlignEnd: {
    textAlign: 'end',
  },
  cy: {
    width: '100vw',
    height: '90vh',
    position: 'relative',
    margin: 'auto',
    background: '#fafafa',
    'background-image': 'radial-gradient(#bebebe 1px, transparent 0)',
    'background-size': '18px 18px',
  },
  nodeIconStyle: {
    width: '30%',
    background: '#DEEBFF',
    // color:'#ccc', 
    borderTopLeftRadius: '9px',
    borderBottomLeftRadius: '9px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nodeTextStyle: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '15px',
    color: '#000', // Light text color for better contrast
    background: '#FFF',
    Border:'2px solid #808080'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '2px !important',
    backgroundColor: grey[400],
  },
})
