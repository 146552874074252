import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'api'
import { environment } from 'environments/environment'

export const getAllModelInstanceTypes = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.get(
      `/model_instance_types/?pageNumber=${projectContent.page}&pageSize=${projectContent.size}&status=ACTIVE`,
    )

    const data: any = response.data
    return data
  },
)

export const getModelInstanceTypesById = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.get(
      `/model_instance_types/configuration?id=${projectContent.id}`,
    )

    const data: any = response.data
    return data
  },
)

export const getAllModelInstances = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.post(
      `/model_instances/_search?pageNumber=${projectContent.page}&pageSize=${projectContent.size}`,
      projectContent.search,
    )

    const data: any = response.data
    return data
  },
)

export const searchModelInstances = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.post(
      `/model_instances/_search?pageNumber=${projectContent.page}&pageSize=${projectContent.size}`,
      projectContent.search,
    )

    const data: any = response.data
    return data
  },
)

export const addEditModelInstances = async (projectContent: any) => {
  const response: any = await axiosInstance.post(
    `/model_instances/`,
    projectContent,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const removeSelectedModelInstances = createAsyncThunk(
  'project',
  async (projectContent: any) => {
    const response: any = await axiosInstance.patch(
      `/model_instances/disabled`,
      projectContent,
    )

    const data: any = JSON.parse(JSON.stringify(response))
    return data
  },
)

export const testDataSourceConnection = async (projectContent: any) => {
  const response: any = await axiosInstance.post(
    `${environment.TEST_CONNECTION}/data_repository_status/`,
    projectContent,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const getStatusFilterTypes = async () => {
  const response: any = await axiosInstance.get(`/status_filters`)

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}
