/* eslint-disable prettier/prettier */
export const SIGN_IN = 'SignIn'
export const SIGN_UP = 'SignUp;'
export const TOKEN_INFINITIX = 'infinitixToken'
export const PROFILEINFO = 'profileInfo'
export const DrawerWidth = '240px'
export const INFINITY_X_BREADCRUMBS = 'inifinityx_breadcrumbs'
export const UserRoleScopes: any = [
  { id: 1, name: 'PROJECT' },
  { id: 2, name: 'ORG' },
  { id: 3, name: 'APP' },
]

export const UserRoleStatus: any = [
  { id: 1, name: 'ACTIVE' },
  { id: 2, name: 'INACTIVE' },
]

export const UserStatus: any = [
  { id: 1, name: 'LIVE' },
  { id: 2, name: 'DISABLED' },
  { id: 3, name: 'PENDING' },
  { id: 4, name: 'SHOW_ALL' },
]

export const PAGE_ROUTE_CONSTANTS = {
  LOGIN: 'login',
  SIGNUP: 'completeSignup',
  HOME: 'dashboard',
  DATA_SOURCE: 'repositories',
  PROJECTS: 'projects',
  DATA_PIPELINE: 'data-pipeline',
  API_COMPONENT: 'api-component',
  VISUALIZATION_COMPONENT: 'visualization-component',
  DOCUMENT_PROCESSING: 'document-processing',
  KNOWLEDGE_RETRIEVAL: 'knowledge-retrieval',
  MODEL_TRAINING: 'model-training',
  DQ_PIPLINE: 'DQ-pipeline',
  DATA_PIPELINE_LIST: 'components',
  SELECT_TEMPLATE: 'select-template',
  GRAPH_DASHBOARD: 'graph-dashboard',
  APPS_PAGE: 'apps',
  APPS_REPORT_PAGE: 'apps-report-page',
  APPS_GRAPH_DASHBOARD: 'apps-graph-dashboard',
  APPS_KNOWLEDGE_GRAPH: 'apps-knowledge-graph',
  ALL_APPS_LIST: 'all-apps-list',
  OPERATIONS_PAGE: 'operations',
  OPERATION_DETAIL_LIST_PAGE: 'operation-detail-list-page',
  ALL_OPERATIONS_LIST: 'all-operations-list',
  OPERATION_DETAIL_TABLE: 'operation-detail-table',
  ADMIN: 'admin',
  FileViewer: 'fileViewer',
  SUPERADMIN: 'sysadmin/organizations',
  SUPERADMINDASHBOARD: 'sysadmin/Dashboard',
  CUSTOMFUNCTIONS: 'CustomFuction',
  ORGPREFERENCE: 'Settings',
  APPS_FILTERED_GRAPH_DASHBOARD: 'app_filtered-graph-dashboard',
}
export const DATASOURCE_NAV_BAR = [
  {
    id: 0,
    name: 'Repository Config',
    isActive: true,
  },
  // {
  //   id: 1,
  //   name: 'Data Sinks',
  //   isActive: false,
  // },
  {
    id: 2,
    name: 'Data Quality Metrics',
    isActive: false,
  },
  {
    id: 3,
    name: 'Data Quality Checks',
    isActive: false,
  },
  {
    id: 4,
    name: 'Manage LLM/ Model instances',
    isActive: false,
  },
  {
    id: 4,
    name: 'Custom Fuctions',
    isActive: true,
  },
  // {
  //   id: 4,
  //   name: 'App Components',
  //   isActive: false,
  // },
]

export const ADMIN_NAV_BAR = [
  {
    id: 0,
    name: 'Users',
    isActive: true,
  },
  {
    id: 1,
    name: 'Role Permissions',
    isActive: false,
  },
  {
    id: 2,
    name: 'Org Member Roles',
    isActive: false,
  },
  {
    id: 3,
    name: 'Project Member Roles',
    isActive: false,
  },
  {
    id: 4,
    name: 'App Member Roles',
    isActive: false,
  },
  {
    id: 5,
    name: 'Operation Member Roles',
    isActive: false,
  },
]

export const SUPER_ADMIN_NAV_BAR = [
  {
    id: 0,
    name: 'User',
    isActive: true,
  },
  {
    id: 1,
    name: 'Group',
    isActive: false,
  },
  {
    id: 2,
    name: 'Organization',
    isActive: false,
  },
  // {
  //   id: 3,
  //   name: 'Project Member Roles',
  //   isActive: false,
  // },
  // {
  //   id: 4,
  //   name: 'App Member Roles',
  //   isActive: false,
  // },
]

export const HEADER_PAGES = [
  {
    title: 'Dashboard',
    route: PAGE_ROUTE_CONSTANTS.HOME,
  },
  {
    title: 'Repositories',
    route: PAGE_ROUTE_CONSTANTS.DATA_SOURCE,
  },
  {
    title: 'Projects',
    route: PAGE_ROUTE_CONSTANTS.PROJECTS,
  },
  {
    title: 'Admin',
    route: PAGE_ROUTE_CONSTANTS.ADMIN,
  },
  // {
  //   title: 'SuperAdmin',
  //   route: PAGE_ROUTE_CONSTANTS.SUPERADMIN,
  // },

  {
    title: 'Apps',
    route: PAGE_ROUTE_CONSTANTS.APPS_PAGE,
  },
  {
    title: 'Operations',
    route: PAGE_ROUTE_CONSTANTS.OPERATIONS_PAGE,
  },
]

export const REGEX_EXPRESSION = {
  INTEGER: /^\d*$/,
  FLOAT: /^-?\d*\.?\d*$/,
}

export const TRANSFORMATION_FN_CONSTANTS = {
  DOCUMENT_TYPE: {
    DOCUMENT: 'DOCUMENT',
    KNOWLEDGE: 'NL_QUERY',
  },
  TRANSFORMATION_TYPE: {
    EMBEDDING: 'EMBEDDING',
    RETRIEVAL: 'RETRIEVAL',
    DS: 'DS',
    SOURCE_TRANSLATION: 'SOURCE_TRANSLATION',
    DOCUMENT_EXTRACTION: 'DOCUMENT_EXTRACTION',
    MODEL_TRAINING: 'MODEL_TRAINING',
    SQL: 'SQL',
  },
}
