/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useCallback } from 'react'

import {
  AppBar,
  Box,
  ButtonBase,
  Container,
  IconButton,
  ListItem,
  ListItemIcon,
  Toolbar,
  Typography,
} from '@mui/material'
import { AutoAwesome } from '@mui/icons-material'

import NotificationsIcon from '@mui/icons-material/Notifications'
import NavDrawerComponent from 'components/templates/nav-drawer'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { grey } from '@mui/material/colors'

import LeftSideHeader from './LeftSideHeader'
import SearchInputComponent from 'components/atoms/search-input'
import { useNavigate } from 'react-router-dom'
import logo from 'assets/img/Infinitix_logo192.png'
const NewHeader = () => {
  const open = useSelector((state: RootState) => state.navDraweSlice.open)
  const navigate = useNavigate()
  const id = window.location.href.includes('sysadmin') ? '123' : null
  const handleCloseNavMenu = useCallback(
    (page: string) => {
      if (!id) navigate(`/${page}`)
      else navigate('/sysadmin/Dashboard')
    },
    [navigate],
  )

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${grey[300]}`,
        position: 'sticky',
        top: 0,
        width: '100%',
        zIndex: 1100,
      }}
    >
      <AppBar position="static" color={'inherit'}>
        <Container sx={{ zIndex: '1300' }} maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
                display: 'flex',
                ml: 3,
              }}
            >
              <ListItem>
                <ButtonBase onClick={() => handleCloseNavMenu('dashboard')}>
                  <ListItemIcon>
                    <img
                      src={logo}
                      alt="logo"
                      width={35}
                      height={30}
                      style={{ borderRadius: '5px' }}
                    />
                  </ListItemIcon>
                  <Typography sx={{ fontWeight: '600' }}>InfinitiX</Typography>
                </ButtonBase>
              </ListItem>
              {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <SearchInputComponent
                  icon={<AutoAwesome />}
                  sx={{
                    height: '2.3rem',
                    width: '40rem',
                    '@media only screen and (max-width: 910px)': {
                      width: '100%',
                    },
                  }}
                  placeholder="Search Using AI"
                />
                <IconButton
                  size="large"
                  aria-label="notification"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <NotificationsIcon />
                </IconButton>
              </Box> */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <LeftSideHeader />
      <NavDrawerComponent open={open} />
    </Box>
  )
}

export default NewHeader
