import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import LandingImage from 'components/templates/landingImage'
import SignUp from './signup'
import logo from 'assets/img/Infinitix_logo192.png'

const AccountSignUp = () => {
  return (
    <>
      <Grid container>
        <Grid
          item
          md={7}
          xs={12}
          sx={{
            height: '100vh',
            background: '#fff',
          }}
        >
          <LandingImage />
        </Grid>
        <Grid item md={5} xs={12} sx={{ background: '#FFF' }}>
          <Box
            sx={{
              height: '100vh', // Make sure this takes full height for centering
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'left',
              flexDirection: 'column',
              background: '#FFF',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'left',
                marginBottom: '60px',
                flexDirection: 'row',
                justifyContent: 'left', // Center horizontally
                background: '#FFF',
              }}
            >
              <img
                src={logo}
                alt="logo"
                width={50}
                height={50}
                style={{ borderRadius: '5px' }}
              />
              <Box>
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{
                    fontSize: '26px',
                    fontWeight: 700,
                    color: 'black',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  InfinitiX
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontWeight: 550,
                      color: 'black',
                      marginLeft: '10px',
                      marginTop: '10px',
                    }}
                  >
                    AI Platform
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    color: 'black',
                    marginLeft: '2px',
                  }}
                >
                  Build 2x faster
                </Typography>
              </Box>
            </Box>

            <>
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: '500',
                  marginBottom: '30px',
                }}
                gutterBottom
              >
                Enter Your Information
              </Typography>
            </>
            <Box sx={{ width: '80%', background: '#FFF' }}>
              <SignUp />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default AccountSignUp
