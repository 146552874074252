/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */

import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import DataRenderer from "./Datarenderer";
import { useTheme } from "ThemeContext";

  const BotMessageCard = ({ data }) => {
    const { isDarkMode, handleThemeChange } = useTheme();
    return (
      <Card sx={{ backgroundColor:!isDarkMode? "#ffffff":'#ccc', marginBottom: 2,borderRadius:'20px',marginRight:'50px' }}>
        <CardContent>
          <Typography variant="body1" color="text.primary">
            {/* {text} */}
            {/* <MyBarChart /> */}
            {data}
            {/* <DataRenderer data={data} /> */}
          </Typography>
          <Typography variant="caption" color="text.secondary">
           AI
          </Typography>
        </CardContent>
      </Card>
    );
  };

  export default BotMessageCard;
