/* eslint-disable prettier/prettier */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React, { useCallback } from 'react'

interface ConfirmDialog {
  open: boolean
  handleClose: any
  dialogContentText?: string
  dialogHeading?: string
  handleYesButton?: any
}

const ConfirmationDialog = (props: ConfirmDialog) => {
  const {
    open,
    handleClose,
    dialogContentText,
    handleYesButton,
    dialogHeading,
  } = props
  const handleYesClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    handleYesButton?.()
    handleClose()
  }, [])
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ backgroundColor: 'transparent !important' }}
      >
        {dialogHeading}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogContentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: 'transparent !important' }}>
        <Button
          onClick={(event) => {
            event.stopPropagation()
            handleClose()
          }}
        >
          No
        </Button>
        <Button onClick={handleYesClick} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
