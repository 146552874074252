import { ArrowBack } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import Text from 'components/atoms/Text'
import DropdownComponent from 'components/atoms/dropdown'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { AppDispatch } from 'store/store'
import { EditDataSourceProps } from 'types/props-types'
import lodash from 'lodash'
import { Admin } from 'api/services'
// import { CUSTOM_COLORS } from 'theme'
import { UserRoleScopes } from 'utility/constants'
import RolePermissionDynamicForm from './RolePermissionsDynamicForm'
import DefaultRoleConfirmationDialog from './DefaultRoleConfirmationDialog'

function EditRolePermissions({
  heading,
  onClose,
  data,
  triggerReload,
  type,
  messageConfig,
  showMessage,
}: EditDataSourceProps) {
  const editedData = useMemo(() => lodash.cloneDeep(data), [data])

  const [userScopes, setUserScopes] = useState([])
  const [selectedScope, setSelectedScope] = useState('')
  const [userRole, setUserRole] = useState('')
  const [userRoleLabel, setUserRoleLabel] = useState('')
  const [userRoleDesc, setUserRoleDesc] = useState('')

  const [editConfiguration, setEditConfiguration] = useState<any>({})
  const [error, setError] = useState<any>({})
  const [isSaveLoading, setSaveLoading] = useState(false)

  const [isDefaultRoleExists, setIsDefaultRoleExists] = useState(false)
  const [isDefaultRole, setIsDefaultRole] = useState(false)
  const [showDefaultRoleConfirmation, setShowDefaultRoleConfirmation] =
    useState(false)

  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    setUserScopes(UserRoleScopes)
  }, [])

  useEffect(() => {
    if (data) {
      setSelectedScope(data?.scope)
      setUserRole(data?.role)
      setUserRoleLabel(data?.label)
      setUserRoleDesc(data?.role_description)
      setEditConfiguration(data?.permissions)
      setIsDefaultRole(data?.is_system_generate_role)
    }
  }, [data])

  useEffect(() => {
    dispatch(Admin.getOrgDefaultRole({ id: selectedScope }))
      .then((res: any) => {
        setIsDefaultRoleExists(res.payload.isDefaultRoleExists)
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
      })
  }, [selectedScope])

  const handleDefaultRoleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isDefaultRoleExists && event.target.checked) {
        setShowDefaultRoleConfirmation(true)
      } else {
        setIsDefaultRole(event.target.checked)
      }
    },
    [isDefaultRoleExists],
  )

  const handleConfirmationDialogClose = useCallback((confirmation: boolean) => {
    setIsDefaultRole(confirmation)
    setShowDefaultRoleConfirmation(false)
  }, [])

  const handleScopeChange = useCallback((e: any) => {
    setSelectedScope(e.target.value)
    setError({ ...error, config: '' })
  }, [])

  const handleUserRoleChange = useCallback((e: any) => {
    if (e.target.value !== '') {
      setUserRole(e.target.value)
      setError({ ...error, role: '' })
    } else {
      setUserRole(e.target.value)
    }
  }, [])

  const handleShowAlert = useCallback(
    (msg: string, type = 'success') => {
      showMessage({
        ...messageConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [messageConfig],
  )

  const handleRequiredFieldValidation = useCallback(() => {
    if (Object.keys(editConfiguration).length === 0) {
      setError({ ...error, config: 'Please Select Scope' })
      return false
    }

    if (userRole === '') {
      setError({ ...error, role: 'This is Mandatory Field' })
      return false
    }

    if (userRoleLabel === '') {
      setError({ ...error, roleLabel: 'This is Mandatory Field' })
      return false
    }

    if (userRoleDesc === '') {
      setError({ ...error, roleDesc: 'This is Mandatory Field' })
      return false
    }

    return true
  }, [
    userRole,
    userRoleLabel,
    selectedScope,
    editConfiguration,
    error,
    isSaveLoading,
  ])

  const handleSaveData = useCallback(() => {
    const isValidationOk = handleRequiredFieldValidation()

    if (!isValidationOk) {
      return
    }

    setSaveLoading(true)
    if (type === 'edit') {
      editedData.role = userRole
      editedData.label = userRoleLabel
      editedData.scope = data.scope
      editedData.role_description = userRoleDesc
      editedData.permissions = editConfiguration
      editedData.is_system_generated_role = isDefaultRole

      delete editedData.last_updated_by_user
      delete editedData.last_updated_date
      delete editedData.is_system_generate_role
      delete editedData.status

      Admin.addEditRolePermissions(editedData)
        .then((res: any) => {
          setSaveLoading(false)
          if (res?.status === 200) {
            triggerReload?.()
            handleShowAlert(
              res?.data?.message
                ? res.data.message
                : 'Role Permissions Updated Successfully',
            )
            onClose?.()
          } else {
            triggerReload?.()
            handleShowAlert(
              res?.error?.message
                ? res?.error?.message
                : 'Something Went Wrong',
              'error',
            )
            onClose?.()
          }
        })
        .catch((error: any) => {
          setSaveLoading(false)
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        })
    } else {
      editedData.id = null
      editedData.role = userRole
      editedData.label = userRoleLabel
      editedData.scope = data.scope
      editedData.role_description = userRoleDesc
      editedData.permissions = editConfiguration
      editedData.is_system_generated_role = isDefaultRole

      delete editedData.last_updated_by_user
      delete editedData.last_updated_date
      delete editedData.is_system_generate_role
      delete editedData.status

      Admin.addEditRolePermissions(editedData)
        .then((res: any) => {
          setSaveLoading(false)
          if (res?.status === 200) {
            triggerReload?.()
            handleShowAlert(
              res?.data?.message
                ? res.data.message
                : 'Role Permissions Updated Successfully',
            )
            onClose?.()
          } else {
            triggerReload?.()
            handleShowAlert(
              res?.error?.message
                ? res?.error?.message
                : 'Something Went Wrong',
              'error',
            )
            onClose?.()
          }
        })
        .catch((error: any) => {
          setSaveLoading(false)
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        })
    }
  }, [
    userRole,
    userRoleLabel,
    selectedScope,
    data,
    editConfiguration,
    userRoleDesc,
    isSaveLoading,
    isDefaultRole,
  ])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '424px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '28px 0px 28px 0px',
          gap: '16px',
        }}
      >
        <ArrowBack sx={{ cursor: 'pointer' }} onClick={onClose} />
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          {heading}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '32px',
          overflowY: 'scroll',
          paddingTop: '20px',
        }}
      >
        {/* <SelectComponent /> */}
        <DropdownComponent
          sx={{ width: 361 }}
          size="small"
          label="Scope *"
          value={selectedScope}
          onChange={(e) => handleScopeChange(e)}
          disabled={type === 'edit' || type === 'view'}
        >
          {userScopes?.length > 0 ? (
            userScopes.map((item: any) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">
              <em>No Record Found</em>
            </MenuItem>
          )}
        </DropdownComponent>
        <Text
          label="Role *"
          value={userRole}
          onChange={handleUserRoleChange}
          name="role"
          error={error}
          disabled={type === 'view'}
        />
        <Text
          label="Role Display Name *"
          value={userRoleLabel}
          onChange={(e: any) => {
            setUserRoleLabel(e.target.value)
            setError({ ...error, roleLabel: '' })
          }}
          name="roleLabel"
          disabled={type === 'view'}
          error={error}
        />
        <Text
          label="Role Description *"
          value={userRoleDesc}
          onChange={(e: any) => {
            setUserRoleDesc(e.target.value)
            setError({ ...error, roleDesc: '' })
          }}
          multiline={true}
          disabled={type === 'view'}
          name="roleDesc"
          error={error}
        />
        {selectedScope !== '' && (
          <Box
            sx={{
              width: 361,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography>Default Role</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <Switch
                checked={isDefaultRole}
                onChange={handleDefaultRoleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={type === 'view'}
              />
              <Typography>Yes</Typography>
            </Stack>
          </Box>
        )}
        {Object.keys(editConfiguration)?.length > 0 && (
          <Box>
            <RolePermissionDynamicForm
              formData={editConfiguration}
              handleChange={setEditConfiguration}
              type={type}
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            marginRight: '40px',
            justifyContent: 'flex-end',
            paddingBottom: '15%',
          }}
        >
          {type !== 'view' && (
            <Button
              sx={{
                color: '#42526E',
                // background: CUSTOM_COLORS.tableRowBgLightGray,
              }}
              variant="text"
              onClick={handleSaveData}
              disabled={isSaveLoading}
            >
              {isSaveLoading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          )}
        </Box>
      </Box>
      <DefaultRoleConfirmationDialog
        open={showDefaultRoleConfirmation}
        handleClose={handleConfirmationDialogClose}
      />
    </Box>
  )
}

export default EditRolePermissions
