/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { memo, useCallback, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'

import SearchInputComponent from 'components/atoms/search-input'
import CardDetailsMolecule from 'components/molecules/card-details'

import { ApiServices } from 'api/services'
import LoaderComponent from 'components/atoms/loader-component'
import { useNavigate } from 'react-router-dom'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import NewProjectComponent from 'pages/home/new-project'
import { changeDateFormat } from 'utility/utility'
import { AccountTree, AddBox, SettingsApplications } from '@mui/icons-material'
import NoRecordsFoundComponent from 'components/atoms/no-record'
import { useSelector } from 'react-redux'
import { getProfileInfo, removeProfileInfo, removeToken } from 'api/apiUtility'
import { ReactComponent as AppIcon } from 'assets/icons/application.svg'
import { ReactComponent as ProjectIcon } from 'assets/icons/project.svg'

const Home = () => {
  const timer: any = React.useRef(null)
  const profileState = useSelector((state: any) => state.profileInfoSlice)
  const profileInfo = getProfileInfo()

  const [isLoading, setLoading] = useState(true)
  const [recentProjectData, setRecentProjectData] = useState([])
  const [recentAppData, setRecentAppData] = useState([])
  const [recentComponentData, setRecentComponentData] = useState([])
  const [open, setOpen] = React.useState(false)
  const [showAllForRecent, setShowAllForRecent] = useState(false)
  const [showAllForLeft, setShowAllForLeft] = useState(false)
  const [lastViewedCount, setLastViewedCount] = useState<any>(5)

  const [reloadPage, setReloadPage] = useState<any>(false)
  const [projectType, setProjectType] = useState<any>('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [searchInput, setSearchInput] = useState('')
  //RBAC Changes
  const [userRoles, setUserRoles] = useState<any>()
  const [newButtonOptions, setNewButtonOptions] = useState<Array<any>>([])

  const openMenu = Boolean(anchorEl)

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [anchorEl, setAnchorEl],
  )

  const navigate = useNavigate()

  const callRecentProjectDataApi = useCallback(async () => {
    if (getProfileInfo()?.teamId) {
      const params = {
        search: {
          org_id: getProfileInfo().teamId,
          project_type: 'DEFAULT',
          search: '',
        },
      }
      try {
        setLoading(true)

        const resp = await ApiServices.getProjectsAndApps(params)
        setLoading(false)
        setRecentProjectData(resp.data.data)
      } catch (error: any) {
        setLoading(false)
        console.log('error', error)
      }
    }
  }, [getProfileInfo])

  const callRecentAppsDataApi = useCallback(async () => {
    if (getProfileInfo()?.teamId) {
      const params = {
        search: {
          org_id: getProfileInfo().teamId,
          project_type: 'APP',
          search: '',
        },
      }
      try {
        setLoading(true)
        const resp = await ApiServices.getProjectsAndApps(params)
        setLoading(false)
        setRecentAppData(resp.data.data)
      } catch (error: any) {
        setLoading(false)
        console.log('error', error)
      }
    }
  }, [getProfileInfo])

  const callRecentComponentDataApi = useCallback(async () => {
    try {
      setLoading(true)

      const resp = await ApiServices.getRecentUpdatedComponents()
      setLoading(false)
      setRecentComponentData(resp.data)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [])

  useEffect(() => {
    callRecentProjectDataApi()
    callRecentAppsDataApi()
    callRecentComponentDataApi()
  }, [
    callRecentProjectDataApi,
    callRecentComponentDataApi,
    reloadPage,
    profileState,
  ])

  useEffect(() => {
    const profileInfoRoles = profileInfo
    if (profileInfoRoles && !userRoles) {
      setUserRoles(profileInfoRoles?.orgRole?.permissions?.home)
    }
  }, [profileInfo])

  useEffect(() => {
    if (userRoles) {
      const options = []
      if (userRoles.createProject) {
        options.push({
          label: 'New Project',
          icon: <AccountTree />,
          onclick: () => {
            setOpen(true)
            setProjectType('project')
            handleClose()
          },
        })
      }
      if (userRoles.createApp) {
        options.push({
          label: 'New App',
          icon: <SettingsApplications />,
          onclick: () => {
            setOpen(true)
            setProjectType('app')
            handleClose()
          },
        })
      }
      setNewButtonOptions(options)
    }
  }, [userRoles])

  const onSearchInputChange = useCallback(async () => {
    const params = {
      search: {
        org_id: getProfileInfo().teamId,
        project_type: 'DEFAULT',
        search: searchInput,
      },
    }
    try {
      setLoading(true)

      const resp = await ApiServices.getProjectsAndApps(params)
      setLoading(false)
      setRecentProjectData(resp.data.data)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [searchInput])

  const navigateToDataPipelineList = useCallback(
    (id: string) => {
      navigate(
        `/${PAGE_ROUTE_CONSTANTS.PROJECTS}/${PAGE_ROUTE_CONSTANTS.DATA_PIPELINE_LIST}?projectId=${id}`,
      )
    },
    [navigate],
  )

  const navigateToProjectsList = useCallback(() => {
    navigate(`/${PAGE_ROUTE_CONSTANTS.PROJECTS}`)
  }, [navigate])

  const navigateToGraphDashboard = useCallback(
    (id: string) => {
      navigate(
        `/${PAGE_ROUTE_CONSTANTS.APPS_PAGE}/${PAGE_ROUTE_CONSTANTS.APPS_REPORT_PAGE}?projectId=${id}`,
      )
    },
    [navigate],
  )

  const navigateToAllAppList = useCallback(() => {
    navigate(
      `/${PAGE_ROUTE_CONSTANTS.APPS_PAGE}/${PAGE_ROUTE_CONSTANTS.ALL_APPS_LIST}`,
    )
  }, [navigate])

  const addNewProject = useCallback(
    async ({
      project_name,
      project_user,
      project_description,
      projectType,
    }: any) => {
      const payload: any = {
        data: {
          source: '10_org',
          project_items: [
            {
              project_name,
              project_description,
              configuration: {},
              active: true,
              project_user_id: project_user,
            },
          ],
        },
      }

      if (projectType === 'app') {
        payload.data.project_type = 'APP'
      }

      try {
        setLoading(true)
        const res: any = ApiServices.addNewProject(payload)
        setLoading(false)
        // callRecentProjectDataApi()
        setTimeout(() => {
          setReloadPage(Math.random())
        }, 1000)
        // console.log('res', res.data)
      } catch (error: any) {
        setLoading(false)
        console.log(error)
      }
    },
    [callRecentProjectDataApi, projectType],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [anchorEl, setAnchorEl])

  const inputHandler = useCallback(() => {
    onSearchInputChange()
  }, [searchInput, onSearchInputChange])

  const debounce: any =
    (func: () => void, timeout = 500) =>
    (...args: any) => {
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        func.apply(this, args)
      }, timeout)
    }

  const processChange = debounce(inputHandler)

  return (
    <>
      <Box sx={{ margin: '16px' }}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12}>
            <Container sx={{ paddingBottom: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '2%',
                }}
                // sx={{
                //   display: 'flex',
                //   alignItems: 'center',
                //   width: '100%',
                //   justifyContent: 'space-between',
                //   marginBottom: '1rem',
                // }}
              >
                <SearchInputComponent
                  value={searchInput}
                  onChange={(e: any) => setSearchInput(e.target.value)}
                  onKeyUp={processChange}
                />
                <Box sx={{ textAlign: 'end' }}>
                  <Button
                    id="demo-customized-button"
                    aria-controls={
                      openMenu ? 'demo-customized-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<AddBox />}
                    disabled={newButtonOptions.length === 0}
                  >
                    New
                  </Button>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                  >
                    {newButtonOptions.map((option) => (
                      <MenuItem key={option.label} onClick={option.onclick}>
                        <Box sx={{ display: 'flex', gap: '5px' }}>
                          {option.icon}
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Box>
            </Container>
            <Container sx={{ paddingBottom: 2, position: 'relative' }}>
              {/* <Box
        sx={{
          position: 'absolute',
          top: '20',
          right: '0',
          display: 'flex',
          gap: '1rem',
        }}
      >
        <DropdownComponent
          value={lastViewedCount}
          // label={'Last viewed'}
          sx={{ width: '7rem', height: '20px' }}
          // size="small"
          variant='standard'
          onChange={(e) => setLastViewedCount(e.target.value)}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
        </DropdownComponent>
      </Box> */}
              {userRoles && userRoles?.recentProjects && (
                <Container>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', py: 3 }}>
                    <Typography
                      sx={{ fontSize: '18px', fontWeight: '600' }}
                      gutterBottom
                    >
                      Recent Projects
                    </Typography>
                    {profileInfo &&
                      profileInfo?.orgRole?.permissions?.projects?.view && (
                        <Button
                          sx={{ marginLeft: 'auto', textTransform: 'none' }}
                          onClick={() => {
                            navigateToProjectsList()
                          }}
                        >
                          {showAllForRecent ? 'See Less' : 'See All'}
                        </Button>
                      )}
                  </Box>
                  <Grid container spacing={2}>
                    {recentProjectData &&
                      recentProjectData
                        .slice(
                          0,
                          showAllForRecent
                            ? recentProjectData.length
                            : lastViewedCount,
                        )
                        .map((item: any) => {
                          return (
                            <Grid key={`recent_${item.sNo}`} item md={4} sm={6}>
                              <CardDetailsMolecule
                                label={`${item.project_name}`}
                                subLabel={`Last updated ${changeDateFormat(
                                  item.last_updated_date,
                                )}`}
                                onClick={() =>
                                  profileInfo &&
                                  profileInfo?.orgRole?.permissions?.projects
                                    ?.view &&
                                  navigateToDataPipelineList(item.id)
                                }
                                version="v2"
                                handleDetails={(e) => {
                                  // details drawer
                                }}
                                icon={<ProjectIcon />}
                              ></CardDetailsMolecule>
                            </Grid>
                          )
                        })}
                    {recentProjectData.length === 0 && (
                      <Box sx={{ pl: '15px', width: '100%' }}>
                        <NoRecordsFoundComponent />
                      </Box>
                    )}
                  </Grid>
                  <Divider sx={{ mt: 2 }} />
                </Container>
              )}
              {userRoles && userRoles.recentApps && (
                <Container>
                  <Box sx={{ display: 'flex', alignItems: 'baseline', py: 3 }}>
                    <Typography
                      sx={{ fontSize: '18px', fontWeight: '600' }}
                      gutterBottom
                    >
                      Recent Apps
                    </Typography>
                    {profileInfo &&
                      profileInfo?.orgRole?.permissions?.apps?.view && (
                        <Button
                          sx={{ marginLeft: 'auto', textTransform: 'none' }}
                          onClick={() => {
                            navigateToAllAppList()
                          }}
                        >
                          {showAllForRecent ? 'See Less' : 'See All'}
                        </Button>
                      )}
                  </Box>
                  <Grid container spacing={2}>
                    {recentAppData &&
                      recentAppData
                        .slice(
                          0,
                          showAllForRecent
                            ? recentAppData.length
                            : lastViewedCount,
                        )
                        .map((item: any) => {
                          return (
                            <Grid key={`recent_${item.sNo}`} item md={4} sm={6}>
                              <CardDetailsMolecule
                                label={`${item.project_name}`}
                                subLabel={`Last updated ${changeDateFormat(
                                  item.last_updated_date,
                                )}`}
                                onClick={() =>
                                  profileInfo &&
                                  profileInfo?.orgRole?.permissions?.apps
                                    ?.view &&
                                  navigateToGraphDashboard(item.id)
                                }
                                handleDetails={(e) => {
                                  // details drawer
                                }}
                                version="v2"
                                icon={<AppIcon />}
                              ></CardDetailsMolecule>
                            </Grid>
                          )
                        })}
                    {recentProjectData.length === 0 && (
                      <Box sx={{ pl: '15px', width: '100%' }}>
                        <NoRecordsFoundComponent />
                      </Box>
                    )}
                  </Grid>
                  <Divider sx={{ mt: 2 }} />
                </Container>
              )}
              <Container sx={{ display: 'none' }}>
                <Box sx={{ display: 'flex', alignItems: 'baseline', py: 3 }}>
                  <Typography variant="button" gutterBottom>
                    Pick up where you left of
                  </Typography>
                  <Button
                    sx={{ marginLeft: 'auto', textTransform: 'none' }}
                    onClick={() => {
                      setShowAllForLeft(!showAllForLeft)
                    }}
                  >
                    {showAllForLeft ? 'See less' : 'See all'}
                  </Button>
                </Box>
                <Grid container spacing={2}>
                  {recentComponentData &&
                    recentComponentData
                      .slice(0, showAllForLeft ? recentComponentData.length : 3)
                      .map((item: any) => {
                        return (
                          <Grid key={`shared_${item.sNo}`} item md={4} sm={6}>
                            <CardDetailsMolecule
                              label={`${item.component_name}`}
                              subLabel={`Last updated ${changeDateFormat(
                                item.last_updated_date,
                              )}`}
                              onClick={() =>
                                navigateToDataPipelineList(item.id)
                              }
                              version="v2"
                              handleDetails={(e) => {
                                // details drawer
                              }}
                            ></CardDetailsMolecule>
                          </Grid>
                        )
                      })}
                </Grid>
                <Divider sx={{ mt: 2 }} />
              </Container>
            </Container>
          </Grid>
          <Grid item md={5} sx={{ width: '100%' }}>
            {/* <Container>
      
              <Container sx={{ mt: 2, padding: 2 }}>
                <Typography
                  variant="button"
                  gutterBottom
                  sx={{ fontWeight: 'bold' }}
                >
                  Alerts
                </Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item md={10} sm={10}>
                    <AlertCompoment
                      title="Dependent component missing"
                      desc="'KYC API' being access in 'KYC Output View' is not published"
                      status="error"
                      onClose={() => {
                        alert('test cross')
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={2}
                    sm={2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: blue[900],
                    }}
                  >
                    <Button
                      variant="text"
                      sx={{ textTransform: 'none', minWidth: 'auto' }}
                    >
                      View
                    </Button>
                    <Icon component={CallMadeIcon} />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item md={10} sm={10}>
                    <AlertCompoment
                      title="Job completed successfully"
                      desc="'KYC Pipeline' job ran successfully. View more details in the Operations page."
                      status="success"
                      onClose={() => {
                        alert('test')
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={2}
                    sm={2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: blue[900],
                    }}
                  >
                    <Button
                      variant="text"
                      sx={{ textTransform: 'none', minWidth: 'auto' }}
                    >
                      View
                    </Button>
                    <Icon component={CallMadeIcon} />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item md={10} sm={10}>
                    <AlertCompoment
                      title="Column name change needs review"
                      desc="'cust_addr' column name change in 'KYC Pipeline' needs your review."
                      status="warning"
                      onClose={() => {
                        alert('test')
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={2}
                    sm={2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: blue[900],
                    }}
                  >
                    <Button
                      variant="text"
                      sx={{ textTransform: 'none', minWidth: 'auto' }}
                    >
                      View
                    </Button>
                    <Icon component={CallMadeIcon} />
                  </Grid>
                </Grid>
              </Container>
            </Container> */}
          </Grid>
        </Grid>
      </Box>
      {open && (
        <NewProjectComponent
          key={Math.random()}
          open={open}
          setOpen={setOpen}
          projectType={projectType}
          addNewProject={({
            project_name,
            project_user,
            project_description,
          }: any) =>
            addNewProject({
              project_name,
              project_user,
              project_description,
              projectType,
            })
          }
        />
      )}
      {isLoading && <LoaderComponent open={isLoading} />}
    </>
  )
}

export default memo(Home)
