/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApiServices } from 'api/services'
import React, { useCallback, useEffect, useState } from 'react'
import ChartTable from './ChartTable'
import { Box, TextField, Button, MenuItem, Grid } from '@mui/material'
import DropdownComponent from 'components/atoms/dropdown'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
import { useNavigate } from 'react-router-dom'

// import DataSourceTable from 'components/DataSourceTable'

interface TableChartComponentProps {
  chartData: any
  setLoading?: any
  index?: any
  showFilters?: any
}

function TableChartComponent(props: TableChartComponentProps) {
  const { chartData, setLoading, index = 0, showFilters = true } = props

  const [tableDetails, setTableDetails] = useState<any>()
  const [tableColumns, setTableColumns] = useState<any>([])
  const [tableRowData, setTableRowData] = useState<any>()

  const [filterClusterSize, setFilterClusterSize] = useState([])
  const [selectedClusterSize, setSelectedClusterSize] = useState(null)

  const [filterIndexes, setFilterIndexes] = useState([])
  const [selectedFilterIndex, setSelectedFilterIndexes] = useState(null)

  const [search, setSearch] = useState('')

  const [resetTableData, setResetTableData] = useState(false)
  const navigate = useNavigate()
  // console.log('Table Row Data= ', tableRowData)

  useEffect(() => {
    if (chartData) {
      if (
        chartData.configuration.snapshot[index].configuration.snapshot
          .data_repository_binding
      ) {
        setTableDetails(
          chartData.configuration.snapshot[index].configuration.snapshot
            .data_repository_binding,
        )
      } else {
        setTableDetails({
          table_file_name:
            chartData.configuration.snapshot[index].configuration.snapshot
              .input_table,
          data_repository_id:
            chartData.configuration.snapshot[index].configuration.snapshot
              .source_repository,
          record_limit: 20,
        })
      }
    }
    if (chartData) {
      const columns: any = []
      const retain_columns_01 =
        chartData.configuration.snapshot[index].configuration.snapshot
          .retain_columns_01
      const retain_columns_02 =
        chartData.configuration.snapshot[index].configuration.snapshot
          .retain_columns_02

      const dataColumns = [...retain_columns_01, ...retain_columns_02]

      dataColumns.map((col: any) => {
        if (col !== null && (col.display === true || col.selected === true)) {
          const colData = {
            field: col.column_name,
            headerName: col.label,
            width: 150,
          }
          columns.push(colData)
        }
      })
      setTableColumns(columns)
    }
  }, [chartData])

  const callGetAllColumnsApi = useCallback(
    async (tableDetails: any) => {
      try {
        setLoading?.(true)
        const resp = await ApiServices.getDetailsColumnData({
          data_repository_id: tableDetails?.data_repository_id,
          table_file_name: tableDetails?.table_file_name,
          record_limit: tableDetails?.record_limit,
        })
        setLoading(false)
        setTableRowData(
          JSON.parse(resp.data.dataset_records).map(
            (item: any, index: number) => ({ ...item, id: index }),
          ),
        )
      } catch (error: any) {
        setLoading?.(false)
        console.log('error', error)
      }
    },
    [tableDetails],
  )
  const callGetAllFilteredColumns = useCallback(async () => {
    try {
      setLoading?.(true)
      const resp = await ApiServices.getFilteredColumnData({
        data_repository_id: tableDetails?.data_repository_id,
        table_file_name: tableDetails?.table_file_name,
        record_limit: tableDetails?.record_limit,
        cluster_index: selectedFilterIndex,
        cluster_size: selectedClusterSize,
        custom_search: search,
      })
      setLoading(false)
      setTableRowData(
        JSON.parse(resp.data.dataset_records).map(
          (item: any, index: number) => ({ ...item, id: index }),
        ),
      )
    } catch (error: any) {
      setLoading?.(false)
      console.log('error', error)
    }
  }, [tableDetails, selectedFilterIndex, selectedClusterSize, search])

  const getFilterClusterSize = useCallback(async (tableDetails: any) => {
    try {
      setLoading?.(true)
      const resp = await ApiServices.getClusterSize({
        data_repository_id: tableDetails?.data_repository_id,
        table_file_name: tableDetails?.table_file_name,
      })
      setLoading(false)
      setFilterClusterSize(JSON.parse(resp.data.dataset_records))
    } catch (error: any) {
      setLoading?.(false)
      console.log('error', error)
    }
  }, [])

  const getFilterIndex = useCallback(
    async (tableDetails: any) => {
      try {
        setLoading?.(true)
        const resp = await ApiServices.getClusterIndex({
          data_repository_id: tableDetails?.data_repository_id,
          table_file_name: tableDetails?.table_file_name,
          record_limit: tableDetails?.record_limit,
          cluster_size: selectedClusterSize,
          start: 0,
        })
        setLoading(false)
        setFilterIndexes(JSON.parse(resp.data.dataset_records))
      } catch (error: any) {
        setLoading?.(false)
        console.log('error', error)
      }
    },
    [selectedClusterSize],
  )

  useEffect(() => {
    if (tableDetails) {
      callGetAllColumnsApi(tableDetails)
      getFilterClusterSize(tableDetails)
    }
  }, [tableDetails])

  useEffect(() => {
    if (tableDetails) {
      callGetAllColumnsApi(tableDetails)
    }
  }, [resetTableData])

  useEffect(() => {
    if (selectedClusterSize !== null) {
      getFilterIndex(tableDetails)
    }
  }, [selectedClusterSize])

  const handleSelectedClusterSize = useCallback((e: any) => {
    setSelectedClusterSize(e.target.value)
  }, [])

  const handleSelectedFilterIndex = useCallback((e: any) => {
    setSelectedFilterIndexes(e.target.value)
  }, [])

  const handleSearchText = useCallback((e: any) => {
    setSearch(e.target.value)
  }, [])

  const handleFilterSubmit = useCallback(() => {
    callGetAllFilteredColumns()
  }, [tableDetails, selectedFilterIndex, selectedClusterSize, search])

  const handleFilterReset = useCallback(() => {
    setSelectedClusterSize(null)
    setSelectedFilterIndexes(null)
    setSearch('')
    setResetTableData(!resetTableData)
  }, [resetTableData])

  return (
    <div>
      {showFilters && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Grid container spacing={4}>
            <Grid item lg={3}>
              <Box>
                <DropdownComponent
                  sx={{ width: 300 }}
                  size="small"
                  label="Cluster Size"
                  value={selectedClusterSize}
                  onChange={(e) => handleSelectedClusterSize(e)}
                  disabled={search !== ''}
                  // error={handleDSError() || false}
                >
                  {filterClusterSize?.length > 0 ? (
                    filterClusterSize.map((item: any) => (
                      <MenuItem key={item.clustersize} value={item.clustersize}>
                        {item.clustersize}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>No Record Found</em>
                    </MenuItem>
                  )}
                </DropdownComponent>
                {/* <Typography
            sx={{
              fontSize: '0.75rem',
              margin: '4px 0px 0px 14px',
              fontWeight: '400',
              color: '#d32f2f',
            }}
          >
            {error?.config}
          </Typography> */}
              </Box>
            </Grid>
            <Grid item lg={3}>
              <Box>
                <DropdownComponent
                  sx={{ width: 300 }}
                  size="small"
                  label="Index"
                  value={selectedFilterIndex}
                  onChange={(e) => handleSelectedFilterIndex(e)}
                  disabled={search !== ''}
                  // error={handleDSError() || false}
                >
                  {filterIndexes?.length > 0 ? (
                    filterIndexes.map((item: any) => (
                      <MenuItem key={item.index} value={item.index}>
                        {item.index}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>No Record Found</em>
                    </MenuItem>
                  )}
                </DropdownComponent>
                {/* <Typography
            sx={{
              fontSize: '0.75rem',
              margin: '4px 0px 0px 14px',
              fontWeight: '400',
              color: '#d32f2f',
            }}
          >
            {error?.config}
          </Typography> */}
              </Box>
            </Grid>
            <Grid item lg={3}>
              <TextField
                label="Search"
                size="small"
                value={search}
                onChange={handleSearchText}
                disabled={selectedClusterSize !== null}
              />
            </Grid>
            <Grid
              item
              lg={3}
              display={'flex'}
              gap={'10px'}
              justifyContent="end"
            >
              <Button variant="contained" onClick={() => handleFilterSubmit()}>
                Submit
              </Button>
              <Button variant="contained" onClick={() => handleFilterReset()}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {tableColumns && (
        <ChartTable columns={tableColumns} rows={tableRowData} />
      )}
    </div>
  )
}

export default TableChartComponent
