export const EdgeTypesAndColors = [
  {
    type: 'EmailEdge',
    color: '#219653',
    name: 'Email',
  },
  {
    type: 'AddressEdge',
    color: '#AACCFF',
    name: 'Address',
  },
  {
    type: 'MobileEdge',
    color: '#F7CA80',
    name: 'Mobile',
  },
]

export const NodeTypesAndColorsForKnowledgeGraph = [
  {
    type: 'Case',
    color: '#fd7e14',
    name: 'Case',
  },
  {
    type: 'Defendant',
    color: '#AACCFF',
    name: 'Defendant',
  },
  {
    type: 'Plaintiff',
    color: '#219653',
    name: 'Plaintiff',
  },
  {
    type: 'Location',
    color: '#6f42c1',
    name: 'Location',
  },
  {
    type: 'Category',
    color: '#E49B0F',
    name: 'Category',
  },
  {
    type: 'Verdict',
    color: '#20c997',
    name: 'Verdict',
  },
  {
    type: 'Date',
    color: '#80deea',
    name: 'Date',
  },
  {
    type: 'Penalty',
    color: 'red',
    name: 'Penalty',
  },
  {
    type: 'Dispute',
    color: '#f57c00',
    name: 'Dispute',
  },
]

export const COLORS_ARRAY = [
  '#fd7e14',
  '#AACCFF',
  '#219653',
  '#F7CA80',
  '#6f42c1',
  '#E49B0F',
  '#20c997',
  '#80deea',
  '#f57c00',
]

export const getEdgeLineColor = (edgeType: string) => {
  const findEdgeType = EdgeTypesAndColors.find((item) => item.type === edgeType)

  return findEdgeType ? findEdgeType.color : 'green'
}

export const getNodeBackgroundColorForKnowledgeGraph = (nodeType: string) => {
  const findNodeType = NodeTypesAndColorsForKnowledgeGraph.find(
    (item) => item.type === nodeType,
  )

  return findNodeType
    ? findNodeType.color
    : COLORS_ARRAY[COLORS_ARRAY.length - 1]
}
