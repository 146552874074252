import { Box, Typography } from '@mui/material'
import React from 'react'

interface NoRecordsFoundComponentProps {
  msg?: string
}

const NoRecordsFoundComponent = ({ msg }: NoRecordsFoundComponentProps) => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h6" gutterBottom>
        {msg ? msg : 'No Records Found'}
      </Typography>
    </Box>
  )
}

export default NoRecordsFoundComponent
