import { useState } from 'react'
import cytoscape from 'cytoscape'
import dagre from 'cytoscape-dagre'
import 'cytoscape-context-menus/cytoscape-context-menus.css'
import contextMenus from 'cytoscape-context-menus'
import nodeHtmlLabel from 'cytoscape-node-html-label'
import euler from 'cytoscape-euler'

cytoscape.use(dagre)
cytoscape.use(contextMenus)
cytoscape.use(nodeHtmlLabel)
cytoscape.use(euler)

const useCytoscapeHook = (props) => {
  const { stylesheet } = props
  const [cy, setCy] = useState(null)

  const initCy = (container) => {
    const cyInstance = cytoscape({
      container: container,
      style: stylesheet,
      minZoom: 0.5,
      maxZoom: 5,
    })
    setCy(cyInstance)
  }

  return {
    initCy,
    cy,
  }
}

export default useCytoscapeHook
