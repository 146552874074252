import axiosInstance from 'api'
import { environment } from 'environments/environment'

export const getRepositoryMetaDataById = async (projectContent: any) => {
  const response: any = await axiosInstance.get(
    `${environment.TEST_CONNECTION}/data_repository_metadata/${projectContent.id}`,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const getAllDQMatrices = async (params: {
  pageNumber?: number | any
  pageSize?: number | any
  search: {
    dq_metrics_profiling_function_type?: string | any
    search?: string | any
    status?: string | any
  }
}) => {
  const response: any = await axiosInstance.post(
    `dq_metrics/_search?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`,
    params.search,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const addNewDQMatrices = async (params: { data: any }) => {
  const response: any = await axiosInstance.post(`/dq_metrics/`, params)

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const disableDQMatrices = async (params: { ids: any }) => {
  const response: any = await axiosInstance.patch(
    `/dq_metrics/disabled`,
    params,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const getDQProfilingFunctions = async () => {
  const response: any = await axiosInstance.get(
    `/dq_metrics_profiling_function_types/`,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const getDQMatricsFunctions = async (projectContent: {
  pageNumber: number | any
  pageSize: number | any
  funType: string | any
}) => {
  const response: any = await axiosInstance.get(
    `/dq_metrics_functions?pageNumber=${projectContent.pageNumber}&pageSize=${projectContent.pageSize}&dqMetricsProfilingFunctionType=${projectContent.funType}&status=ACTIVE`,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const getDQMatricsFunctionDetailsbyId = async (projectContent: {
  id: string | any
}) => {
  const response: any = await axiosInstance.get(
    `/dq_metrics_functions/details?id=${projectContent.id}`,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}

export const getDQMatricsFunctionColumnsType = async (projectContent: {
  repositoryId: string | any
  objectTable: string | any
  profilingFun: string | any
  delimiter?: string | any
}) => {
  const response: any = await axiosInstance.get(
    `${environment.TEST_CONNECTION}/data_repository_metadata/${projectContent.repositoryId}/columns_by_type?dataset=${projectContent.objectTable}&dq_metrics_profiling_function_type=${projectContent.profilingFun}&delimiter=${projectContent.delimiter}`,
  )

  const data: any = JSON.parse(JSON.stringify(response))
  if (data.status === 200) {
    return data
  } else {
    throw Error
  }
}
