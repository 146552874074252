import { Box, TextField } from '@mui/material'
import React, { useCallback } from 'react'
import { TextProps } from 'types/props-types'

const Text = (props: TextProps) => {
  const {
    label,
    value,
    onChange,
    name = '',
    disabled,
    error,
    multiline = false,
    onBlur,
    placeholder,
  } = props

  const handleSetError = useCallback(() => {
    if (typeof error?.[name] !== 'undefined') {
      if (error?.[name] !== '') {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }, [error])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <label style={{ fontSize: '14px' }} htmlFor="outlined-size-small">
        {label}
      </label>
      <TextField
        sx={{ width: 361 }}
        InputProps={{
          style: { fontSize: 14, height: multiline ? 90 : 40 },
        }}
        name={name}
        id="outlined-size-small"
        size="small"
        variant="outlined"
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={handleSetError() || false}
        helperText={error?.[name]}
        multiline={multiline}
        rows={4}
        onBlur={onBlur}
        placeholder={placeholder ? placeholder : ''}
      />
    </Box>
  )
}

export default Text
