/* eslint-disable prettier/prettier */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import DataSourceTable from 'components/DataSourceTable'
import { GridColDef } from '@mui/x-data-grid'
import { Button } from '@mui/material'
import { Delete, Edit, RemoveRedEye } from '@mui/icons-material'
// import rowsData from 'api/DataSource.json'
import DrawerComponent from 'components/atoms/drawer'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'store/store'
import { Admin } from 'api/services'
import SnackbarComponent, {
  snackbarConfigTypes,
} from 'components/atoms/snackbar-component'
import { Variant } from 'types/props-types'
import { setPagination } from 'store/dataSourceTypesSlice'

import { setPage } from 'store/dataSourceTypesSlice'
import UserRoleHeader from './UserRoleHeader'

import { getProfileInfo } from 'api/apiUtility'
import EditOperationUserRole from './EditOperationUserRole'
import AddOperationUserRole from './AddOperationUserRole'

const OperationUserRoles = () => {
  const dispatch = useDispatch<AppDispatch>()
  const profileInfo = getProfileInfo()

  const [userRoles, setUserRoles] = useState<any>()

  const [showAddDrawerForm, setShowAddDrawerForm] = useState(false)
  const [reloadDSTable, setReloadDSTable] = useState(false)
  const [showEditDSForm, setShowEditDSForm] = useState<{
    isVisible: boolean
    data: any
    type: 'edit' | 'view' | 'delete' | ''
  }>({
    isVisible: false,
    data: {},
    type: '',
  })

  const [searchInput, setSearchInput] = useState('')
  const [alertConfig, setAlertConfig] = useState<snackbarConfigTypes>({
    isOpen: false,
    type: 'success',
    autoHideDuration: 4000,
    message: '',
  })
  const [selectedRows, setSelectedRows] = useState<any>()

  const [totalRecords, setTotalRecords] = useState(0)
  const [page, setApiPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const [drawerKey, setDrawerKey] = useState(0)

  const dsState = useSelector((state: RootState) => state.dataSourceSlice)

  const [tableRows, setTableRows] = useState([])
  const dsColumns = useMemo(() => {
    const columns: GridColDef[] = [
      {
        field: 'operation',
        valueGetter: (params: any) => {
          return params?.operation_name || ''
        },
        headerName: 'Operation',
        flex: 2,
      },
      {
        field: 'role',
        valueGetter: (params: any) => {
          return params?.label || ''
        },
        headerName: 'Roles',
        flex: 2,
      },
      {
        field: 'userCount',
        headerName: 'User Count',
        flex: 2,
      },
      {
        field: 'configuration',
        headerName: '',
        renderCell: (params) => {
          return (
            <Button
              sx={{ color: '#42526E', textTransform: 'none',padding:'5px !important' ,border:'none !important',background:'transparent !important'}}
              variant="text"
              startIcon={<RemoveRedEye  />}
              onClick={(event) => handleRowViewClick(event, params)}
              disabled={!userRoles?.view_configuration}
            >
              {/* Configuration */}
            </Button>
          )
        },
        flex: 1,
      },

      {
        field: 'edit',
        headerName: '',
        renderCell: (params) => {
          return (
            <Button
              sx={{ color: '#42526E', textTransform: 'none' ,padding:'5px !important' ,border:'none !important',background:'transparent !important'}}
              variant="text"
              startIcon={<Edit  />}
              onClick={(event) => handleRowEditClick(event, params)}
              disabled={!userRoles?.edit}
            >
              {/* Edit */}
            </Button>
          )
        },

        flex: 1,
      },
      {
        field: 'delete',
        headerName: '',
        renderCell: (params) => {
          return (
            <Button
              sx={{ color: '#42526E', textTransform: 'none',padding:'5px !important' ,border:'none !important',background:'transparent !important' }}
              variant="text"
              startIcon={<Delete  />}
              onClick={(event) => handleRowDeleteClick(event, params)}
              disabled={!userRoles?.delete}
            >
              {/* Delete */}
            </Button>
          )
        },
        flex: 1,
      },
    ]
    return columns
  }, [userRoles])

  useEffect(() => {
    const profileInfoRoles = profileInfo
    if (profileInfoRoles && !userRoles) {
      setUserRoles(profileInfoRoles?.orgRole?.permissions?.operation_member_roles)
    }
  }, [profileInfo, userRoles])

  useEffect(() => {
    setApiPage(dsState.paginationData.page)
  }, [dsState.paginationData.page])

  useEffect(() => {
    const newPage =
      Math.ceil(parseFloat(`${totalRecords / pageSize}`)) >= page ? page : 1
    setApiPage(newPage)
  }, [pageSize])

  useEffect(() => {
    dispatch(
      Admin.getOperationMemberRoles({
        pageNumber: page,
        pageSize: pageSize,
        search: { search: '' },
      }),
    )
      .then((res: any) => {
        if (res?.data?.totalResults) {
          const pagination = {
            totalRecords: res?.data?.totalResults,
            pageSize: pageSize,
          }
          dispatch(setPagination(pagination))
        }
        setTableRows(
          res?.payload?.dataDetails.map((item: any, index: number) => {
            return { ...item, id: index }
          }),
        )
        setTotalRecords(res?.payload?.totalResults)
        const pagination = {
          totalRecords: res?.payload?.totalResults,
          pageSize: pageSize,
        }
        dispatch(setPagination(pagination))
      })
      .catch((error) => {
        if (error.message) {
          handleShowAlert(error.message, 'error')
        } else {
          handleShowAlert('Something went wrong', 'error')
        }
      })
  }, [reloadDSTable, page, pageSize])

  const handleSearchQuery = useCallback(() => {
    dispatch(setPage(1))
    setTableRows([])
    dispatch(
      Admin.getOperationMemberRoles({
        pageNumber: page,
        pageSize: pageSize,
        search: { search: searchInput },
      }),
    )
      .then((res: any) => {
        if (res?.data?.totalResults) {
          const pagination = {
            totalRecords: res?.data?.totalResults,
            pageSize: pageSize,
          }
          dispatch(setPagination(pagination))
        }
        setTableRows(
          res?.payload?.dataDetails.map((item: any, index: number) => {
            return { ...item, id: index }
          }),
        )
        setTotalRecords(res?.payload?.totalResults)
        const pagination = {
          totalRecords: res?.payload?.totalResults,
          pageSize: pageSize,
        }
        dispatch(setPagination(pagination))
      })
      .catch((error) => {
        if (error.message) {
          handleShowAlert(error.message, 'error')
        } else {
          handleShowAlert('Something went wrong', 'error')
        }
      })
  }, [searchInput])

  const handleAddButtonClick = useCallback(() => {
    setShowAddDrawerForm(!showAddDrawerForm)
  }, [])

  const handleRowViewClick = useCallback((event: any, data: any) => {
    event.stopPropagation()

    setShowEditDSForm({ isVisible: true, data: data?.row, type: 'view' })
  }, [])

  const handleRowEditClick = useCallback((event: any, data: any) => {
    event.stopPropagation()

    setShowEditDSForm({ isVisible: true, data: data?.row, type: 'edit' })
  }, [])

  const handleRowDeleteClick = useCallback((event: any, data: any) => {
    event.stopPropagation()

    setShowEditDSForm({ isVisible: true, data: data?.row, type: 'delete' })
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setShowAddDrawerForm(false)
    setDrawerKey((prevKey) => prevKey + 1)
  }, [])

  const handleCloseEditDrawer = useCallback(() => {
    setShowEditDSForm({ isVisible: false, data: {}, type: '' })
    setDrawerKey((prevKey) => prevKey + 1)
  }, [])

  const handleTableReload = useCallback(() => {
    setReloadDSTable(!reloadDSTable)
  }, [reloadDSTable])

  const handleShowAlert = useCallback(
    (msg: string, type: Variant = 'success') => {
      setAlertConfig({
        ...alertConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [alertConfig],
  )

  const handleRemoveDataSource = useCallback(() => {
    const dsIds = {
      ids: selectedRows,
    }
    if (dsIds.ids?.length > 0) {
      Admin.removeSelectedUserRole(dsIds)
        .then((res: any) => {
          if (res?.status === 200) {
            handleTableReload()
            handleShowAlert('Role Permissions disabled Successfully')
          } else {
            handleTableReload()
            handleShowAlert('Something Went Wrong', 'error')
          }
        })
        .catch((error: any) => {
          const errorMessage = error.response
            ? error.response.data.message
            : error.message
          handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
        })
    }
  }, [selectedRows])

  const handleTablePageChange = useCallback(
    (params: any) => {
      setPageSize(params.pageSize)

      const pagination = {
        totalRecords: totalRecords,
        pageSize: params.pageSize,
      }
      dispatch(setPagination(pagination))
    },
    [totalRecords],
  )

  const handleDrawerHeading = useCallback((type: any) => {
    switch (type) {
      case 'edit':
        return 'Edit Operation User Roles'
      case 'view':
        return 'View Operation User Roles'
      case 'clone':
        return 'Clone Operation User Roles'
      case 'delete':
        return 'Delete Operation User Roles'
      default:
        return 'No Title'
    }
  }, [])

  return (
    <Fragment>
      <UserRoleHeader
        heading="Operation User Roles"
        addButtonClick={handleAddButtonClick}
        removeButtonClick={handleRemoveDataSource}
        search={searchInput}
        setSearch={setSearchInput}
        handleSearchQuery={handleSearchQuery}
        userRoles={userRoles}
      />
      <DataSourceTable
        rows={tableRows}
        columns={dsColumns}
        getSelectedRows={setSelectedRows}
        handlePageChange={handleTablePageChange}
        totalRecords={totalRecords}
        checkboxSelection={false}
        disableRowSelectionOnClick={true}
      />
      <DrawerComponent
        key={drawerKey}
        displayDrawer={showAddDrawerForm}
        width="424px"
      >
        <AddOperationUserRole
          heading="Add Role Permissions"
          onClose={handleCloseDrawer}
          triggerReload={handleTableReload}
          messageConfig={alertConfig}
          showMessage={setAlertConfig}
        />
      </DrawerComponent>
      {Object.keys(showEditDSForm.data).length > 0 && (
        <DrawerComponent
          key={drawerKey}
          displayDrawer={showEditDSForm.isVisible}
          width="424px"
        >
          <EditOperationUserRole
            heading={handleDrawerHeading(showEditDSForm.type)}
            onClose={handleCloseEditDrawer}
            data={showEditDSForm.data}
            triggerReload={handleTableReload}
            type={showEditDSForm.type}
            messageConfig={alertConfig}
            showMessage={setAlertConfig}
          />
        </DrawerComponent>
      )}
      <SnackbarComponent
        open={alertConfig.isOpen}
        setOpen={setAlertConfig}
        type={alertConfig.type}
        autoHideDuration={alertConfig.autoHideDuration}
        message={alertConfig.message}
        alertConfig={alertConfig}
      />
    </Fragment>
  )
}

export default OperationUserRoles
