/* eslint-disable prettier/prettier */
// import { makeStyles } from '@material-ui/core';
import React from 'react'
import { Dialog as MuiDialog } from '@mui/material'
import { makeStyles } from '@mui/styles'

interface DialogProps {
  children?: JSX.Element[] | JSX.Element
  handleClose: () => void
  open: boolean
  fullwidth?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
    backgroundColor: 'transparent !important',
  },
  
  topPaperScrollBody: {
    verticalAlign: 'top',
    backgroundColor: 'transparent !important',
  },
  transparentBackground: {
    backgroundColor: 'transparent !important',
  },
})

const Dialog = ({
  children,
  handleClose,
  open,
  fullwidth = false,
  maxWidth,
}: DialogProps) => {
  const classes = useStyles()
  return (
    <MuiDialog
      fullWidth={fullwidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      sx={{ background:'transparent !important'}}
      open={open}
      scroll="paper"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      {children}
    </MuiDialog>
  )
}

export default Dialog
