import { ArrowBack } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { Admin } from 'api/services'
import DropdownComponent from 'components/atoms/dropdown'

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { AppDispatch } from 'store/store'
import { EditOrgRolesProps } from 'types/props-types'
import DeleteIcon from '@mui/icons-material/Delete'

const EditOrgUserRole = ({
  heading,
  onClose,
  data,
  triggerReload,
  messageConfig,
  showMessage,
  type,
}: EditOrgRolesProps) => {
  const [userRoles, setUserRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState([])

  const [allUsers, setAllUsers] = useState<any>([])
  const [selectedUser, setSelectedUser] = useState([])

  const [error, setError] = useState<any>({})
  const [isSaveLoading, setSaveLoading] = useState(false)

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    setSelectedRole(data?._id?._id)
    setSelectedUser(data?.users)
  }, [])

  useEffect(() => {
    dispatch(
      Admin.getAllUserRoles({
        page: 1,
        size: 20,
        search: {
          status: 'ACTIVE',
          scope: 'ORG',
          searchAll: false,
        },
      }),
    )
      .then((res: any) => {
        setUserRoles(res?.payload?.data)
      })
      .catch((error) => {
        if (error.message) {
          handleShowAlert(error.message, 'error')
        } else {
          handleShowAlert('Something went wrong', 'error')
        }
      })
  }, [])

  useEffect(() => {
    dispatch(Admin.getAllUsers({ search: '' }))
      .then((res: any) => {
        setAllUsers(res.payload.data)
      })
      .catch((error) => {
        if (error.message) {
          handleShowAlert(error.message, 'error')
        } else {
          handleShowAlert('Something went wrong', 'error')
        }
      })
  }, [])

  const handleUserChange = useCallback(
    (_: any, value: any) => {
      if (type === 'edit' || type === 'delete') {
        if (value?.length > selectedUser.length) {
          const uniqueSet: any = new Set(
            value.map((obj: any) => JSON.stringify(obj)),
          )
          const uniqueData: any = [...uniqueSet].map((strObj) =>
            JSON.parse(strObj),
          )
          setSelectedUser(uniqueData)
        }
      } else {
        const uniqueSet: any = new Set(
          value.map((obj: any) => JSON.stringify(obj)),
        )
        const uniqueData: any = [...uniqueSet].map((strObj) =>
          JSON.parse(strObj),
        )
        setSelectedUser(uniqueData)
      }
      setError({ ...error, user: '' })
    },
    [selectedUser],
  )

  const handleDelete = useCallback((user_id: any, _id: any) => {
    setSelectedUser((prevSelected) =>
      prevSelected.filter((user: any) => user.user_id !== user_id),
    )
    if (type === 'delete') {
      dispatch(Admin.deleteUserOrgRole(_id))
        .then(() => {
          handleShowAlert('User Removed Successfully')
          triggerReload?.()
        })
        .catch((error) => {
          if (error.message) {
            handleShowAlert(error.message, 'error')
          } else {
            handleShowAlert('Something went wrong', 'error')
          }
        })
    }
  }, [])

  const handleRoleChange = useCallback((e: any) => {
    setSelectedRole(e.target.value)
    setError({ ...error, role: '' })
  }, [])

  const handleShowAlert = useCallback(
    (msg: string, type = 'success') => {
      showMessage({
        ...messageConfig,
        isOpen: true,
        type: type,
        message: msg,
      })
    },
    [messageConfig],
  )

  const handleRequiredFieldValidation = useCallback(() => {
    if (selectedRole.length === 0) {
      setError({ ...error, role: 'Please Select Role' })
      return false
    }

    if (selectedUser.length === 0) {
      setError({ ...error, user: 'Please Select User' })
      return false
    }

    return true
  }, [selectedRole, selectedUser, error, isSaveLoading])

  const handleSaveData = useCallback(() => {
    const isValidationOk = handleRequiredFieldValidation()

    if (!isValidationOk) {
      return
    }

    setSaveLoading(true)

    const newDataSource = {
      org_role_id: selectedRole,
      users: selectedUser.map((user: any) => user._id),
    }

    Admin.addEditUserRoles(newDataSource)
      .then((res: any) => {
        setSaveLoading(false)
        if (res?.status === 200) {
          triggerReload?.()
          handleShowAlert(
            res?.data?.message
              ? res.data.message
              : 'User Role Added Successfully',
          )
          onClose?.()
        } else {
          triggerReload?.()
          handleShowAlert(
            res?.error?.message ? res?.error?.message : 'Something Went Wrong',
            'error',
          )
          onClose?.()
        }
      })
      .catch((error: any) => {
        setSaveLoading(false)
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        handleShowAlert(errorMessage || 'Something Went Wrong', 'error')
      })
  }, [selectedRole, selectedUser, error, isSaveLoading])

  const handleDSError = useCallback(() => {
    if (typeof error?.config !== 'undefined') {
      if (error?.config !== '') {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }, [error])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '424px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '28px 0px 28px 0px',
          gap: '16px',
        }}
      >
        <ArrowBack sx={{ cursor: 'pointer' }} onClick={onClose} />
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          {heading}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '32px',
          overflowY: 'scroll',
          paddingTop: '20px',
        }}
      >
        {/* <SelectComponent /> */}
        <Box>
          <DropdownComponent
            sx={{ width: 361 }}
            size="small"
            label="Roles *"
            value={selectedRole}
            onChange={(e) => {
              handleRoleChange(e)
            }}
            error={handleDSError() || false}
            disabled={type === 'view' || type === 'edit'}
          >
            {userRoles?.length > 0 ? (
              userRoles.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>No Record Found</em>
              </MenuItem>
            )}
          </DropdownComponent>
          <Typography
            sx={{
              fontSize: '0.75rem',
              margin: '4px 0px 0px 14px',
              fontWeight: '400',
              color: '#d32f2f',
            }}
          >
            {error?.role}
          </Typography>
        </Box>

        <Box>
          <Autocomplete
            sx={{ width: 361 }}
            multiple
            limitTags={1}
            size="small"
            id="tags-outlined"
            value={selectedUser}
            options={allUsers || []}
            getOptionLabel={(option: any) => option.user_id}
            onChange={handleUserChange}
            disabled={type === 'view'}
            disableClearable={type === 'edit' || type === 'delete'}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Select Users *"
                placeholder="Select Users"
              />
            )}
            renderTags={(value) =>
              value.map((option: any, index) => (
                <div key={index} style={{ display: 'inline-block', margin: 2 }}>
                  {option.user_id}
                </div>
              ))
            }
          />
          <Typography
            sx={{
              fontSize: '0.75rem',
              margin: '4px 0px 0px 14px',
              fontWeight: '400',
              color: '#d32f2f',
            }}
          >
            {error?.user}
          </Typography>
          {selectedUser.length > 0 && (
            <Typography
              variant="body1"
              style={{ marginTop: '16px', fontWeight: '600' }}
            >
              Selected Users
            </Typography>
          )}
          <List
            sx={{
              width: 361,
              '& .MuiListItem-root': {
                padding: '0px',
              },
            }}
          >
            {selectedUser.map((user: any) => (
              <ListItem
                key={user._id}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {user.user_id}
                {type !== 'view' && type !== 'edit' && (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(user.user_id, user._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            marginRight: '40px',
            justifyContent: 'flex-end',
            paddingBottom: '15%',
          }}
        >
          {type !== 'view' && type !== 'delete' && (
            <Button
              sx={{ color: '#42526E' }}
              variant="text"
              onClick={handleSaveData}
              disabled={isSaveLoading}
            >
              {isSaveLoading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default EditOrgUserRole
