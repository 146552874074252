/* eslint-disable react/jsx-key */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Drawer,
  TextField,
  Select,
  MenuItem,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import {
  getAllDataPlatforms,
  getAllLanguageRuntimes,
  getAllStorageLocationTypes,
  getDataRepositoryDetailsByID,
  getDataRepositoryTypes,
  getOrgPreference,
  updateOrgPreference,
} from 'api/services/api-services'
import { decryptSettings, encryptObject } from 'utility/enc-dec'
import { dataSourceService } from 'api/services'
import SnackbarComponent, {
  snackbarConfigTypes,
} from 'components/atoms/snackbar-component'
import Notificationpreference from './Notificationpreference'
import { getProfileInfo, removeProfileInfo, removeToken } from 'api/apiUtility'
import { useNavigate } from 'react-router-dom'
import DropdownComponent from 'components/atoms/dropdown'
import axios from 'axios'

interface DataPlatform {
  id: string
  data_platforms_name: string
  data_platforms_display_name: string
}

interface LanguageRuntime {
  id: string
  language_runtimes_name: string
  language_runtimes_display_name: string
}

interface OrgPreference {
  supported_data_platforms: Array<{
    _id: string
    dataPlatformsName: string
    dataPlatformsDisplayName: string
  }>
  supported_language_runtimes: Array<{
    _id: string
    languageRuntimesName: string
    languageRuntimesDisplayName: string
  }>
}

const Orgpreference = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [data, setData] = useState<any>(null)
  const [selectedPreference, setSelectedPreference] = useState<any>(null)
  const [inputValues, setInputValues] = useState<any>({})
  const [dataPlatforms, setDataPlatforms] = useState<DataPlatform[]>([])
  const [languageRuntimes, setLanguageRuntimes] = useState<LanguageRuntime[]>(
    [],
  )
  const [selectedDataPlatforms, setSelectedDataPlatforms] = useState<
    Set<string>
  >(new Set())
  const [selectedLanguageRuntimes, setSelectedLanguageRuntimes] = useState<
    Set<string>
  >(new Set())
  const [tabValue, setTabValue] = useState(0)
  const [DocumentStorageLocationTypes, setDocumentStorageLocationTypes] =
    useState<any>()
  const [AllSourceLocationTypes, setAllSourceLocationTypes] = useState<any>()
  const [selectedlocationType, setSelectedLocationType] = useState('')
  const [selectedSourceType, setSelectedSourceType] = useState('')
  const [SelectedConfigurationByID, setSelectedConfigurationByID] = useState()
  const handleTabChange = (event: any, newValue: number) => {
    setTabValue(newValue)
  }
  // const[storagepreferenceID,setStoragePreferenceID]=useState();
  const [isConnectionTested, setConnectionTested] = useState(false)
  const [isTestLoading, setTestLoading] = useState(false)
  const [isSaveLoading, setSaveLoading] = useState(false)
  const [userRoles, setUserRoles] = useState<any>();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const handleDrawerOpen = (preference: any = null) => {
    setSelectedConfigurationByID(preference?._id || null)
    const initialValues = preference
      ? decryptSettings(preference?.configuration?.input).reduce(
          (acc: any, input: any) => ({ ...acc, [input.key]: input.value }),
          {},
        )
      : {} // Empty object if it's a new preference
    setInputValues(initialValues)
    setSelectedLocationType(preference?.storageLocationTypeId._id)
    setSelectedSourceType(preference?.dataRepositoryTypeId._id)
    if (preference)
      setSelectedPreference({
        ...preference,
        configuration: {
          ...preference?.configuration,
          input: decryptSettings(preference?.configuration?.input), // Make sure this is the decrypted value
        },
      })

    setDrawerOpen(true)
  }
  const navigate = useNavigate()
  const handleDrawerClose = () => {
    setConnectionTested(false)
    setTestLoading(false)
    setSaveLoading(false)
    setDrawerOpen(false)
    setSelectedPreference(null)
  }

  const handleInputChange = (key: string, value: any) => {
    setInputValues((prevValues: any) => ({
      ...prevValues,
      [key]: value,
    }))
  }

  const [alertConfig, setAlertConfig] = useState<snackbarConfigTypes>({
    isOpen: false,
    type: 'success',
    autoHideDuration: 4000,
    message: 'Operation successful!',
  })

  const showSnackbar = () => {
    setAlertConfig({ ...alertConfig, isOpen: true })
  }
  const profileInfo = getProfileInfo()

  useEffect(() => {
    const profileInfoRoles = profileInfo
    if (profileInfoRoles && !userRoles) {
      setUserRoles(profileInfoRoles?.orgRole?.permissions?.org_system_preferences
      )
    }
  }, [profileInfo, userRoles])








  const handleSubmit = () => {
    const payload = {
      supported_data_platforms: Array.from(selectedDataPlatforms),
      supported_language_runtimes: Array.from(selectedLanguageRuntimes),
      // default_storage_preferences: data?.default_storage_preferences.map(
      //   ({ _id, ...preference }: any) => ({
      //     locationType: selectedlocationType,
      //     dataRepositoryTypeId: selectedSourceType,
      //     configuration: {
      //       ...preference.configuration,
      //       input: preference.configuration.input.map((input: any) => ({
      //         ...input,
      //         value: inputValues[input.key] || input.value,
      //       })),
      //     },
      //   }),
      // ),
    }

    updateOrgPreference(payload)
      .then((response: any) => {
        setAlertConfig({
          ...alertConfig,
          isOpen: true,
          type: 'success',
          message: response?.data?.message
            ? response.data.message
            : 'Data Repository Connected Successfully',
        })
        getOrgPreference1()
        handleDrawerClose()
      })
      .catch((error: any) => {
        console.error('Error saving preferences:', error)
      })
  }
  const handleSubmitTab2 = () => {
    const payload = {
      // supported_data_platforms: Array.from(selectedDataPlatforms),
      // supported_language_runtimes: Array.from(selectedLanguageRuntimes),
      default_storage_preferences: [
        {
          storage_location_type_id: selectedlocationType,
          data_repository_type_id: selectedSourceType,
          configuration: {
            input: encryptObject(
              selectedPreference?.configuration?.input.map((input: any) => ({
                ...input,
                value: inputValues[input.key] || input.value,
              })),
            )['data'],
          },
          id: SelectedConfigurationByID,
        },
      ],
    }

    updateOrgPreference(payload)
      .then((response: any) => {
        getOrgPreference1()
        handleDrawerClose()
      })
      .catch((error: any) => {
        console.error('Error saving preferences:', error)
      })
  }

  const getOrgPreference1 = async () => {
    try {
      const response = await getOrgPreference()
      setData(response?.data || [])
      const { supported_data_platforms, supported_language_runtimes } =
        response.data || {}
      setSelectedDataPlatforms(
        new Set(supported_data_platforms.map((p: any) => p._id)),
      )
      setSelectedLanguageRuntimes(
        new Set(supported_language_runtimes.map((r: any) => r._id)),
      )
    } catch (error: any) {
      console.error('Error fetching organizations:', error)
    }
  }

  const getAllLanguageRuntimes1 = async () => {
    try {
      const response = await getAllLanguageRuntimes()
      setLanguageRuntimes(response?.data?.data || [])
    } catch (error: any) {
      console.error('Error fetching Language runtimes:', error)
    }
  }

  const getAllDataPlatforms1 = async () => {
    try {
      const response = await getAllDataPlatforms()
      setDataPlatforms(response?.data?.data || [])
    } catch (error: any) {
      console.error('Error fetching data platforms:', error)
    }
  }
  const getStorageLocationTypes = async () => {
    try {
      const response = await getAllStorageLocationTypes()
      setDocumentStorageLocationTypes(response?.data?.data || [])
    } catch (error: any) {
      console.error('Error fetching Types:', error)
    }
  }
  const getSourcetypes = async () => {
    try {
      const payload = {
        search: '',
        data_repository_type: null,
        db_type: ['DOCUMENT'],
        status: 'ACTIVE',
      }
      const response = await getDataRepositoryTypes(payload)
      setAllSourceLocationTypes(response?.data?.data || [])
    } catch (error: any) {
      console.error('Error fetching Types:', error)
    }
  }



 
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getAllDataPlatforms1()
        await getAllLanguageRuntimes1()
        await getOrgPreference1()
        await getStorageLocationTypes()
        await getSourcetypes()
      } catch (error: any) {
        console.error('Error fetching data sequentially:', error)
      }
    }

    fetchData()
  }, [])

  const handleDataPlatformChange = (id: string, checked: boolean) => {
    setSelectedDataPlatforms((prev) => {
      const newSet = new Set(prev)
      if (checked) {
        newSet.add(id)
      } else {
        newSet.delete(id)
      }
      return newSet
    })
  }

  const handleLanguageRuntimeChange = (id: string, checked: boolean) => {
    setSelectedLanguageRuntimes((prev) => {
      const newSet = new Set(prev)
      if (checked) {
        newSet.add(id)
      } else {
        newSet.delete(id)
      }
      return newSet
    })
  }

  const getConfigurationByID = async (id: string) => {
    try {
      const response = await getDataRepositoryDetailsByID(id)
      setSelectedPreference(response?.data)
    } catch (error: any) {
      console.error('Error fetching configuration:', error)
    }
  }

  useEffect(() => {
    //
    if (selectedSourceType) getConfigurationByID(selectedSourceType)
  }, [selectedSourceType])

  useEffect(() => {
    //log
  }, [selectedPreference])

  const getDatabyUsingKey = (key: any) => {
    const arr = selectedPreference?.configuration?.input
    const index = arr?.findIndex((item: any) => item?.key === key)
    if (index !== -1 && key !== 'port') {
      return inputValues[key]
    } else if (index !== -1 && key === 'port') {
      return parseInt(inputValues[key])
    } else {
      return null
    }
  }

  const handleTestConnection = () => {
    const caseType = selectedPreference?.dataRepositoryTypeId
      ?.dataRepositoryType
      ? selectedPreference.dataRepositoryTypeId.dataRepositoryType
      : selectedPreference?.data_repository_type

    const testObject: any = {
      repositoryType: caseType,
      databaseSettings: null,
      awsS3Settings: null,
      awsAthenaSettings: null,
      azureBlobStorageSettings: null,
      elasticSearchSettings: null,
    }

    switch (caseType) {
      case 'RELATIONAL_POSTGRESQL':
        testObject.databaseSettings = encryptObject({
          server: getDatabyUsingKey('server'),
          hostname: getDatabyUsingKey('hostname'),
          username: getDatabyUsingKey('username'),
          password: getDatabyUsingKey('password'),
          database: getDatabyUsingKey('database'),
          port: getDatabyUsingKey('port') | 0,
        })['data']
        break
      case 'AWS_ATHENA':
        testObject.awsAthenaSettings = encryptObject({
          regionName: getDatabyUsingKey('region_name'),
          awsAccessKeyId: getDatabyUsingKey('aws_access_key_id'),
          awsSecretAccessKey: getDatabyUsingKey('aws_secret_access_key'),
          s3StagingDir: getDatabyUsingKey('s3_staging_dir'),
          database: getDatabyUsingKey('database'),
        })['data']
        break
      case 'AWS_S3':
        testObject.awsS3Settings = encryptObject({
          regionName: getDatabyUsingKey('region_name'),
          awsAccessKeyId: getDatabyUsingKey('aws_access_key_id'),
          awsSecretAccessKey: getDatabyUsingKey('aws_secret_access_key'),
          bucketName: getDatabyUsingKey('bucket_name'),
          directoryAbsolutePath: getDatabyUsingKey('directory_absolute_path'),
        })['data']
        break
      case 'AZURE_BLOB_STORAGE':
        testObject.azureBlobStorageSettings = encryptObject({
          connectionString: getDatabyUsingKey('connection_string'),
          containerName: getDatabyUsingKey('container_name'),
        })['data']
        break
      case 'RELATIONAL_MSSQL':
        testObject.databaseSettings = encryptObject({
          server: getDatabyUsingKey('server'),
          hostname: getDatabyUsingKey('hostname'),
          username: getDatabyUsingKey('username'),
          password: getDatabyUsingKey('password'),
          database: getDatabyUsingKey('database'),
          port: getDatabyUsingKey('port') | 0,
        })['data']
        break
      case 'DGRAPH':
        testObject.dgraphSettings = encryptObject({
          dgraphEndpoint: getDatabyUsingKey('url'),
        })['data']
        break
      case 'ELASTIC_SEARCH':
        testObject.elasticSearchSettings = encryptObject({
          elasticSearchUrl: getDatabyUsingKey('elastic_search_url'),
          elasticCloudId: getDatabyUsingKey('elastic_cloud_id'),
          elasticApiKey: getDatabyUsingKey('elastic_api_key'),
        })['data']
    }

    dataSourceService
      .testDataSourceConnection(testObject)
      .then((res: any) => {
        // setTestLoading(false)
        // handleShowAlert(
        // res?.data?.message
        //   ? res.data.message
        //   : 'Data Repository Connected Successfully',
        // )
        setAlertConfig({
          ...alertConfig,
          isOpen: true,
          type: 'success',
          message: res?.data?.message
            ? res.data.message
            : 'Data Repository Connected Successfully',
        })
        setConnectionTested(true)
      })
      .catch((error: any) => {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message

        // setTestLoading(false)
        // handleShowAlert(errorMessage, 'error')
        setAlertConfig({
          ...alertConfig,
          type: 'error',
          isOpen: true,
          message: errorMessage,
        })
      })
  }

  return (
    <Grid container>
      <SnackbarComponent
        setOpen={setAlertConfig}
        type={alertConfig.type}
        autoHideDuration={alertConfig.autoHideDuration}
        message={alertConfig.message}
        alertConfig={alertConfig}
        open={alertConfig.isOpen}
      />
      <Grid
        item
        xs={12}
        sx={{
          height: '87vh',
          marginTop: '10px',
          paddingLeft: '50px',
          paddingRight: '50px',
        }}
      >
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab
            label="System Preferences"
            sx={{ color: '#808080', fontWeight: '600', fontSize: '14px' }}
            disabled={userRoles && !userRoles?.view}
          />
          <Tab
            label="Storage Location Preferences"
            sx={{ color: '#808080', fontWeight: '600', fontSize: '14px' }}
            disabled={userRoles && !userRoles?.view}
          />
          <Tab
            label="Notification Preferences"
            sx={{ color: '#808080', fontWeight: '600', fontSize: '14px' }}
            disabled={userRoles && !userRoles?.view}
          />
        </Tabs>

        {tabValue === 0 && (
          <>
            {/* System Preferences */}
            <Box
              sx={{
                marginTop: '20px',
                display: 'grid',
                // gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '20px',
              }}
            >
              <Card sx={{ background: 'transparent', boxShadow: 'none' }}>
                <CardContent sx={{ background: 'transparent' }}>
                  <Box sx={{ fontSize: '18px', fontWeight: '500' }}>
                    Supported Data Platforms
                  </Box>
                  <FormGroup>
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '20px', // Space between the columns
                        marginTop: '20px', // Apply margin to the entire row
                      }}
                    >
                      {dataPlatforms.map((platform) => (
                        <FormControlLabel
                          key={platform.id}
                          control={
                            <Checkbox
                              checked={selectedDataPlatforms.has(platform.id)}
                              onChange={(e) =>
                                handleDataPlatformChange(
                                  platform.id,
                                  e.target.checked,
                                )
                              }
                            />
                          }
                          label={platform.data_platforms_display_name}
                        />
                      ))}
                    </Box>
                  </FormGroup>
                </CardContent>
              </Card>
              <Card sx={{ background: 'transparent', boxShadow: 'none' }}>
                <CardContent sx={{ background: 'transparent' }}>
                  <Box sx={{ fontSize: '18px', fontWeight: '500' }}>
                    Supported Language Runtimes
                  </Box>
                  <FormGroup>
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '20px', // Space between the columns
                        marginTop: '20px', // Apply margin to the entire row
                      }}
                    >
                      {languageRuntimes.map((runtime) => (
                        <FormControlLabel
                          key={runtime.id}
                          control={
                            <Checkbox
                              checked={selectedLanguageRuntimes.has(runtime.id)}
                              onChange={(e) =>
                                handleLanguageRuntimeChange(
                                  runtime.id,
                                  e.target.checked,
                                )
                              }
                            />
                          }
                          label={runtime.language_runtimes_display_name}
                        />
                      ))}
                    </Box>
                  </FormGroup>
                </CardContent>
              </Card>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '40px',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Box>
          </>
        )}

        {tabValue === 1 && (
          <>
            {/* Storage Location Preferences */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                sx={{ marginBottom: '20px' }}
                disabled={userRoles && !userRoles?.create}
                onClick={() => {
                  setSelectedLocationType('')
                  setSelectedSourceType('')
                  setSelectedPreference(null)
                  handleDrawerOpen(null)
                }} // Open drawer with blank fields
              >
                Add New
              </Button>
            </Box>
            <TableContainer
              component={Paper}
              sx={{ background: 'transparent' }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Location Type</TableCell>
                    <TableCell>Data Repository Type ID</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.default_storage_preferences?.map((preference: any) => (
                    <TableRow>
                      <TableCell>
                        {
                          preference?.storageLocationTypeId
                            ?.storageLocationTypeDisplayName
                        }
                      </TableCell>
                      <TableCell>
                        {preference?.dataRepositoryTypeId?.label}
                      </TableCell>

                      <TableCell>
                        {/* <Button
                          variant="contained"
                          onClick={() => handleDrawerOpen(preference)}
                        >
                          Edit
                          
                        </Button> */}
                        <IconButton
                          onClick={() => handleDrawerOpen(preference)}
                          disabled={userRoles && !userRoles?.edit}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {tabValue === 2 && (
          <>
            {/* notification Preferences */}
            <Notificationpreference />
          </>
        )}
      </Grid>

      <Drawer
        anchor="left"
        open={drawerOpen}
        sx={{ zIndex: '300' }}
        onClose={handleDrawerClose}
      >
        <Box
          sx={{
            width: 400,
            padding: '20px',
            marginLeft: '60px',
            marginTop: '60px',
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ marginBottom: '20px' }}>
            {selectedPreference ? 'Edit Preference' : 'New Preference'}
          </Typography>
          <Box sx={{ display: 'grid', gap: '10px' }}>
            <>
              {/* <Typography>{'System Location Type'}</Typography> */}
              <DropdownComponent
                sx={{ width: 361, marginBottom: '20px' }}
                // required={true}
                label={'System Location Type *'}
                size="small"
                value={selectedlocationType || ''}
                onChange={(e) =>
                  setSelectedLocationType((e.target as HTMLInputElement).value)
                }
                // fullWidth
                error={false}
              >
                {DocumentStorageLocationTypes?.map((location: any) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.storage_location_type_display_name}
                  </MenuItem>
                ))}
              </DropdownComponent>

              {/* <Typography>{'Source Type'}</Typography> */}
              <DropdownComponent
                sx={{ width: 361 }}
                // required={true}
                label={'System Source Type *'}
                size="small"
                value={selectedSourceType || ''}
                onChange={(e) =>
                  setSelectedSourceType((e.target as HTMLInputElement).value)
                }
                fullWidth
              >
                {AllSourceLocationTypes?.map((source: any) => (
                  <MenuItem key={source.id} value={source.id}>
                    {source.data_repository_type}
                  </MenuItem>
                ))}
              </DropdownComponent>
            </>

            <Box component="form" noValidate autoComplete="off">
              {selectedPreference?.configuration?.input?.map(
                (input: any, index: number) => (
                  <Box sx={{ marginBottom: '15px' }} key={index}>
                    <Typography>
                      {input.label} {input.isRequired ? ' * ' : ' '}
                    </Typography>
                    {/* Render a TextField or Select based on input type */}
                    {input.type === 'TEXTINPUT' ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={inputValues[input.key] || ''}
                        // required={input.isRequired}
                        defaultValue={input.value}
                        onChange={(e) =>
                          handleInputChange(input.key, e.target.value)
                        }
                        // disabled={!input.isEditable}
                      />
                    ) : input.type === 'SELECT' ? (
                      <Select
                        fullWidth
                        size="small"
                        value={inputValues[input.key] || ''}
                        // required={input.isRequired}
                        defaultValue={input.value}
                        onChange={(e) =>
                          handleInputChange(input.key, e.target.value)
                        }
                        disabled={!input.isEditable}
                      >
                        {/* Replace these with actual dynamic options if available in API */}
                        {/* <MenuItem value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem> */}
                      </Select>
                    ) : null}
                  </Box>
                ),
              )}
            </Box>
          </Box>
          {/* <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: '20px' }}
            onClick={handleSubmitTab2}
          >
            Save
          </Button> */}
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              marginRight: '40px',
              justifyContent: 'flex-end',
              paddingBottom: '15%',
            }}
          >
            <Button
              sx={{
                color: '#42526E',
                // background: CUSTOM_COLORS.tableRowBgLightGray,
              }}
              variant="text"
              onClick={handleTestConnection}
              disabled={isTestLoading}
            >
              {isTestLoading ? (
                <CircularProgress size={24} />
              ) : (
                'Test Connection'
              )}
            </Button>

            <Button
              sx={{
                color: '#42526E',
                // background: CUSTOM_COLORS.tableRowBgLightGray,
              }}
              variant="text"
              onClick={handleSubmitTab2}
              disabled={isSaveLoading || !isConnectionTested}
            >
              {isSaveLoading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Grid>
  )
}

export default Orgpreference
