/* eslint-disable no-unused-vars */
import Logo from 'assets/img/cognicraft-logo.png'
const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const TooltipTemplate = (data: any) => {
  return ` <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Function Documentation</title>
    <style>
      * {
        margin: 0;
        padding: 0;
      }
      body {
        font-family: Arial, sans-serif;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      th,
      td {
        padding: 10px;
        border: 1px solid #ddd;
        text-align: left;
      }
      th {
        background-color: #f4f4f4;
      }
      h1 {
        text-align: center;
      }
    </style>
  </head>
  <body>
    <div
      style="
        width: 100%;
        min-height: 50px;
        background-color: #D9E6F2;
        display: flex;
        margin-bottom: 20px;
      "
    >
      <div style="display: flex; align-items: center; margin-left: 20px">
        <img
          src=${Logo}
          width="30px"
          height="30px"
          alt="Cognicraft Logo"
        />      
        </div>
      <div
        style="
          display: flex;
          flex: 1;
          align-items: center;
          margin-left: 20px;
          justify-content: flex-start;
        "
      >
        <h2>Function Help</h2>
      </div>
    </div>
    <table>
      <tbody>
        ${Object.keys(data)
          .map((key) => {
            return `<tr>
                      <th>${capitalizeFirstLetter(key)}:</th>
                      <td>${data[key] || ''}</td>
                    </tr>`
          })
          .join('')}
      </tbody>
    </table>
  </body>
</html>
`
}
export const TooltipNoDataFound = () => {
  return ` <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Function Documentation</title>
    <style>
      * {
        margin: 0;
        padding: 0;
      }
      body {
        font-family: Arial, sans-serif;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      th,
      td {
        padding: 10px;
        border: 1px solid #ddd;
        text-align: left;
      }
      th {
        background-color: #f4f4f4;
      }
      h1 {
        text-align: center;
      }
    </style>
  </head>
  <body>
    <div
      style="
        width: 100%;
        min-height: 50px;
        background-color: #D9E6F2;
        display: flex;
        margin-bottom: 20px;
      "
    >
      <div style="display: flex; align-items: center; margin-left: 20px">
        <img
          src=${Logo}
          width="30px"
          height="30px"
          alt="Cognicraft Logo"
        />      
        </div>
      <div
        style="
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: flex-start;
          margin-left: 20px;
          margin-right: 20px;
        "
      >
        <h2>Function Help</h2>
      </div>
    </div>
      <table style="width:100%">
        <thead>
          <tr>
           <th>No Data Found</th>
          </tr>
        </thead>
        <tbody>
          <tr>
           
          </tr>
          <!-- You can add more rows for other functions in a similar manner -->
        </tbody>
      </table>
  </body>
</html>
`
}
