import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { TOKEN_INFINITIX } from 'utility/constants'

const useAuth = () => {
  const user = localStorage.getItem(TOKEN_INFINITIX)
  if (user) {
    return true
  } else {
    return false
  }
}

const PublicRoutes = () => {
  const auth = useAuth()
  return auth ? (
    <>
      <Navigate to={'/'} />
    </>
  ) : (
    <Outlet />
  )
}

export default PublicRoutes
