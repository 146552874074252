/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import {
  Box,
  Button,
  Container,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'

import DrawerComponent from 'components/atoms/drawer'
import CloseIcon from '@mui/icons-material/Close'
import useClasses from 'hooks/styleHook'
import { styles } from '../../../style'
import { ApiServices } from 'api/services'
import _ from 'lodash'
import { getRandomNumber } from 'utility/utility'
import {
  CONFIGURATION_INPUT_TYPES,
  DefaultTranformerPopup,
  NODE_TYPES_NAMES,
} from '../../data-pipeline-constants'

import {
  ConfigurationInputInterface,
  NodeDrawerPropertyInterface,
} from '../../data-pipeline-types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Dialog from 'components/molecules/Dialog'

import { removeProfileInfo, removeToken } from 'api/apiUtility'
import DynamicFormForNodeDetails from '../../components/DynamicFormForNodeDetails'
import StreamingDataDialog from './StreamingDataDialog'
import FolderExplorer from '../../FolderExplorer'

const DefaultNodeProperty: NodeDrawerPropertyInterface = {
  nodeType: '',
  show: false,
  isEdit: false,
  nodeAttached: null,
}

const DrawerWidth = 550
const StreamingDataPipeline = forwardRef((props: any, ref) => {
  const {
    showNodePropertiesDrawer,
    setLoading,
    setNodePropertiesDrawer,
    getProjectDetails,
    projectId,
    componentId,
    selectedNode,
    componentDetailsData,
    NODE_TYPES,
    projectRole,
  } = props
  const [drawerConfiguration, setDrawerConfiguration] = useState<
    ConfigurationInputInterface[]
  >([])
  const [
    tranformationOperationConfiguration,
    setTranformationOperationConfiguration,
  ] = useState<ConfigurationInputInterface[]>([])

  const [nodeName, setNodeName] = useState('')
  const [componentNodeDetailConfig, setComponentNodeDetailConfig] =
    useState<any>({})
  const [openTransformerPopup, setOpenTransformerPopup] = useState(
    DefaultTranformerPopup,
  )
  const [searchParams] = useSearchParams()
  const [outputNodeData, setOutputNodeData] = useState<any>()
  const [isInputNodeError, setInputNodeError] = useState(false)
  const [componentNodeTemplateId, setComponentNodeTemplateId] = useState<any>()
  const [componentNodeTypeId, setComponentNodeTypeId] = useState<any>()
  const [selectedTranformationTemplateId, setselectedTranformationTemplateId] =
    useState(null)
  const [componentType, setComponentType] = useState('')
  const [originNodeDetail, setOriginNodeDetail]: any = useState([])

  const [inputTable, setInputTable]: any = useState('')

  const classes = useClasses(styles)

  const singleTextSearchInputRef = useRef(null)
  const [singleTextSearch, setSingleTextSearch] = useState('')

  const multiTextSearchInputRef = useRef(null)
  const [multiTextSearch, setMultiTextSearch] = useState('')

  const [openFolderExplorer, setOpenFolderExplorer] = useState({
    isOpen: false,
    type: '',
    data: {},
    input: {},
  })

  const navigate = useNavigate()

  const MultiObjTextSearchInputRef = useRef(null)
  const [MultiObjTextSearch, setMultiObjSearch] = useState('')

  useEffect(() => {
    const inputElement: any = singleTextSearchInputRef.current
    const handleFocus = () => {
      if (inputElement) {
        inputElement.focus()
      }
    }
    if (inputElement) {
      inputElement.addEventListener('blur', handleFocus)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('blur', handleFocus)
      }
    }
  }, [singleTextSearch])

  useEffect(() => {
    const inputElement: any = multiTextSearchInputRef.current
    const handleFocus = () => {
      if (inputElement) {
        inputElement.focus()
      }
    }
    if (inputElement) {
      inputElement.addEventListener('blur', handleFocus)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('blur', handleFocus)
      }
    }
  }, [multiTextSearch])

  useEffect(() => {
    const inputElement: any = MultiObjTextSearchInputRef.current
    const handleFocus = () => {
      if (inputElement) {
        inputElement.focus()
      }
    }
    if (inputElement) {
      inputElement.addEventListener('blur', handleFocus)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('blur', handleFocus)
      }
    }
  }, [MultiObjTextSearch])

  useEffect(() => {
    const compType = searchParams.get('componentType')
    compType && setComponentType(compType)
  }, [searchParams])

  const handleSingleFolderClick = (input: any) => {
    const repositoryId = componentNodeDetailConfig.configuration.input.find(
      (item: any) => item.key === NODE_TYPES.target.keys.target_repository,
    )

    if (repositoryId && repositoryId.dropdownData.length > 0) {
      const repositoryData = repositoryId.dropdownData.find(
        (item: any) => item.id === repositoryId.value,
      )

      if (repositoryData) {
        setOpenFolderExplorer({
          isOpen: true,
          type: 'single',
          data: repositoryData,
          input: input,
        })
      }
    }
  }

  const handleFileExplorerCloseClick = () => {
    setOpenFolderExplorer({ isOpen: false, type: '', data: {}, input: {} })
  }

  const getDataRepository = useCallback(
    async (key: string, dbTypeProp?: string) => {
      let dbType = ''
      if (dbTypeProp) {
        dbType = dbTypeProp
      }
      try {
        const payload = {
          data_repository_status: ['LIVE'],
          db_type: dbType ? [dbType] : [],
        }
        const resp: any = await ApiServices.getDataRepository(payload)
        const sourceList = resp.data.data
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [componentType],
  )

  const getInputTableData = useCallback(
    async (arg: any) => {
      const { dataRepositoryId, key } = arg
      try {
        setLoading(true)
        const resp: any = await ApiServices.getInputTableData(dataRepositoryId)
        setLoading(false)
        const sourceList = resp.data.dataset
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
            // repository.value = ''
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getInputFolderData = useCallback(
    async (arg: any) => {
      const { dataRepositoryId, key } = arg
      try {
        setLoading(true)
        const resp: any = await ApiServices.getInputFolderData(dataRepositoryId)
        setLoading(false)
        const sourceList = resp.data.dataset
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
            // repository.value = ''
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getRetainColumnsData = useCallback(
    async (arg: any) => {
      const { dataRepositoryId, dataSets, key } = arg

      try {
        setLoading(true)
        const resp: any = await ApiServices.getRetainColumnsData(
          dataRepositoryId,
          dataSets,
        )
        setLoading(false)

        const sourceList = resp.data.columns

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getInputNodes = useCallback(
    async (arg: Record<string, string>) => {
      const { key, componentNodeId } = arg

      try {
        const payload = {
          componentId,
          componentNodeId,
        }
        const resp: any = await ApiServices.getInputNodes(payload)
        const sourceList = resp.data
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )

          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [componentId],
  )

  const getOutputNodes = useCallback(async (arg: Record<string, any>) => {
    const { key: inputDataFrameKey, inputNodeIds, key2: retainColumnsKey } = arg
    try {
      const payload = {
        inputNodeIds: JSON.stringify(inputNodeIds),
      }
      const resp: any = await ApiServices.getOutputNodes(payload)
      const sourceList = resp.data
      setOutputNodeData(sourceList)
      setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
        const newData = [...prev]
        const repository_inputDataFrame = newData.find(
          (item: ConfigurationInputInterface) => item.key === inputDataFrameKey,
        )
        if (repository_inputDataFrame) {
          repository_inputDataFrame.dropdownData =
            sourceList.output_dataframe || sourceList.retain_variables
        }
        if (retainColumnsKey) {
          const repository_retainColumns = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === retainColumnsKey,
          )

          if (repository_retainColumns) {
            repository_retainColumns.dropdownData = sourceList.retain_columns
          }
        }

        return [...newData]
      })
    } catch (error: any) {
      if (
        error?.response?.data?.error ===
          'User session not authorized. Please sign in' ||
        error?.response?.data?.error === 'jwt expired'
      ) {
        removeToken()
        removeProfileInfo()
        navigate('/login')
      }
      console.log('error', error)
    }
  }, [])

  const getColumnsToFilterData = useCallback(
    async (arg: any) => {
      const { dataRepositoryId, dataSets, key } = arg

      try {
        setLoading(true)
        const resp: any = await ApiServices.getRetainColumnsData(
          dataRepositoryId,
          dataSets,
        )
        setLoading(false)

        const sourceList = resp.data.columns

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === key,
          )
          if (repository) {
            repository.dropdownData = sourceList
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getInputOutputFieldData = useCallback(
    async (arg: any) => {
      const { inputKey, outputKey, outputKey2, id } = arg
      let param = { ids: [''] }
      try {
        setLoading(true)
        if (id) {
          param = {
            ids: [id],
          }
        } else {
          param = {
            ids: [
              `${componentDetailsData.configuration.input.nodes[0].data.id}`,
            ],
          }
        }
        const resp: any = await ApiServices.getComponentOutputNodes(param.ids)
        setLoading(false)
        const sourceList = resp.data
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === inputKey,
          )
          if (repository) {
            repository.dropdownData = sourceList.filter_columns
            // repository.value = ''
          }
          return [...newData]
        })

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === outputKey,
          )
          if (repository) {
            repository.dropdownData = sourceList.retain_columns
            // repository.value = ''
          }
          return [...newData]
        })

        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) => item.key === outputKey2,
          )
          if (repository) {
            repository.dropdownData = sourceList.retain_columns
            // repository.value = ''
          }
          return [...newData]
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading, componentDetailsData],
  )

  const updateEditFields = useCallback(
    (arg: any) => {
      const {
        componentInputConfigurationData,
        isEdit = false,
        originNodeId,
      } = arg
      const inputConfiguration =
        componentInputConfigurationData.configuration.input

      if (componentInputConfigurationData) {
        let nodeType = ''
        if (componentInputConfigurationData.component_node_type_id) {
          nodeType =
            componentInputConfigurationData.component_node_type_id
              .componentNodeType
        } else if (componentInputConfigurationData.component_node_type) {
          nodeType = componentInputConfigurationData.component_node_type
        }
        if (
          nodeType ===
          NODE_TYPES_NAMES.STREAMING_01_DATA_SOURCE.componentNodeType
        ) {
          getDataRepository(
            NODE_TYPES.source.keys.source_repository,
            'STREAMING',
          )
          if (isEdit) {
            const sourceRepositoryValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.source.keys.source_repository,
            )?.value

            const inputTableValue = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.source.keys.input_table,
            )?.value
            if (sourceRepositoryValue)
              getInputTableData({
                dataRepositoryId: sourceRepositoryValue,
                key: NODE_TYPES.source.keys.input_table,
              })
            if (sourceRepositoryValue && inputTableValue) {
              getRetainColumnsData({
                dataRepositoryId: sourceRepositoryValue,
                dataSets: inputTableValue,
                key: NODE_TYPES.source.keys.retain_columns,
              })
            }
          }
        } else if (
          nodeType ===
          NODE_TYPES_NAMES.STREAMING_03_TRANSFORMATION.componentNodeType
        ) {
          const inputNodesValue = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.transformation.keys.input_node,
          )?.value
          setOriginNodeDetail(inputNodesValue)
          if (inputNodesValue) {
            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.transformation.keys.input_data_frame,
              key2: NODE_TYPES.transformation.keys.retain_columns,
            })
          }

          if (isEdit) {
            getInputNodes({
              key: NODE_TYPES.target.keys.input_node,
              componentNodeId: originNodeId,
            })
          }
        } else if (
          nodeType ===
          NODE_TYPES_NAMES.STREAMING_04_DATA_TARGET.componentNodeType
        ) {
          getDataRepository(NODE_TYPES.target.keys.target_repository)

          const inputNodesValue = inputConfiguration.find(
            (item: any) =>
              item.key === NODE_TYPES.transformation.keys.input_node,
          )?.value

          if (inputNodesValue) {
            getOutputNodes({
              inputNodeIds: inputNodesValue,
              key: NODE_TYPES.transformation.keys.input_data_frame,
              key2: NODE_TYPES.transformation.keys.retain_columns,
            })
          }

          if (isEdit) {
            getInputNodes({
              key: NODE_TYPES.target.keys.input_node,
              componentNodeId: originNodeId,
            })
            const sourceRepositoryValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.target.keys.source_repository,
            )?.value
            const targetRepositoryValue = inputConfiguration.find(
              (item: any) =>
                item.key === NODE_TYPES.target.keys.target_repository,
            )?.value

            const outputTableValue = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.target.keys.output_table,
            )?.value

            if (targetRepositoryValue) {
              getInputTableData({
                dataRepositoryId: targetRepositoryValue,
                key: NODE_TYPES.target.keys.output_table,
              })
              getInputFolderData({
                dataRepositoryId: targetRepositoryValue,
                key: NODE_TYPES.documentProcessing.keys.output_table,
              })
            }
            if (targetRepositoryValue && outputTableValue) {
              getRetainColumnsData({
                dataRepositoryId: targetRepositoryValue,
                dataSets: outputTableValue,
                key: NODE_TYPES.target.keys.retain_columns,
              })
            }

            const inputTableValue = inputConfiguration.find(
              (item: any) => item.key === NODE_TYPES.target.keys.input_table,
            )?.value
            if (sourceRepositoryValue)
              getInputTableData({
                dataRepositoryId: sourceRepositoryValue,
                key: NODE_TYPES.target.keys.input_table,
              })

            if (sourceRepositoryValue && inputTableValue) {
              getRetainColumnsData({
                dataRepositoryId: sourceRepositoryValue,
                dataSets: inputTableValue,
                key: NODE_TYPES.target.keys.retain_columns,
              })
            }
          }
        }
      }
    },
    [
      getDataRepository,
      getInputTableData,
      getRetainColumnsData,
      getInputNodes,
      NODE_TYPES,
      componentDetailsData,
    ],
  )

  const getComponentNodeDetailsForTransformation = useCallback(
    async (arg: any) => {
      const { templateId } = arg
      try {
        setLoading(true)
        const component_node_type_id = templateId
        const resp = await ApiServices.getComponentNodeDetailsConfiguration(
          component_node_type_id,
        )
        setLoading(false)
        const inputConfiguration = resp.data
        setTranformationOperationConfiguration(inputConfiguration)
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const handleTransformationPopup = useCallback(
    (transformerPopupProperty: any) => {
      const objectType = transformerPopupProperty.objectType
      const checkInputNodeValue = drawerConfiguration.find(
        (item) => item.key === NODE_TYPES.transformation.keys.input_node,
      )
      const inputNodeNotRequiredIds = [
        NODE_TYPES_NAMES.DOCUMENT_01_SOURCE.id,
        NODE_TYPES_NAMES.RETRIEVAL_01_REQUEST.id,
        NODE_TYPES_NAMES.TRAINING_01_SOURCE.id,
        NODE_TYPES_NAMES.API_01_DATA_SOURCE.id,
      ]
      let sourceTypeId
      if (componentNodeDetailConfig?.component_node_type_id?._id) {
        sourceTypeId = componentNodeDetailConfig?.component_node_type_id?._id
      } else {
        sourceTypeId = componentNodeDetailConfig?.id
      }

      if (
        (!checkInputNodeValue || checkInputNodeValue.value.length === 0) &&
        !inputNodeNotRequiredIds.includes(sourceTypeId)
      ) {
        setInputNodeError(true)
        return
      }
      setselectedTranformationTemplateId(objectType)
      getComponentNodeDetailsForTransformation({ templateId: objectType })

      setOpenTransformerPopup(transformerPopupProperty)
    },
    [drawerConfiguration, getComponentNodeDetailsForTransformation, NODE_TYPES],
  )

  const handleTransformationDialogClose = useCallback(() => {
    setOpenTransformerPopup(DefaultTranformerPopup)
  }, [])

  useEffect(() => {
    if (selectedNode && selectedNode.id) {
      const component_node_type_id = selectedNode.component_node_type_id
      const nodeType = component_node_type_id

      setNodePropertiesDrawer({
        nodeType,
        show: true,
        isEdit: true,
      })
      const nodeId = selectedNode.id
      const getComponentNode = async () => {
        try {
          const resp = await ApiServices.getComponentNodeDetails(nodeId)
          const inputConfiguration = resp.data.configuration.input
          setComponentNodeDetailConfig(resp.data)
          setComponentNodeTemplateId(resp.data?.component_node_template_id?._id)
          setComponentNodeTypeId(resp.data?.component_node_type_id?._id)
          setDrawerConfiguration(inputConfiguration)
          updateEditFields({
            componentInputConfigurationData: resp.data,
            isEdit: true,
            originNodeId: nodeId,
          })
        } catch (error: any) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
          console.log('error', error)
        }
      }
      getComponentNode()
    }
  }, [selectedNode, setNodePropertiesDrawer, updateEditFields])

  const getComponentNodeDetailsConfiguration = useCallback(
    async ({ nodeTypeId, originNodeDetail }: any) => {
      try {
        const resp: any =
          await ApiServices.getComponentNodeDetailsConfiguration(nodeTypeId)
        const inputConfiguration = resp.data.configuration.input
        setComponentNodeDetailConfig(resp.data)
        setComponentNodeTypeId(resp.data?.id)
        //todo: code not deleted for future reference
        // if (
        //   originNodeDetail &&
        //   (originNodeDetail.newNodeTypeName ===
        //     NODE_TYPES.transformation.componentNodeTypeName ||
        //     originNodeDetail.newNodeTypeName ===
        //       NODE_TYPES.target.componentNodeTypeName)
        // ) {
        if (
          originNodeDetail &&
          originNodeDetail.id &&
          originNodeDetail.component_node_name
        ) {
          const repository = inputConfiguration.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.transformation.keys.input_node,
          )
          if (repository) {
            repository.dropdownData = [
              {
                id: originNodeDetail.id,
                component_node_name: originNodeDetail.component_node_name,
              },
            ]
            repository.value = [originNodeDetail.id]
            if (resp.data) {
              let nodeType = ''
              if (resp.data.component_node_type_id) {
                nodeType = resp.data.component_node_type_id.componentNodeType
              } else if (resp.data.component_node_type) {
                nodeType = resp.data.component_node_type
              }
              if (
                nodeType ===
                NODE_TYPES_NAMES.API_02_API_OUTPUT.componentNodeType
              ) {
                getInputOutputFieldData({
                  inputKey: NODE_TYPES.source.keys.filter_columns,
                  outputKey: NODE_TYPES.source.keys.retain_columns,
                  outputKey2: NODE_TYPES.source.keys.retain_columns_02,
                  id: originNodeDetail.id,
                })
              }
            }
            setOriginNodeDetail([originNodeDetail.id])
          }
        }
        // }

        setDrawerConfiguration(inputConfiguration)
        updateEditFields({
          componentInputConfigurationData: resp.data,
          originNodeDetail,
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [updateEditFields, NODE_TYPES],
  )

  const getComponentNodeTemplateConfiguration = useCallback(
    async ({ nodeTypeId, originNodeDetail }: any) => {
      setComponentNodeTemplateId(nodeTypeId)
      try {
        const resp: any =
          await ApiServices.getComponentNodeTemplateConfiguration(nodeTypeId)
        const inputConfiguration = resp.data.configuration.input
        setComponentNodeDetailConfig(resp.data)
        //todo: code not deleted for future reference
        // if (
        //   originNodeDetail &&
        //   originNodeDetail.newNodeTypeName ===
        //     NODE_TYPES.transformation.componentNodeTypeName
        // ) {
        if (
          originNodeDetail &&
          originNodeDetail.id &&
          originNodeDetail.component_node_name
        ) {
          const repository = inputConfiguration.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.transformation.keys.input_node,
          )
          if (repository) {
            repository.dropdownData = [
              {
                id: originNodeDetail.id,
                component_node_name: originNodeDetail.component_node_name,
              },
            ]
            repository.value = [originNodeDetail.id]
            if (resp.data) {
              let nodeType = ''
              if (resp.data.component_node_type_id) {
                nodeType = resp.data.component_node_type_id.componentNodeType
              } else if (resp.data.component_node_type) {
                nodeType = resp.data.component_node_type
              }
              if (
                nodeType ===
                NODE_TYPES_NAMES.API_02_API_OUTPUT.componentNodeType
              ) {
                getInputOutputFieldData({
                  inputKey: NODE_TYPES.source.keys.filter_columns,
                  outputKey: NODE_TYPES.source.keys.retain_columns,
                  outputKey2: NODE_TYPES.source.keys.retain_columns_02,
                  id: originNodeDetail.id,
                })
              }
            }
            setOriginNodeDetail([originNodeDetail.id])
          }
        }
        // }
        setDrawerConfiguration(inputConfiguration)
        updateEditFields({
          componentInputConfigurationData: resp.data,
          originNodeDetail,
        })
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        console.log('error', error)
      }
    },
    [updateEditFields, NODE_TYPES],
  )

  const changeInputConfigurationOfInputTable = (isDocument: boolean) => {
    if (isDocument) {
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === NODE_TYPES.target.keys.output_table,
      )
      if (index > -1) {
        updateDrawerConfiguration[index].type =
          CONFIGURATION_INPUT_TYPES.SINGLEFILE
      }
      setDrawerConfiguration(updateDrawerConfiguration)
    } else {
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === NODE_TYPES.target.keys.output_table,
      )
      if (index > -1) {
        updateDrawerConfiguration[index].type =
          CONFIGURATION_INPUT_TYPES.SINGLETEXT
      }
      setDrawerConfiguration(updateDrawerConfiguration)
    }
  }

  const onDropDownChange = useCallback(
    (arg: any) => {
      const { input, e: event } = arg
      const inputConfiguration = drawerConfiguration
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        updateDrawerConfiguration[index].value = event.target.value
      }
      setDrawerConfiguration(updateDrawerConfiguration)
      if (input.key === NODE_TYPES.source.keys.source_repository) {
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.input_table,
          )
          if (repository) {
            repository.dropdownData = []
            repository.value = ''
          }
          const retainColrepository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.retain_columns,
          )
          if (retainColrepository) {
            retainColrepository.dropdownData = []
            retainColrepository.value = []
          }

          if (componentDetailsData?.component_type === 'DATA_ACCESS_API') {
            const filterColrepository = newData.find(
              (item: ConfigurationInputInterface) =>
                item.key === NODE_TYPES.source.keys.filter_columns,
            )
            if (filterColrepository) {
              filterColrepository.dropdownData = []
              filterColrepository.value = []
            }
          }

          const dqMetricItem = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.dq_metrics,
          )
          if (dqMetricItem) {
            dqMetricItem.dropdownData = []
            dqMetricItem.value = []
          }
          const dq_checksItem = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.dq_checks,
          )
          if (dq_checksItem) {
            dq_checksItem.dropdownData = []
            dq_checksItem.value = []
          }
          return [...newData]
        })
        const inputTable = inputConfiguration.find(
          (item: any) => item.key === NODE_TYPES.source.keys.input_table,
        )
        const inputFolder = inputConfiguration.find(
          (item: any) =>
            item.key === NODE_TYPES.documentProcessing.keys.input_folder,
        )
        if (inputTable) {
          getInputTableData({
            dataRepositoryId: event.target.value,
            key: NODE_TYPES.source.keys.input_table,
          })
        }
        if (inputFolder) {
          getInputFolderData({
            dataRepositoryId: event.target.value,
            key: NODE_TYPES.documentProcessing.keys.input_folder,
          })
        }
      } else if (
        input.key === NODE_TYPES.documentProcessing.keys.master_index_repository
      ) {
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key ===
              NODE_TYPES.documentProcessing.keys.master_index_table,
          )
          if (repository) {
            repository.dropdownData = []
            repository.value = ''
          }
          return [...newData]
        })
        const inputTable = inputConfiguration.find(
          (item: any) =>
            item.key === NODE_TYPES.documentProcessing.keys.master_index_table,
        )

        if (inputTable) {
          getInputTableData({
            dataRepositoryId: event.target.value,
            key: NODE_TYPES.documentProcessing.keys.master_index_table,
          })
        }
      } else if (input.key === NODE_TYPES.source.keys.input_table) {
        let dataRepositoryId = ''
        const sourceRepository = updateDrawerConfiguration.find(
          (item) => item.key === NODE_TYPES.source.keys.source_repository,
        )
        const filterColumns = updateDrawerConfiguration.find(
          (item) => item.key === NODE_TYPES.source.keys.filter_columns,
        )

        if (sourceRepository) dataRepositoryId = sourceRepository.value
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]

          const retainColrepository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.retain_columns,
          )
          if (retainColrepository) {
            retainColrepository.dropdownData = []
            retainColrepository.value = []
          }
          const dqMetricItem = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.dq_metrics,
          )

          if (componentDetailsData?.component_type === 'DATA_ACCESS_API') {
            const filterColrepository = newData.find(
              (item: ConfigurationInputInterface) =>
                item.key === NODE_TYPES.source.keys.filter_columns,
            )
            if (filterColrepository) {
              filterColrepository.dropdownData = []
              filterColrepository.value = []
            }
          }

          if (dqMetricItem) {
            dqMetricItem.dropdownData = []
            dqMetricItem.value = []
          }

          const dq_checksItem = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.source.keys.dq_checks,
          )
          if (dq_checksItem) {
            dq_checksItem.dropdownData = []
            dq_checksItem.value = []
          }
          return [...newData]
        })

        setInputTable(event.target.value)
        getRetainColumnsData({
          dataRepositoryId,
          dataSets: event.target.value,
          key: NODE_TYPES.source.keys.retain_columns,
        })

        if (filterColumns) {
          getColumnsToFilterData({
            dataRepositoryId: dataRepositoryId,
            dataSets: event.target.value,
            key: NODE_TYPES.source.keys.filter_columns,
          })
        }
      } else if (input.key === NODE_TYPES.target.keys.target_repository) {
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.target.keys.output_table,
          )
          if (repository) {
            repository.dropdownData = []
            repository.value = ''
          }
          const retainColrepository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.target.keys.retain_columns,
          )
          if (retainColrepository) {
            retainColrepository.dropdownData = []
            retainColrepository.value = []
          }

          return [...newData]
        })

        const outputTable = updateDrawerConfiguration.find(
          (item) => item.key === NODE_TYPES.target.keys.output_table,
        )

        if (outputTable) {
          const allRepositoryData = inputConfiguration.find(
            (item) => item.key === NODE_TYPES.target.keys.target_repository,
          )
          if (allRepositoryData) {
            const selectedRepository = allRepositoryData.dropdownData.find(
              (item: any) => item.id === event.target.value,
            )

            if (selectedRepository.db_type.includes('DOCUMENT')) {
              changeInputConfigurationOfInputTable(true)
              getInputFolderData({
                dataRepositoryId: event.target.value,
                key: NODE_TYPES.documentProcessing.keys.output_table,
              })
            } else {
              changeInputConfigurationOfInputTable(false)
              getInputTableData({
                dataRepositoryId: event.target.value,
                key: NODE_TYPES.target.keys.output_table,
              })
            }
          }
        }
      } else if (input.key === NODE_TYPES.transformation.keys.input_node) {
        setInputNodeError(false)
        setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
          const newData = [...prev]
          const repository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.transformation.keys.input_data_frame,
          )
          if (repository) {
            repository.dropdownData = []
            repository.value = ''
          }
          const retainColrepository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key === NODE_TYPES.transformation.keys.retain_columns,
          )
          if (retainColrepository) {
            retainColrepository.dropdownData = []
            retainColrepository.value = []
          }
          const tranformationToApplyrepository = newData.find(
            (item: ConfigurationInputInterface) =>
              item.key ===
              NODE_TYPES.transformation.keys.transformations_to_apply,
          )
          if (tranformationToApplyrepository) {
            tranformationToApplyrepository.value = []
          }
          return [...newData]
        })
        if (
          componentNodeDetailConfig?.component_node_type ===
            'DOCUMENT_04_DATA_TARGET' ||
          componentNodeDetailConfig?.component_node_type_id
            ?.componentNodeType === 'DOCUMENT_04_DATA_TARGET'
        ) {
          getOutputNodes({
            inputNodeIds: event.target.value,
            key: NODE_TYPES.documentProcessing.keys.input_variables,
          })
          getOutputNodes({
            inputNodeIds: event.target.value,
            key: NODE_TYPES.documentProcessing.keys.master_index_variables,
          })
        } else {
          setOriginNodeDetail(event.target.value)
          getOutputNodes({
            inputNodeIds: event.target.value,
            key: NODE_TYPES.transformation.keys.input_data_frame,
            key2: NODE_TYPES.transformation.keys.retain_columns,
          })
        }
      }
    },
    [
      drawerConfiguration,
      NODE_TYPES,
      componentDetailsData,
      getInputTableData,
      getInputFolderData,
      getRetainColumnsData,
      getColumnsToFilterData,
      getOutputNodes,
    ],
  )

  const onTextChange = useCallback(
    (arg: any) => {
      const { input, e: event } = arg
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        if (
          (input && input.key === 'output_dataframe') ||
          input.key === 'output_column'
        ) {
          const value = event.target.value
          const noSpacesValue = value.replace(/\s+/g, '')
          updateDrawerConfiguration[index].value = noSpacesValue
        } else {
          updateDrawerConfiguration[index].value = event.target.value
        }
      }
      setDrawerConfiguration(updateDrawerConfiguration)
    },
    [drawerConfiguration],
  )

  const onMultiColumnTextChange = useCallback(
    (arg: any) => {
      const { input, e: event, index: currentIndex, item, allData } = arg
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        if (updateDrawerConfiguration[index].value.length === 0) {
          const allValues = allData.map((data: any) => ({
            ...data,
            selected: false,
          }))
          updateDrawerConfiguration[index].value = allValues
        }
        const values: any = { ...item, ...input.value?.[currentIndex] }
        values[event.target.name] = event.target.value
        updateDrawerConfiguration[index].value[currentIndex] = values
      }
      setDrawerConfiguration(updateDrawerConfiguration)
    },
    [drawerConfiguration],
  )

  const onMultiColumnCheckboxChange = useCallback(
    (arg: any) => {
      const { input, e: event, index: currentIndex, item, allData } = arg

      const aliasValueKey = input.objectInput.find(
        (item: any) => item.key === 'alias' || item.key === 'label',
      )
      const updateDrawerConfiguration = [...drawerConfiguration]
      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )
      if (index > -1) {
        if (updateDrawerConfiguration[index].value.length === 0) {
          const allValues = allData.map((data: any) => ({
            ...data,
            selected: false,
          }))
          updateDrawerConfiguration[index].value = allValues
        }
        const values: any = { ...item, ...input.value?.[currentIndex] }
        values[event.target.name] = event.target.checked
        if (values.alias || values.alias === '') {
          values.alias =
            input.value
              .filter((item: any) => item !== null)
              .find(
                (v: any) =>
                  v[aliasValueKey.value] === item[aliasValueKey.value],
              )?.alias ||
            input.dropdownData.find(
              (d: any) => d[aliasValueKey.value] === item[aliasValueKey.value],
            )[aliasValueKey.value]
        } else {
          values.label =
            input.value
              .filter((item: any) => item !== null)
              .find(
                (v: any) =>
                  v[aliasValueKey.value] === item[aliasValueKey.value],
              )?.label ||
            input.dropdownData.find(
              (d: any) => d[aliasValueKey.value] === item[aliasValueKey.value],
            )[aliasValueKey.value]
        }

        updateDrawerConfiguration[index].value[currentIndex] = values
      }
      setDrawerConfiguration(updateDrawerConfiguration)
    },
    [drawerConfiguration],
  )

  const handleDelete = useCallback(
    (arg: any) => {
      const { input, value } = arg
      const updateDrawerConfiguration = [...drawerConfiguration]

      const index = updateDrawerConfiguration.findIndex(
        (item) => item.key === input.key,
      )

      if (index > -1) {
        const updatedValues = _.without(
          updateDrawerConfiguration[index].value,
          value,
        )
        updateDrawerConfiguration[index].value = updatedValues
        setDrawerConfiguration(updateDrawerConfiguration)

        // Use updated values directly instead of relying on state
        const updatedNodeDetail = updatedValues
        getOutputNodes({
          inputNodeIds: updatedNodeDetail,
          key: NODE_TYPES?.transformation?.keys?.input_data_frame,
          key2: NODE_TYPES?.transformation?.keys?.retain_columns,
        })
      }
    },
    [drawerConfiguration, getOutputNodes],
  )

  const handleMultiObjDelete = (arg: any) => {
    const { input, e: event, value } = arg
    const updateDrawerConfiguration = [...drawerConfiguration]
    const index = updateDrawerConfiguration.findIndex(
      (item) => item.key === input.key,
    )
    if (index > -1) {
      updateDrawerConfiguration[index].value = _.reject(
        updateDrawerConfiguration[index].value,
        value,
      )
    }
    setDrawerConfiguration(updateDrawerConfiguration)
    event.preventDefault()
  }

  const onEditMultiNode = useCallback(
    ({ editItem, objectParams_Value, objectType }: Record<string, any>) => {
      handleTransformationPopup({
        isEdit: true,
        open: true,
        id: editItem.id,
        nodeName: editItem[objectParams_Value],
        objectType,
      })
    },
    [handleTransformationPopup],
  )

  const onDeleteMultiNode = useCallback(
    ({ editItem, key }: Record<string, any>) => {
      setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
        const newData = [...prev]
        const repository: any = newData.find(
          (item: ConfigurationInputInterface) => item.key === key,
        )
        const deleteTransformer = repository?.value.filter(
          (tranformerItem: any) => tranformerItem.id !== editItem.id,
        )
        repository.value = deleteTransformer
        return [...newData]
      })
    },
    [],
  )
  // console.log(drawerConfiguration);

  const updateComponent = useCallback(
    async (arg: any) => {
      const payload = {
        data: arg,
      }
      try {
        setLoading(true)
        await ApiServices.updateComponent(payload)
        setLoading(false)
        getProjectDetails()
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [getProjectDetails, setLoading],
  )

  const onUpdateComponentNodeApi = useCallback(
    async (payload: any, newNodeId: string) => {
      try {
        setLoading(true)
        await ApiServices.updateComponentNode(payload)
        setLoading(false)
        setNodePropertiesDrawer(DefaultNodeProperty)
        getProjectDetails()
        if (!showNodePropertiesDrawer.isEdit) {
          const defaultData = {
            project_id: projectId,
            component_id: componentId ? componentId : '',
            configuration: {
              input: {
                nodes: [],
                edges: [],
              },
            },
          }
          const newData = componentDetailsData
            ? { ...componentDetailsData }
            : defaultData
          const node = {
            data: {
              id: newNodeId,
              component_node_name: selectedNode.component_node_name,
              component_node_type_id: selectedNode.component_node_type_id,
            },
          }

          newData.configuration.input.nodes.push(node)
          if (
            showNodePropertiesDrawer.nodeAttached &&
            showNodePropertiesDrawer.nodeAttached.sourceId
          ) {
            const edge = {
              data: {
                source: showNodePropertiesDrawer.nodeAttached.sourceId,
                target: newNodeId,
              },
            }
            newData.configuration.input.edges.push(edge)
          }
          if (!newData.project_id) newData.project_id = projectId
          if (!newData.id) newData.id = componentId

          updateComponent(newData)

          // setComponentDetailsData(newData)
        } else if (showNodePropertiesDrawer.isEdit) {
          const extraedges: string[] =
            payload?.configuration?.input[0]?.value || []
          const targetedges: string = payload?.id
          // console.log(payload?.configuration?.input[0]?.value);
          const defaultData = {
            project_id: projectId,
            component_id: componentId ? componentId : '',
            configuration: {
              input: {
                nodes: [],
                edges: [],
              },
            },
          }
          const newData = componentDetailsData
            ? { ...componentDetailsData }
            : defaultData
          const node = {
            data: {
              id: newNodeId,
              component_node_name: selectedNode.component_node_name,
              component_node_type_id: selectedNode.component_node_type_id,
            },
          }

          // Remove existing edges to avoid duplicates
          const findSelectedNode = newData.configuration.input.nodes.findIndex(
            (node: any) =>
              node?.data.component_node_type_id ===
              selectedNode.component_node_type_id,
          )

          if (findSelectedNode === -1) {
            newData.configuration.input.nodes.push(node)
          }

          // newData.configuration.input.edges = [];
          newData.configuration.input.edges =
            newData.configuration.input.edges.filter(
              (edge: any) => edge?.data?.target !== targetedges,
            )

          extraedges?.forEach((sourceId: string) => {
            const edge: any = {
              data: {
                source: sourceId,
                target: targetedges,
              },
            }
            // newData.configuration.input.edges.push(edge);
            const edgeExists: any = newData?.configuration?.input?.edges?.some(
              (existingEdge: any) =>
                existingEdge?.data?.source === sourceId &&
                existingEdge?.data?.target === targetedges,
            )

            // Only push the edge if it doesn't already exist
            if (!edgeExists) {
              newData.configuration.input.edges.push(edge)
            }
          })

          if (!newData.project_id) newData.project_id = projectId
          if (!newData.id) newData.id = componentId

          updateComponent(newData)
          getProjectDetails()
        } else {
          getProjectDetails()
        }
      } catch (error: any) {
        if (
          error?.response?.data?.error ===
            'User session not authorized. Please sign in' ||
          error?.response?.data?.error === 'jwt expired'
        ) {
          removeToken()
          removeProfileInfo()
          navigate('/login')
        }
        setLoading(false)
        setNodePropertiesDrawer(DefaultNodeProperty)
      }
    },
    [
      componentDetailsData,
      selectedNode,
      showNodePropertiesDrawer,
      getProjectDetails,
      updateComponent,
      projectId,
      componentId,
      setLoading,
      setNodePropertiesDrawer,
    ],
  )

  const onClickUpdateComponent = useCallback(() => {
    const inputData = [...drawerConfiguration].map(
      (item: ConfigurationInputInterface) => {
        if (item.dropdownData) delete item.dropdownData
        return item
      },
    )
    const newNodeId = getRandomNumber()
    const payload = {
      id: selectedNode.id ? selectedNode.id : newNodeId,
      component_node_template_id: componentNodeTemplateId,
      component_node_name: nodeName,
      project_id: projectId,
      // component_node_type_name:
      //   componentNodeDetailConfig?.component_node_type_name,
      component_id: componentId ? componentId : '',
      component_node_type_id: selectedNode.component_node_type_id,
      configuration: {
        input: inputData,
      },
      status: 'live',
    }

    onUpdateComponentNodeApi(payload, newNodeId)
  }, [
    drawerConfiguration,
    selectedNode,
    nodeName,
    componentId,
    onUpdateComponentNodeApi,
    componentNodeTemplateId,
  ])

  useImperativeHandle(
    ref,
    () => ({
      setNodeName,
      getComponentNodeDetailsConfiguration,
      getComponentNodeTemplateConfiguration,
      setDrawerConfiguration,
    }),
    [
      getComponentNodeDetailsConfiguration,
      getComponentNodeTemplateConfiguration,
    ],
  )

  const updateTransformationApply = useCallback(
    (value: Record<string, string>, isEdit = false) => {
      setDrawerConfiguration((prev: ConfigurationInputInterface[]) => {
        const newData = [...prev]
        const repository = newData.find(
          (item: ConfigurationInputInterface) =>
            item.key ===
            NODE_TYPES.transformation.keys.transformations_to_apply,
        )
        if (repository) {
          if (!isEdit) {
            repository.value.push(value)
          } else {
            const findNode = repository.value.find(
              (item: Record<string, string>) => item.id === value.id,
            )
            if (findNode) {
              findNode.component_node_name = value.component_node_name
            }
          }
        }
        return [...newData]
      })
    },
    [NODE_TYPES],
  )

  const getDrawerHeading = useCallback(() => {
    if (showNodePropertiesDrawer.isEdit) {
      if (componentNodeDetailConfig?.component_node_name) {
        return `Edit ${componentNodeDetailConfig?.component_node_name}`
      } else {
        return `Edit Data Source Node`
      }
    } else {
      if (componentNodeDetailConfig?.component_node_type_name) {
        return `Add ${
          componentNodeDetailConfig?.component_node_type_name + ' Node'
        }`
      } else {
        return `Add Data Source Node`
      }
    }
  }, [showNodePropertiesDrawer, componentNodeDetailConfig])

  return (
    <>
      <DrawerComponent
        anchor={'right'}
        open={showNodePropertiesDrawer.show}
        width={`${DrawerWidth}px`}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.stopPropagation()
            event.preventDefault()
            onClickUpdateComponent()
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => {
              setNodePropertiesDrawer(DefaultNodeProperty)
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            {getDrawerHeading()}
          </Typography>
        </Box>
        <Container sx={{ mt: 5, p: '0 !important' }}>
          <Box>
            <Typography variant="body2">Node Name</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={nodeName}
              disabled={
                showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
              }
              onChange={(e) => {
                setNodeName(e.target.value)
              }}
            />
          </Box>
          {drawerConfiguration.map((item: ConfigurationInputInterface) => {
            return DynamicFormForNodeDetails(
              item,
              {
                onTextChange,
                onDropDownChange,
                handleMultiObjDelete,
                handleDelete,

                onMultiColumnTextChange,
                onMultiColumnCheckboxChange,
                setDrawerConfiguration,
              },
              DrawerWidth,
              classes,
              {
                singleTextSearchInputRef,
                singleTextSearch,
                setSingleTextSearch,
              },
              { multiTextSearchInputRef, multiTextSearch, setMultiTextSearch },
              {
                MultiObjTextSearchInputRef,
                MultiObjTextSearch,
                setMultiObjSearch,
              },
              showNodePropertiesDrawer,
              projectRole,
              drawerConfiguration,
              onEditMultiNode,
              onDeleteMultiNode,
              handleTransformationPopup,
              isInputNodeError,
              handleSingleFolderClick,
            )
          })}
          <Box sx={{ mt: 2, mb: 3, textAlign: 'end' }}>
            <Button
              variant="contained"
              onClick={() => {
                setNodePropertiesDrawer(DefaultNodeProperty)
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={
                showNodePropertiesDrawer?.isEdit && !projectRole?.edit_nodes
              }
              variant="contained"
              type="submit"
              // onClick={onClickUpdateComponent}
              sx={{ marginLeft: 3 }}
            >
              Save
            </Button>
          </Box>
        </Container>

        {openTransformerPopup.open && (
          <StreamingDataDialog
            openTransformerPopup={openTransformerPopup}
            handleTransformationDialogClose={handleTransformationDialogClose}
            inputConfigurationData={DynamicFormForNodeDetails}
            tranformationOperationConfiguration={
              tranformationOperationConfiguration
            }
            componentId={componentId}
            projectId={projectId}
            originNodeDetail={originNodeDetail}
            selectedNode={selectedNode}
            setLoading={setLoading}
            updateTransformationApply={updateTransformationApply}
            NODE_TYPES={NODE_TYPES}
            drawerConfiguration={drawerConfiguration}
            selectedTranformationTemplateId={selectedTranformationTemplateId}
            componentNodeTypeId={componentNodeTypeId}
            componentNodeTemplateId={componentNodeTemplateId}
            outputNodeData={outputNodeData}
            projectRole={projectRole}
            inputTable={inputTable}
            dynamicFormForNodeParams={{
              DrawerWidth: DrawerWidth,
              classes: classes,
              singleTextSearchInputRef: singleTextSearchInputRef,
              singleTextSearch: singleTextSearch,
              setSingleTextSearch: setSingleTextSearch,
              multiTextSearchInputRef: multiTextSearchInputRef,
              multiTextSearch: multiTextSearch,
              setMultiTextSearch: setMultiTextSearch,
              MultiObjTextSearchInputRef: MultiObjTextSearchInputRef,
              MultiObjTextSearch: MultiObjTextSearch,
              setMultiObjSearch: setMultiObjSearch,
              showNodePropertiesDrawer: showNodePropertiesDrawer,
              projectRole: projectRole,
              drawerConfiguration: drawerConfiguration,
              onEditMultiNode: onEditMultiNode,
              onDeleteMultiNode: onDeleteMultiNode,
              handleTransformationPopup: handleTransformationPopup,
              isInputNodeError: isInputNodeError,
              handleSingleFolderClick: handleSingleFolderClick,
            }}
          />
        )}
        {openFolderExplorer.isOpen && (
          <Dialog
            open={openFolderExplorer.isOpen}
            handleClose={handleFileExplorerCloseClick}
            fullwidth={true}
            maxWidth="xl"
          >
            <FolderExplorer
              onClose={handleFileExplorerCloseClick}
              metadata={openFolderExplorer.data}
              type={openFolderExplorer.type}
              onSelectItem={onDropDownChange}
              input={openFolderExplorer.input}
            />
          </Dialog>
        )}
      </DrawerComponent>
    </>
  )
})

StreamingDataPipeline.displayName = 'StreamingDataPipeline'

export default StreamingDataPipeline
