/* eslint-disable no-unused-vars */

import React, { useCallback, useEffect, useState } from 'react'
import { Box, Switch, Typography } from '@mui/material'
import { ApiServices } from 'api/services'
import AppNetworkFilter from './app-filter'
import { INFINITY_X_BREADCRUMBS } from 'utility/constants'
import { COLORS_ARRAY } from 'pages/apps/apps-utility/utility'
import ChartTable from 'components/components/ChartTable'
import GraphComponent from './graph-component'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
import { useNavigate } from 'react-router-dom'

const label = { inputProps: { 'aria-label': 'Switch demo' } }

const AppNetworkVisualization = (props: any) => {
  const { setLoading } = props
  const [networkData, setNetworkData] = useState({
    nodes: [],
    edges: [],
  })
  const [searchedMobileNumber, setSearchedMobileNumber] = useState('')
  const [showNoData, setShowNoData] = useState(false)
  const [tableData, setTableData] = useState<any>([])
  const [edgesTypesColors, setEdgesTypesColors] = useState<any>([])
  const [chartTableToggle, setChartTableToggle] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    const ele = document.getElementById(INFINITY_X_BREADCRUMBS)
    if (ele) {
      ele.style.marginLeft = '300px'
    }
    return () => {
      if (ele) {
        ele.style.marginLeft = '0px'
      }
    }
  }, [])

  const getAppsDGraphNetworkData = useCallback(
    async (mobileNumber: string) => {
      try {
        setShowNoData(false)
        setSearchedMobileNumber(mobileNumber)
        setLoading(true)
        const resp = await ApiServices.getAppsDGraphNetworkData(mobileNumber)
        const edgeTypes = resp.data.data.edgeTypes
        const edgeColorData: any = []
        edgeTypes.forEach((item: string, index: number) => {
          const edgeObj = {
            color: COLORS_ARRAY[index],
            name: item,
          }
          edgeColorData.push(edgeObj)
        })
        setEdgesTypesColors(edgeColorData)
        setLoading(false)
        const edges = resp.data.data.edges
        const edgesWithColor = edges.map((item: any) => {
          return {
            data: {
              ...item.data,
              color: edgeColorData.find(
                (colorItem: any) => colorItem.name === item.data.type,
              ).color,
            },
          }
        })
        setNetworkData({
          nodes: resp.data.data.nodes,
          edges: edgesWithColor,
        })
        if (resp.data.data.nodes.length === 0) {
          setShowNoData(true)
        }
      } catch (error: any) {
        setShowNoData(true)
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading],
  )

  const getAppsDGraphTableData = useCallback(
    async (mobileNumber: string) => {
      try {
        setShowNoData(false)
        setSearchedMobileNumber(mobileNumber)
        setLoading(true)
        const resp = await ApiServices.getAppsDGraphTableData(mobileNumber)
        setTableData(resp.data.data)
      } catch (error: any) {
        if (chartTableToggle) {
          setShowNoData(true)
        }
        setLoading(false)
        console.log('error', error)
      }
    },
    [setLoading, chartTableToggle],
  )

  const getAppNetworkAndTableData = useCallback(
    (mobileNumber: string) => {
      getAppsDGraphNetworkData(mobileNumber)
      getAppsDGraphTableData(mobileNumber)
    },
    [getAppsDGraphNetworkData, getAppsDGraphTableData],
  )

  const tableComponent = useCallback(() => {
    const tableKeys =
      tableData && tableData.length > 0 ? Object.keys(tableData[0]) : []
    const tableColumns: any = []
    tableKeys.forEach((item: string) => {
      if (item == 'source' || item == 'target') {
        const sourceTargetItem = Object.keys(tableData[0][item])

        sourceTargetItem.forEach((item2) => {
          const obj: any = {
            field: `${item}.${item2}`,
            headerName: `${item}.${item2}`.toUpperCase(),
            width: 150,
            valueGetter: (value: any, row: any) => {
              return `${row[item][item2]}`
            },
          }
          tableColumns.push(obj)
        })
      } else {
        const obj = {
          field: item,
          headerName: item.toUpperCase(),
          width: 150,
        }
        tableColumns.push(obj)
      }
    })
    return (
      <div style={{ width: '100%', maxWidth: '75vw' }}>
        <ChartTable columns={tableColumns} rows={tableData} />
      </div>
    )
  }, [tableData])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppNetworkFilter
          getAppsDGraphNetworkData={getAppNetworkAndTableData}
        />
        <Box sx={{ width: '100%' }}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <Typography>Chart</Typography>
              <Switch
                {...label}
                checked={chartTableToggle}
                onChange={(e: any) => setChartTableToggle(e.target.checked)}
              />
              <Typography>Table</Typography>
            </Box>
            {chartTableToggle ? (
              tableComponent()
            ) : (
              <GraphComponent
                networkData={networkData}
                searchedMobileNumber={searchedMobileNumber}
                showNoData={showNoData}
                getAppNetworkAndTableData={getAppNetworkAndTableData}
                edgesTypesColors={edgesTypesColors}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default AppNetworkVisualization
