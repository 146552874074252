/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
// src/components/UserMessageCard.js
import React from 'react';
import { Card, CardContent, Typography } from "@mui/material";
import { useTheme } from "ThemeContext";


const UserMessageCard = ({ text, audio }) => {
  const { isDarkMode, handleThemeChange } = useTheme();

  return (
    <Card sx={{ backgroundColor:!isDarkMode? "#f2f2f2":'#808080', marginBottom: 2,borderRadius:'20px' ,marginLeft:'50px'}}>
      <CardContent>
        <Typography variant="body2">{text}</Typography>
        {audio && (
          <audio controls>
            <source src={audio} type="audio/webm" />
            Your browser does not support the audio element.
          </audio>
        )}
      </CardContent>
    </Card>
  );
};

export default UserMessageCard;












// import React from "react";
// import { Card, CardContent, Typography } from "@mui/material";
// import { useTheme } from "ThemeContext";
// const UserMessageCard = ({ text }) => {
// const { isDarkMode, handleThemeChange } = useTheme();

//   return (
//     <Card sx={{ backgroundColor:!isDarkMode? "#f2f2f2":'#808080', marginBottom: 2,borderRadius:'20px' ,marginLeft:'50px'}}>
//       <CardContent>
//         <Typography variant="body1" color="text.primary">
//           {text}
//         </Typography>
//         <Typography variant="caption" color="text.secondary">
//           You
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// };

// export default UserMessageCard;
