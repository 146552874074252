import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  profileInfo: {},
}

export const profileInfoSlice = createSlice({
  name: 'navDrawer',
  initialState,
  reducers: {
    setProfileInformation: (state, action) => {
      // state.open = !state.open
      state.profileInfo = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setProfileInformation } = profileInfoSlice.actions

export default profileInfoSlice.reducer
