/* eslint-disable no-unused-vars */
import { Box } from '@mui/material'
import { ApiServices } from 'api/services'
import DrawerComponent from 'components/atoms/drawer'
import LoaderComponent from 'components/atoms/loader-component'
import TwoDTableApiBased from 'components/components/TwoDTableApiBased'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { setComponentName } from 'store/breadcrumbSlice'
import { AppDispatch } from 'store/store'
import GraphFilter from './components/GraphFilter'
import SnackbarComponent, {
  snackbarConfigTypes,
} from 'components/atoms/snackbar-component'

function AppFilteredGraph() {
  const dispatch = useDispatch<AppDispatch>()
  const [alertConfig, setAlertConfig] = useState<snackbarConfigTypes>({
    isOpen: false,
    type: 'success',
    autoHideDuration: 4000,
    message: '',
  })
  const [searchParams] = useSearchParams()

  const [componentId, setComponentId] = useState('')
  const [tableIndex, setTableIndex] = useState(0)
  const [graphFilters, setGraphFilters] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [chartComponentData, setChartComponentData] = useState<any>()

  useEffect(() => {
    if (searchParams) {
      const component_Id = searchParams.get('componentId')
      component_Id && setComponentId(component_Id)
      const index = searchParams.get('index')
      index && setTableIndex(parseInt(index))
    }
  }, [searchParams])

  const callRecentAppsDataApi = useCallback(async (projectId: string) => {
    try {
      setLoading(true)
      const resp = await ApiServices.getDetailsSnapShotData(projectId)
      dispatch(setComponentName(resp?.data?.component_name))
      setLoading(false)
      setChartComponentData(resp.data)
    } catch (error) {
      setLoading(false)
      console.log('error', error)
    }
  }, [])

  useEffect(() => {
    if (componentId) {
      callRecentAppsDataApi(componentId)
    }
  }, [callRecentAppsDataApi, componentId])

  useEffect(() => {
    if (chartComponentData) {
      if (
        chartComponentData.configuration.snapshot[tableIndex].configuration
          .snapshot
      ) {
        const snapshot =
          chartComponentData.configuration.snapshot[tableIndex].configuration
            .snapshot
        const filterColumns = snapshot?.filter_columns
          .filter((item: any) => item !== null && item.selected === true)
          .map((item: any) => {
            return {
              key: item.column_name,
              label: item.label || item.alias,
              type: 'TEXTINPUT',
              condition: item.condition,
              dataType: item.data_type,
              value: item.default || '',
              allowDisplay: true,
              // isRequired: true,
            }
          })

        setGraphFilters(filterColumns)
      }
    }
  }, [chartComponentData])

  return (
    <Box
      sx={{ padding: '30px', paddingLeft: '400px', backgroundColor: '#fff' }}
    >
      {chartComponentData && (
        <Box>
          <TwoDTableApiBased
            chartData={chartComponentData}
            setLoading={setLoading}
            index={tableIndex}
            filterData={graphFilters}
          />
          <DrawerComponent displayDrawer={true} width="424px">
            <GraphFilter
              heading="Apply Filter"
              filterData={graphFilters}
              setFilterData={setGraphFilters}
              messageConfig={alertConfig}
              showMessage={setAlertConfig}
            />
          </DrawerComponent>
        </Box>
      )}
      <LoaderComponent open={isLoading} />
      <SnackbarComponent
        open={alertConfig.isOpen}
        setOpen={setAlertConfig}
        type={alertConfig.type}
        autoHideDuration={alertConfig.autoHideDuration}
        message={alertConfig.message}
        alertConfig={alertConfig}
      />
    </Box>
  )
}

export default AppFilteredGraph
