import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  open: false,
}

export const navDraweSlice = createSlice({
  name: 'navDrawer',
  initialState,
  reducers: {
    updateNavState: (state) => {
      // state.open = !state.open
      state.open = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateNavState } = navDraweSlice.actions

export default navDraweSlice.reducer
