/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { CUSTOM_COLORS } from 'theme'
import RolePermissions from '../RolePermissions/RolePermissions'
import { ADMIN_NAV_BAR } from 'utility/constants'
import OrgUserRoles from '../OrgMemberRoles/OrgUserRoles'
import ProjectUserRoles from '../ProjectMemberRoles/ProjectUserRoles'
import { getProfileInfo } from 'api/apiUtility'
import AppUserRoles from '../AppMemberRoles/AppUserRoles'
import UserManagement from '../UserManagement/UserManagement'
import OperationUserRoles from '../OperationMemberRoles/OperationUserRoles'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: '79vh', overflowY: 'scroll' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '0px 25px 25px 0px' }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const TabsPanel = () => {
  const profileInfo = getProfileInfo()

  const [value, setValue] = useState(0)
  const [userRoles, setUserRoles] = useState<any>()

  useEffect(() => {
    const profileInfoRoles = profileInfo
    if (profileInfoRoles && !userRoles) {
      setUserRoles(profileInfoRoles?.orgRole?.permissions)
    }
  }, [profileInfo, userRoles])

  const navBar = useMemo(() => {
    return JSON.parse(JSON.stringify(ADMIN_NAV_BAR))
  }, [])

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue)
    },
    [],
  )

  const checkPermissions = useCallback(
    (tabName: string) => {
      switch (tabName) {
        case 'Users':
          return !userRoles?.users?.view
        case 'Role Permissions':
          return !userRoles?.role_permissions?.view
        case 'Org Member Roles':
          return !userRoles?.org_member_roles?.view
        case 'Project Member Roles':
          return !userRoles?.project_member_roles?.view
        case 'App Member Roles':
          return !userRoles?.app_member_roles?.view
        case 'Operation Member Roles':
          return !userRoles?.operation_member_roles?.view
      }
    },
    [userRoles],
  )

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {navBar &&
            navBar.map((navItem: any) => (
              <Tab
                key={navItem.id}
                sx={{
                  fontSize: '14px',
                  fontWeight: '600',
                  marginRight: '32px',
                  borderBottom: `2px solid ${CUSTOM_COLORS.tabOptionsBorder}`,
                }}
                disabled={checkPermissions(navItem.name)}
                label={navItem.name}
                {...a11yProps(navItem.id)}
              />
            ))}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <UserManagement />
      </CustomTabPanel>
      {userRoles && userRoles?.role_permissions?.view && (
        <CustomTabPanel value={value} index={1}>
          <RolePermissions />
        </CustomTabPanel>
      )}
      {userRoles && userRoles?.org_member_roles?.view && (
        <CustomTabPanel value={value} index={2}>
          <OrgUserRoles />
        </CustomTabPanel>
      )}
      {userRoles && userRoles?.project_member_roles?.view && (
        <CustomTabPanel value={value} index={3}>
          <ProjectUserRoles />
        </CustomTabPanel>
      )}
      <CustomTabPanel value={value} index={4}>
        <AppUserRoles />
      </CustomTabPanel>
      {userRoles && userRoles?.operation_member_roles?.view && (
        <CustomTabPanel value={value} index={5}>
          <OperationUserRoles />
        </CustomTabPanel>
      )}
    </Box>
  )
}

export default TabsPanel
