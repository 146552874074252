/* eslint-disable no-unused-vars */
import useClasses from 'hooks/styleHook'
import useCytoscapeHook from 'hooks/useCytoscape'
import { style } from './stylesheet'
import { styles } from '../graph-component/styles'
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { Box } from '@mui/material'
import NoRecordsFoundComponent from 'components/atoms/no-record'
import AppGraphControl from 'pages/apps/graph-component/app-graph-control'
import { renderToString } from 'react-dom/server'
import { EulerLayout } from 'utility/cytoscape-layout'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
import { useNavigate } from 'react-router-dom'

const GraphComponent = (props: any) => {
  const {
    networkData,
    searchedMobileNumber,
    showNoData,
    getAppNetworkAndTableData,
    edgesTypesColors,
  } = props
  const tooltipRef = useRef(null)
  const { cy, initCy } = useCytoscapeHook({ stylesheet: style })
  const classes = useClasses(styles)
  const graphRef = useRef()
  const [cyInstance, setCyInstance] = useState<any>()
  const [renderLayput, setRenderLayout] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (graphRef.current) {
      initCy(graphRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setCyInstance(cy)
  }, [cy])

  /** Node Mouseover and mouseout */
  useEffect(() => {
    if (cyInstance) {
      cyInstance.on('mouseover', 'node', function (event: any) {
        const node = event.target
        const nodeData = node.data()
        const tooltip: any = tooltipRef.current
        tooltip.innerHTML = renderToString(
          <Box>
            <Box>
              <strong>Name :</strong> {`${nodeData.cname}`}
            </Box>
            <Box>
              <strong>Address :</strong> {`${nodeData.address}`}
            </Box>
            <Box>
              <strong>Mobile :</strong> {`${nodeData.mobile_no}`}
            </Box>
            <Box>
              <strong>Sim type :</strong> {`${nodeData.simtype}`}
            </Box>
          </Box>,
        )

        tooltip.style.display = 'block'

        const positionX = event.renderedPosition.x
        const positionY = event.renderedPosition.y

        tooltip.style.left = positionX - 20 + 'px'
        tooltip.style.top = positionY + 'px'
      })

      cyInstance.on('mouseout', 'node', function (event: any) {
        const tooltip: any = tooltipRef.current
        tooltip.style.display = 'none'
      })
    }
  }, [cyInstance])

  const handleNodeColor = useCallback(() => {
    if (cyInstance) {
      const nodes = cyInstance.nodes()
      nodes.forEach((node: any) => {
        const nodeMobileNumber = node.data('mobile_no').toString()
        if (nodeMobileNumber !== searchedMobileNumber) {
          node.style('background-color', 'red')
        }
      })
    }
  }, [cyInstance, searchedMobileNumber])

  const triggerLayout = useCallback(() => {
    if (cyInstance) {
      cyInstance.layout(EulerLayout).run()
      handleNodeColor()
      setRenderLayout(false)
    }
  }, [cyInstance, handleNodeColor])

  useEffect(() => {
    if (cyInstance && showNoData) {
      cyInstance.elements().remove()
    }
  }, [cyInstance, showNoData])

  useEffect(() => {
    if (networkData.nodes.length > 0) {
      setRenderLayout(true)
    }
  }, [networkData])

  /** Handle Node Click */
  useEffect(() => {
    if (cyInstance) {
      cyInstance.on('click', 'node', function (evt: any) {
        const node = evt.target
        const nodeMobileNumber = node.data('mobile_no').toString()
        getAppNetworkAndTableData(nodeMobileNumber)
      })
    }
  }, [cyInstance, getAppNetworkAndTableData])

  useEffect(() => {
    if (cyInstance && renderLayput) {
      const finalData = [...networkData.nodes, ...networkData.edges]
      cyInstance.elements().remove()
      cyInstance.add(finalData)
      try {
        triggerLayout()
      } catch (error: any) {
        console.log('error', error)
      }
    }
  }, [cyInstance, triggerLayout, networkData, renderLayput])

  const showLegends = useMemo(() => {
    return (
      <Box sx={{ position: 'absolute', right: 0, top: 5, marginRight: '20px' }}>
        {edgesTypesColors.map((item: any) => {
          return (
            <Box key={item.name} sx={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  width: '15px',
                  height: '15px',
                  backgroundColor: item.color,
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              ></span>
              {item.name}
            </Box>
          )
        })}
      </Box>
    )
  }, [edgesTypesColors])

  const nodeTooltip = useMemo(() => {
    return (
      <Box
        ref={tooltipRef}
        sx={{
          position: 'absolute',
          zIndex: 9999,
          border: '1px solid black',
          padding: '1px 5px',
          borderRadius: '9px',
          // background: 'white',
          display: 'none',
        }}
      ></Box>
    )
  }, [])

  return (
    <>
      <Box className={classes.cy} ref={graphRef}>
        <AppGraphControl cy={cyInstance} />
        {showLegends}
        {nodeTooltip}
        {showNoData && (
          <Box
            sx={{
              position: 'absolute',
              top: 20,
              zIndex: 9999,
              left: 500,
            }}
          >
            <NoRecordsFoundComponent />
          </Box>
        )}
      </Box>
    </>
  )
}

export default GraphComponent
