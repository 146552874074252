/* eslint-disable no-unused-vars */
import { Box, Typography } from '@mui/material'
import { dataSourceService } from 'api/services'
import FolderFileExplorerComponent from './FolderFileExplorer'
import React, { useCallback, useEffect, useState } from 'react'

import { Close } from '@mui/icons-material'
import { decryptSettings } from 'utility/enc-dec'

const STATUS = {
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
}

const FolderExplorer = (props: any) => {
  const { metadata, onClose, type, onSelectItem, input } = props
  const [fileExplorerData, setFileExplorerData] = useState<any>([])
  const [absolutePath, setAbsolutePath] = useState<any>()
  const [loadingStatus, setLoadingStatus] = useState<any>({
    status: STATUS.LOADING,
  })
  const [prefixValue, setPrefixValue] = useState('')
  // pdf file

  const getDataRepositoryFileExplorer = useCallback(async (args: any) => {
    const { id, prefix = '' } = args
    setPrefixValue(prefix)
    const payload = {
      data_repository_id: id,
      prefix,
      page_size: 100,
      index: 0,
    }
    try {
      setLoadingStatus({
        status: STATUS.LOADING,
      })
      const response =
        await dataSourceService.get_data_repository_file_explorer(payload)

      const onlyFolders = response.data.dataset.filter(
        (data: any) => data.size === null,
      )
      setFileExplorerData(onlyFolders)

      setLoadingStatus({
        status: STATUS.SUCCESS,
      })
    } catch (error: any) {
      console.log(error)
      setLoadingStatus({
        status: STATUS.ERROR,
      })
    }
  }, [])

  useEffect(() => {
    if (metadata) {
      const decryptedConfiguration = decryptSettings(
        metadata?.configuration?.input,
      )
      const absolutePath = decryptedConfiguration?.find(
        (item: Record<string, string>) =>
          item.key === 'directory_absolute_path',
      )?.value
      const prefix = absolutePath
        ? `${absolutePath}${absolutePath.endsWith('/') ? '' : '/'}`
        : ''

      getDataRepositoryFileExplorer({
        id: metadata.id,
        prefix,
      })

      setAbsolutePath(prefix)
    }
  }, [metadata, getDataRepositoryFileExplorer])

  const onClickFolder = useCallback(
    (prefix: string) => {
      getDataRepositoryFileExplorer({ id: metadata.id, prefix })
    },
    [metadata, getDataRepositoryFileExplorer],
  )

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '30px',
            padding: '20px',
            paddingTop: '30px',
            position: 'sticky',
            top: 0,
            zIndex: 100,
            background: 'white',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'start',
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
              File Explorer
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '30px',
                height: '30px',
                background: 'red',
              }}
            >
              <Close
                sx={{
                  color: 'white',
                  cursor: 'pointer',
                  border: '1px solid red',
                }}
                onClick={onClose}
              />
            </Box>
          </Box>
        </Box>
        <FolderFileExplorerComponent
          fileData={fileExplorerData}
          loadingStatus={loadingStatus}
          prefixValue={prefixValue}
          setPrefixValue={setPrefixValue}
          onClickFolderText={onClickFolder}
          // onFileClick={onFileClick}
          absolutePath={absolutePath}
          type={type}
          onSelectItem={onSelectItem}
          input={input}
          onClose={onClose}
        />
      </Box>
    </>
  )
}

export default FolderExplorer
