/* eslint-disable no-duplicate-case */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import CardDetailsMolecule from 'components/molecules/card-details'
import React, { useCallback, useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import { ApiServices } from 'api/services'
import LoaderComponent from 'components/atoms/loader-component'
import { changeDateFormat } from 'utility/utility'
import NoRecordsFoundComponent from 'components/atoms/no-record'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'store/store'
import { setProjectName } from 'store/breadcrumbSlice'
import { ReactComponent as DataPipeline } from 'assets/icons/data-pipeline.svg'
import { ReactComponent as ApiComponents } from 'assets/icons/api-components.svg'
import { ReactComponent as Visualization } from 'assets/icons/visualization.svg'
import { ReactComponent as DocumentProcessing } from 'assets/icons/document-processing.svg'
import { ReactComponent as KnowledgeRetrieval } from 'assets/icons/knowledge-retrival.svg'
import { ReactComponent as DqPipeline } from 'assets/icons/dq-pipeline.svg'
import { ReactComponent as ModelTraining } from 'assets/icons/model-training.svg'
import { removeProfileInfo, removeToken } from 'api/apiUtility'

const DataPipelineList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const [isLoading, setLoading] = useState(false)
  const [projectId, setProjectId] = useState<string>()
  const [projectData, seProjectData] = useState<any>()
  const [apiProjectData, setApiProjectData] = useState<any>()
  const [showAllDataPipeline, setShowAllDataPipeline] = useState(false)
  const [showAllDQPipeline, setShowAllDQPipeline] = useState(false)
  const [showAllApiComponents, setShowAllApiComponents] = useState(false)
  const [showAllVisualComponents, setShowAllVisualComponents] = useState(false)
  const [showAllDocumentProcessing, setShowAllDocumentProcessing] =
    useState(false)
  const [showAllKnowledgeRetrieval, setShowAllKnowledgeRetrieval] =
    useState(false)
  const [showAllModelTraining, setShowAllModelTraining] = useState(false)
  const [showAllStreamingDataPipeline, setShowAllStreamingDataPipeline] =
    useState(false)

  const [projectRole, setProjectRole] = useState<any>()
  const [unauthorized, setUnauthorize] = useState(false)

  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams) {
      const project_Id = searchParams.get('projectId')
      project_Id && setProjectId(project_Id)
    }
  }, [searchParams])

  useEffect(() => {
    if (projectId) {
      const getProjectDetails = async () => {
        try {
          setLoading(true)
          const resp: any = await ApiServices.getProjectDetails(projectId)

          seProjectData(resp.data)
          setApiProjectData(resp?.data)
          dispatch(setProjectName(resp?.data?.project_name))
          setLoading(false)
        } catch (error: any) {
          seProjectData(null)
          setLoading(false)
          console.log('error', error)
        }
      }

      const getProjRoleDetails = async () => {
        try {
          setLoading(true)
          const projRoles: any =
            await ApiServices.getProjectRoleDetails(projectId)
          setProjectRole(projRoles.data.role.permissions)
          setLoading(false)
        } catch (error: any) {
          if (error?.response?.status === 401) {
            setUnauthorize(true)
          }
          console.log('error', error)
        }
      }

      getProjectDetails()
      getProjRoleDetails()
    }
  }, [projectId])

  const navigateToDataPipeline = useCallback(
    (id: string, componentType: string) => {
      navigate(
        `/${PAGE_ROUTE_CONSTANTS.PROJECTS}/${PAGE_ROUTE_CONSTANTS.DATA_PIPELINE_LIST}/${PAGE_ROUTE_CONSTANTS.DATA_PIPELINE}?projectId=${projectId}&componentId=${id}&componentType=${componentType}`,
      )
    },
    [navigate, projectId],
  )

  const navigateToComponentTemplate = useCallback(
    (componentType: string) => {
      navigate(
        `/${PAGE_ROUTE_CONSTANTS.PROJECTS}/${PAGE_ROUTE_CONSTANTS.DATA_PIPELINE_LIST}/${PAGE_ROUTE_CONSTANTS.SELECT_TEMPLATE}?projectId=${projectId}&componentType=${componentType}`,
      )
    },
    [navigate, projectId],
  )

  const handleViewAllDataPipeline = useCallback(() => {
    setShowAllDataPipeline(!showAllDataPipeline)
  }, [showAllDataPipeline])

  const filteredDataPipelinesItems = apiProjectData?.component_items?.filter(
    (item: any) => item?.component_type === 'DATA_PIPELINE',
  )

  const renderDataPipelineItems = showAllDataPipeline
    ? filteredDataPipelinesItems
    : filteredDataPipelinesItems?.slice(0, 3)

  const handleViewAllApiComponents = useCallback(() => {
    setShowAllApiComponents(!showAllApiComponents)
  }, [showAllApiComponents])

  const filteredApiComponents = apiProjectData?.component_items?.filter(
    (item: any) => item?.component_type === 'DATA_ACCESS_API',
  )

  const renderApiComponents = showAllApiComponents
    ? filteredApiComponents
    : filteredApiComponents?.slice(0, 3)

  const handleViewAllVisualComponents = useCallback(() => {
    setShowAllVisualComponents(!showAllVisualComponents)
  }, [showAllVisualComponents])

  const filteredDataVisualization = apiProjectData?.component_items?.filter(
    (item: any) => item?.component_type === 'DATA_VISUALIZATION',
  )

  const renderDataVisualization = showAllVisualComponents
    ? filteredDataVisualization
    : filteredDataVisualization?.slice(0, 3)

  const handleViewAllDocumentProcessing = useCallback(() => {
    setShowAllDocumentProcessing(!showAllDocumentProcessing)
  }, [showAllDocumentProcessing])

  const filteredDocumentProcessing = apiProjectData?.component_items?.filter(
    (item: any) => item?.component_type === 'DOCUMENT_PROCESSING',
  )

  const renderDocumentProcessing = showAllDocumentProcessing
    ? filteredDocumentProcessing
    : filteredDocumentProcessing?.slice(0, 3)

  const handleViewAllKnowledgeRetrieval = useCallback(() => {
    setShowAllKnowledgeRetrieval(!showAllKnowledgeRetrieval)
  }, [showAllKnowledgeRetrieval])

  const filteredKnowledgeRetrieval = apiProjectData?.component_items?.filter(
    (item: any) => item?.component_type === 'KNOWLEDGE_RETRIEVAL',
  )

  const renderKnowledgeRetrieval = showAllKnowledgeRetrieval
    ? filteredKnowledgeRetrieval
    : filteredKnowledgeRetrieval?.slice(0, 3)

  const handleViewAllDQPipeline = useCallback(() => {
    setShowAllDQPipeline(!showAllDQPipeline)
  }, [showAllDQPipeline])

  const filteredDQPipeline = apiProjectData?.component_items?.filter(
    (item: any) => item?.component_type === 'DQ_PIPELINE',
  )

  const renderDQPipeline = showAllDQPipeline
    ? filteredDQPipeline
    : filteredDQPipeline?.slice(0, 3)

  const handleViewAllModelTraining = useCallback(() => {
    setShowAllModelTraining(!showAllModelTraining)
  }, [showAllModelTraining])

  const filteredModelTraining = apiProjectData?.component_items?.filter(
    (item: any) => item?.component_type === 'MODEL_TRAINING',
  )

  const renderModelTraining = showAllModelTraining
    ? filteredModelTraining
    : filteredModelTraining?.slice(0, 3)

  const handleViewAllStreamingDataPipeline = useCallback(() => {
    setShowAllStreamingDataPipeline(!showAllStreamingDataPipeline)
  }, [showAllStreamingDataPipeline])

  const filteredStreamingDataPipeline = apiProjectData?.component_items?.filter(
    (item: any) => item?.component_type === 'STREAMING_DATA_PIPELINE',
  )

  const renderStreamingDataPipeline = showAllStreamingDataPipeline
    ? filteredStreamingDataPipeline
    : filteredStreamingDataPipeline?.slice(0, 3)

  return (
    <>
      <Box>
        <Container sx={{ mt: 2, paddingBottom: 2 }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>

          {projectData && (
            <Container>
              <Box>
                <Typography
                  variant="button"
                  gutterBottom
                  sx={{ fontWeight: 'bold', fontSize: 24 }}
                >
                  {projectData.project_name}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'baseline', py: 2 }}>
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  variant="button"
                  gutterBottom
                >
                  {'Data pipelines'}
                </Typography>
                {/* <Button
                  sx={{
                    textTransform: 'none',
                    color: CUSTOM_COLORS.black,
                  }}
                >
                  See all
                </Button> */}
                {projectRole && projectRole?.data_pipeline?.create && (
                  <Button
                    sx={{ marginLeft: 'auto', textTransform: 'none' }}
                    color="primary"
                    onClick={() => navigateToComponentTemplate('DATA_PIPELINE')}
                  >
                    + New
                  </Button>
                )}
              </Box>
              {projectRole && projectRole?.data_pipeline?.view && (
                <Grid container spacing={2}>
                  {renderDataPipelineItems.map((component: any) => {
                    return (
                      <Grid key={`project_${component.sNo}`} item md={3} sm={6}>
                        <CardDetailsMolecule
                          label={`${component.component_name}`}
                          subLabel={`Last updated ${changeDateFormat(
                            component.last_updated_date,
                          )}`}
                          onClick={() =>
                            navigateToDataPipeline(
                              component.id,
                              component.component_type,
                            )
                          }
                          version="v2"
                          icon={<DataPipeline />}
                          // data={{ type: 'component' }}
                          handleDetails={(e) => {
                            // details drawer
                          }}
                        ></CardDetailsMolecule>
                      </Grid>
                    )
                  })}
                  {filteredDataPipelinesItems.length > 3 && (
                    <Grid item md={3} sm={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          sx={{
                            width: '100px',
                            height: '100px',
                            textTransform: 'capitalize',
                          }}
                          onClick={handleViewAllDataPipeline}
                        >
                          {!showAllDataPipeline ? 'See All' : 'Sell Less'}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
              {projectRole &&
                projectRole?.data_pipeline?.view &&
                (!renderDataPipelineItems ||
                  renderDataPipelineItems.length === 0) && (
                  <NoRecordsFoundComponent msg="No components created yet" />
                )}
              {unauthorized && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              {projectRole && !projectRole?.data_pipeline?.view && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box sx={{ display: 'flex', alignItems: 'baseline', py: 2 }}>
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  variant="button"
                  gutterBottom
                >
                  {`API COMPONENTS`}
                </Typography>
                {projectRole && projectRole?.data_access_api?.create && (
                  <Button
                    sx={{ marginLeft: 'auto', textTransform: 'none' }}
                    color="primary"
                    onClick={() =>
                      navigateToComponentTemplate('DATA_ACCESS_API')
                    }
                  >
                    + New
                  </Button>
                )}
              </Box>
              {projectRole && projectRole?.data_access_api?.view && (
                <Grid container spacing={2}>
                  {apiProjectData &&
                    renderApiComponents.map((item: any) => {
                      return (
                        <Grid key={`recent_${item.sNo}`} item md={3} sm={6}>
                          <CardDetailsMolecule
                            label={`${item.component_name}`}
                            subLabel={`Last updated ${changeDateFormat(
                              item.last_updated_date,
                            )}`}
                            onClick={() =>
                              navigateToDataPipeline(
                                item.id,
                                item.component_type,
                              )
                            }
                            version="v2"
                            handleDetails={(e) => {
                              // details drawer
                            }}
                            icon={<ApiComponents />}
                            // data={{ type: 'component' }}
                          ></CardDetailsMolecule>
                        </Grid>
                      )
                    })}
                  {filteredApiComponents.length > 3 && (
                    <Grid item md={3} sm={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          sx={{
                            width: '100px',
                            height: '100px',
                            textTransform: 'capitalize',
                          }}
                          onClick={handleViewAllApiComponents}
                        >
                          {!showAllApiComponents ? 'See All' : 'Sell Less'}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
              {projectRole &&
                projectRole?.data_access_api?.view &&
                (!renderApiComponents || renderApiComponents.length === 0) && (
                  <NoRecordsFoundComponent msg="No components created yet" />
                )}
              {unauthorized && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              {projectRole && !projectRole?.data_access_api?.view && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box sx={{ display: 'flex', alignItems: 'baseline', py: 2 }}>
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  variant="button"
                  gutterBottom
                >
                  {`VISUALIZATION COMPONENTS`}
                </Typography>
                {projectRole && projectRole?.data_visualization?.create && (
                  <Button
                    sx={{ marginLeft: 'auto', textTransform: 'none' }}
                    color="primary"
                    onClick={() =>
                      navigateToComponentTemplate('DATA_VISUALIZATION')
                    }
                  >
                    + New
                  </Button>
                )}
              </Box>
              {projectRole && projectRole?.data_visualization?.view && (
                <Grid container spacing={2}>
                  {apiProjectData &&
                    renderDataVisualization.map((item: any) => {
                      return (
                        <Grid key={`recent_${item.sNo}`} item md={3} sm={6}>
                          <CardDetailsMolecule
                            label={`${item.component_name}`}
                            subLabel={`Last updated ${changeDateFormat(
                              item.last_updated_date,
                            )}`}
                            onClick={() =>
                              navigateToDataPipeline(
                                item.id,
                                item.component_type,
                              )
                            }
                            version="v2"
                            handleDetails={(e) => {
                              // details drawer
                            }}
                            icon={<Visualization />}
                            // data={{ type: 'component' }}
                          ></CardDetailsMolecule>
                        </Grid>
                      )
                    })}
                  {filteredDataVisualization.length > 3 && (
                    <Grid item md={3} sm={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          sx={{
                            width: '100px',
                            height: '100px',
                            textTransform: 'capitalize',
                          }}
                          onClick={handleViewAllVisualComponents}
                        >
                          {!showAllVisualComponents ? 'See All' : 'Sell Less'}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
              {projectRole &&
                projectRole?.data_visualization?.view &&
                (!renderDataVisualization ||
                  renderDataVisualization.length === 0) && (
                  <NoRecordsFoundComponent msg="No components created yet" />
                )}
              {unauthorized && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              {projectRole && !projectRole?.data_visualization?.view && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box sx={{ display: 'flex', alignItems: 'baseline', py: 2 }}>
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  variant="button"
                  gutterBottom
                >
                  {`DOCUMENT PROCESSING COMPONENTS`}
                </Typography>
                {projectRole && projectRole?.document_processing?.create && (
                  <Button
                    sx={{ marginLeft: 'auto', textTransform: 'none' }}
                    color="primary"
                    onClick={() =>
                      navigateToComponentTemplate('DOCUMENT_PROCESSING')
                    }
                  >
                    + New
                  </Button>
                )}
              </Box>
              {projectRole && projectRole?.document_processing?.view && (
                <Grid container spacing={2}>
                  {apiProjectData &&
                    renderDocumentProcessing.map((item: any) => {
                      return (
                        <Grid key={`recent_${item.sNo}`} item md={3} sm={6}>
                          <CardDetailsMolecule
                            label={`${item.component_name}`}
                            subLabel={`Last updated ${changeDateFormat(
                              item.last_updated_date,
                            )}`}
                            onClick={() =>
                              navigateToDataPipeline(
                                item.id,
                                item.component_type,
                              )
                            }
                            version="v2"
                            handleDetails={(e) => {
                              // details drawer
                            }}
                            icon={<DocumentProcessing />}
                            // data={{ type: 'component' }}
                          ></CardDetailsMolecule>
                        </Grid>
                      )
                    })}
                  {filteredDocumentProcessing.length > 3 && (
                    <Grid item md={3} sm={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          sx={{
                            width: '100px',
                            height: '100px',
                            textTransform: 'capitalize',
                          }}
                          onClick={handleViewAllDocumentProcessing}
                        >
                          {!showAllDocumentProcessing ? 'See All' : 'Sell Less'}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
              {projectRole &&
                projectRole?.document_processing?.view &&
                (!renderDocumentProcessing ||
                  renderDocumentProcessing.length === 0) && (
                  <NoRecordsFoundComponent msg="No components created yet" />
                )}
              {unauthorized && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              {projectRole && !projectRole?.document_processing?.view && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box sx={{ display: 'flex', alignItems: 'baseline', py: 2 }}>
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  variant="button"
                  gutterBottom
                >
                  {`KNOWLEDGE RETRIEVAL COMPONENTS`}
                </Typography>
                {projectRole && projectRole?.knowledge_retrieval?.create && (
                  <Button
                    sx={{ marginLeft: 'auto', textTransform: 'none' }}
                    color="primary"
                    onClick={() =>
                      navigateToComponentTemplate('KNOWLEDGE_RETRIEVAL')
                    }
                  >
                    + New
                  </Button>
                )}
              </Box>
              {projectRole && projectRole?.knowledge_retrieval?.view && (
                <Grid container spacing={2}>
                  {apiProjectData &&
                    renderKnowledgeRetrieval.map((item: any) => {
                      return (
                        <Grid key={`recent_${item.sNo}`} item md={3} sm={6}>
                          <CardDetailsMolecule
                            label={`${item.component_name}`}
                            subLabel={`Last updated ${changeDateFormat(
                              item.last_updated_date,
                            )}`}
                            onClick={() =>
                              navigateToDataPipeline(
                                item.id,
                                item.component_type,
                              )
                            }
                            version="v2"
                            handleDetails={(e) => {
                              // details drawer
                            }}
                            icon={<KnowledgeRetrieval />}
                            // data={{ type: 'component' }}
                          ></CardDetailsMolecule>
                        </Grid>
                      )
                    })}
                  {filteredKnowledgeRetrieval.length > 3 && (
                    <Grid item md={3} sm={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          sx={{
                            width: '100px',
                            height: '100px',
                            textTransform: 'capitalize',
                          }}
                          onClick={handleViewAllKnowledgeRetrieval}
                        >
                          {!showAllKnowledgeRetrieval ? 'See All' : 'Sell Less'}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
              {projectRole &&
                projectRole?.knowledge_retrieval?.view &&
                (!renderKnowledgeRetrieval ||
                  renderKnowledgeRetrieval.length === 0) && (
                  <NoRecordsFoundComponent msg="No components created yet" />
                )}
              {unauthorized && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              {projectRole && !projectRole?.knowledge_retrieval?.view && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box sx={{ display: 'flex', alignItems: 'baseline', py: 2 }}>
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  variant="button"
                  gutterBottom
                >
                  {`Data Quality Pipeline`}
                </Typography>
                {projectRole && projectRole?.dq_pipeline?.create && (
                  <Button
                    sx={{ marginLeft: 'auto', textTransform: 'none' }}
                    color="primary"
                    onClick={() => navigateToComponentTemplate('DQ_PIPELINE')}
                  >
                    + New
                  </Button>
                )}
              </Box>
              {projectRole && projectRole?.dq_pipeline?.view && (
                <Grid container spacing={2}>
                  {apiProjectData &&
                    renderDQPipeline.map((item: any) => {
                      return (
                        <Grid key={`recent_${item.sNo}`} item md={3} sm={6}>
                          <CardDetailsMolecule
                            label={`${item.component_name}`}
                            subLabel={`Last updated ${changeDateFormat(
                              item.last_updated_date,
                            )}`}
                            onClick={() =>
                              navigateToDataPipeline(
                                item.id,
                                item.component_type,
                              )
                            }
                            version="v2"
                            icon={<DqPipeline />}
                            handleDetails={(e) => {
                              // details drawer
                            }}
                            // data={{ type: 'component' }}
                          ></CardDetailsMolecule>
                        </Grid>
                      )
                    })}
                  {filteredDQPipeline.length > 3 && (
                    <Grid item md={3} sm={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          sx={{
                            width: '100px',
                            height: '100px',
                            textTransform: 'capitalize',
                          }}
                          onClick={handleViewAllDQPipeline}
                        >
                          {!showAllDQPipeline ? 'See All' : 'Sell Less'}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
              {projectRole &&
                projectRole?.dq_pipeline?.view &&
                (!renderDQPipeline || renderDQPipeline.length === 0) && (
                  <NoRecordsFoundComponent msg="No components created yet" />
                )}
              {unauthorized && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              {projectRole && !projectRole?.dq_pipeline?.view && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box sx={{ display: 'flex', alignItems: 'baseline', py: 2 }}>
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  variant="button"
                  gutterBottom
                >
                  {`MODEL TRAINING PIPELINES`}
                </Typography>
                {projectRole && projectRole?.model_training?.create && (
                  <Button
                    sx={{ marginLeft: 'auto', textTransform: 'none' }}
                    color="primary"
                    onClick={() =>
                      navigateToComponentTemplate('MODEL_TRAINING')
                    }
                  >
                    + New
                  </Button>
                )}
              </Box>
              {projectRole && projectRole?.model_training?.view && (
                <Grid container spacing={2}>
                  {apiProjectData &&
                    renderModelTraining.map((item: any) => {
                      return (
                        <Grid key={`recent_${item.sNo}`} item md={3} sm={6}>
                          <CardDetailsMolecule
                            label={`${item.component_name}`}
                            subLabel={`Last updated ${changeDateFormat(
                              item.last_updated_date,
                            )}`}
                            onClick={() =>
                              navigateToDataPipeline(
                                item.id,
                                item.component_type,
                              )
                            }
                            version="v2"
                            icon={<ModelTraining />}
                            handleDetails={(e) => {
                              // details drawer
                            }}
                            // data={{ type: 'component' }}
                          ></CardDetailsMolecule>
                        </Grid>
                      )
                    })}
                  {filteredModelTraining.length > 3 && (
                    <Grid item md={3} sm={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          sx={{
                            width: '100px',
                            height: '100px',
                            textTransform: 'capitalize',
                          }}
                          onClick={handleViewAllModelTraining}
                        >
                          {!showAllModelTraining ? 'See All' : 'Sell Less'}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
              {projectRole &&
                projectRole?.model_training?.view &&
                (!renderModelTraining || renderModelTraining.length === 0) && (
                  <NoRecordsFoundComponent msg="No components created yet" />
                )}
              {unauthorized && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              {projectRole && !projectRole?.model_training?.view && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}

              {/* <Divider sx={{ mt: 2, mb: 2 }} />
              <Box sx={{ display: 'flex', alignItems: 'baseline', py: 2 }}>
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  variant="button"
                  gutterBottom
                >
                  {`STREAMING DATA PIPELINE`}
                </Typography>
                {projectRole && projectRole?.model_training?.create && (
                  <Button
                    sx={{ marginLeft: 'auto', textTransform: 'none' }}
                    color="primary"
                    onClick={() =>
                      navigateToComponentTemplate('STREAMING_DATA_PIPELINE')
                    }
                  >
                    + New
                  </Button>
                )}
              </Box>
              {projectRole && projectRole?.model_training?.view && (
                <Grid container spacing={2}>
                  {apiProjectData &&
                    renderStreamingDataPipeline.map((item: any) => {
                      return (
                        <Grid key={`recent_${item.sNo}`} item md={3} sm={6}>
                          <CardDetailsMolecule
                            label={`${item.component_name}`}
                            subLabel={`Last updated ${changeDateFormat(
                              item.last_updated_date,
                            )}`}
                            onClick={() =>
                              navigateToDataPipeline(
                                item.id,
                                item.component_type,
                              )
                            }
                            version="v2"
                            icon={<ModelTraining />}
                            handleDetails={(e) => {
                              // details drawer
                            }}
                            // data={{ type: 'component' }}
                          ></CardDetailsMolecule>
                        </Grid>
                      )
                    })}
                  {filteredStreamingDataPipeline.length > 3 && (
                    <Grid item md={3} sm={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          sx={{
                            width: '100px',
                            height: '100px',
                            textTransform: 'capitalize',
                          }}
                          onClick={handleViewAllStreamingDataPipeline}
                        >
                          {!showAllModelTraining ? 'See All' : 'Sell Less'}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
              {projectRole &&
                projectRole?.model_training?.view &&
                (!renderStreamingDataPipeline ||
                  renderStreamingDataPipeline.length === 0) && (
                  <NoRecordsFoundComponent msg="No components created yet" />
                )}
              {unauthorized && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )}
              {projectRole && !projectRole?.model_training?.view && (
                <NoRecordsFoundComponent msg="You are not authorized to view this section" />
              )} */}
            </Container>
          )}
        </Container>
      </Box>
      <LoaderComponent open={isLoading} />
    </>
  )
}
export default DataPipelineList
