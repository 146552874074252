/* eslint-disable no-unused-vars */
import { Box, Typography } from '@mui/material'
import { dataSourceService } from 'api/services'
import FileExplorerComponent from 'components/components/FileExplorer'
import React, { useCallback, useEffect, useState } from 'react'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import { Close } from '@mui/icons-material'
import { decryptSettings } from 'utility/enc-dec'
import Dialog from 'components/molecules/Dialog'
import DocumentPreview from './DocumentPreview'
import { removeProfileInfo, removeToken } from 'api/apiUtility'
import { useNavigate } from 'react-router-dom'

const STATUS = {
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
}

function getFileTypeFromUrl(fileUrl: string) {
  const baseUrl = fileUrl.split('?')[0]
  const fileName = baseUrl.split('/').pop()
  const fileType = `.${fileName?.split('.').pop()}`
  return fileType
}

const excel: any = [
  'xlsx',
  'xlsm',
  'xlsb',
  'xltx',
  'xltm',
  'xls',
  'xlt',
  'xml',
  'xlam',
  'xla',
  'xlw',
  'xlr',
]

const RepositoryFileExplorer = (props: any) => {
  const { metadata, onClose } = props
  const [fileExplorerData, setFileExplorerData] = useState<any>([])
  const [absolutePath, setAbsolutePath] = useState<any>()
  const [loadingStatus, setLoadingStatus] = useState<any>({
    status: STATUS.LOADING,
  })
  const [prefixValue, setPrefixValue] = useState('')
  // pdf file
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [fileURL, setFileURL] = useState('')
  const [fileType, setFileType] = useState<any>('')
  //json file
  const [jsonData, setJsonData] = useState<any>()

  const handleCloseDialog = useCallback(() => {
    setOpenFilePreview(!openFilePreview)
  }, [openFilePreview])
  const navigate = useNavigate()
  const getDataRepositoryFileExplorer = useCallback(async (args: any) => {
    const { id, prefix = '' } = args
    setPrefixValue(prefix)
    const payload = {
      data_repository_id: id,
      prefix,
      page_size: 100,
      index: 0,
    }
    try {
      setLoadingStatus({
        status: STATUS.LOADING,
      })
      const response =
        await dataSourceService.get_data_repository_file_explorer(payload)
      setFileExplorerData(response.data)

      setLoadingStatus({
        status: STATUS.SUCCESS,
      })
    } catch (error: any) {
      console.log(error)
      setLoadingStatus({
        status: STATUS.ERROR,
      })
    }
  }, [])

  useEffect(() => {
    if (metadata) {
      const decryptedConfiguration = decryptSettings(
        metadata?.configuration?.input,
      )
      const absolutePath = decryptedConfiguration?.find(
        (item: Record<string, string>) =>
          item.key === 'directory_absolute_path',
      )?.value
      const prefix = absolutePath
        ? `${absolutePath}${absolutePath.endsWith('/') ? '' : '/'}`
        : ''

      getDataRepositoryFileExplorer({
        id: metadata.id,
        prefix,
      })

      setAbsolutePath(prefix)
    }
  }, [metadata, getDataRepositoryFileExplorer])

  const onClickFolder = useCallback(
    (prefix: string) => {
      getDataRepositoryFileExplorer({ id: metadata.id, prefix })
    },
    [metadata, getDataRepositoryFileExplorer],
  )

  const getFilePreSignedUrl = useCallback(
    async (payload: Record<string, string>) => {
      try {
        setLoadingStatus({
          status: STATUS.LOADING,
        })
        // const response =
        //   await dataSourceService.get_file_pre_signed_url(payload)
        // setLoadingStatus({
        //   status: STATUS.SUCCESS,
        // })
        // const fileUrl = response?.data?.url

        // if (fileUrl) {
        //   const fileType = getFileTypeFromUrl(fileUrl)
        //   const url = `${window.location.origin}/${PAGE_ROUTE_CONSTANTS.FileViewer}?fileUrl=${fileUrl}&fileType=${fileType}`
        //   window.open(url, '_blank')
        // }
        const filePath = payload?.file_name

        // Split the file path by the dot and pop the last element to get the extension
        const fileExtension = filePath.split('.').pop()
        setFileType(fileExtension)

        if (fileExtension === 'pdf') {
          const response: any = await dataSourceService.get_file_blob_data(
            payload,
            { responseType: 'arraybuffer' },
          )
          setLoadingStatus({ status: STATUS.SUCCESS })
          const arrayBuffer = response.data
          const blob = new Blob([arrayBuffer], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          // const link = document.createElement('a')
          // link.href = url
          // link.download = 'downloaded.pdf'
          // link.click()

          // // Open the file in a new tab
          // window.open(url, '_blank')

          // // Optionally revoke the object URL to free up memory
          // window.URL.revokeObjectURL(url)

          // setFileURL(url)
          // setOpenFilePreview(true)

          if (url) {
            const fileType = fileExtension
            const browserUrl = `${window.location.origin}/${PAGE_ROUTE_CONSTANTS.FileViewer}?fileUrl=${url}&fileType=${fileType}`
            window.open(browserUrl, '_blank')
          }
        } else if (fileExtension === 'json') {
          const response: any =
            await dataSourceService.get_file_blob_data(payload)
          setLoadingStatus({ status: STATUS.SUCCESS })
          setJsonData(response.data)
          setOpenFilePreview(true)
        } else if (excel.includes(fileExtension)) {
          const response: any = await dataSourceService.get_file_blob_data(
            payload,
            { responseType: 'arraybuffer' },
          )
          setLoadingStatus({ status: STATUS.SUCCESS })
          const arrayBuffer = response.data
          const blob = new Blob([arrayBuffer])
          const url = URL.createObjectURL(blob)

          // setFileURL(url)
          // setOpenFilePreview(true)

          if (url) {
            const fileType = fileExtension
            const browserUrl = `${window.location.origin}/${PAGE_ROUTE_CONSTANTS.FileViewer}?fileUrl=${url}&fileType=${fileType}`
            window.open(browserUrl, '_blank')
          }
        } else {
          const response: any = await dataSourceService.get_file_blob_data(
            payload,
            { responseType: 'arraybuffer' },
          )
          setLoadingStatus({ status: STATUS.SUCCESS })
          const arrayBuffer = response.data
          const blob = new Blob([arrayBuffer])
          const url = URL.createObjectURL(blob)

          // setFileURL(url)
          // setOpenFilePreview(true)

          if (url) {
            const fileType = fileExtension
            const browserUrl = `${window.location.origin}/${PAGE_ROUTE_CONSTANTS.FileViewer}?fileUrl=${url}&fileType=${fileType}`
            window.open(browserUrl, '_blank')
          }
        }
      } catch (error: any) {
        console.log(error)
        setLoadingStatus({
          status: STATUS.ERROR,
        })
      }
    },
    [openFilePreview],
  )

  const onFileClick = useCallback(
    (file: string) => {
      getFilePreSignedUrl({
        data_repository_id: metadata.id,
        file_name: file,
      })
    },
    [metadata, getFilePreSignedUrl],
  )

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '30px',
            padding: '20px',
            paddingTop: '30px',
            position: 'sticky',
            top: 0,
            zIndex: 100,
            // background: 'white',
          }}
        >
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'start' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
              File Explorer
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '30px',
                height: '30px',
                background: 'red',
              }}
            >
              <Close
                sx={{
                  color: 'white',
                  cursor: 'pointer',
                  border: '1px solid red',
                }}
                onClick={onClose}
              />
            </Box>
          </Box>
        </Box>
        <FileExplorerComponent
          fileData={fileExplorerData?.dataset}
          loadingStatus={loadingStatus}
          prefixValue={prefixValue}
          setPrefixValue={setPrefixValue}
          onClickFolderText={onClickFolder}
          onFileClick={onFileClick}
          absolutePath={absolutePath}
        />
        <Dialog
          maxWidth="xl"
          open={openFilePreview}
          handleClose={handleCloseDialog}
        >
          {fileType && fileType === 'pdf' && (
            <DocumentPreview onClose={handleCloseDialog}>
              <object
                data={fileURL}
                type="application/pdf"
                width="900px"
                height="780px"
              />
            </DocumentPreview>
          )}
          {fileType && fileType === 'json' && (
            <DocumentPreview onClose={handleCloseDialog}>
              <pre
                style={{
                  whiteSpace: 'pre-wrap', // This property enables wrapping of text within the <pre> tag
                  wordWrap: 'break-word', // This allows words to break at arbitrary points to prevent overflow
                  background: '#f5f5f5',
                  padding: '10px',
                  borderRadius: '5px',
                  maxWidth: '100%', // Ensures it doesn’t exceed the container’s width
                  overflowX: 'auto', // If the content still overflows, a scrollbar appears
                }}
              >
                {JSON.stringify(jsonData, null, 2)}
              </pre>
            </DocumentPreview>
          )}
          {fileType && excel.includes(fileType) && (
            <DocumentPreview onClose={handleCloseDialog}>
              <iframe
                width="900px"
                height="800px"
                frameBorder="0"
                scrolling="no"
                src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                  fileURL,
                )}`}
              ></iframe>
            </DocumentPreview>
          )}
        </Dialog>
      </Box>
    </>
  )
}

export default RepositoryFileExplorer
