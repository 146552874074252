import React, { useCallback, useMemo } from 'react'
import lodash from 'lodash'
import { Stack } from '@mui/material'
import Text from 'components/atoms/Text'
import { DynamicFormProps } from 'types/props-types'
import { REGEX_EXPRESSION } from 'utility/constants'

function DynamicParams({
  formData,
  handleChange,
  type,
  error,
  setError,
}: DynamicFormProps) {
  // const { formData, handleChange } = prop
  const state = useMemo(() => lodash.cloneDeep(formData), [formData])

  const handleTextChange = useCallback(
    (event: any) => {
      const index = state?.findIndex(
        (item: any) => item?.key === event?.target?.name,
      )
      const inputValue = event?.target?.value

      if (state[index]?.type && state[index].type === 'integer') {
        if (inputValue === '' || REGEX_EXPRESSION.INTEGER.test(inputValue)) {
          state[index].value = event?.target?.value
          handleChange(state)
          setError?.({ ...error, [state[index].key]: '' })
        }
      } else if (state[index]?.type && state[index].type === 'float') {
        if (inputValue === '' || REGEX_EXPRESSION.FLOAT.test(inputValue)) {
          state[index].value = event?.target?.value
          handleChange(state)
          setError?.({ ...error, [state[index].key]: '' })
        }
      } else {
        state[index].value = event?.target?.value
        handleChange(state)
        setError?.({ ...error, [state[index].key]: '' })
      }
    },
    [formData, type],
  )

  return (
    <Stack gap={'16px'}>
      {formData &&
        formData
          ?.filter((item: any) => item.allowDisplay !== false)
          .map((item: any, index: number) => {
            if (item?.fieldType === 'TEXTINPUT') {
              return (
                <Text
                  key={index}
                  label={
                    item?.isRequired
                      ? item?.label + ` (only ${item.type}) *`
                      : item?.label + ` (only ${item.type}) `
                  }
                  value={item?.value}
                  name={item?.key}
                  onChange={handleTextChange}
                  error={error}
                  disabled={type === 'view'}
                />
              )
            }
          })}
    </Stack>
  )
}

export default DynamicParams
