/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import {
  Folder,
  Search,
  Settings,
  Help,
  Person,
  GridView,
  AdminPanelSettings,
  RocketLaunch,
  Logout,
  ExpandLess,
  ExpandMore,
  MiscellaneousServices,
} from '@mui/icons-material'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import { styled } from '@mui/system'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import { useNavigate } from 'react-router-dom'
import { getProfileInfo, removeProfileInfo, removeToken } from 'api/apiUtility'
import { useTheme as useThemeContext } from 'ThemeContext'
// const getQueryParam = (param: string) => {
//   const queryParams = new URLSearchParams(window.location.search)
//   return queryParams.get(param)
// }
const drawerWidth = 60 // Initial collapsed width
const expandedDrawerWidth = 240 // Expanded width on hover

const SIDE_HEADER_PAGES = [
  {
    title: 'Repositories',
    route: PAGE_ROUTE_CONSTANTS.DATA_SOURCE,
    icon: <GridView />,
  },
  {
    title: 'Projects',
    route: PAGE_ROUTE_CONSTANTS.PROJECTS,
    icon: <Folder />,
  },
  // {
  //   title: 'Admin',
  //   route: PAGE_ROUTE_CONSTANTS.ADMIN,
  //   icon: <AdminPanelSettings />,
  // },
  {
    title: 'Apps',
    route: PAGE_ROUTE_CONSTANTS.APPS_PAGE,
    icon: <RocketLaunch />,
  },
  // {
  //   title: 'Org Preference',
  //   route: PAGE_ROUTE_CONSTANTS.ORGPREFERENCE,
  //   icon: <AppRegistrationIcon />,
  // },
  {
    title: 'Operations',
    route: PAGE_ROUTE_CONSTANTS.OPERATIONS_PAGE,
    icon: <MiscellaneousServices />,
  },
  // {
  //   title: 'Custom Function',
  //   route: PAGE_ROUTE_CONSTANTS.CUSTOMFUNCTIONS,
  //   icon: <RocketLaunch />,
  // },
  // {
  //   title: 'Custom Function',
  //   route: PAGE_ROUTE_CONSTANTS.CUSTOMFUNCTIONS,
  //   icon: <RocketLaunch />,
  // },

  // {
  //   title: 'SuperAdmin',
  //   route: PAGE_ROUTE_CONSTANTS.SUPERADMIN,
  //   icon: <AdminPanelSettings />,
  // },
]
const SUPERADMIN_SIDE_HEADER_PAGES = [
  {
    title: 'Dashboard',
    route: PAGE_ROUTE_CONSTANTS.SUPERADMINDASHBOARD,
    icon: <GridView />,
  },
  {
    title: 'Organizations',
    route: PAGE_ROUTE_CONSTANTS.SUPERADMIN,
    icon: <AdminPanelSettings />,
  },
]

const SidebarContainer = styled('div')(({ theme }: any) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  //   height: '100vh',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  '&:hover': {
    width: expandedDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}))

const LeftSideHeader: React.FC = () => {
  const navigate = useNavigate()
  const profileInfo = getProfileInfo()

  const theme = useTheme()
  const [allUserRules, setAllUserRules] = useState<any>()
  const [headerMenu, setHeaderMenu] = useState<any>([])
  const [SuperAdminheaderMenu, setSuperAdminHeaderMenu] = useState<any>([])
  const [isMouseEnter, setMouseEnter] = useState(false)
  const { isDarkMode, handleThemeChange } = useThemeContext()
  const [openUserMenu, setOpenUserMenu] = useState(false)
  const id = window.location.href.includes('sysadmin') ? '123' : null
  const iconStyle = {
    transition: 'transform 0.3s ease-in-out',
    transform: isDarkMode ? 'rotate(180deg)' : 'rotate(0deg)',
    color: isDarkMode ? 'inherit' : 'inherit',
    // fontSize: 'small',
  }

  useEffect(() => {
    const profileInfoRoles = profileInfo
    if (profileInfoRoles && !allUserRules) {
      setAllUserRules(profileInfoRoles?.orgRole?.permissions)
    }
    setTimeout(() => {
      setHeaderMenu(SIDE_HEADER_PAGES)
      setSuperAdminHeaderMenu(SUPERADMIN_SIDE_HEADER_PAGES)
    }, 1100)
  }, [profileInfo, headerMenu, allUserRules, SuperAdminheaderMenu])

  const handleLogout = useCallback(() => {
    removeToken()
    removeProfileInfo()
    navigate('/login')
  }, [navigate])

  const checkUserPermission = useCallback(
    (pageTitle: string) => {
      if (pageTitle === 'Repositories') {
        if (
          !allUserRules?.repository_config?.view &&
          !allUserRules?.data_quality_metrics?.view &&
          !allUserRules?.data_quality_checks?.view &&
          !allUserRules?.manage_llm_model_instance?.view
        ) {
          return true
        } else {
          return false
        }
      }
      if (pageTitle === 'Projects') {
        return !allUserRules?.projects?.view
      }
      if (pageTitle === 'Admin') {
        if (
          !allUserRules?.users?.view &&
          !allUserRules?.role_permissions?.view &&
          !allUserRules?.org_member_roles?.view &&
          !allUserRules?.project_member_roles?.view &&
          !allUserRules?.app_member_roles?.view
        ) {
          return true
        } else {
          return false
        }
      }
      if (pageTitle === 'Apps') {
        return !allUserRules?.apps?.view
      }
      if (pageTitle === 'Operations') {
        return !allUserRules?.operations?.view
      }
      // if (pageTitle === 'Settings') {
      //   return !allUserRules?.org_system_preferences?.view
      // }
    },
    [allUserRules],
  )

  const handleCloseNavMenu = useCallback(
    (page: string) => {
      navigate(`/${page}`)
    },
    [navigate],
  )

  return (
    <SidebarContainer>
      <Drawer
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => {
          setMouseEnter(false)
          setOpenUserMenu(false)
        }}
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            marginTop: theme.spacing(8.1),
            height: '92vh !important',
            boxSizing: 'border-box',
            backgroundColor: theme.palette.background.paper,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            '&:hover': {
              width: expandedDrawerWidth,
              overflowX: 'hidden',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            },
          },
        }}
      >
        {!id ? (
          <List>
            {/* <ListItemButton>
              {!isMouseEnter && (
                <ListItemIcon>
                  <Search />
                </ListItemIcon>
              )}
              <TextField size="small" placeholder="Search data, files, etc." />
            </ListItemButton> */}
            {headerMenu.map((page: any) => (
              <ListItemButton
                key={page.title}
                onClick={() => handleCloseNavMenu(page.route)}
                disabled={checkUserPermission(page.title)}
              >
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText sx={{ fontWeight: '600' }} primary={page.title} />
              </ListItemButton>
            ))}
          </List>
        ) : (
          <List>
            {SuperAdminheaderMenu.map((page: any) => (
              <ListItemButton
                key={page.title}
                onClick={() => handleCloseNavMenu(page.route)}
                disabled={checkUserPermission(page.title)}
              >
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText sx={{ fontWeight: '600' }} primary={page.title} />
              </ListItemButton>
            ))}
          </List>
        )}
        <div style={{ flexGrow: 1 }} />
        <List style={{ marginBottom: '40px' }}>
          <ListItemButton onClick={handleThemeChange}>
            <ListItemIcon>
              {isDarkMode ? (
                <Brightness4Icon style={iconStyle} />
              ) : (
                <Brightness7Icon style={iconStyle} />
              )}
            </ListItemIcon>
            <ListItemText primary="Dark/Normal Mode" />
          </ListItemButton>
          {/* <ListItemButton>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText primary="Help" />
          </ListItemButton> */}
          <ListItemButton onClick={() => navigate('/settings')} disabled={!allUserRules?.org_system_preferences?.view}>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>

            <ListItemText primary="Settings" />
          </ListItemButton>

          <ListItemButton onClick={() => navigate('/admin')}>
            <ListItemIcon>
              <AdminPanelSettings />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItemButton>
          <ListItemButton onClick={() => setOpenUserMenu(!openUserMenu)}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <Tooltip title={profileInfo?.username}>
              <Typography
                noWrap
                sx={{ width: '200px', wordWrap: 'break-word' }}
              >
                {profileInfo?.username}
              </Typography>
            </Tooltip>
            {openUserMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openUserMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} onClick={() => handleLogout()}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Drawer>
    </SidebarContainer>
  )
}

export default LeftSideHeader
