import React from 'react'
import { Box } from '@mui/material'
import infinitixImage from 'assets/img/infinitix.jpg'
const LandingImage = () => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={infinitixImage} alt={infinitixImage} width={'600px'} />
    </Box>
  )
}
export default LandingImage
