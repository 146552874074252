/* eslint-disable no-unused-vars */
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import CardDetailsMolecule from 'components/molecules/card-details'
import React, { useCallback, useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import { PAGE_ROUTE_CONSTANTS } from 'utility/constants'
import { ApiServices } from 'api/services'
import LoaderComponent from 'components/atoms/loader-component'
import { changeDateFormat } from 'utility/utility'
import NoRecordsFoundComponent from 'components/atoms/no-record'
import { getProfileInfo, removeProfileInfo, removeToken } from 'api/apiUtility'

const AllAppsList = () => {
  const [isLoading, setLoading] = useState(false)
  const [recentAppsData, setRecentAppData] = useState<any>()

  const navigate = useNavigate()

  const callRecentAppsDataApi = useCallback(async () => {
    const params = {
      search: {
        org_id: getProfileInfo().teamId,
        project_type: 'APP',
        search: '',
      },
    }
    try {
      setLoading(true)
      const resp = await ApiServices.getProjectsAndApps(params)
      setLoading(false)
      setRecentAppData(resp.data.data)
    } catch (error: any) {
      setLoading(false)
      console.log('error', error)
    }
  }, [])

  useEffect(() => {
    callRecentAppsDataApi()
  }, [callRecentAppsDataApi])

  const navigateToDataPipelineList = useCallback(
    (id: string) => {
      navigate(
        `/${PAGE_ROUTE_CONSTANTS.APPS_PAGE}/${PAGE_ROUTE_CONSTANTS.APPS_REPORT_PAGE}?projectId=${id}`,
      )
    },
    [navigate],
  )

  return (
    <>
      <Box>
        <Container sx={{ mt: 2, paddingBottom: 2 }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Container>
            <Box sx={{ mb: 2 }}>
              <Typography
                sx={{ fontWeight: 'bold' }}
                variant="button"
                gutterBottom
              >
                {`All Your Reports`}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {recentAppsData &&
                recentAppsData.map((item: any) => {
                  return (
                    <Grid key={`recent_${item.sNo}`} item md={4} sm={6}>
                      <CardDetailsMolecule
                        label={`${item.project_name}`}
                        subLabel={`Last updated ${changeDateFormat(
                          item.last_updated_date,
                        )}`}
                        version="v2"
                        onClick={() => navigateToDataPipelineList(item.id)}
                        handleDetails={(e) => {
                          // details drawer
                        }}
                      ></CardDetailsMolecule>
                    </Grid>
                  )
                })}
            </Grid>
            {recentAppsData && recentAppsData.length === 0 && (
              <NoRecordsFoundComponent />
            )}
            <Divider sx={{ mt: 2 }} />
          </Container>
        </Container>
      </Box>
      <LoaderComponent open={isLoading} />
    </>
  )
}

export default AllAppsList
