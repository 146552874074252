import { blue } from '@mui/material/colors'
import { getNodeBackgroundColorForKnowledgeGraph } from 'pages/apps/apps-utility/utility'
import { truncateText } from 'utility/utility'

export const style: any = [
  {
    selector: 'node',
    style: {
      'background-color': function (element: any) {
        const nodeType = element.data('type')
        return getNodeBackgroundColorForKnowledgeGraph(nodeType)
      },
      label: function (element: any) {
        const name = element.data('name')
        return truncateText(name)
      },
      'font-size': 7,
      'text-valign': 'bottom',
      'text-halign': 'center',
      'text-wrap': 'wrap',
      'text-max-width': 50,
      'text-overflow-wrap': 'anywhere',
      width: 25,
      height: 25,
      'border-width': '1px',
    },
  },
  {
    selector: 'edge',
    style: {
      width: 1,
      'line-color': blue[900],
      'target-arrow-color': blue[900],
      'target-arrow-shape': 'triangle',
      'source-arrow-shape': 'circle',
      'source-arrow-color': blue[900],
      'curve-style': 'bezier',
    },
  },
]
